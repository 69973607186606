angular.module('core').directive('onscScrollIntoView', () => {
  return {
    restrict: 'A',
    link: (_$scope, $element) => {
      const element = $element[0];
      DEBUG: console.info('core/directive/scroll-into-view', element);
      element.scrollIntoView({inline: 'center', block: 'center', behavior: 'smooth'});
    },
  };
});
