// @ts-nocheck
angular.module('core')

  .factory('HttpCache', ['http', '$q', (http, $q) => {

    const TIMEOUT = 60000;

    function getExpireTimeFromHeader(res) {
    //const date = res.headers('date');
      if (!res || !res.headers) return 0;
      const maxAge = (res.headers('cache-control') || '').match(/(\d+)/);
      //return date && maxAge ? Date.parse(date) + 1000 * maxAge[0] : 0;
      return maxAge ? Date.now() + 1000 * maxAge[0] : 0;
    }

    class HttpCache
    {
      entries = new Map();
      minExpire = 0;
      errorExpire = 0;
      fnRequest = null;
      fnResolve = null;
      fnError = null;
      prefix = '';
      useCdn = false;

      constructor(config = {}) {
        this.fnError = err => {throw err;};
        this.fnResolve = res => res.data;
        this.fnRequest = id => http(this.useCdn).get(this.prefix + id, { dontIntercept: true });
        Object.assign(this, config);
      }

      set(id, expire, result) {
        this.entries.set(id, [expire, $q.resolve(result)]);
        return result;
      }

      clear() {
        this.entries.clear();
      }

      delete(id) {
        this.entries.delete(id);
      }

      get(id, fnRequest = null, fnResolve = null, fnError = null) {
        const entry = this.entries.get(id) || [0, null];

        if (entry[0] <= Date.now()) {
          entry[0] = Date.now() + TIMEOUT;
          const stale = entry[1];
          entry[1] = (fnRequest || this.fnRequest)(id)
            .catch(err => (fnError || this.fnError)(err, id))
            .then(res => {
              if (res) {
                res.expire = Math.max(Date.now() + this.minExpire, getExpireTimeFromHeader(res));
                entry[0] = res.expire;
              }
              return res;
            })
            .then(res => (fnResolve || this.fnResolve)(res, stale))
            .catch(err => {
              entry[0] = Date.now() + this.errorExpire;
              DEBUG: console[err.status < 500 ? 'warn' : 'error']('HttpCache ' + this.prefix + id, { err, cache: this });
              throw err;
            });
          this.entries.set(id, entry);
        }

        return entry[1];
      }
    }

    return HttpCache;
  }]);
