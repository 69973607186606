import angular from 'angular';
import {RootScope} from 'acng/zz-app';

interface InjectableMap {
  $translate: angular.translate.ITranslateService;
  http: core.Http;
  $rootScope: RootScope;
  $compile: angular.ICompileService;
  Amateur: import('acng/amateurPool/factory/Amateur').AmateurConstructor;
  AmateurFilter: import('acng/amateurPool/factory/Filter').FilterConstructor;
  user: import('acng/userPool/factory/user').User;
  onsOverlay: import('./overlay').OverlayConstructor;
  sock: core.Sock;
  payment: import('acng/payment/factory/payment').Payment;
  Payback: import('acng/payment/factory/payback').IPayback;
  fskTransFilter: import('acng/userPool/0fsk').Filter;
  Movie: import('acng/moviePool/factory/Movie').IMovieConstructor;
  PictureSet: import('acng/picturePool/factory/PictureSet').IPictureSetConstructor;
  Picture: import('acng/picturePool/factory/Picture').IPictureConstructor;
  Livecam: import('acng/livecam/factory/livecam').LivecamConstructor;
  Otm: import('acng/amateurPool/factory/Otm').IOtmFactory;
  price: core.price;
  $location: angular.ILocationService;
  $animate: angular.animate.IAnimateService;
  friends: any;
  Hotornot: any;
  logger: any;
  onsLaforgeEvents: any;
  dateFilter: angular.IFilterDate;
  $sanitize: any;
  games: import("acng/games/factory/games").GamesService
}

const cache = new Map<string, any>();

export function inject<K extends keyof InjectableMap>(injectable: K): InjectableMap[K] {
  let service = cache.get(injectable);
  if (!service) {
    service = angular.element(document).injector().get(injectable);
    cache.set(injectable, service);
  }
  return service;
}

export function isInjectable(injectable: string) {
  return angular.element(document).injector().has(injectable);
}

/**
 * @deprecated
 */
export const ngDigest = () => inject('$rootScope').$digest();

export const $compile = '$compile';
