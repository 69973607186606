angular
  .module('userPool')

  .directive('onswUserDescription', [
    'Widget',
    'User',
    (Widget, User) => ({
      scope: {},
      template: `
        <form class="box" style="position:relative" ng-submit="submit()">
          <label>
            <span translate="userPool.description"></span>
            <textarea name="description" rows="6" ng-model="description" ng-blur="submit()"></textarea>
          </label>
        </form>`,
      link: (scope, element) => {
        const widget = new Widget(scope, element, 'ons-form');
        scope.changed = false;

        widget.busy(User.getProfile()).then((res) => {
          element.addClass('ready');
          scope.description = res.data.description;
        });

        scope.submit = () => {
          widget
            .ifNotBusy(() => User.setDescription(scope.description))
            .then(() => () => (scope.changed = false))
            .catch((err) => widget.notify(err));
        };
      },
    }),
  ]);
