import {cdn, get, post} from 'acng/core/service/backend.js';
import {inject} from 'acng/core/service/ng';

/**
 * @typedef PackageData
 * @property {string} name
 * @property {number} points
 * @property {number} coins
 */

/**
 * @typedef UserAchievementData
 * @property {string} created_at
 * @property {number} value
 * @property {AchievementData} achievement
 */

/**
 * @typedef AchievementData
 * @property {number} id
 * @property {number} value
 * @property {string} name
 */

/**
 * @param {string} name
 * @param {HTMLElement} [element]
 * @returns {Promise<AchievementData>}
 */
export const getAchievement = async (name, element) => cdn(`achievement/${name}`, element);

/**
 * @param {HTMLElement} [element]
 * @returns {Promise<UserAchievementData[]>}
 */
export const getHistory = async (element) => get('achievements/latest', element);

/**
 * @param {HTMLElement} [element]
 * @returns {Promise<PackageData[]>}
 */
export const getPackages = async (element) => cdn('achievements/packages', element);

/**
 * @param {string} name
 * @param {HTMLElement} [element]
 * @returns {Promise<PackageData | null>}
 */
export const redeemPackage = async (name, element) =>
  post('achievements/redeemCoins', {name}, element, async (error) => {
    if (error.code === 4002) {
      inject('payment').overlay('payment.buyReasonPoints');
      return null;
    } else {
      throw error;
    }
  });
