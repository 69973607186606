// @ts-nocheck
export const //
  type = 'factory',
  name = 'PayttachmentTile',
  param = factory;
import angular from 'angular';
import {player} from 'acng/core/factory/flowplayer';
import {createVideo, append} from '@acng/frontend-bounty';
factory.$inject = ['AmateurTile', 'payttachment', '$compile', '$translate'];
/**
 * @param {*} AmateurTile -
 * @param {import('../factory/payttachment').PayttachmentConstructor} payttachment -
 * @param {angular.ICompileService} $compile -
 * @param {angular.translate.ITranslateService} $translate
 * @returns {*} -
 */
function factory(AmateurTile, payttachment, $compile, $translate) {
  /** */
  return class extends AmateurTile {
    load(scope, id) {
      return payttachment
        .get(id)
        .then(p => {
          scope.fn = scope.fnMain = scope.fnDefault = {movie: 'video', picture: 'picture'}[p.type];
          scope.mediaId = p.id;
          scope.mediaType = 'Payttachment';
          scope.payttachmentType = p.type;
          if (!p.dummy) {
            scope.image = p.thumb;
          }
          return super
            .load(scope, p.amateurId)
            .then(a => [p, a])
            .catch(err => {
              console.warn('Payttachment Tile Amateur error', {err, p});
              return [p, null];
            });
        })
        .then(r => {
          let [p, a] = r;
          scope.title = $translate.instant('messenger.payttachment.' + p.type + '.stock', {nickname: a.getNickname()});
          scope.player = async () => {
            if (p.type == 'picture') {
              $compile('<image-overlay src="' + p.file + '"></image-overlay>')(scope).appendTo(angular.element('body'));
            }
            if (p.type == 'movie') {
              console.info('PayttachmentTile start player');
              const x = angular.element(this.el).find('.video-js');
              if (x.length) {
                x.remove();
                return;
              }
              const video = createVideo();
              append(angular.element(this.el).find('.top')[0], video);
              (await player(video, p.file)).on('click', ev => ev.stopPropagation());
            }
          };
        });
    }
  }
}
