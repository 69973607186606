/**
 * @module acng/privacyPolicy
 */
import angular from 'angular';
import {getCookies} from '@acng/frontend-bounty';

import {Feature} from 'acng/core/model/feature';
import {t} from 'acng/locale/config/translate.js';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate.js';

const featureName = 'privacyPolicy';
const VERBOSE = false;
new Feature(featureName);

// Check for Google Analytics opt-out
const w = /** @type {{[key: string]: any}} */ (/** @type {unknown} */ (window));
for (const key of Object.keys(getCookies())) {
  DEBUG: VERBOSE && console.debug('privacyPolicy check cookie', key);
  if (/^ga-disable-/.test(key)) {
    // opt-out
    w[key] = true;
    console.info('privacyPolicy', key);
  }
}

/**
 * Google Analytics opt-out and set cookies
 * TODO make (non-angularjs) component for that to be used in plain template
 */
w.disableGa = () => {
  if (!w.ga || !w.ga.loaded) return;
  for (const tracker of w.ga.getAll()) {
    const key = `ga-disable-${tracker.get('trackingId')}`;
    // opt-out
    w[key] = true;
    // set cookie
    document.cookie = `${key}=true;max-age=31536000`;
  }
  (async () =>
    w.alert(await t('privacyPolicy.gaDisabled').catch(() => 'Google tracker are disabled now.')))();
};

// Provide Translations for angularjs
angular.module(featureName, [angularTranslate]).config(provideTranslations(featureName));
