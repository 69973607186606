import {body, documentElement} from '@acng/frontend-bounty';
import {createMutex} from '@acng/frontend-bounty/mutex';
import {createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';
import {digest} from '@acng/frontend-stargazer';

import {authUser, startUpLanguage} from 'acng/core/service/env.js';
import {inject} from 'acng/core/service/ng.js';

import {putCountry} from '../service/http.js';
import {rootRoute} from '@acng/frontend-voyager';

export const POSSIBLE_LANGUAGES = /** @type {const} */ (['de', 'en']);

/**
 * @typedef {typeof POSSIBLE_LANGUAGES[number]} Language
 */

export const ctxLocale = createGlobalContext(
  /** @type {Language} */ (authUser?.preferred_language ?? startUpLanguage)
);

const mutex = createMutex(true);

/**
 * @param {Language} countryCode
 */
export const setLocale = async (countryCode) => {
  try {
    await mutex(async () => {
      await putCountry(countryCode, documentElement);
      await useLocale(countryCode);
    });
  } catch (reason) {
    if (reason === mutex) {
      console.warn('locale change already in progress');
    } else {
      throw reason;
    }
  }
};

/**
 * @type {Language | undefined}
 */
let nextLocale;

/**
 * @param {Language} countryCode
 */
export const useLocale = async (countryCode) => {
  if (getLocale() == countryCode) {
    return;
  }
  body.lang = countryCode;
  nextLocale = countryCode;
  digest();
  rootRoute.reload(inject('$location').path());
  inject('$translate').use(countryCode);
  ctxLocale[CTX_PROVIDE](null, countryCode);
};

export const getLocale = () => nextLocale ?? ctxLocale[CTX_VALUE](documentElement);
