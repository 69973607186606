'use strict';

angular.module('core').directive('onsCrop', [function () {
  return {
    restrict: 'C',
    link: function (scope, element) {
      element.on('DOMSubtreeModified', function (event) {
        setTimeout(function () {
          element.toggleClass('cropped', element.find('.box').height() > parseFloat(element.css('maxHeight')));
        });
      });
    }
  };
}]);
