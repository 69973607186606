import {NODES, Widget} from '@acng/frontend-stargazer';
import {
  on,
  addClass,
  removeClass,
  hasClass,
  remove,
  cloneNode,
  append,
  setText,
  setAttribute,
  isUndefined,
} from '@acng/frontend-bounty';

import {achievementFeature} from '../config/feature.js';
import {ctxVipPoints} from '../service/sock.js';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {typeguard} from '@acng/frontend-bounty/typeguard.js';

const MODULE = 'achievement/widget/points';

const CSS_WUMPER = 'wumper';
const CSS_ANIMATION = 'animation';

achievementFeature.defineWidget(
  'onsw-user-points',
  class extends Widget {
    static consumables = [ctxVipPoints];
    render() {
      create(this);
    }
  }
);

/**
 * @param {Widget} element
 */
function create(element) {
  ASSERT: typeguard(
    MODULE,
    element.nodes,
    NODES({
      link: HTMLAnchorElement,
      gain: HTMLSpanElement,
      diff: Text,
      points: Text,
    })
  );

  const {link, gain, points, diff} = element.nodes;

  remove(gain);
  achievementFeature.translate('points').then((text) => setAttribute(link, 'title', text));

  ctxVipPoints[CTX_OBSERVE](element, (value, previous) => {
    removeClass(element, CSS_WUMPER);
    if (isUndefined(previous) || isNaN(previous) || value <= previous || !hasClass(element, CSS_ANIMATION)) {
      // Assign the new points value without "flying points" animation.
      setText(points, `${value}`);
      if (!isUndefined(previous) && value > previous) {
        addClass(element, CSS_WUMPER);
      }
    } else {
      // Start the "flying points" animation
      setText(diff, `${value - previous}`);
      const ani = cloneNode(gain);
      on(ani, 'animationend', () => {
        remove(ani);
        // Assign the new points value to the template when the animation ended.
        setText(points, `${value}`);
        // And wumper, of course
        addClass(element, CSS_WUMPER);
      });
      append(element, ani);
    }
  });

  // The wumper has to be removed.
  on(element, 'transitionend', () => removeClass(element, CSS_WUMPER));
}
