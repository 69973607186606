angular.module('ons-LaForge-client')
  .run(['onsLaforgeEvents', onsLaforgeEvents => {

    class Kiss extends onsLaforgeEvents.LogEvent {

      constructor(recipient, left, messages) {
        super();
        this.type = 'Kiss';
        this.recipient = recipient;
        this.messagesInDialog = messages.filter(msg => msg.payload.type != 'kiss').length;
        this.left = left;
      }

      getDTO() {
        const dto = super.getDTO();
        dto.recipient = this.recipient;
        dto.left = this.left;
        dto.messagesInDialog = this.messagesInDialog;

        return dto;
      }

    }

    onsLaforgeEvents.Kiss = Kiss;

  }]);
