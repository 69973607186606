// @ts-nocheck
import {PictureSet} from '../model/picture-set.js';

angular.module('picturePool')
  .factory('PictureSet', ['http', 'fsk', 'HttpCache', function (http, fsk, HttpCache) {

    const cache = new HttpCache({
      prefix: '/api/picture/set/',
      useCdn: true,
      fnError: (err, id) => {
        if (err.status == 404 || err.status == -1) {
          return http().get('/api/picture/set/blocked/' + id, { dontIntercept: true });
        }
        throw err;
      },
      fnResolve: res => new PictureSet(res.data)
    });
    const hideOldDate = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(); // 3 months

    PictureSet.doNotShow = [];

    PictureSet.isValidId = id => !isNaN(id = parseInt(id)) && id;

    PictureSet.get = function (id) {
      return cache.get(parseInt(id));
    };

    PictureSet.list = function (limit, offset, filter) {
      return http().get('/api/picture/list', {
        params: {
          limit: limit,
          offset: offset,
          fsk: fsk.get(),
          filter: JSON.stringify(filter)
        },
        dontIntercept: true
      }).then(function (res) {
        if (res.headers('x-bl')) {
          res.data.bl = true;
        }
        return {
          total: parseInt(res.headers('x-numfound')) || 0,
          data: res.data
        };
      });
    };

    PictureSet.random = function (limit, nickname) {
      return http().get('/api/picture/random?limit=' + (limit || 5) + (nickname ? '&nickname=' + nickname : ''), { dontIntercept: true });
    };

    PictureSet.categories = function () {
      return cache.get('c', () => http().get('/api/picture/categories', { dontIntercept: true }), res => res.data);
    };

    return PictureSet;
  }])

  .directive('onsdPictureSets', ['PimpMyRide', 'http', 'PictureSet', 'fsk', (PimpMyRide, http, PictureSet, fsk) => PimpMyRide(
    'PictureSets',
    ['nickname', 'search', 'categories', 'limit'],
    v => http().get('/api/picture/list', {
      dontIntercept: true,
      params: {
        limit: v[3],
        fsk: fsk.get(),
        filter: {
          nickname: v[0] || '',
          search: v[1] || '',
          categories: (v[2] || '').split(',').filter(v => !!v)
        }
      }
    })
      .then(res => {
        if (res.headers('x-bl')) {
          res.data.bl = true;
        }
        PictureSet.doNotShow.forEach(id => {
          let i = res.data.indexOf(id);
          if (i >= 0) {
            res.data.splice(i, 1);
          }
        });
        return res.data;
      }),
    v => v[3] > 0
  )])

  .directive('onsdMorePictureSets', ['PimpMyRide', 'PictureSet', (PimpMyRide, PictureSet) => PimpMyRide(
    'MorePictureSets',
    ['setId', 'type', 'limit'],
    v => PictureSet.get(v[0]).then(set => set.more(v[1], Math.max(0, parseInt(v[2])) || 5)),
    v => PictureSet.isValidId(v[0]) && /^(nickname|recommendation)$/.test(v[1])
  )]);
