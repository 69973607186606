angular.module('core').directive('onswPagination', ['user', function (user) {
  return {
    scope: {
      list: '<',
      limit: '<',
      i: '<?',
      onChange: '&?'
    },
    template:
    '<div class="box">' +
    '<span class="ons-item arrow back" ng-class="i > 1 ? \'active\' : \'inactive\'"><span class="box" ng-click="goto(i-1)" ons-icon="::back"><span class="label">&laquo;</span></span></span>' +
    '<span class="ons-item" ng-show="i>1"><span class="box" ng-click="goto(1)"><span class="label">1</span></span></span>' +
    '<span class="ons-item" ng-show="i>4"><span class="box" ng-click="goto(i-2)"><span class="label">{{i-3}}</span></span></span>' +
    '<span class="ons-item" ng-show="i>3"><span class="box" ng-click="goto(i-2)"><span class="label">{{i-2}}</span></span></span>' +
    '<span class="ons-item" ng-show="i>2"><span class="box" ng-click="goto(i-1)"><span class="label">{{i-1}}</span></span></span>' +
    '<span class="ons-item current" ng-show="max>1"><span class="box"><span class="label">{{i}}</span></span></span>' +
    '<span class="ons-item" ng-show="i<max-1"><span class="box" ng-click="goto(i+1)"><span class="label">{{i+1}}</span></span></span>' +
    '<span class="ons-item" ng-show="i<max-2"><span class="box" ng-click="goto(i+2)"><span class="label">{{i+2}}</span></span></span>' +
    '<span class="ons-item" ng-show="i<max-3"><span class="box" ng-click="goto(i+2)"><span class="label">{{i+3}}</span></span></span>' +
    '<span class="ons-item" ng-show="i<max"><span class="box" ng-click="goto(max)"><span class="label">{{max}}</span></span></span>' +
    '<span class="ons-item arrow forward" ng-class="i < max ? \'active\' : \'inactive\'"><span class="box" ng-click="goto(i+1)" ons-icon="::forward"><span class="label">&raquo;</span></span></span>' +
    '</div>',
    link: function (scope, element) {

      let reset;

      scope.$watch('list', list => {

        if (reset) {
          reset();
        }

        if (!Array.isArray(list)) {
          element.addClass('invalid');
          return;
        }

        element.removeClass('invalid');
        element.toggleClass('empty', list.length <= scope.limit);

        scope.max = Math.ceil(list.length / scope.limit) || 1;
        scope.i = 1;

        reset = scope.$watch('i', i => {

          if (i > scope.max) {
            scope.i = 1;
          } else if (i < 0) {
            scope.i = 0;
          } else {
            let offset = (i - 1) * scope.limit;
            if (scope.onChange) {
              scope.onChange({
                item: scope.list.slice(offset, offset + scope.limit),
                scrollTo: selector => {
                  const el = selector ? angular.element(selector) : element.parent();
                  clicked && el[0].scrollIntoView();
                },
                i: i
              });
            }
          }
        });
      });


      let clicked = false;
      scope.goto = index => {
        scope.i = index;
        clicked = true;
        user.guestSignup();
      };
    }
  };
}]);
