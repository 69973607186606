import {Regard} from '../model/regard';
import templateUrl from 'assets/html/regard/send.html';
import {asset} from 'acng/core/service/env';
import {addClass, query, removeClass} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';
import {inject} from 'acng/core/service/ng';

export default 'onswRegardSend';
export const sendComponent: angular.IComponentOptions = {
  bindings: {
    regard: '<',
    regardClose: '&',
  },
  templateUrl: asset(templateUrl),
  controller: ['$scope', '$element', 'fskTransFilter', controller],
};

type Controller = angular.IController & {
  regard?: Regard;
  regardClose(): void;
  text: string;
  sending: boolean;
};

const CSS_WAIT = 'sending';

function controller(
  this: Controller, //
  $scope: angular.IScope,
  $element: JQLite,
  fskTrans: import('acng/userPool/0fsk').Filter
) {
  const element = $element[0];
  const $translate = inject('$translate');

  this.$onChanges = () => {
    if (this.regard) {
      this.text = fskTrans(this.regard.description);
      query<HTMLTextAreaElement>('textarea', element)?.focus();
    } else {
      this.text = '';
    }
  };

  // TODO make an amateur context for the regard-shop or better use the messengers amateur
  $scope.$watch(
    () => Regard.recipient,
    async (amateur) => {
      this.label = amateur ? await $translate('regard.sendHeadline', {nickname: amateur.getNickname()}) : '';
      $scope.$digest();
    }
  );

  this.send = async () => {
    try {
      addClass(element, CSS_WAIT);
      this.sending = true;
      await this.regard?.send(this.text);
      $scope.$apply(() => this.regardClose());
    } catch (reason) {
      if (reason != null) {
        popup(element).error(`${reason}`);
      }
    } finally {
      removeClass(element, CSS_WAIT);
    }
  };
}
