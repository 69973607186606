import {
  HTMLElement,
  addClass,
  classList,
  createArray,
  hasAttribute,
  observe,
  observeSize,
  pushToArray,
  removeClass,
  toggleClass,
  unobserve,
} from '@acng/frontend-bounty';

import {layoutFeature} from '../config/feature.js';

/**
 * Use for debug messages.
 */
const MODULE = 'acng/layout/widget/section';

/**
 * The internals for each optional class contain [parsedClassName, lowerLimit, upperLimit].
 *
 * @typedef {[string, number, number]} OptionalClassEntry
 */

/**
 * Use this symbol as property to store the internal state on an {@link Element} as {@link OptionalClassEntry}-Array.
 */
const OPTIONAL_CLASS_ENTRIES = Symbol();

/**
 * @typedef {HTMLElement & {[OPTIONAL_CLASS_ENTRIES]?: OptionalClassEntry[]}} ResponsiveElement
 */

/**
 * A global {@link ResizeObserver} is utilized to update the elements classList according to 
 * the internal state.
 */
const OBSERVER = observeSize((entries) => {
  for (const {
    target,
    contentRect: {width},
  } of entries) {
    DEBUG: if (hasAttribute(target, 'debug')) console.debug(MODULE, {width, target});

    for (const [parsedClassName, lowerLimit, upperLimit] of getOptionalClassEntries(target)) {
      const enable = width >= lowerLimit && width < upperLimit;

      toggleClass(target, parsedClassName, enable);
    }
  }
});

// Define a CustomElement on the layout-feature.
layoutFeature.defineElement(
  'onsw-section',
  class extends HTMLElement {
    connectedCallback() {
      connect(this);
    }
    disconnectedCallback() {
      disconnect(this);
    }
  }
);

/**
 * @param {ResponsiveElement} host
 */
export const connect = (host) => {
  parseOptionalClasses(host);
  observe(OBSERVER, host);
};

/**
 * @param {ResponsiveElement} host
 */
export const disconnect = (host) => {
  unobserve(OBSERVER, host);
  restoreOptionalClasses(host);
};

/**
 * @param {ResponsiveElement} host
 */
const parseOptionalClasses = (host) => {
  /**
   * @type {Array<[string, number, number]>}
   */
  const optionalClasses = createArray();

  for (const className of createArray(classList(host))) {
    const match = /^(\w+)-(\d+)-(\d*)$/.exec(className);
    if (match) {
      removeClass(host, match[0]);
      pushToArray(optionalClasses, [match[1], parseInt(match[2]), match[3] ? parseInt(match[3]) : Infinity]);
    }
  }

  host[OPTIONAL_CLASS_ENTRIES] = optionalClasses;
};

/**
 * @param {ResponsiveElement} host
 */
const restoreOptionalClasses = (host) => {
  for (const entry of getOptionalClassEntries(host)) {
    const parsedClassName = entry[0];

    removeClass(host, parsedClassName);
    addClass(host, getOriginalClassName(entry));
  }

  delete host[OPTIONAL_CLASS_ENTRIES];
};

/**
 * @param {Element} host
 * @returns {Array<OptionalClassEntry>}
 */
const getOptionalClassEntries = (host) => {
  const entries = /** @type {ResponsiveElement} */ (host)[OPTIONAL_CLASS_ENTRIES];

  DEBUG: if (!entries) {
    if (hasAttribute(host, 'debug')) console.warn(MODULE, 'optional class entries are not defined');
    return [];
  }

  return entries;
};

/**
 * @param {OptionalClassEntry} entry
 */
const getOriginalClassName = ([className, lowerLimit, upperLimit]) =>
  `${className}-${lowerLimit}${upperLimit < Infinity ? `-${upperLimit}` : ''}`;
