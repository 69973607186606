/**
 * @module acng/alternativeProducts
 */
import './config/routes';
import angular from 'angular';
import AlternativeProducts, {Products} from './factory/products';
import onswAlternativeProducts, {productsComponent} from './widget/products';
import featureName from './config/feature.js';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

angular
  .module(featureName, ['core', 'userPool', 'amateurPool', angularTranslate])
  .config(provideTranslations(featureName))
  .service(AlternativeProducts, Products)
  .component(onswAlternativeProducts, productsComponent);
