angular.module('core')
  .run(['isMobile', '$window', 'iOS', (isMobile, $window, iOS) => {
    if (!isMobile || iOS) return;
    var prev = $(document).height();
    $window.addEventListener('resize', function () {
      var cur = $(document).height();
      if (prev > cur && document.activeElement) {
        var el = document.activeElement;
        if (el.parentNode.tagName.toUpperCase() === 'LABEL') {
          el = el.parentNode;
        }
        el.scrollIntoView && el.scrollIntoView();
      }
      prev = cur;
    });
  }]);
