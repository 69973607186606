/**
 * @module amateurPool/widgets/context
 */
import {ctxAmateur} from '../context/amateur.js';

export default 'onswAmateurContext';
export const contextComponent: angular.IComponentOptions = {
  bindings: {amateur: '<'},
  controller: [
    '$element',
    function (this: angular.IController, $element: JQLite) {
      const element = $element.parent()[0];
      this.$onChanges = () => ctxAmateur.provide(element, this.amateur ?? null);
      this.$onDestroy = () => ctxAmateur.revoke(element);
    },
  ],
};
