angular.module('core').directive('onswHeading', function () {
  return {
    scope: {
      label: '@',
      parameters: '@'
    },
    template: '<h1 class="box"><span class="label">{{label | translate:values}}</span></h1>',
    link: function (scope, element) {
      element[0].classList.add('ons-heading', 'ons-item');

      scope.$watch('parameters', function (values) {
        scope.values = values ? angular.fromJson(values) : {};
      });
    }
  };
});
