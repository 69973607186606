import {popup} from "@acng/frontend-discovery";

angular.module('userPool').directive('onswPasswordSet', ['userPoolApi', '$translate', '$location', function (userPoolApi, $translate, $location) {
  return {
    scope: {
      email: '@',
      overlay: '<?',
      token: '@',
      redirect: '@?',
      buttonLabel: '@?'
    },
    templateUrl: '/template/userPool.passwordSet',
    link: function (scope, element) {
      element.addClass('ons-form');

      if (!scope.buttonLabel) {
        scope.buttonLabel = 'userPool.changePassword';
      }

      scope.formData = {
        token: scope.token,
        email: $location.search().email || scope.email
      };

      scope.showEmail = !scope.formData.email;
      scope.reset = function () {
        userPoolApi.resetPassword(scope.formData)
          .then(function () {
            $translate('userPool.changedPassword').then(function (text) {
              popup(element[0]).info(text);
              if (scope.redirect) {
                // TODO turbo boost
                $location.path(scope.redirect);
              }
            });
            if (scope.overlay) scope.overlay.close();
          })
          .catch(function (err) {
            if (scope.overlay && (err.status < 0 || err.status == 404 || err.status >= 500)) {
              scope.overlay.close();
            }
          });
      };
    }
  };
}]);
