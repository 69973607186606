import findKey from 'underscore/modules/findKey.js';

import {getLocale} from 'acng/locale/model/locale.js';

angular.module('userPool')
  .controller('userPoolDoiHintCtrl', ['$scope', 'user', '$translate', 'webmailDomains', 'isMobile', '$window', 'onsOverlay', function ($scope, user, $translate, webmailDomains, isMobile, $window, onsOverlay) {

    if (user.doi_at) {
      if ($scope.overlay)
        $scope.overlay.close();else
      {
        $window.history.back(1);
      }
    }

    $scope.language = getLocale();
    $scope.type = user.guest ? 'doi' : 'soi';
    $scope.user = user;
    $scope.isMobile = isMobile;
    $scope.email = $scope.signupEmail || user.email;
    $scope.espDomain = $scope.email.split('@')[1];
    $scope.espName = findKey(webmailDomains, function (list) {
      return list.indexOf($scope.espDomain) != -1;
    }) || 'generic';

    $scope.getsFreeCoinsForDoi = user.getsFreeCoinsForDoi();
    $scope.freeCoinsValue = user.getFreeCoinsValue();

    prepareDoiHintTranslations();

    function prepareDoiHintTranslations() {
      var keys = getTranslationKeysForUnspamGuide($scope.espName);
      if (keys.length == 0) keys = getTranslationKeysForUnspamGuide('generic');

      var doiHintKey = 'userPool.doiHint.' + $scope.type;
      keys.push(doiHintKey);

      $translate(keys).then(function (vars) {
        $scope.intro = vars[doiHintKey];
        $scope.unspamGuidance = vars;
        delete vars[doiHintKey];
      });
    }

    function getTranslationKeysForUnspamGuide(esp) {
      return Object.keys($translate.getTranslationTable()).filter(function (key) {
        return key.indexOf('userPool.howToUnspam.' + esp) === 0;
      });
    }

    $scope.openNewWindow = function () {
      if ($scope.espName == 'generic') return;
      window.open('http://www.' + $scope.espDomain);
    };

    $scope.openConfirmMailOverlay = function () {
      onsOverlay.create('confirmMail').open();
      $scope.overlay && $scope.overlay.close();
    };

  }]);
