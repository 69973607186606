import {Widget} from '@acng/frontend-stargazer';
import {hide, setText, show, whenAll} from '@acng/frontend-bounty';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';

import {repeatElement} from 'acng/layout/service/repeat.js';
import {achievementFeature} from '../config/feature.js';
import {getHistory} from '../service/http.js';
import {ctxVipPoints} from '../service/sock.js';
import {CTX_ALERT} from '@acng/frontend-relativity/minify';

achievementFeature.defineWidget(
  'onsw-latest-achievements',
  class extends Widget {
    static consumables = [ctxVipPoints];
    render() {
      create(this);
    }
  }
);

/**
 * @param {Widget} element
 */
const create = async (element) => {
  ASSERT: typeguard(
    '',
    element.nodes,
    OBJECT({
      time: IS(HTMLTimeElement),
      repeat: IS(HTMLElement),
      value: IS(Text),
      descr: IS(Text),
    })
  );

  const {repeat, time, value, descr} = element.nodes;

  hide(repeat);

  const achievements = await getHistory(element).then((data) =>
    whenAll(
      data.map(async ({created_at, value, achievement}) => ({
        created_at: new Date(created_at),
        value,
        descr: await achievementFeature.translate([achievement.name, 'action'], {value: `${value}`}),
      }))
    )
  );

  show(repeat);

  repeatElement(repeat, achievements, (ua) => {
    time.dateTime = ua.created_at.toISOString();
    setText(time, ua.created_at.toLocaleDateString(document.body.lang));
    setText(value, `${ua.value}`);
    setText(descr, ua.descr);
  });

  // TODO stargazer: get clientrect with one frame, then animate

  ctxVipPoints[CTX_ALERT](element, () => element.applyTemplate());
};
