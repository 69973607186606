import {Widget} from '@acng/frontend-stargazer';
import {addClass, append, createSpan, removeChildNodes, setText} from '@acng/frontend-bounty';

import {countComments, ctxCommentable} from '../model/commentable.js';
import {commentFeature} from '../config/feature.js';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';

commentFeature.defineWidget(
  'onsw-comment-count',
  class extends Widget {
    static template = false;
    static consumables = [ctxCommentable];

    connectedCallback() {
      addClass(this, 'ons-item');
      ctxCommentable[CTX_OBSERVE](this, async (commentable) => {
        removeChildNodes(this);
        if (commentable) {
          const box = createSpan('box');
          const label = createSpan('label');
          const value = createSpan('value');
          setText(value, `${await countComments(commentable)}`);
          setText(label, await commentFeature.translate('comments'));
          append(box, label, value);
          append(this, box);
        }
      });
    }
  }
);
