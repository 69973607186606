import angular from 'angular';
import {getIcon} from '../service/icon';
import {show, hide} from '@acng/frontend-bounty';

export default 'onsIcon';
export const iconDirective = [
  () => ({
    scope: false,
    restrict: 'A',
    controller: ['$scope', '$element', '$attrs', '$window', controller],
  }),
];

function controller(scope: angular.IScope, element: JQLite, attrs: angular.IAttributes, $window: angular.IWindowService) {
  let box = element.children('.box');
  if (!box.length) {
    box = element;
  }

  const unreg = attrs.$observe<string>('onsIcon', async value => {
    let paramsString = value!;

    if (paramsString.substring(0, 2) == '::') {
      paramsString = paramsString.substring(2);
      unreg();
    }

    const size = parseInt($window.getComputedStyle(box[0]).fontSize) || 32;

    if (paramsString[0] == '{') {
      unreg();
      const getIconsConfig = () => scope.$eval(paramsString);
      let elements: Promise<SVGSVGElement | null>[];
      elements = [];
      angular.forEach(getIconsConfig(), (visible: boolean, name: string) => {
        elements.unshift(
          getIcon(name)
            .catch(() => getIcon('not'))
            .then(icon => {
              if (!icon) {
                return null;
              }
              icon.setAttribute('width', size.toString());
              icon.setAttribute('height', size.toString());
              if (visible) {
                show(icon);
              } else {
                hide(icon);
              }
              return icon;
            })
        );
      });
      Promise.all(elements)
        .then(icons => {
          icons.forEach(icon => {
            if (icon) {
              box.prepend(icon);
            }
          });
        })
        .then(() => {
          scope.$watch(
            getIconsConfig,
            (icons: Record<string, string>) => {
              angular.forEach(icons, (show, name) => {
                let el = box.children('.icon.' + name);
                el[show ? 'show' : 'hide']();
              });
            },
            true
          );
        });
      return;
    }

    const icons = await Promise.all(
      paramsString
        .split(',')
        .filter(name => !!name)
        .map(name => {
          return name == '*' ? Promise.resolve(name) : getIcon(name);
        })
    );

    box.children('.icon').remove();

    let action: 'append' | 'prepend' = 'prepend';
    for (const icon of icons) {
      if (!icon) {
        continue;
      }
      if (typeof icon == 'string') {
        action = 'append';
        continue;
      }
      icon.setAttribute('width', size.toString());
      icon.setAttribute('height', size.toString());
      box[action](icon);
    }
  });
}
