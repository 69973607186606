angular.module('picturePool')

  .directive('onswPictureSetInfo', ['Widget', 'user', 'PictureSet', (Widget, user, PictureSet) => {

    return {
      scope: {
        pictureSet: '<'
      },
      templateUrl: '/template/picturePool.info',
      link: (scope, element) => {

        const widget = new Widget(scope, element);

        scope.user = user;
        scope.$watch('pictureSet', set => {

          scope.set = widget.wait(set, set instanceof PictureSet);
        });
      }
    };
  }]);
