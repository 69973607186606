import {media, UTM} from 'acng/core/service/env';
import {inject} from 'acng/core/service/ng';
import kissImageSrc from 'assets/basic/img/kiss.small.png';
import {publishUserMessage} from '../service/event-bus.js';
import {FULLY_ASSIGNED} from '@acng/frontend-bounty/typeguard.js';

/** @typedef {import('../service/typeguard').MessageData} _MessageData */

/**
 * @implements {_MessageData}
 */
export class Message {
  /**
   * @param {import('acng/amateurPool/factory/Amateur').Amateur} amateur
   * @param {_MessageData} data
   */
  constructor(amateur, data) {
    this.amateur = amateur;

    ASSERT: {
      this.sender = data.sender;
      this.timestamp_ms = data.timestamp_ms;
      this.body = data.body;
      this.payload = data.payload;
      this.attachment = data.attachment;
      this.fromLivecam = data.fromLivecam;

      FULLY_ASSIGNED(this, data);
    }

    Object.assign(this, data);

    this.time = new Date(data.timestamp_ms);
    // Filter and escape unclosed < chars, because the angular sanitzier cuts the string at the position.
    this.html = data.body.replace(/<(?![^<]+>)/g, '&lt;');
    this.isMine = data.sender.id !== amateur.id;
    this.ready = false;
    /**
     * @type {string | undefined}
     */
    this.icon = undefined;

    const {type, image} = this.payload;
    const $translate = inject('$translate');
    switch (type) {
      case 'kiss':
        this.payload.image = `${media.assets}/${kissImageSrc}`;
        this.payload.text = $translate.instant('kiss.received');
        break;
      case 'match':
        this.icon = 'match2';
        this.payload.text = $translate.instant('hotornot.systemMessageMatch');
        break;
      case 'game.invitation':
        this.icon = 'gameColor';
        this.payload.text = $translate.instant('games.invited');
        break;
      case 'sexicon':
      case 'regard':
        if (!image) {
          console.warn('messenger/factory/message sexicon or regard without image');
          break;
        }
        if (!/^http/.test(image)) {
          this.payload.image = `${media.content.items}${image}`;
        }
        break;
    }

    if (this.attachment && this.attachment.status !== 'deleted') {
      // prefix the attachment url with cdn host if needed.
      // TODO why pictures? it could be a movie too
      // TODO the acng backend should send the same format as uhura/troi does
      if (!/^http/.test(this.attachment.url)) {
        this.attachment.url = `${media.content.pictures}${this.attachment.url}`;
      }
    }

    if (this.attachment && (this.attachment.status === 'deleted' || this.attachment.status === 'locked')) {
      this.attachment = null;
    }
  }

  isFresh() {
    return Date.now() - this.time.getTime() < 15e3;
  }

  /**
   * @todo TODO this is only used in ContactTile, so move it there
   */
  async getMessageText() {
    if (this.payload.utm_id) {
      return this.translate().then((message) => message.html);
    }

    const $translate = inject('$translate');
    switch (this.payload.type) {
      case 'payttachment':
        return $translate.instant('messenger.payttachment.message');
      case 'sexicon':
        return this.isMine ? $translate.instant('sexicon.sent') : this.html;
      case 'kiss':
        return $translate.instant('kiss.kissed');
      case 'game.invitation':
        return $translate.instant('games.invited');
      case 'match':
        return $translate.instant('hotornot.systemMessageMatch');
      case 'voice':
        return $translate.instant('messenger.voicemessage.preview');
      default:
        return this.html;
    }
  }

  /**
   * @deprecated
   */
  isIcebreaker() {
    return (this.payload.ibid ?? 0) > 0;
  }

  async translate() {
    if (!this.body && this.payload.utm_id) {
      // TODO the utm call should use fetch
      const http = inject('http');
      try {
        const res = await http().get(`${UTM}/translation/${this.payload.utm_id}`);
        this.html = this.isMine
          ? res.data.source_text //
          : res.data.translated_text;
      } catch (reason) {
        console.error(`Message.translate UTM#${this.payload.utm_id} error`, {message: this, reason});
      }
    }
    this.ready = true;
    return this;
  }

  publish() {
    publishUserMessage(this);
  }
}
