/**
 * Global channel to control the templates displayed in the "View" component.
 * 
 * @see [View](../widget/view.js)
 * @see [Middleware](../service/view-middleware.js)
 *
 * @module
 */

import {createGlobalContext} from '@acng/frontend-relativity';
import {documentElement} from '@acng/frontend-bounty';

/**
 * @type {Record<string, ?HTMLTemplateElement>}
 */
const viewConfig = {};

export const ctxView = createGlobalContext(viewConfig);

/**
 * @param {string} name
 * @param {?HTMLTemplateElement} template
 */
export const updateView = (name, template) => {
  viewConfig[name] = template;
  ctxView.provide(documentElement, viewConfig);
};
