import {listen} from 'acng/core/context/event-bus.js';
import {Session} from './game';
import {typeguard, EVENTBUS_GAME} from '../service/typeguard.js';

export {name, type, GamesService as param};

/**
 * @typedef {import('../service/typeguard').SessionData} SessionData
 * @typedef {import('../service/typeguard').InvitationData} InvitationData
 * @typedef {import('acng/amateurPool/factory/Amateur').Amateur} Amateur
 * @todo (perf) use faster init
 */
const name = 'games';
const type = 'service';
/**  */
export class GamesService extends EventTarget {
  /** @override */
  static $inject = ['http', 'user'];
  /**
   * @param {core.Http} http - load sessions, invitations, post invitation
   * @param {import('acng/userPool/factory/user').User} user - check guest
   */
  constructor(http, user) {
    super();
    /**
     * @type {Promise<SessionData[]>}
     * @private
     */
    let sessions;
    /**
     * @type {Promise<InvitationData[]>}
     * @private
     */
    let invitations;
    /**
     * @type {Map<number, Session>}
     * @private
     */
    const all = new Map();
    /**
     * @type {Map<string, Session>}
     * @private
     */
    const amateurs = new Map();
    /**
     * Get reference to session object from internal map. Created if not exits.
     * @param {number} id - playpal session/invitation id
     * @returns {Session} Existing or created game session object
     */
    const get = id => {
      if (!all.has(id)) {
        all.set(id, new Session(id));
      }
      return /** @type {Session} */ (all.get(id));
    };
    /**
     * Register Invitation
     * @param {InvitationData} data - playpal invitation data
     * @returns {Session} Reference to managed session object
     * @private
     */
    const registerInvitation = data => {
      /*const amateur = await Amateur.get(data.toId.split('-')[1]);
      if (!amateur) {
        throw new Error(`session #${session.id} amateur "${data.toId}" not found`);
      }*/
      const session = get(data.id);
      session.updateWithInvitation(user, data);
      if (!session.amateurId) {
        throw new Error(`session #${session.id} has no amateurId`);
      }
      const amateur = amateurs.get(session.amateurId);
      if (!amateur || (session.invitedAt ?? 0) > (amateur.invitedAt ?? 0)) {
        amateurs.set(session.amateurId, session);
      }
      return session;
    };
    /**
     * Register Session
     * @param {SessionData} data - playpal session data
     * @returns {Session} Reference to managed session object
     */
    const registerSession = data => {
      const session = get(data.id);
      session.updateWithSession(user, data);
      return session;
    };
    this.sessionOfAmateur = amateurs;
    this.sessions = all;
    /**
     * @param {number} id - playpal game id
     * @returns {Promise<?Game>} game object or null
     */
    this.getGame = async function (id) {
      /** @type {angular.IHttpResponse<Game[]>} */
      const res = await http(true).get('/api/games');
      return res.data ? res.data.find(game => game.id == id) ?? null : null;
    };
    /**
     * @param {string} amateurId - id of amateur to get the running game from
     * @returns {Promise<Session|undefined>} running session or undefined
     */
    this.getRunningGame = async function (amateurId) {
      await this.getSessions();
      const session = amateurs.get(amateurId);
      if (session?.getState().isActive) {
        return session;
      }
    };
    /**
     * @param {Amateur} amateur - amateur to get the latest game from
     * @returns {Promise<Session|undefined>} latest session or undefined
     */
    this.getLatestGame = async function (amateur) {
      await this.getSessions();
      return amateurs.get(amateur.id);
    };
    /**
     * @param {Amateur} amateur - amateur to invite
     * @returns {Promise<Session>} created session
     * @todo game-parameter
     */
    this.invite = async function (amateur) {
      if (user.guestSignup()) {
        throw null;
      }
      if (!amateur.isOnline()) {
        throw new Error(`amateur #${amateur.id} is not online`);
      }
      const res = await http().post(`/api/games/invitation/${amateur.id}`);
      const session = registerInvitation(res.data);
      this.dispatchEvent(new CustomEvent('update', {detail: {session}}));
      DEBUG: console.debug('games invite', {amateur, session});
      return session;
    };
    /**
     * @returns {Promise<InvitationData[]>} any
     */
    this.getInvitations = function () {
      if (user.guest) {
        return Promise.resolve([]);
      }
      if (!invitations) {
        invitations = http()
          .get('/api/games/invitations')
          .then(
            /**
             * @param {ng.IHttpResponse<InvitationData[]>} res - http response
             * @returns {InvitationData[]} All invitations sent by the currently logged in user
             */
            res => {
              DEBUG: console.groupCollapsed('games/factory/games getInvitations');
              for (const data of res.data) {
                registerInvitation(data);
              }
              DEBUG: console.groupEnd();
              return res.data.filter(invitation => invitation.status != 'accepted');
            }
          );
      }
      return invitations;
    };
    /**
     * @returns {Promise<SessionData[]>} All running and decided games of the currently logged in user
     */
    this.getSessions = function () {
      if (user.guest) {
        return Promise.resolve([]);
      }
      if (!sessions) {
        sessions = Promise.all([
          this.getInvitations(),
          /*http()
            .get('/api/games/sessions?status=pending')
            .then(
              **
               * @param {ng.IHttpResponse<SessionData[]>} res - http response
               * @returns {SessionData[]} All running games of the currently logged in user
               *
              res => {
                for (const data of res.data) {
                  registerSession(data);
                }
                return res.data;
              }
            ),*/
          http()
            .get('/api/games/sessions')
            .then(
              /**
               * @param {ng.IHttpResponse<SessionData[]>} res - http response
               * @returns {SessionData[]} All decided games of the currently logged in user
               */
              res => {
                DEBUG: console.groupCollapsed('games/factory/games getSessions');
                for (const data of res.data) {
                  registerSession(data);
                }
                DEBUG: console.groupEnd();
                return res.data;
              }
            ),
        ]).then(all => all[1]); //.then(all => all[2].concat(all[1]));
      }
      return sessions;
    };
    /**
     * @param {Amateur} amateur - amateur to get the statistics for
     * @returns {Promise<Stats>} Statistics about the games played against this amateur
     * @todo TODO(feature) use "get flag()" instead
     */
    this.getStats = async function (amateur) {
      await this.getSessions();
      let total = 0;
      let won = 0;
      for (const session of all.values()) {
        if (session.amateurId != amateur.id) {
          continue;
        }
        const state = session.getState();
        if (state.hasSession) {
          total++;
        }
        if (state.isWon) {
          won++;
        }
      }
      return {
        amateurId: amateur.id,
        total,
        won,
      };
    };
    /* register socket event handler */
    listen('games.update', async data => {
      ASSERT: typeguard('', data, EVENTBUS_GAME());

      console.info('games/service/games event incoming', {data});
      if (data.invitation) {
        const session = registerInvitation(data.invitation);
        this.dispatchEvent(new CustomEvent('update', {detail: {session}}));
      }
      if (data.session) {
        const session = registerSession(data.session);
        this.dispatchEvent(new CustomEvent('update', {detail: {session}}));
      }
    });
  }
}
/**
 * @typedef Stats
 * @property {string} amateurId - ID of this Amateur
 * @property {number} total - Total number of games played against this amateur
 * @property {number} won - Number of games won against this amateur
 */
/**
 * @typedef Game
 * @property {number} id -
 * @property {string} webComponentLoaderPath -
 * @property {string} webComponentPath -
 */
