import {inject} from 'acng/core/service/ng.js';
import {post} from 'acng/core/service/backend.js';

import {messengerFeature} from '../config/feature.js';
import {getRecentMessage} from './recent-message.js';
import {publishUserMessage} from './event-bus.js';

/**
 * It might be easier to use a `MessageDraft`.
 *
 * @see [MessageDraft](../model/message-composer.js)
 *
 * @param {HTMLElement} context
 * @param {import('../model/message').Message} message
 */
export const sendMessage = async (context, message) => {
  const {amateur} = message;
  const nickname = amateur.getNickname();

  const lastMessage = await getRecentMessage(amateur);

  const {time} = await post(
    'message/send',
    {
      body: message.body,
      recipient: amateur.id,
      price: inject('price').get(amateur),
      attachment_id: message.attachment?.id,
      icebreakerAnswer: lastMessage?.isIcebreaker() ?? false,
    },
    context,
    /** @returns {Promise<{time: number}>} */
    async (error) => {
      if (error.code == -2) {
        error.message = await messengerFeature.translate('signupRequired', {nickname});
      } else if (error.code >= 4000 && error.code < 5000) {
        error.message = await messengerFeature.translate('paymentRequired', {nickname});
      }
      throw error;
    }
  );

  if (lastMessage?.isIcebreaker()) {
    inject('logger')(
      // @ts-ignore
      new (inject('onsLaforgeEvents').IcebreakerResponse)(
        lastMessage.body, //
        lastMessage.payload.ibid,
        amateur.id
      )
    );
  }

  message.timestamp_ms = time * 1000;
  publishUserMessage(message);
};
