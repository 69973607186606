/**
 * @module acng/livecam
 */
import angular from 'angular';
import './config/routes';
import {provideTranslations} from 'acng/core/service/angular-translate.js';
import {hasFeature} from 'acng/core/service/env';
import {extendSlideshow} from './config/toycontrol-banner';
import './widgets/list-sidebar.js';
import './widgets/motto.js';
import name from './config/feature.js';

if (hasFeature(name)) {
  extendSlideshow();
}

angular
  .module(name)
  .config(provideTranslations(name));
