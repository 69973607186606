/**
 * Runtime checks for debugging purpose.
 *
 * @module
 */
import {
  ARRAY,
  ENUM,
  OBJECT,
  STRING,
  INTEGER,
  ANY,
  RECORD,
  typeguard,
  OPTIONAL,
  BOOLEAN,
} from '@acng/frontend-bounty/typeguard';

export {typeguard};

/**
 * @typedef {import('@acng/frontend-bounty/typeguard').Asserted<X_NOTIFICATIONS>} Hm
 */

export const X_NOTIFICATIONS = ARRAY(
  OBJECT({
    id: INTEGER,
    type: ENUM(/** @type {const} */ (['danger', 'warning', 'info', 'success'])),
    message: STRING,
  })
);

export const X_USERPOPUPS = ARRAY(
  OBJECT({
    id: INTEGER,
    user_id: INTEGER,
    created_at: STRING,
    template: STRING,
  })
);

export const ERROR_RESPONSE_BODY = OBJECT({
  code: INTEGER,
  message: STRING,
  reasons: RECORD(ANY, STRING),
});

export const availableValidators = /** @type {const} */ ([
  "user/check-nickname",
  "user/check-password",
  "mail/check-email-address",
]);

export const AVAILABLE_VALIDATORS = ENUM(availableValidators);

export const VALIDATOR_DATA = OBJECT({
  failed: ARRAY(INTEGER),
  messages: OPTIONAL(ARRAY(STRING)),
  rules: ARRAY(STRING),
  suggestions: OPTIONAL(ARRAY(STRING)),
  valid: BOOLEAN,
});
