import {popup} from '@acng/frontend-discovery';

angular.module('userPool').directive('onswChangeEmail', [
  'Widget',
  'user',
  'supportEmail',
  '$translate',
  function (Widget, user, supportEmail, $translate) {
    return {
      scope: {
        overlay: '<?',
      },
      templateUrl: '/template/userPool.changeEmail',
      link: function (scope, element) {
        scope.transParams = {supportEmail};
        const widget = new Widget(scope, element, 'ons-form');
        scope.$on('apivalidator.validate', (e, p) => {
          e.stopPropagation();
          widget.busy(p);
        });

        scope.send = function () {
          if (!scope.email) return;
          widget
            .ifNotBusy(() => user.changeEmail(scope.email))
            .then(() => {
              scope.overlay && scope.overlay.close();
              return $translate('userPool.changeEmailCheckMail').then((text) => popup().info(text));
            })
            .catch((err) => {
              if (err.status == 409) {
                $translate('userPool.changeEmailEntryAlreadyExists').then((text) =>
                  popup(element[0]).warn(text)
                );
              } else if (err.data.reasons) {
                popup(element[0]).warn(Object.values(err.data.reasons).join('<hr>'));
              } else {
                $translate('exception.generic').then((text) => popup(element[0]).warn(text));
              }
            });
        };
      },
    };
  },
]);
