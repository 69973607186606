angular.module('core')
  .filter('fskMask', ['fsk', 'http', function (fsk, http) {

    var expForFsk = {};
    var targets;

    function getTargets() {
      return targets = targets || http().get('api/fsk/targets').then(function (res) {
        return res.data;
      });
    }

    function filter(subject) {
      if (!subject) return;
      if (!expForFsk.hasOwnProperty(fsk.get())) return '';
      if (expForFsk[fsk.get()] == null) return subject;

      return subject.replace(expForFsk[fsk.get()], function (match) {
        return match.charAt(0) + '*'.repeat(match.length - 2) + match.charAt(match.length - 1);
      });
    }

    function genExpressionForFsk() {
      var list = [];
      getTargets().then(function (fskTargets) {

        Object.keys(fskTargets).forEach(function (key) {
          if (key >= fsk.get()) {
            list = list.concat(fskTargets[key]);
          }
        });

        expForFsk[fsk.get()] = list.length != 0 ? new RegExp(list.join('|'), 'ig') : null;
      });
    }

    genExpressionForFsk(fsk);

    fsk.addEventListener('change', function (ev) {
      genExpressionForFsk(ev.detail.fsk);
    });

    filter.$stateful = true;
    return filter;

  }]);
