angular.module('core').directive('onswOpenOverlay', ['onsOverlay', onsOverlay => {

  return {
    scope: {
      label: '@',
      value: '@',
      hookname: '@',
      replaceOverlay: '<?'
    },
    template: (element, attrs) =>
      '<span class="box" ng-click="click()" ng-attr-title="{{label|translate}}">' + (
        attrs.label ? '<span class="label">{{label|translate}}</span>' : '') + (
        attrs.value ? '<span class="value">{{value}}</span>' : '') +
    '</span>',
    link: function (scope, element) {

      element.addClass('ons-item');
      scope.click = () => {
        if (scope.replaceOverlay) {
          scope.replaceOverlay.hide();
        }
        onsOverlay.create(scope.hookname).disableBackdrop().open();
      };
    }
  };
}]);
