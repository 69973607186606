import {removeChildNodes, addClass, toggleClass, createSpan, append, removeClass, onClick, off, prepend, setText, remove} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';
import {CTX_OBSERVE, CTX_SUBSCRIBE_CLASSNAME, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getIcon} from 'acng/core/service/icon';
import {LAYOUT_CLASS_DISABLED, LAYOUT_CLASS_WAIT} from 'acng/layout/config/css-classes';

import {checkPin, addPin, removePin, ctxPins} from '../service';

export default 'onswPinButton';
export const buttonComponent: angular.IComponentOptions = {
  transclude: true,
  controller: ['$element', '$translate', '$transclude', 'user', controller],
};

ctxAmateur[CTX_SUBSCRIBE_TAGNAME]('onsw-pin-button');
ctxPins[CTX_SUBSCRIBE_CLASSNAME]('box pin-button');

// TODO subscribeAmateur();

const CSS_ACTIVE = 'active';
const CSS_INACTIVE = 'inactive';
// TODO const CSS_EMPTY = 'empty';

function controller(
  this: angular.IController, //
  $element: JQLite,
  $translate: angular.translate.ITranslateService,
  $transclude: angular.ITranscludeFunction,
  user: import('acng/userPool/factory/user').User
) {
  const element = $element[0];

  addClass(element, 'ons-item');

  ctxAmateur[CTX_OBSERVE](element, async (amateur) => {
    removeChildNodes(element);
    removeClass(element, CSS_ACTIVE, CSS_INACTIVE);
    if (!amateur) {
      return;
    }
    let activeIcon: SVGSVGElement | undefined;
    const box = createSpan('box', 'pin-button');
    const label = createSpan('label');
    const enable = () => {
      onClick(box, click);
      removeClass(box, LAYOUT_CLASS_WAIT, LAYOUT_CLASS_DISABLED);
    };
    const click = async () => {
      off(box, 'click', click);
      addClass(box, LAYOUT_CLASS_WAIT);

      try {
        if (!checkPin(amateur)) {
          await addPin(amateur);
          removeClass(box, LAYOUT_CLASS_WAIT);
          addClass(box, LAYOUT_CLASS_DISABLED);
          await popup(box).info(await $translate('pin.added', {nickname: amateur.getNickname()}));
        } else {
          await removePin(amateur);
          removeClass(box, LAYOUT_CLASS_WAIT);
          addClass(box, LAYOUT_CLASS_DISABLED);
          await popup(box).info(await $translate('pin.removed', {nickname: amateur.getNickname()}));
        }
      } catch (reason: any) {
        if (reason.status == 401) {
          user.guestSignup('pin.signupRequired', {nickname: amateur.getNickname()});
        } else {
          console.error(reason);
          // TODO widget.notify(err); behaviour
          if (reason?.data?.message) {
            removeClass(box, LAYOUT_CLASS_WAIT);
            addClass(box, LAYOUT_CLASS_DISABLED);
            await popup(box).error(reason.data.message);
          }
          throw reason;
        }
      } finally {
        enable();
      }
    };
    ctxPins[CTX_OBSERVE](box, async (pinsAreAvailable) => {
      if (!pinsAreAvailable) {
        return;
      }
      const isPin = checkPin(amateur);
      toggleClass(box, CSS_ACTIVE, isPin);
      toggleClass(box, CSS_INACTIVE, !isPin);
      getIcon(isPin ? 'favoriteColor' : 'noFavorite').then(svg => {
        if (activeIcon) {
          remove(activeIcon);
        }
        activeIcon = svg;
        prepend(box, svg);
      });
    });
    $transclude(clone => clone?.appendTo(box));
    append(box, label);
    enable();
    append(element, box);
    setText(label, await $translate('pin.pin'));
  });
}
