angular.module('livecam')

  .directive('onswLivecamHeading', ['Widget', 'Livecam', (Widget, Livecam) => {

    return {
      scope: {
        token: '@'
      },
      template: '<h1 class="box">{{ \'livecam.livecamShow\' | translate:{nickname:nickname} }}</h1>',
      link: (scope, element) => {

        new Widget(scope, element, 'ons-heading');

        scope.$watch('token', t => {
          var session = Livecam.get(t);
          if (session) {
            scope.nickname = session.nickname;
          }
        });
      }
    };
  }]);
