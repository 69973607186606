angular.module('livecam', ['pascalprecht.translate', 'core', 'amateurPool', 'userPool'])
  .directive('onsdLivecams', ['PimpMyRide', 'Livecam', (PimpMyRide, Livecam) => PimpMyRide('Livecams', false, Livecam.list)])
  .directive('onsdLivecamsLoading', ['PimpMyRide', 'Livecam', (PimpMyRide, Livecam) => PimpMyRide('LivecamsLoading', false, Livecam.getLoadingList)])
  .directive('onscCamCount', ['Livecam', '$animate', (Livecam, $animate) => {

    return {
      scope: false,
      restrict: 'C',
      link: (scope, element) => {

        const label = element.find('.label');
        const value = angular.element('<span class="value"></span>');

        Livecam.list().then(list => {

          scope.cams = list;
          value.html(list.length);
          value.insertAfter(label);

          scope.$watch('cams.length', (count, prev) => {

            element.toggleClass('empty', !count);

            if (count < prev) {
              $animate.setClass(element, 'decrease-before')
                .then(() => {
                  value.html(count);
                  return $animate.setClass(element, 'decrease-after');
                })
                .then(() => element[0].classList.remove('decrease-before', 'decrease-after'));
            } else if (count > prev) {
              $animate.setClass(element, 'increase-before')
                .then(() => {
                  value.html(count);
                  return $animate.setClass(element, 'increase-after');
                })
                .then(() => element[0].classList.remove('increase-before', 'increase-after'));
            }
          });
        });

      }
    };
  }]);
