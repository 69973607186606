import angular from 'angular';

angular.module('messenger')

  .directive('onswAttachmentCreate', ['Widget', 'attachmentList', function (Widget, attachmentList) {
    return {
      scope: {
        datauploaded: '&?'
      },
      templateUrl: '/template/messenger.attachmentCreate',
      /**
       * @param {angular.IScope & {
       *   save: Function;
       *   busy: boolean;
       *   uploadName: string;
       *   image: unknown;
       *   datauploaded: (params: Record<string, unknown>) => void;
       * }} scope
       */
      link: function (scope, element) {

        const widget = new Widget(scope, element, 'ons-form');

        scope.save = function () {
          scope.busy = true;
          widget.ifNotBusy(() => attachmentList.add(scope.image, scope.uploadName))
            .then(/** @param {import('../factory/attachmentList').Attachment} attachment */ function (attachment) {
              widget.notify('core.saved');
              if (scope.datauploaded) {
                scope.datauploaded({ attachment: attachment });
              }
            })
            .catch(/** @param {unknown} error */ function (error) {
              if (error !== null) {
                widget.notify(error);
              }
            })
            .finally(function () {
              scope.uploadName = '';
              scope.image = null;
              scope.busy = false;
            });
        };
      }
    };
  }]);
