import {Feature} from '../model/feature.js';

const name = 'core';
export default name;
export const coreFeature = new Feature(name, false);

/**
 * @deprecated
 */
export const hookFeature = new Feature('hook', false);

/**
 * @deprecated
 */
export const tileFeature = new Feature('tile', false);
