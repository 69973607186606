angular.module('amateurPool')
  .directive('onscGotoProfile', ['$compile',
    function ($compile) {
      return {
        scope: false,
        restrict: 'C',
        link: function (scope, element) {
          var box = element.children('.box');
          if (!box.length) {
            console.error('no box found');
            return;
          }
          var linkFn = $compile(
            '<span class="goto">' +
        '<span class="label" translate="amateurPool.toProfile"></span>' +
        '</span>'
          );
          box.append(linkFn(scope));
        }
      };
    }]
  );
