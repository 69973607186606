/**
 * @module acng/picturePool/config/laforge
 */
import {rootRoute} from 'acng/core/config/routes';
import {pictureSetsRoute} from './routes';
import {on} from '@acng/frontend-bounty';

runLaforge.$inject = ['logger', 'onsLaforgeEvents', 'user'];
/* prettier-ignore */
/**
 * @param {any} logger -
 * @param {any} onsLaforgeEvents -
 * @param {import('acng/userPool/factory/user').User} user -
 */
export function runLaforge(logger, onsLaforgeEvents, user) {
  /* The pictureSetsRoute is not on the same branch as the amateurRoute.
   * So use the root settled event to get the amateur,
   * which is fired when all async routes are finished.
   * TODO improve laforge to use the timings from voyager
   * TODO then just the leave event can be used
   */
  on(pictureSetsRoute, 'enter', () => on(rootRoute, 'settled', () => {
    const amateur = rootRoute.globals.amateur;

    // log only when an user visits the list of an amateur
    if (user.guest || !amateur) {
      return;
    }

    // prepare the log event
    DEBUG: console.debug('picturePool/config/laforge prepare log', {amateur});
    const laforgeEvent = new onsLaforgeEvents.PicturesVisit(
      user.uid,
      rootRoute.globals.amateur
    );

    on(pictureSetsRoute, 'leave', () => {
      // log the log event
      DEBUG: console.debug('picturePool/config/laforge log', {amateur});
      logger(laforgeEvent.endVisit());
    }, {once: true});
  }, {once: true}));
}
