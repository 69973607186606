import {
  EVENT_CLICK,
  HTMLElement,
  addClass,
  append,
  closest,
  hasClass,
  on,
  query,
  queryAll,
  removeClass,
  window,
} from '@acng/frontend-bounty';
import {getIcon} from 'acng/core/service/icon.js';
import {layoutFeature} from '../config/feature.js';
import {inject} from 'acng/core/service/ng.js';
import {LAYOUT_CLASS_ACTIVE, LAYOUT_CLASS_INACTIVE} from '../config/css-classes.js';

export const ONSW_MENU_BUTTON = 'onsw-menu-button';
const SELECTOR_MENU = '.layout-menu-main';
const TAGNAME_DETAILS = 'details';

layoutFeature.defineElement(
  ONSW_MENU_BUTTON,
  class extends HTMLElement {
    constructor() {
      super();

      this.menu = query(SELECTOR_MENU);
      if (!this.menu) {
        throw ReferenceError(SELECTOR_MENU);
      }

      addClass(this, 'layout-link', 'layout-menu-button');
      getIcon('menu').then((svg) => append(this, svg));
      inject('$rootScope').$on('$locationChangeSuccess', () => this.closeMenu());

      on(window, EVENT_CLICK, ({target}) => {
        if (
          !closest(target, ONSW_MENU_BUTTON, () => this.toggleMenu()) &&
          !closest(target, SELECTOR_MENU, () => this.closeSubmenus(closest(target, TAGNAME_DETAILS)))
        ) {
          this.closeMenu();
        }
      });
    }

    toggleMenu() {
      if (hasClass(this.menu, LAYOUT_CLASS_ACTIVE)) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    }

    openMenu() {
      addClass(this.menu, LAYOUT_CLASS_ACTIVE);
      removeClass(this.menu, LAYOUT_CLASS_INACTIVE);
    }

    closeMenu() {
      removeClass(this.menu, LAYOUT_CLASS_ACTIVE);
      addClass(this.menu, LAYOUT_CLASS_INACTIVE);
      this.closeSubmenus();
    }

    /**
     * @param {EventTarget | null} [clickedElement]
     */
    closeSubmenus(clickedElement) {
      for (const details of queryAll(TAGNAME_DETAILS, this.menu)) {
        if (details == clickedElement) {
          continue;
        }
        details.open = false;
      }
    }
  }
);
