import angular from 'angular';
import {connectPopupController} from '@acng/frontend-discovery';
import {closest} from '@acng/frontend-bounty';

import {ImageTile} from 'acng/core/tiles/ImageTile';

import {ctxAmateur} from '../context/amateur.js';
import {isMobile} from 'acng/core/service/env.js';
import {IS, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';

/** also used in onsw-livecams-sidebar */
export const ignoreClicks = 'onsw-messenger-send, onsw-open-dialog-button, onsw-pin-button, .layout-note';

angular.module('amateurPool').factory('AmateurTile', ['Amateur',
  /**
   * @param {import('acng/amateurPool/factory/Amateur').AmateurConstructor} Amateur
   */
  (Amateur) => {
    class AmateurTile extends ImageTile {
      /**
       * @param {Scope} scope
       * @param {unknown} amateur_id
       */
      async load(scope, amateur_id) {
        ASSERT: typeguard('', amateur_id, STRING);
        ASSERT: typeguard('', this.box, IS(HTMLElement));

        try {
          const amateur = await Amateur.get(amateur_id);
          ctxAmateur.provide(this.box, amateur);
          connectPopupController(this.box);
          if (amateur.age > 35) {
            //throw `${amateur.nickname} aged ${amateur.age}`;
          }
          this.apply({
            amateur: amateur,
            nickname: amateur.getNickname(),
            age: amateur.age,
            from: await amateur.getLocation(),
            focusDraft: () => {
              if (!isMobile) {
                this.el?.querySelector('.detail')?.addEventListener('transitionend', () => {
                  this.box?.querySelector('textarea')?.focus();
                }, {once: true});
              }
            },
            /**
             * @param {Event} ev
             */
            click: (ev) => {
              if (closest(ev.target, ignoreClicks, undefined, this.box)) {
                return;
              }
              amateur.goto();
            },
            desc: amateur.getDescription()
          });
          if (!scope.image || scope.forceProfileImage) {
            scope.image = amateur.getImageUrl(scope.imageFormat || '800');
          }
          await super.load(scope, {});
          return amateur;
        } catch (err) {
          await super.load(scope, {});
          throw err;
        }
      }
      hookname() {
        return 'tileAmateur';
      }
    }
    return AmateurTile;
  }
]);

/**
 * @typedef {angular.IScope & import('acng/core/tiles/ImageTile').ImageTileScope & {
 *   forceProfileImage: boolean;
 *   imageFormat: import('../model/amateur/images.js').AmateurProfileImageFormat;
 * }} Scope
 */
