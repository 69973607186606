/**
 * @module acng/messenger/factory/attachmentList
 */
export const //
  type = 'factory',
  name = 'attachmentList',
  param = factory;
/**
 * @typedef Attachments
 * @property {() => Promise<Attachment[]>} get
 * @property {Function} add -
 * @property {Function} remove -
 */
/**
 * @typedef AttachmentData
 * @property {number} id -
 * @property {string} name -
 * @property {string} url -
 * @property {boolean} locked -
 * @example
 * {
 *   "id": 551,
 *   "name": "tegh",
 *   "url": "/mediafiles/ng/11/807_/7/1652106874.jpg",
 *   "locked": false
 * }
 */
/**
 * @typedef AttachmentTrait
 * @property {string} type -
 * @property {() => Promise<void>} remove
 */
/**
 * @typedef {AttachmentData & AttachmentTrait} Attachment
 */
import angular from 'angular';
import {media} from 'acng/core/service/env';
import {listen} from 'acng/core/context/event-bus.js';
factory.$inject = ['http', 'user'];
/**
 * @param {core.Http} http -
 * @param {import("acng/userPool/factory/user").User} user -
 * @returns {Attachments} -
 */
function factory(http, user) {
  /** @type {Promise<Attachment[]> | undefined} */
  let attachments;
  /**
   * @class
   * @param {AttachmentData} obj -
   * @this {Attachment}
   */
  function Attachment(obj) {
    this.id = obj.id;
    // this.attachment_id = obj.attachment_id; TODO error on get/set for sake
    this.name = obj.name;
    this.locked = obj.locked;
    this.url = media.content.pictures + obj.url;
    this.type = /\.(jpeg|jpg)$/.test(obj.url) ? 'image' : 'movie';
  }
  /**
   * @returns {Promise<void>} -
   * @this {Attachment}
   */
  Attachment.prototype.remove = async function () {
    await remove(this);
  };
  /**
   * @returns {Promise<Attachment[]>} -
   */
  async function fetch() {
    /** @type {angular.IHttpResponse<AttachmentData[]>} */
    const res = await http().get('api/attachments');
    return res.data.map((item) => new Attachment(item));
  }
  /**
   * @returns {Promise<Attachment[]>} -
   */
  async function get() {
    return user.guest ? [] : (attachments ??= fetch());
  }
  /**
   * @param {Attachment} item -
   * @returns {Promise<void>} -
   */
  async function remove(item) {
    await http().delete(`api/attachments/${item.id}`);
  }
  /**
   * @param {File} file -
   * @param {string} [name] -
   * @returns {Promise<Attachment>} -
   */
  async function add(file, name) {
    var data = new FormData();
    data.append('file', file);
    if (name) {
      data.append('name', name);
    }
    try {
      if (user.guest) {
        throw {status: 401};
      }
      const res = await http().post('api/attachments', data, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined},
        dontIntercept: true,
      });
      return new Attachment(res.data);
    } catch (err) {
      if (/** @type {any} */ (err)?.status == 401) {
        user.guestSignup('messenger.signupRequiredForAttachment') || user.clear();
        throw null;
      }
      throw err;
    }
  }

  listen('attachment.added', () => (attachments = undefined));
  listen('attachment.deleted', () => (attachments = undefined));

  return {
    get: get,
    remove: remove,
    add: add,
  };
}
