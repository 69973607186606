/**
 * @module ancg/regard/widget/categories
 */
import {RootScope} from 'acng/zz-app';
import {RegardCategory} from '../model/category';
import {Regard} from '../model/regard';
import templateUrl from 'assets/html/regard/categories.html';
import {asset} from 'acng/core/service/env';

export default 'onswRegardCategories';
export const categoriesComponent: angular.IComponentOptions = {
  bindings: {
    categorySelect: '&',
  },
  templateUrl: asset(templateUrl),
  controller: ['$scope', '$rootScope', controller],
};

type Scope = angular.IScope & {
  categories?: RegardCategory[];
  regards?: Regard[];
  activeCategory?: RegardCategory;
  openCategory(item: RegardCategory): void;
};

type Controller = angular.IController & {
  categorySelect(params: {item: RegardCategory}): void;
};

function controller(this: Controller, $scope: Scope, $rootScope: RootScope) {
  async function fetch() {
    $scope.categories = await RegardCategory.list();
    delete $scope.activeCategory;
    $scope.regards = [];
    $scope.$root.$apply();
  }

  $scope.openCategory = item => {
    $scope.activeCategory = item;
    this.categorySelect({item});
  };

  $rootScope.$on('$translateChangeSuccess', fetch);
  fetch();
}
