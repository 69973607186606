/**
 * Provides the *PaymentBonus* currently active for the *AuthUser*.
 *
 * It is used in the following places
 * - Slideshow on the Start Page
 * - Slideshow in the Sidebar
 * - The Payment Overlay
 * - Special Teaser
 *
 * This module also ensures that a bonus overlay is displayed one minute after a
 * bonus becomes available or one minute after login.
 * If the user is currently in a livecam show or has any other overlay, the
 * display of that overlay is delayed.
 *
 * @module PaymentBonus
 * @author Jacob Viertel <jv@onscreen.net>
 * @see [AuthUser](../../userPool/context/auth-user.js)
 */

import {isArray, setTimeout} from '@acng/frontend-bounty';
import {createElement, createGlobalContext, watch} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';
import {now} from '@acng/frontend-bounty/timing/now.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';

import {ARCHIMEDES} from 'acng/core/service/env.js';
import {inject, isInjectable} from 'acng/core/service/ng.js';
import {discardToken, useToken} from 'acng/userPool/service/token.js';
import {ARCHIMEDES_BONUS, EVENTBUS_BONUS, typeguard} from '../service/typeguard.js';
import {Bonus} from '../model/bonus.js';
import {listen} from 'acng/core/context/event-bus.js';
import {TRANSACTION} from '../service/event-bus.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';

const MODULE = 'payment/context/bonus';
const VERBOSE = true;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

/**
 * @type {?Bonus}
 */
export let bonus = null;

/**
 * @type {import('@acng/frontend-relativity').Context<?Bonus>}
 */
export const ctxBonus = createGlobalContext(bonus);

(() => {
  let expireTimeout = 0;

  /**
   * @param {?import('../service/typeguard.js').BonusData} data
   */
  const putBonusData = (data) => {
    if (data?.name != bonus?.name) {
      bonus = data ? new Bonus(data) : null;
      ctxBonus[CTX_PROVIDE](null, bonus);
    }
  };

  const getBonus = throttle(async () => {
    DEBUG: if (VERBOSE) console.debug(`${MODULE} getBonus`);
    const payment = inject('payment');
    const token = await useToken();
    await payment.loadAndGetCustomerId();

    try {
      /**
       * Archi calls have to use the legacy http factory for now.
       * @type {{data: unknown}}
       */
      const res = await inject('http')().get(`${ARCHIMEDES}/customer/${payment.customerId}/bonus`, {
        headers: {'X-AuthToken': token},
      });

      ASSERT: typeguard(MODULE, res.data, ARCHIMEDES_BONUS());

      const bonusData = !res.data || isArray(res.data) || !res.data.value ? null : res.data;

      DEBUG: if (VERBOSE) console.info(`${MODULE} ${ARCHIMEDES}`, bonusData);

      putBonusData(bonusData);
    } catch (/** @type {any} */ err) {
      if (err?.data && err.data.error == 1001) {
        discardToken(token);
        await getBonus();
        return;
      }

      throw err;
    }
  }, 3000);

  DEBUG: if (VERBOSE) getBonus.enableDebug(MODULE);

  const livecamIsActive = () => {
    if (!isInjectable('Livecam')) {
      return false;
    }

    return inject('Livecam').isActive();
  };

  let overlayShowed = false;
  let overlayTimeout = 0;

  async function overlay() {
    DEBUG: if (VERBOSE) console.debug(`${MODULE} overlay checks`);

    const onsOverlay = inject('onsOverlay');
    const user = inject('user');
    const showAdOverlay = (user.isInSplittyGroup(42, 1) || user.isInGroup('age18-25')) && !user.isPremium();
    await getBonus();
    overlayTimeout = 0;
    if (!bonus && !showAdOverlay) {
      return;
    }
    if (!onsOverlay.count() && !livecamIsActive()) {
      if (showAdOverlay) {
        onsOverlay.create('adOverlay').open();
      } else {
        onsOverlay.create('bonusOverlay', {bonus}).open();
      }
      overlayShowed = true;
    } else {
      overlayTimeout = setTimeout(overlay, 10000);
    }
  }

  const host = createElement();
  DEBUG: host.dataset.module = MODULE;

  watch(
    host,
    ([eventBusUser, bonus], [prevUser]) => {
      DEBUG: console.debug(`${MODULE} watch`, {eventBusUser, bonus, prevUser});

      if (eventBusUser?.id != prevUser?.id) {
        getBonus();
        return;
      }
      if (!bonus) {
        return;
      }

      if (!overlayTimeout && !overlayShowed) {
        overlayTimeout = setTimeout(overlay, 60000);
      }

      if (bonus.expiresAt) {
        clearTimeout(expireTimeout);

        expireTimeout = setTimeout(() => {
          DEBUG: if (VERBOSE) console.info(`${MODULE} bonus expired`);

          ctxBonus[CTX_PROVIDE](null, null);
          setTimeout(getBonus, 1000);
        }, Math.min(1000 * 3600 * 24, bonus.expiresAt.getTime() - now()));
      }
    },
    ctxEventBusUser,
    ctxBonus
  );

  listen('bonus', (data) => {
    ASSERT: typeguard(MODULE, data, EVENTBUS_BONUS());
    DEBUG: if (VERBOSE) console.info(`${MODULE} event bus`, data.bonus);

    putBonusData(data.bonus);
  });

  listen(TRANSACTION, getBonus);
})();
