angular.module('friend')

  .factory('FriendTile', ['AmateurTile', 'Friend', (AmateurTile, Friend) => {
    class FriendTile extends AmateurTile
    {
      hookname() {
        return 'tileFriend';
      }
    }
    return FriendTile;
  }]);
