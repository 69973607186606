/**
 * @module acng/friend
 */
import angular from 'angular';
import {runTracking} from './config/routes';
import './config/feature.js';

angular
  .module('friend', ['pascalprecht.translate', 'amateurPool', 'core'])
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    $translatePartialLoaderProvider => {
      $translatePartialLoaderProvider.addPart('friend');
    },
  ])
  .run(runTracking);
