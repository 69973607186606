/**
 * A component that can display arbitrary content, mostly provided by the router
 * and currently displayed only in the main area of the page.
 *
 * @todo
 * - Make angularjs compile conditional with a template attribute "data-angular-free".
 * - Remove angularjs completely
 *
 * @module
 */

import {Widget, Rendering} from '@acng/frontend-stargazer';
import {rootRoute} from '@acng/frontend-voyager';
import {getAttribute, removeChildNodes} from '@acng/frontend-bounty';
import {connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {inject} from 'acng/core/service/ng.js';
import {ctxView} from '../context/view.js';

const MODULE = 'layout/widget/view';

/**
 * @type {angular.IRootScopeService}
 */
let $rootScope;

/**
 * @type {angular.ICompileService}
 */
let $compile;

/**
 * Show a template which is provided by {@link ctxView}.
 */
export class View extends Widget {
  static consumables = [ctxView];
  static template = false;

  static async setup() {
    $rootScope = inject('$rootScope');
    $compile = inject('$compile');
  }

  /**
   * @type {?angular.IScope}
   */
  #scope = null;

  #originalClassName = this.className;

  [connectedCallback]() {
    ctxView.observe(this, (config) => {
      DEBUG: if (this.hasAttribute('debug')) console.info(`${MODULE} observe`, {element: this, config});

      const name = getAttribute(this, 'name');
      const resource = name && config[name];

      if (!resource) {
        this.#clear();
      } else {
        this.#view(resource);
      }
    });
  }

  [disconnectedCallback]() {
    this.#clear();
  }

  /**
   * @param {HTMLTemplateElement} resource
   */
  #view(resource) {
    DEBUG: if (this.hasAttribute('debug')) console.info(`${MODULE} view`, {element: this, resource});

    const template = new Rendering(resource);
    template.toElement(this, rootRoute.globals);

    // Tweak! The 'compiled' attribute is set by angular and prevents double compile on startup.
    if (getAttribute(this, 'compiled')) {
      const scope = $rootScope.$new(false);
      $compile(this)(scope);
      this.#scope = scope;
    }
  }

  #clear() {
    DEBUG: if (this.hasAttribute('debug')) console.info(`${MODULE} clear`, {element: this});

    removeChildNodes(this);
    this.className = this.#originalClassName;
    this.#scope?.$destroy();
    this.#scope = null;
  }
}
