angular.module('userPool').directive('onswProfileImage', ['userPoolApi', 'media', 'user', '$location', 'Widget', (userPoolApi, media, user, $location, Widget) => {

  return {
    scope: {},
    templateUrl: '/template/userPool.profileImg',
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-form');

      scope.user = user;
      widget.busy(userPoolApi.getProfile().then(function (res) {
        scope.profile = res.data;
        angular.forEach(scope.profile.images, function (images, format) {
          angular.forEach(images, function (image, size) {
            scope.profile.images[format][size] = media.user + image;
          });
        });
        element.addClass('ready');
      }).then(function () {
        userPoolApi.getWaitingImages().then(function (res) {
          scope.images = res.data;
        });
      }));

      scope.uploadImage = function () {

        if (!scope.image) return;

        scope.busy = true;
        var data = new FormData();
        data.append('image', scope.image);

        widget.busy(userPoolApi.uploadImage(data).then(function (res) {
          $location.path('/profile/image/' + res.data.name);
        }).finally(function () {
          scope.busy = false;
        })).catch(function (err) {
          widget.notify(err);
        });
      };
    }
  };
}]);
