import {Error, isArray} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';
import {SONUS} from 'acng/core/service/env.js';
import {useToken, discardToken} from 'acng/userPool/service/token.js';

/**
 * @typedef ResponseData
 * @property {number} id
 * @property {string} name
 * @property {string} type
 * @property {string} path
 * @property {string} duration
 * @property {string} expired
 * @property {string[]} [errors]
 */

/**
 * @param {HTMLElement} context
 * @param {Blob} recordedData
 * @returns {Promise<ResponseData>}
 */
export const uploadVoiceMessage = async (context, recordedData) => {
  const formData = new FormData();
  DEBUG: console.log(recordedData);
  let file = new File([recordedData], `${Date.now()}.mp3`, {
    type: 'audio/mpeg-3',
  });

  formData.append('file', file, file.name);
  const secret = await useToken();
  const res = await fetch(`${SONUS}/voicemessages`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Token': secret,
    },
  });

  /**
   * @type {ResponseData}
   */
  const resJson = await res.json();
  if (res.status !== 200) {
    if (
      res.status === 400 &&
      Array.isArray(resJson.errors) &&
      resJson.errors.length > 0 &&
      resJson.errors[0] === 'sonus_invalid_token'
    ) {
      DEBUG: console.log('voicemessageRecorder.ts', 'failed to upload voice message', 'try again');
      // Token invalid renew and retry
      discardToken(secret);
      return uploadVoiceMessage(context, recordedData);
    }

    DEBUG: console.error(`Failed to upload voicemessage.`, resJson);
    popup(context).error('Failed to upload voicemessage');
    throw new Error(`Failed to upload voicemessage`);
  }

  return resJson;
};

/**
 * @param {HTMLElement} context
 * @param {number} id
 * Sonus voice message ID
 *
 * @returns {Promise<ResponseData>}
 */
export const getVoiceMessage = async (context, id) => {
  const secret = await useToken();
  const res = await fetch(`${SONUS}/voicemessages/${id}`, {
    headers: {
      'X-Token': secret,
    },
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorData = await res.json();
        if (isArray(errorData.errors) && errorData.errors[0] === 'sonus_invalid_token') {
          discardToken(secret);
          return getVoiceMessage(context, id);
        }
      default:
        DEBUG: console.error(`Failed to get voicemessage.`, await res.text());
        popup(context).error('Failed to get voicemessage.');
    }
  }

  return await res.json();
};
