/**
 * Some external public services require a valid {@link WorfToken} for user authentication.
 *
 * Some of these services:
 * - TROI
 * - SONUS
 * - ARCHIMEDES
 *
 * This service module provides functions to acquire such a token.
 *
 * @module
 */

import {rejected} from '@acng/frontend-bounty/promise.js';
import {MINMAX, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {Error} from '@acng/frontend-bounty/types.js';
import {now} from '@acng/frontend-bounty/timing/now.js';

import {get} from 'acng/core/service/backend.js';

const MODULE = 'userPool/service/token';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.info(`Verbose import ${MODULE}`);

/**
 *
 * @see {@link discardToken} to renew an expired key.
 *
 * @returns {Promise<WorfToken>}
 * A valid {@link WorfToken} which may have already expired.
 */
export const useToken = async () => {
  if (!token) {
    try {
      if (!willHaveToken) {
        DEBUG: if (VERBOSE) console.info(`${MODULE} first call`);

        willHaveToken = get(API_TOKEN);
      }

      token = await willHaveToken;
    } catch (reason) {
      DEBUG: console.error(`${MODULE} The loading method failed.`, reason);

      willHaveToken = null;

      throw Error(API_TOKEN);
    }

    ASSERT: typeguard(MODULE, token, MINMAX(40, 40, STRING));
  }

  return token;
};

/**
 * Call if you discovered an invalid {@link WorfToken} in order to acquire a
 * renewed token with the next useToken call.
 *
 * @throws Error if called more than once per {@link minimumRenewInterval} ms.
 *
 * @see {@link useToken}
 *
 * @param {WorfToken} invalid
 *
 * @returns {void}
 */
export const discardToken = (invalid) => {
  if (token !== invalid) {
    return;
  }

  token = null;

  if (now() - renewAt < minimumRenewInterval) {
    DEBUG: console.error(`${MODULE} minimum renewal interval exceeded.`);

    willHaveToken = rejected(Error(API_TOKEN_RENEW));

    return;
  }

  DEBUG: if (VERBOSE) console.info(`${MODULE} renew`);

  renewAt = now();
  willHaveToken = get(API_TOKEN_RENEW);
};

/**
 * @type {WorfToken | null}
 */
let token;

/**
 * The module is initialized without a {@link WorfToken}.
 * The first call to {@link useToken} will fetch a token from the backend.
 * Invalid/expired tokens are supposed to be renewed with {@link discardToken}.
 *
 * @type {Promise<WorfToken> | null}
 */
let willHaveToken;

/**
 * Timestamp (user-agent time) of the last performed call to {@link discardToken}.
 */
let renewAt = 0;

/**
 * The frequency of discard-calls is limited to this interval in milliseconds.
 */
const minimumRenewInterval = 5000;

const API_TOKEN = 'token';
const API_TOKEN_RENEW = `${API_TOKEN}/renew`;

/**
 * The worf token is a secret string with forty characters.
 *
 * @typedef {string} WorfToken
 */
