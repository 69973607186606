angular.module('core')
  .directive('imageOverlay', () => {

    function link(scope, element, attrs) {

      scope.url = attrs.src;

      element.on('wheel', function (e) {
        e.preventDefault();
        e.stopPropagation();
      });

      scope.close = function () {
        element.remove();
      };

    }

    return {
      template: `
<div ng-click="close()" class="imageOverlay">
  <div class="backdrop"></div>
  <img ng-src="{{url}}">
</div>`,
      restrict: 'E',
      link: link
    };
  });
