import {
  HTMLTemplateElement,
  createComment,
  createElement,
  getAttribute,
  hide,
  isFunction,
  isInstanceOf,
  on,
  setStyle,
  show,
} from '@acng/frontend-bounty';
import {connectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {TAGNAME_TEMPLATE} from '@acng/frontend-bounty/dom/type.js';
import {STRING, IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {connectPopupController, createDropdown} from '@acng/frontend-discovery';
import {watch} from '@acng/frontend-relativity';
import {Widget, defineController} from '@acng/frontend-stargazer';
import {ctxAmateur} from 'acng/amateurPool/context/amateur';
import {ctxOnline} from 'acng/amateurPool/service/online';
import {hasFeature} from 'acng/core/service/env';
import {inject} from 'acng/core/service/ng';
import {ctxAuthUser} from 'acng/userPool/context/auth-user';

const MODULE = "layout/config/render";
const VERBOSE = true;
DEBUG: if (VERBOSE) console.warn(`Import verbose ${MODULE}`);

defineController(':popup', (element, value) => {
  DEBUG: if (VERBOSE) console.info(MODULE, ':popup', {element, value});

  connectPopupController(element, value);
});

defineController(':dropdown', (element) => {
  DEBUG: if (VERBOSE) console.info(MODULE, ':dropdown', {element});

  createDropdown(element);
});

defineController(':on', (element, value, scope) => {

  const eventType = value;
  const typeAttrName = `:on:${eventType}`;
  const listenerKey = getAttribute(element, typeAttrName) || eventType;

  ASSERT: typeguard(MODULE, listenerKey, STRING);

  /**
   * @type {?(ev: Event) => void}
   */
  const listener = scope?.[listenerKey];


  if (listener) {
    ASSERT: typeguard(MODULE, listener, IS(Function));

    on(element, eventType, listener.bind(scope));
  }
});

/*
defineControllerClass(
  'if-game-invitable',
  class extends HTMLController {
    static observer = 'if-game-invitable';
    constructor(element) {
      /** @type {HTMLElement} * // TODO NUR Wenn rendering
      this.observer = document.createElement('if-game-invitable');
      setStyle(this.observer, 'display', 'none', 'important');
    }

    connectedCallback() {
      const element = this.element;
      element.before(this.observer);
      console.warn('if-game', this);
      watch(this.observer, ([amateur]) => {
        if (amateur?.hasGames() && amateur.isOnline() && inject('user').hasGames()) {
          show(element);
        } else {
          hide(element);
        }
      }, ctxAmateur, ctxAuthUser, ctxOnline);
    }

    disconnectedCallback() {
      console.warn('if-game', this);
      this.observer.remove();
    }
  }
);
*/

defineController('if', async () => {
  /**
   * @type {Record<string, Function>}
   */
  const cache = {};

  return (element, value, params) => {
    if (!cache[value]) {
      const match = value.split('=>');
      const fn = new Function(
        'p',
        `"use strict"; try { const ${match[0]} = p; return !!(${match[1]}) } catch (reason) { return false }`
      );
      console.warn('IFMA created function', match[0], match[1]);
      cache[value] = fn;
    }

    // TODO Proxy-Object für params + context
    console.warn('IFMA execute function', {element, ...params});
    const isTemplate = isInstanceOf(element, HTMLTemplateElement);
    if (cache[value].call(element, params)) {
      if (isTemplate) {
        element.content.firstElementChild?.setAttribute(':if', value);
        element.replaceWith(element.content);
      }
    } else if (!isTemplate) {
      const template = createElement(TAGNAME_TEMPLATE);
      template.setAttribute(':if', value);
      element.replaceWith(template);
      template.content.append(element);
    }
  };
});
