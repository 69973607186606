/**
 * @module acng/sexicon/directive/sexicon
 */
//TODO: add correct types instead of any
export const name = 'onsdSexicons';
export const type = 'directive';
export const param = directive;
directive.$inject = ['PimpMyRide', 'Sexicon', 'price', 'Amateur'];
/**
 * @param {*} PimpMyRide -
 * @param {types.SexiconConstructor} Sexicon -
 * @param {*} price -
 * @param {*} Amateur -
 * @returns {*} -
 */
function directive(PimpMyRide, Sexicon, price, Amateur) {
  return PimpMyRide(
    'Sexicons',
    'amateur',
    amateur =>
      Sexicon.load().then(() => {
        return {
          categories: Sexicon.categories(),
          getPrice: () => price.get({type: 'sexicon', data: amateur}),
        };
      }),
    amateur => amateur instanceof Amateur
  );
}
/* Types */
import * as types from '../factory/sexicon'; //eslint-disable-line no-unused-vars
