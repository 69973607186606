import {popup} from "@acng/frontend-discovery";

angular.module('userPool')
  .run(['$rootScope', 'userPoolApi', '$translate', 'portal', '$location', function ($rootScope, userPoolApi, $translate, portal, $location) {

    var matches = /\/user\/reactivate\/(.+)/.exec($location.path());

    if (!matches) {
      return;
    }

    userPoolApi.reactivate(matches[1]).then(function (res) {
      $rootScope.$broadcast('userPool.login.success', res.data);
      $translate('userPool.accountReactivatedLong', { 'nickname': res.data.nickname, 'portal': portal }).then(function (text) {
        popup().info(text);
        $location.url('/');
      });
    });

  }]);
