import angular from 'angular';
import {isInstanceOf} from '@acng/frontend-bounty';
import {Dialog} from '../model/dialog.js';

angular.module('messenger').directive('onswNicknameFilter', [
  'Widget',
  'http',
  (Widget, http) => {
    /**
     * @type {angular.IDirectiveLinkFn<angular.IScope & {
     *   list: (string | Dialog)[];
     *   search: (q: string) => string; 
     *   onFilterChange: (arg: {
           item: filtered,
           noMatch: boolean,
           filter: string
     *   }) => void
     * }>}
     */
    function link(scope, element) {
      const widget = new Widget(scope, element, 'ons-form');

      /**
       * @type {?string[]}
       */
      let ids = null;
      let filtered = [];

      var $input = element.find('input');
      $input.on('keydown', function ($event) {
        if ($event.keyCode == 13) {
          $input.blur();
          return false;
        }
        return true;
      });

      /**
       * @param {string} newQ
       */
      scope.search = function (newQ) {
        if (angular.isDefined(newQ) && q != newQ) {
          search(newQ);
        }
        return q;
      };

      var q = '';
      /**
       * @param {string} newQ
       */
      function search(newQ) {
        q = newQ;
        if (!q.length) {
          ids = null;
          update();
          return;
        }

        widget.clear().busy(
          http()
            .get('/api/message-profile/search/' + encodeURI(newQ))
            .then(
              /** @param {{data: string[]}} res */ function (res) {
                ids = res.data;
                update();
              }
            )
        );
      }

      scope.$watchGroup(['list', 'list.length', 'list.changed'], update);

      function update() {
        const a = ids;
        filtered = a
          ? scope.list.filter((i) => (isInstanceOf(i, Dialog) ? a.indexOf(i.id) : a.indexOf(i)) !== -1)
          : scope.list;
        scope.onFilterChange({
          item: filtered,
          noMatch: !!ids && !filtered.length,
          filter: q,
        });
      }
    }

    return {
      scope: {
        list: '<',
        onFilterChange: '&',
      },
      restrict: 'A',
      link: link,
      template:
        '<form class="box"><label><span></span><input type="text" ng-model="search" ng-model-options="{ debounce: 1000, getterSetter: true }" placeholder="{{\'messenger.filterNickname\' | translate}}"><div ons-icon="::close" ng-show="search()" ng-click="search(\'\')" class="box"></div></label></form>',
    };
  },
]);
