/**
 * @module acng/games/widgets/user-stats
 * @typedef {import('acng/amateurPool/factory/Amateur').Amateur} Amateur
 * @typedef {import('../factory/games').GamesService} GamesService
 */
import {media} from 'acng/core/service/env';
import templateUrl from '../../../html/games/onsw-game-user-stats.html';
export const name = 'onswGameUserStats';
export const type = 'component';
/** @type {angular.IComponentOptions} */
export const param = {
  bindings: {
    amateur: '<',
  },
  controllerAs: 'count',
  templateUrl: `${media.assets}/${templateUrl}`,
  /**
   */
  controller: class {
    static $inject = ['games'];
    /** @param {GamesService} games - load stats from games service */
    constructor(games) {
      /**
       * load game stats for amateur.
       * @param {object} changes -
       * @param {angular.IChangesObject<Amateur>} changes.amateur -
       */
      this.$onChanges = async ({amateur: {currentValue: amateur}}) => {
        DEBUG: console.debug('onsw-game-user-stats $onChanges', {amateur});
        this.won = 0;
        this.total = 0;
        if (!amateur) {
          return;
        }
        const stats = await games.getStats(amateur);
        if (stats.amateurId == amateur.id) {
          this.won = stats.won;
          this.total = stats.total;
        }
      };
    }
  },
};
