angular.module('picturePool').run(['PictureSet', 'Picture', '$location',

  function (PictureSet, Picture, $location) {

    Picture.prototype.goto = function () {
      PictureSet.get(this.set_id).then(function (set) {
        set.goto();
      });
    };

    Picture.prototype.slideshow = function () {
      return PictureSet.get(this.set_id)
        .then(set => set.buy(this.id).then(() => set))
        .then(set => $location.url('/sedcard/' + set.nickname + '/pictures/set/' + set.id + '/item/' + this.id))
        .catch(err => {
          if (typeof err == 'object' && err.status == 402) {
            throw null;
          }
          throw err;
        });
    };

    Picture.prototype.slideshowFn = function () {
      return () => this.slideshow();
    };

  }]
);
