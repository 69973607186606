angular.module('amateurPool')

  .factory('ModeratedAmateurTile', ['AmateurTile', 'user', 'fskTransFilter', (AmateurTile, user, fskTransFilter) => {
    class ModeratedAmateurTile extends AmateurTile {
      load(scope, mod) {
        scope.imageFormat = '800';
        if (mod.image_url) {
          scope.image = mod.image_url;
        }

        return super.load(scope, `${mod.amateur_id}`)
          .then(() => {
            if (mod.nickname) {
              scope.nickname = mod.nickname;
            }
            if (mod.description) {
              scope.desc = fskTransFilter(mod.description);
            }
            scope.click = () => user.guestSignup();
          });
      }
      hookname() {
        return 'tileAmateur';
      }
    }
    return ModeratedAmateurTile;
  }]);
