import {NODES, Widget} from '@acng/frontend-stargazer';
import {HTMLInputElement} from '@acng/frontend-bounty/types';
import {closest, createSvg, on, queryAll, replace, setText, toggleClass, whenAll} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';

import {achievementFeature} from '../config/feature.js';
import {inject} from 'acng/core/service/ng';
import {getPackages, redeemPackage} from '../service/http.js';
import {LAYOUT_CLASS_ITEM} from 'acng/layout/config/css-classes.js';

import vipSvg from 'assets/basic/img/vip.svg';
import {asset} from 'acng/core/service/env.js';
import {typeguard} from '@acng/frontend-bounty/typeguard.js';
import {repeatElement} from 'acng/layout/service/repeat.js';

const MODULE = 'achievement/widget/points-to-coins';

/**
 * @type {HTMLElement}
 */
let ICON;

achievementFeature.defineWidget(
  'onsw-points-to-coins',
  class extends Widget {
    static async setup() {
      ICON = await createSvg(asset(vipSvg));
    }
    render() {
      create(this);
    }
  }
);

/**
 * @param {Widget} element
 */
const create = async (element) => {
  const points = inject('user').points;
  const packages = await getPackages(element);

  ASSERT: typeguard(
    MODULE,
    element.nodes,
    NODES({
      repeat: Element,
      radio: HTMLInputElement,
      button: HTMLButtonElement,
      name: Text,
      descr: Text,
      icon: Text,
    })
  );

  const {name, descr, icon, repeat, radio, button} = element.nodes;

  replace(icon, ICON);

  repeatElement(
    repeat,
    await whenAll(
      packages.map(async (pkg) => ({
        value: pkg.name,
        isAllowed: points >= pkg.points,
        label: await achievementFeature.translate('packages.value', {coins: `${pkg.coins}`}),
        descr: await achievementFeature.translate('packages.text', {points: `${pkg.points}`}),
      }))
    ),
    (item) => {
      toggleClass(repeat, 'notAvailable', !item.isAllowed);
      radio.disabled = !item.isAllowed;
      radio.value = `${item.value}`;
      radio.name = 'name';
      setText(name, item.label);
      setText(descr, item.descr);
    }
  );

  /** @type {string} */
  let selectedPackageName;

  // TODO add bounty disable & enable
  on(element, 'change', (evt) => {
    DEBUG: if (!(evt.target instanceof HTMLInputElement)) {
      throw TypeError();
    }
    selectedPackageName = evt.target.value;
    button.disabled = false;
    closest(
      evt.target,
      `.${LAYOUT_CLASS_ITEM}`,
      (item) => {
        for (const elt of queryAll(`.${LAYOUT_CLASS_ITEM}`, element)) {
          toggleClass(elt, 'active', elt == item);
        }
      },
      element
    );
  });

  on(button, 'click', async () => {
    button.disabled = true;
    const pkg = await redeemPackage(selectedPackageName, element);
    if (pkg) {
      const msg = await achievementFeature.translate('pointsRedeemed', {
        coins: `${pkg.coins}`,
        points: `${pkg.points}`,
      });
      await popup(element).info(msg);
    }
    element.applyTemplate();
  });
};
