/**
 * @module acng/games/widgets/games
 * @todo aktive + die letzten 5 entschiedenen Spiele
 * @todo Countdown für timeout anzeigen!
 */
import {on, off, createTemplate, spliceArray, sizeOfArray} from '@acng/frontend-bounty';
import {createPerceptiveHTML, popup} from '@acng/frontend-discovery';
import {createStream} from '@acng/frontend-relativity';

const logName = 'onswGames';
const VERBOSE_DEBUG = false;
export default logName;
export const gamesComponent: angular.IComponentOptions = {
  transclude: true,
  bindings: {
    amateur: '<?',
    onLoad: '&?',
  },
  controller: ['$element', 'games', 'Amateur', '$transclude', Controller],
};

type GamesService = import('../factory/games').GamesService;
type Session = import('../factory/game').Session;
type Amateur = import('acng/amateurPool/factory/Amateur').Amateur;
type AmateurConstructor = import('acng/amateurPool/factory/Amateur').AmateurConstructor;
type SessionScope = angular.IScope & {
  amateur?: Amateur;
  session?: Session;
};
type OnLoad = (params: {sessions: Session[]}) => void;

const scopes = new WeakMap<object, angular.IScope>();

function Controller(
  this: angular.IController &
    angular.IOnChanges & {
      amateur: Amateur | null;
      onLoad: OnLoad | null;
    },
  $element: JQLite,
  games: GamesService,
  Amateur: AmateurConstructor,
  $transclude: angular.ITranscludeFunction
) {
  let sessions: Session[] = [];
  let omitSession: Session | undefined;
  const element = $element[0];

  const perceptive = createPerceptiveHTML(
    element,
    createStream([], async () => spliceArray(sessions, 0, sizeOfArray(sessions))),
    (session, loader) =>
      new Promise((resolve, reject) => {
        $transclude((clone, $scope) => {
          if (!clone || !$scope) {
            reject(Error(`${logName} template required`));
            return;
          }
          const scope = $scope as SessionScope;
          scopes.set(loader, scope);
          if (!session.amateurId) {
            reject(Error(`${logName} amateurId of session #${session.id} is not defined`));
          }
          Amateur.get(session.amateurId)
            .then((amateur) => {
              scope.amateur = amateur;
              session.amateur = amateur;
              scope.session = session;
              resolve(createTemplate("", ...clone));
            })
            .catch(reject);
        });
      }),
    (loader) => scopes.get(loader)?.$destroy()
  );

  DEBUG: if (VERBOSE_DEBUG) perceptive.enableDebug(logName);

  this.$onChanges = async () => {
    try {
      perceptive.disconnect();
      await games.getSessions();
      omitSession = this.amateur ? await games.getLatestGame(this.amateur) : undefined;
      DEBUG: console.debug('games/widgets/games changes', {omitSession});
      sessions = (
        this.amateur //
          ? [...games.sessions.values()]
          : [...games.sessionOfAmateur.values()]
      )
        .filter(
          (s) =>
            (!this.amateur || s.amateurId == this.amateur.id) && //
            s != omitSession
        )
        .sort((a, b) => b.updatedTime - a.updatedTime);
      perceptive.connect();
      this.onLoad?.({sessions});
    } catch (reason) {
      console.error(`${logName} reason`);
      popup(element).error(`${reason}`);
    }
  };

  const listener: EventListener = async (event) => {
    if (!this.amateur) {
      return;
    }
    const session: Session = (event as CustomEvent).detail.session;
    DEBUG: console.debug(`${logName} listener`, {session, omitSession});
    if (session.id != omitSession?.id) {
      this.$onChanges({});
    }
  };
  on(games, 'update', listener);
  this.$onDestroy = () => off(games, 'update', listener);
}
