import {ImageTile} from 'acng/core/tiles/ImageTile';
import {bonus} from '../context/bonus.js';

export class BonusTile extends ImageTile {
  dontSetBg = true;
  /**
   * @param {Scope} scope
   * @param {BonusTileArg} arg
   */
  async load(scope, arg) {
    scope.format = arg.format || 'otm';
    if (this.image) {
      this.image.remove();
    }
    if (!this.isCompiled) {
      scope.$watch(() => bonus, (bonus) => {
        if (scope.bonus == bonus || scope.bonus?.name == bonus?.name) {
          return;
        }
        scope.bonus = bonus;
        this.disabled = !scope.bonus;
        scope.$emit('tile.update', this);
        if (this.disabled) {
          if (arg.overlay) {
            arg.overlay.close();
          }
        }
      });
    }
    if (!scope.bonus) {
      this.disabled = true;
      throw 'no bonus';
    } else {
      this.disabled = false;
    }
    await super.load(scope, {
      caption: 'payment.bonus.otmHeading',
      src: await scope.bonus.getImageUrl(scope.format),
      click: arg.noclick
        ? undefined
        : () => {
            if (arg.overlay) arg.overlay.close();
            scope.bonus?.click();
          },
    });
    this.disabled = false;
    const oldImage = this.box?.querySelector(':scope > img');
    if (oldImage) {
      oldImage.remove();
    }
    this.box?.prepend(this.image);
  }
  /**
   * @returns {string} -
   */
  hookname() {
    return 'bonus';
  }
}

/**
 * @typedef {{
 *   format?: string;
 *   noclick?: boolean;
 *   overlay?: import('acng/core/service/overlay').Overlay;
 * }} BonusTileArg
 */

/**
 * @typedef {angular.IScope & import('acng/core/tiles/ImageTile').ImageTileScope & {
 *   format: string;
 *   bonus: import('acng/payment/model/bonus').Bonus | null;
 * }} Scope
 */
