import {connectPopupController} from '@acng/frontend-discovery';
import {CTX_ALERT, CTX_PROVIDE, CTX_SUBSCRIBE_TAGNAME, CTX_UNOBSERVE} from '@acng/frontend-relativity/minify';
import {createGlobalContext} from '@acng/frontend-relativity';
import {
  EVENT_CLICK,
  HTMLElement,
  ReferenceError,
  addClass,
  createElement,
  getComputedStyleValue,
  on,
  removeClass,
  setText,
  whenAll,
} from '@acng/frontend-bounty';
import {createMutex} from '@acng/frontend-bounty/mutex';

import {messengerFeature} from '../../config/feature.js';
import {getVoiceMessage} from '../../service/sonus.js';
import {spinner} from 'acng/core/service/spinner.js';
import {
  STYLE_ACTIVE,
  STYLE_DISABLED,
  STYLE_INACTIVE,
  STYLE_PLAYING,
  STYLE_WAITING,
} from 'acng/layout/config/style.js';
import {formatTime} from 'acng/messenger/service/format-time.js';
import {t} from 'acng/locale/config/translate';

/**
 * @typedef {import('wavesurfer.js').default} WaveSurfer
 */

/**
 * @typedef TemplateElements
 * @property {HTMLElement} wavesurferContainer
 * @property {HTMLElement} playBtn
 * @property {HTMLElement} pauseBtn
 * @property {Text} seconds
 * @property {Text} duration
 */

const TAGNAME = 'onsw-message-voice';

/**
 * Shared state
 */
const ctxPlaying = createGlobalContext(false);
ctxPlaying[CTX_SUBSCRIBE_TAGNAME](TAGNAME);

const MUTEX = Symbol();
const WAVESURFER = Symbol();

class VoiceElement extends HTMLElement {
  static observedAttributes = ['data-id'];

  [MUTEX] = createMutex();

  constructor() {
    super();
    connectPopupController(this);
  }

  /**
   * @param {string} name
   * @param {string} oldValue
   * @param {string} newValue
   */
  attributeChangedCallback(name, oldValue, newValue) {
    DEBUG: console.debug(`${TAGNAME} attribute changed`, {
      host: this,
      parent: this.parentElement?.parentElement?.className,
      name,
      oldValue,
      newValue,
    });
    const host = this;
    const id = Number(newValue);

    this[MUTEX](async () => {
      // Initial state
      removeClass(host, STYLE_ACTIVE, STYLE_PLAYING, STYLE_DISABLED);
      addClass(host, STYLE_INACTIVE, STYLE_WAITING);
      host[WAVESURFER]?.destroy();
      delete host[WAVESURFER];

      if (!id) {
        return;
      }

      const [template, {default: WaveSurfer}, data] = await spinner(
        whenAll([
          messengerFeature.render(TAGNAME), //
          import('wavesurfer.js'),
          getVoiceMessage(this, id),
        ]),
        host
      );

      template.toElement(host);

      if (!data.path) {
        // Enter disabled state because Voice message is expired.
        removeClass(host, STYLE_INACTIVE, STYLE_WAITING);
        addClass(host, STYLE_DISABLED);
        return;
      }
      const showInfo = /^true$/.test(`${this.dataset.showinfo}`);
      if (showInfo) {
        const p = createElement('p');
        const isMine = /^true$/.test(`${this.dataset.ismine}`);

        if (isMine) {
          p.innerText = await t('messenger.voicemessage.gameInfo2').catch(() => '');
        } else {
          p.innerText = await t('messenger.voicemessage.gameInfo').catch(() => '');
        }
        host.innerHTML = '';
        host.append(p);
        removeClass(host, STYLE_INACTIVE, STYLE_WAITING);
        return;
      }

      const {wavesurferContainer, playBtn, pauseBtn, seconds, duration} = /** @type {TemplateElements} */ (
        template.nodes
      );
      const options = {
        container: wavesurferContainer,
        waveColor: getComputedStyleValue(host, '--voicemessage-wave-color'),
        progressColor: getComputedStyleValue(host, '--voicemessage-wave-progress-color'),
        url: data.path,
        barWidth: 3,
        barRadius: 3,
        height: 24,
        cursorWidth: 0,
      };
      DEBUG: console.debug(TAGNAME, options);

      const wavesurfer = WaveSurfer.create(options);
      wavesurfer.once('decode', (maxTime) => {
        setText(seconds, formatTime(0));
        setText(duration, formatTime(maxTime));

        // Enter active state.
        removeClass(host, STYLE_INACTIVE, STYLE_WAITING);
        addClass(host, STYLE_ACTIVE);

        on(playBtn, EVENT_CLICK, () => this.play());
        on(pauseBtn, EVENT_CLICK, () => this.pause());
      });
      wavesurfer.on('timeupdate', (currentTime) => setText(seconds, formatTime(currentTime)));
      wavesurfer.on('finish', () => host.pause());
      wavesurfer.on('error', (e) => {
        console.error(e);
        throw e;
      });

      this[WAVESURFER] = wavesurfer;
    });
  }

  play() {
    const wavesurfer = this[WAVESURFER];
    if (!wavesurfer) {
      throw ReferenceError();
    }

    addClass(this, STYLE_PLAYING);
    ctxPlaying[CTX_PROVIDE](null, true);
    ctxPlaying[CTX_ALERT](this, (isPlaying) => isPlaying && this.pause());
    wavesurfer.play();
  }

  pause() {
    const wavesurfer = this[WAVESURFER];
    if (!wavesurfer) {
      throw ReferenceError();
    }

    removeClass(this, STYLE_PLAYING);
    ctxPlaying[CTX_UNOBSERVE](this);
    ctxPlaying[CTX_PROVIDE](null, false);
    wavesurfer.pause();
  }
}

messengerFeature.defineElement(TAGNAME, VoiceElement);
