angular.module('core')

  .factory('jsLoader', ['$q', '$document', '$log', ($q, $document, $log) => {

    return src => {

      $log.info('jsLoader require', src);

      const exists = $document[0].head.querySelector('[src="' + src + '"]');
      if (exists) {
        $log.info('jsLoader exists', src);
        return $q.resolve(exists);
      }

      const q = $q.defer();
      const el = $document[0].createElement('script');
      el.src = src;
      el.type = 'text/javascript';
      el.onload = ev => q.resolve(ev);
      el.onerror = err => q.reject(err);
      $document[0].head.appendChild(el);

      return q.promise;
    };
  }]);
