import angular from 'angular';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {ctxReceivedMessage} from '../context/message.js';
import {getDialog} from '../service/dialogs.js';

ctxReceivedMessage[CTX_SUBSCRIBE_TAGNAME]('onsw-message-notification');

angular.module('messenger')
  .component('onswMessageNotification', {
    template: `<div ng-show="amateur" ng-click="click()" class="box" ons-icon="::chat">
                <span style="float:right" ons-icon="::close" ng-click="remove($event)"></span>
                <span class="text" translate="messenger.messageReceived" translate-value-sender="{{amateur.getNickname()}}"></span>
              </div>`,
    controller: ['$scope', '$element', '$timeout', '$injector',
    /**
     * @param {angular.IScope & {
     *   amateur: import('acng/amateurPool/factory/Amateur').Amateur | null;
     *   remove: (ev?: angular.IAngularEvent) => void;
     *   click: Function;
     * }} $scope
     * @param {JQLite} $element
     * @param {angular.ITimeoutService} $timeout
     * @param {angular.auto.IInjectorService} $injector
     */
    ($scope, $element, $timeout, $injector) => {
      /**
       * @type {angular.IPromise<unknown> | undefined}
       */
      let timer;
      $scope.amateur = null;

      $element.addClass('ons-text');

      ctxReceivedMessage[CTX_OBSERVE]($element[0], (msg) => {
        if (!msg) {
          return;
        }

        if ($injector.has('Livecam') && $injector.get('Livecam').isActive()) {
          return;
        }

        if ($injector.has('Hotornot') && $injector.get('Hotornot').isActive()) {
          return;
        }

        $timeout.cancel(timer);

        $scope.amateur = msg.amateur;
        timer = $timeout($scope.remove, 20000);
        angular.element('body').addClass('message-notification-open');
      });

      $scope.click = () => {
        if (!$scope.amateur) {
          return;
        }

        let dialog = getDialog($scope.amateur.id);
        dialog.show();
        $scope.remove();
      };

      /**
       * @param {angular.IAngularEvent} [ev]
       */
      $scope.remove = (ev) => {
        ev?.stopPropagation?.();
        $scope.amateur = null;
        angular.element('body').removeClass('message-notification-open');
      };
    }]

  });
