import {Feature} from 'acng/core/model/feature.js';

const name = 'moviePool';
export default name;
export const moviePoolFeature = new Feature(name);

[
  'onsd-free-movies',
  'onsd-movies',
  'onsd-more-movies',
  'onsw-amateur-movies-link',
  'onsw-free-movie',
  'onsw-movie-info',
].map((name) => moviePoolFeature.defineRemoveWhenHidden(name, true));

moviePoolFeature.defineRemoveWhenHidden('onsw-movie-player');
