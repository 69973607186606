import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {replaceUrl} from '@acng/frontend-relativity/history';
import {addClass, body, removeClass} from '@acng/frontend-bounty';
import {CTX_OBSERVE, CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {VERBOSE} from 'acng/core/service/debug.js';
import {inject, isInjectable, ngDigest} from 'acng/core/service/ng.js';
import {promise} from 'acng/pin/service.js';

import {messengerFeature} from '../config/feature.js';
import {ctxGlobalDialog, setGlobalDialog} from '../context/global-dialog.js';
import {dialogs, getDialog, online} from '../service/dialogs.js';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {isMobile} from 'acng/core/service/env.js';
import {friends} from 'acng/friend/factory/friend.js';

const MODULE = 'messenger/widget/messenger';

const DISCONNECT = Symbol();

class MessengerElement extends Widget {
  static consumables = [ctxGlobalDialog];

  connectedCallback() {
    const scope = inject('$rootScope');

    scope.friends = friends.amateursOfStatus.accept;
    promise.then((pins) => (scope.pins = pins));
    scope.dialogs = dialogs;
    scope.dialogs.online = () => online;
    scope.matches = [];
    if (isInjectable('Hotornot')) {
      const Hotornot = inject('Hotornot');
      Hotornot.init(false).then(() => (scope.matches = Hotornot.matches));
    }

    ctxAmateur[CTX_PROVIDE](this, null);

    super.connectedCallback();

    ctxGlobalDialog[CTX_OBSERVE](this, async (amateurId, previousId) => {
      DEBUG: VERBOSE(this) && console.info(`${MODULE} reflect global dialog`, {amateurId, previousId});

      if (previousId) {
        if (previousId == amateurId) {
          return;
        }
        getDialog(previousId).open = false;
      }

      if (!amateurId) {
        ctxAmateur[CTX_PROVIDE](this, null);
        scope.dialogAmateur = null;
        removeClass(body, 'show-dialog');
        return;
      }

      const amateur = await inject('Amateur').get(amateurId);

      getDialog(amateurId).open = true;

      ctxAmateur[CTX_PROVIDE](this, amateur);
      if (!isMobile) {
        // TODO Improve that
        setTimeout(() => query(this, 'textarea')?.focus());
      }
      scope.dialogAmateur = amateur;
      addClass(body, 'show-dialog');
    });

    this[DISCONNECT] = [scope.$on('$locationChangeSuccess', openDialogFromLocation)];

    openDialogFromLocation();
  }

  disconnectedCallback() {
    this[DISCONNECT]?.forEach((cb) => cb());
    delete this[DISCONNECT];
  }

  closeDialog() {
    setGlobalDialog(null);
    ngDigest();
  }
}

defineCustomWidget(messengerFeature, 'onsw-messenger', MessengerElement);

/**
 * Conditionally open the dialog given by route param and remove the route param
 * WITHOUT pushing the history.
 *
 * @returns {boolean | undefined}
 * `true` if a dialog has been opened, `undefined` otherwhise.
 */
const openDialogFromLocation = () => {
  const re = /[?&]openMessenger=?(\d*)/;
  const match = re.exec(location.hash);
  if (match) {
    const id = match[1] || dialogs[0]?.id;
    if (id) {
      setGlobalDialog(id, true);
      replaceUrl(location.hash.replace(re, ''));
      return true;
    }
  }
};
