import {hasMyPortal} from 'acng/core/service/env';
import {user} from '../service/user';
import {Route, middleware} from '@acng/frontend-voyager';

/**
 * @param {Route} route
 */
export const isPortal = route => middleware(next => {
  DEBUG: route.VERBOSE && console.debug('userPool/config/middleware isPortal', {hasMyPortal, user, route});

  return (next.isPortal = user && !user.guest && hasMyPortal);
})(route);

/**
 * @param {Route} route
 */
export const isNotPortal = route => middleware(() => {
  DEBUG: route.VERBOSE && console.debug('userPool/config/middleware isNotPortal', {hasMyPortal, user, route});

  return !hasMyPortal;
})(route);

/**
 * @param {Route} route
 */
export const onlyUser = route => middleware(() => {
  DEBUG: route.VERBOSE && console.debug('userPool/config/middleware onlyUser', {user, route});

  return user && !user.guest;
})(route);

/**
 * @param {Route} route
 */
export const onlyGuest = route => middleware(() => {
  DEBUG: route.VERBOSE && console.debug('userPool/config/middleware onlyGuest', {user, route});

  return !user || !!user.guest;
})(route);

/**
 * @param {Route} route
 */
export const onlyUserOrPreview = route => middleware(() => {
  DEBUG: route.VERBOSE && console.debug('userPool/config/middleware preview', {user, route});

  return user && (!user.guest || !!user.preview);
})(route);
