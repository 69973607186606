angular.module('core')
  .filter('price', ['$translate', function ($translate) {

    return function (val) {
      val = parseInt(val);
      if (isNaN(val) || val < 1) {
        return $translate.instant('core.free');
      }

      return val;
    };

  }]);
