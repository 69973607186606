import {Rendering, Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {body, createDiv, hide, query, setText, show} from '@acng/frontend-bounty';
import {EVENT_INPUT, EVENT_KEYDOWN, on} from '@acng/frontend-bounty/event';

import {inject} from 'acng/core/service/ng.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';

import {messengerFeature} from '../config/feature.js';
import {ctxMessageDraft, getMessageDraft} from '../context/message-draft.js';
import {translateFeature} from 'acng/locale/config/translate.js';
import {MessageDraft} from '../model/message-draft.js';
import {ctxLivecamSession} from 'acng/livecam/context/session.js';
import {CTX_ALERT, CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {ctxSentMessage} from '../context/message.js';

const MODULE = 'messenger/widget/send';
const VERBOSE_DEBUG = false;
const ATTR_COMPOSITE = 'compose';
const FLYING_TEXTAREA_TARGET_SELECTOR = '.layout-container.messenger textarea';

const TEMPLATE = () =>
  OBJECT({
    form: IS(HTMLFormElement),
    textarea: IS(HTMLTextAreaElement),
    submitButton: IS(HTMLButtonElement),
  });

/**
 * The placeholder translation will be pre-loaded in the widget setup callback.
 *
 * @type {?Rendering}
 */
let writeMe;

/**
 * Set the placeholder, so the user can see the coin price and guests do not.
 *
 * @param {HTMLTextAreaElement} textarea
 * @param {import('acng/amateurPool/factory/Amateur').Amateur} amateur
 */
const setPlaceholder = (textarea, amateur) => {
  if (writeMe) {
    textarea.placeholder = writeMe?.toText({price: `${inject('price').get(amateur)}`});
  }
};

defineCustomWidget(
  messengerFeature,
  'onsw-messenger-send',
  class extends Widget {
    static async setup() {
      const update = async () => {
        const {guest} = inject('user');
        writeMe = await translateFeature
          .render(messengerFeature.name, guest ? 'writeMe' : 'writeMePrice')
          .catch((reason) => {
            console.error(reason);
            return null;
          });
      };
      await update();
      inject('$rootScope').$on('userPool.login.success', update);
      new MutationObserver(update).observe(body, {attributes: true, attributeFilter: ['lang']});
    }

    render() {
      render(this);
    }
  },
  [ctxAmateur, ctxMessageDraft, ctxLivecamSession, ctxSentMessage]
);

/**
 * @param {Widget} host
 */
const render = (host) => {
  ASSERT: typeguard(MODULE, host.nodes, TEMPLATE());

  const {form, textarea, submitButton} = host.nodes;

  on(textarea, EVENT_KEYDOWN, (ev) => {
    if (ev.key == 'Enter' && !ev.shiftKey) {
      DEBUG: console.info(`${MODULE} submit (enter without shift)`);
      submitButton.click();
      ev.preventDefault();
    }
  });

  if (host.getAttribute(ATTR_COMPOSITE) === null) {
    ctxAmateur[CTX_OBSERVE](host, (amateur) => connectAmateur(host, amateur));
  } else {
    on(textarea, EVENT_INPUT, () => {
      const message = getMessageDraft(host);
      if (message) {
        message.body = textarea.value;
      }
    });

    ctxMessageDraft[CTX_OBSERVE](host, (composer, previous) => {
      if (!composer) {
        return;
      }
      if (composer.voice) {
        hide(host);
      } else {
        show(host);
      }
      if (composer.amateur !== previous?.amateur) {
        connectAmateur(host, composer.amateur);
      }
      composer.focusElement = textarea;
      textarea.value = composer.body;

      form.onsubmit = async (ev) => {
        ev.preventDefault();
        submitButton.disabled = true;
        flyingTextarea(host, textarea);
        composer.body = textarea.value;
        await composer.send(host);
        submitButton.disabled = false;
      };
    });
  }
};

/**
 * @param {Widget} host
 * @param {import('acng/amateurPool/factory/Amateur').Amateur | null} amateur
 */
const connectAmateur = (host, amateur) => {
  ASSERT: typeguard('', host.nodes, TEMPLATE());
  const {form, textarea, submitButton} = host.nodes;

  submitButton.disabled = !amateur;
  textarea.disabled = !amateur;
  textarea.value = '';
  form.onsubmit = null;

  if (!amateur) {
    DEBUG: if (VERBOSE_DEBUG) console.info(`${MODULE} no amateur`);
    return;
  }

  ctxLivecamSession[CTX_OBSERVE](host, (session, previous) => {
    DEBUG: if (VERBOSE_DEBUG)
      console.debug(`${MODULE} observe livecam session`, {
        current: {...session},
        previous: {...previous},
      });
    setPlaceholder(textarea, amateur);
  });

  form.onsubmit = async (ev) => {
    ev.preventDefault();
    submitButton.disabled = true;
    flyingTextarea(host, textarea);
    const composer = new MessageDraft(amateur);
    composer.body = textarea.value;
    await composer.send(host);
    textarea.value = composer.body;
    submitButton.disabled = false;
  };
};

/**
 * @param {Element} host
 * @param {HTMLTextAreaElement} textarea
 */
const flyingTextarea = (host, textarea) => {
  const target = query(FLYING_TEXTAREA_TARGET_SELECTOR);
  if (!target || target === textarea) {
    return;
  }

  const div = createDiv('flying-chat-box');
  setText(div, textarea.value);

  ctxSentMessage[CTX_ALERT](host, () => {
    const rect = textarea.getBoundingClientRect();
    Object.assign(div.style, {
      'position': 'fixed',
      'z-index': '1000',
      'top': `${rect.top}px`,
      'left': `${rect.left}px`,
      'height': `${rect.height}px`,
      'width': `${rect.width}px`,
      'transform-origin': 'top left',
    });
    textarea.style.opacity = '0';
    const targetRect = target.getBoundingClientRect();
    document.body.append(div);
    div
      .animate(
        [
          {transform: 'translate3D(0,0,0)'},
          {
            transform: `translate3D(${(targetRect.left - rect.left) / 2}px, ${
              (targetRect.top - rect.top) / 2
            }px, 0) scale(2)`,
          },
        ],
        {
          duration: 500,
          easing: 'ease-out',
          fill: 'both',
        }
      )
      .finished.then(() => {
        const newRect = target.getBoundingClientRect();
        return div.animate(
          [
            {
              transform: `translate3D(${(targetRect.left - rect.left) / 2}px, ${
                (targetRect.top - rect.top) / 2
              }px, 0) scale(2)`,
            },
            {
              transform: `translate3D(${newRect.left - rect.left}px, ${newRect.top - rect.top}px, 0) scale(${
                newRect.width / rect.width
              }, ${newRect.height / rect.height})`,
            },
          ],
          {
            duration: 500,
            easing: 'ease-in',
            fill: 'both',
          }
        ).finished;
      })
      .then(() => {
        getMessageDraft(target)?.focusIfNotMobile();
        textarea.style.opacity = '1';
        div.remove();
      });
  });
};
