import angular from 'angular';
import {LivecamSession} from '../model/session.js';

angular.module('livecam')
  .directive('onswLivecamClose', ['$location', ($location) => {
    return {
      scope: {},
      template: '<span class="box" ons-icon="::close" ng-click="click()"><span class="label" translate="core.close"></span></span>',
      link: (scope, element) => {
        element.addClass('ons-item');

        scope.click = () => {
          $location.url(LivecamSession.prevPath).replace();
        };
      }
    };
  }]);
