/**
 * @module acng/messenger/factory/payttachment
 */
export const //
  type = 'factory',
  name = 'payttachment',
  param = factory;
import {media} from 'acng/core/service/env';
import playButtonSrc from 'assets/basic/img/play.svg';
factory.$inject = ['http'];
/**
 * @param {core.Http} http -
 * @returns {PayttachmentConstructor} -
 */
function factory(http) {
  /**
   * @implements {Payttachment}
   */
  class CPayttachment {
    /**
     * @param {PayttachmentData} obj -
     */
    constructor(obj) {
      this.id = obj.id;
      this.type = obj.type;
      this.price = obj.price;
      this.amateurId = obj.amateur_id;
      this.file = obj.file ? media.content.pictures + obj.file : null;
      this.thumb = media.content.pictures + obj.thumb;
      this.size = obj.size;
      this.dummy = false;
      if (this.type === 'movie') {
        this.thumb = `${media.assets}/${playButtonSrc}`;
        this.dummy = true;
      }
    }
    /**
     * @param {number} id -
     * @returns {Promise<Payttachment>} -
     */
    static async get(id) {
      const res = await http().get(`/api/payttachment/${id}`, {dontIntercept: true});
      return new this(res.data);
    }
    /**
     *
     */
    async buy() {
      const res = await http().post(`/api/payttachment/${this.id}/buy`, {}, {noMessageBag: true});
      return new CPayttachment(res.data);
    }
  }
  return CPayttachment;
}
/**
 * @typedef {object} PayttachmentBase
 * @property {number} id -
 * @property {string} type -
 * @property {number} price -
 * @property {?string} file -
 * @property {string} thumb -
 * @property {number} size -
 */
/**
 * @typedef {object} PayttachmentDataTrait
 * @property {string} amateur_id -
 */
/**
 * @typedef {object} PayttachmentTrait
 * @property {string} amateurId -
 * @property {boolean} dummy -
 * @property {Function} buy -
 */
/**
 * @typedef {PayttachmentBase & PayttachmentDataTrait} PayttachmentData
 * @typedef {PayttachmentBase & PayttachmentTrait} Payttachment
 */
