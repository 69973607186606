/**
 * @module acng/games/factory/session
 * @typedef {import('acng/amateurPool/factory/Amateur').Amateur} Amateur
 * @typedef {import('acng/userPool/factory/user').User} User
 */
import angular from "angular";

const INVITATION_PENDING = 1 << 0;
const INVITATION_ACCEPTED = 1 << 1;
const INVITATION_DECLINED = 1 << 2;
const INVITATION_TIMED_OUT = 1 << 3;
const SESSION_TURN_USER = 1 << 4;
const SESSION_TURN_PARTNER = 1 << 5;
const SESSION_DECIDED = 1 << 6;
const DECISION_WINNER_USER = 1 << 7;
const DECISION_WINNER_PARTNER = 1 << 8;
const DECISION_DRAW = 1 << 9;
const REASON_TURN = 1 << 10;
const REASON_TIMEOUT = 1 << 11;
/**
 */
export class Session {
  /**
   * For convenience. The original {@link core.InvitationData} provides only the 'uid'
   * @type {string}
   */
  amateurId = '';
  /**
   * @type {Amateur | null}
   */
  #amateur = null;
  /**
   * @type {Date | null}
   */
  expiresAt = null;
  /**
   * @param {number} id - The playpal-id of the session and the invitation
   */
  constructor(id) {
    this.id = id;
    this.state = 0;
  }
  /**
   * @param {Amateur} amateur -
   */
  set amateur(amateur) {
    if (amateur.id !== this.amateurId) {
      throw new Error(`cannot assign amateur #${amateur.id} to session #${this.id}`);
    }
    this.#amateur = amateur;
  }
  /**
   * @returns {Amateur} -
   */
  get amateur() {
    if (!this.#amateur) {
      throw new Error(`amateur ${this.amateurId} was not assigned to session ${this.id}`);
    }
    return this.#amateur;
  }
  /**
   * @param {User} user - Authenticated user
   * @param {import("../service/typeguard").InvitationData} data - playpal data object
   */
  updateWithInvitation(user, data) {
    const {createdAt, updatedAt, expiresAt, gameId, fromId, toId, status} = data; // eslint-disable-line max-len
    DEBUG: console.debug('games/factory/session updateWithInvitation', {data, session: this});
    if (fromId !== user.uid) {
      console.error('games GameSession updateWithInvitation data does not belong to this user.');
      return;
    }
    const uid = toId.split('-');
    if (parseInt(uid[0]) !== 2) {
      console.error('games GameSession updateWithInvitation data with unkown partnerpool.');
      return;
    }
    this.invitedAt = new Date(createdAt);
    this.expiresAt = expiresAt ? new Date(expiresAt) : null;
    this.updatedAt = new Date(updatedAt);
    this.gameId = gameId;
    this.amateurId = uid[1];
    /** @type {Record<string, number>} */
    const c = {
      pending: INVITATION_PENDING,
      accepted: INVITATION_ACCEPTED,
      declined: INVITATION_DECLINED,
      timedOut: INVITATION_TIMED_OUT,
    };
    this.state = (this.state & 0xfff0) + c[status];
  }
  /**
   * @param {User} user - Authenticated user
   * @param {import("../service/typeguard").SessionData} data - playpal data object
   */
  updateWithSession(user, data) {
    const {createdAt, updatedAt, status, decision, decisionReason, winnerId, turnId, turnExpiresAt} = data; // eslint-disable-line max-len
    DEBUG: console.debug('games/factory/session updateWithSession', {data, session: this});
    this.startAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
    this.expiresAt = turnExpiresAt ? new Date(turnExpiresAt) : null;
    let s = 0;
    if (status === 'pending') {
      s |= turnId === user.uid ? SESSION_TURN_USER : SESSION_TURN_PARTNER;
    } else if (status === 'decided') {
      s |= SESSION_DECIDED;
    }
    if (decision === 'winner') {
      s |= winnerId === user.uid ? DECISION_WINNER_USER : DECISION_WINNER_PARTNER; // eslint-disable-line max-len
    } else if (decision === 'draw') {
      s |= DECISION_DRAW;
    }
    if (decisionReason === 'turn') {
      s |= REASON_TURN;
    } else if (decisionReason === 'timeout') {
      s |= REASON_TIMEOUT;
    }
    this.state = s + (this.state & 0xf);
  }
  /**
   * @returns {string} Translation for current {@link games.Session#state}
   */
  getDescription() {
    if (this.state & INVITATION_PENDING) {
      return 'games.wait';
    }
    if (this.state & INVITATION_DECLINED) {
      return 'games.canceled';
    }
    if (this.state & INVITATION_TIMED_OUT) {
      return 'games.timeout';
    }
    if (this.state & SESSION_TURN_USER) {
      return 'games.userTurn';
    }
    if (this.state & SESSION_TURN_PARTNER) {
      return 'games.wait';
    }
    if (this.state & DECISION_WINNER_USER) {
      if (this.state & REASON_TIMEOUT) {
        return 'games.wonTimeout';
      }
      return 'games.won';
    }
    if (this.state & DECISION_WINNER_PARTNER) {
      if (this.state & REASON_TIMEOUT) {
        return 'games.lostTimeout';
      }
      return 'games.lost';
    }
    if (this.state & DECISION_DRAW) {
      return 'games.draw';
    }
    console.warn('games/factory/session state without translation', {session: this, state: this.getState()});
    return '';
  }
  /**
   * @returns {State} state object
   */
  getState() {
    /** @type {State} */
    const states = {
      isPending: this.state === INVITATION_PENDING,
      isDecided: !!(this.state & SESSION_DECIDED),
      isWon: !!(this.state & DECISION_WINNER_USER),
      isLost: !!(this.state & DECISION_WINNER_PARTNER),
      isDraw: !!(this.state & DECISION_DRAW),
      hasTimeout: !!(this.state & (INVITATION_TIMED_OUT | REASON_TIMEOUT)),
      hasTurn: !!(this.state & (SESSION_TURN_USER | SESSION_TURN_PARTNER)),
      hasPayback: !!(
        this.state & (INVITATION_TIMED_OUT | INVITATION_DECLINED) || //
        (this.state & REASON_TIMEOUT && this.state & DECISION_WINNER_USER)
      ),
      userTurn: !!(this.state & SESSION_TURN_USER),
      amateurTurn: !!(this.state & SESSION_TURN_PARTNER),
      hasSession: !!(this.state & 0xff0),
      isDeclined: !!(this.state & INVITATION_DECLINED),
      isActive: !(this.state & (SESSION_DECIDED | INVITATION_TIMED_OUT | INVITATION_DECLINED)), // eslint-disable-line max-len
    };
    return states;
  }
  /**
   * @param {...string} add -
   * @returns {string[]} -
   */
  getActiveCss(...add) {
    const res = Object.entries(this.getState())
      .filter(([, enabled]) => enabled) //
      .map(([css]) => css);
    res.push(...add);
    return res;
  }
  /**
   * @returns {Promise<string>} Playpal token needed to start the game client
   */
  async postToken() {
    const http = angular.element(document).injector().get('http');
    const res = await http().post(`/api/games/token/${this.id}`);
    const {token} = res.data;
    return token;
  }
  /**
   * @returns {number} unix timestamp in ms
   */
  get updatedTime() {
    return this.invitedAt?.getTime() ?? 0;
  }
}
/**
 * {@link Session}
 * @typedef State
 * @property {boolean} isPending -
 * @property {boolean} isWon - {@link Session} is decided and the currently logged in user has won
 * @property {boolean} isLost -
 * @property {boolean} isDecided -
 * @property {boolean} isDraw -
 * @property {boolean} hasTimeout -
 * @property {boolean} hasTurn -
 * @property {boolean} hasPayback -
 * @property {boolean} userTurn -
 * @property {boolean} amateurTurn -
 * @property {boolean} hasSession -
 * @property {boolean} isDeclined -
 * @property {boolean} isActive -
 */
