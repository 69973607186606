angular.module('ons-LaForge-client')
  .run(['onsLaforgeEvents', onsLaforgeEvents => {

    class Visit extends onsLaforgeEvents.LogEvent {

      constructor(user, amateur) {
        super();
        this.type = 'Visit';
        this.user = user;
        this.amateur = amateur;
        this.start = Date.now();
      }

      getDTO() {
        const dto = super.getDTO();
        dto.user = this.user;
        dto.amateurId = this.amateur.id;
        dto.secs = this.secs;

        return dto;
      }

      endVisit() {
        this.secs = Math.round((Date.now() - this.start) / 1000);
        return this;
      }

    }

    onsLaforgeEvents.ProfileVisit = class ProfileVisit extends Visit {
      constructor(user, amateur) {
        super(user, amateur);
        this.type = 'ProfileVisit';
      }

      endVisit() {
        super.endVisit();
        this.amateur.trackVisit(this.secs);
        return this;
      }
    };
    onsLaforgeEvents.MoviesVisit = class MoviesVisit extends Visit {
      constructor(user, amateur) {
        super(user, amateur);
        this.type = 'MovieVisit';
      }
    };
    onsLaforgeEvents.PicturesVisit = class PicturesVisit extends Visit {
      constructor(user, amateur) {
        super(user, amateur);
        this.type = 'PictureVisit';
      }
    };

  }]);
