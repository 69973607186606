import Cropper from 'cropperjs';

(function () {
  'use strict';

  Cropper.setDefaults({});

  angular.module('userPool')
    .directive('crop', function () {

      function link(scope, element) {
        var cropper;
        var img = element.get(0);
        var format = 'normal';
        var formats = {
          wide: 16 / 9,
          normal: 4 / 3
        };

        img.onload = function () {

          var ratio = element.width() / element.height();

          if (ratio > 1.6) {
            format = 'wide';
          }

          cropper = new Cropper(element.get(0), {
            viewMode: 1,
            aspectRatio: formats[format],
            checkCrossOrigin: false,
            zoomable: false,
            guides: false,
            movable: false,
            restore: false,
            crop: function (e) {
              scope.crop(e.detail, format);
            },
            ready: function () {
              var ratioPW = angular.element('<div class="crop-pw">');
              angular.element(this).parent().find('.cropper-view-box').append(ratioPW);
              ratioPW.addClass(format);
            }
          });

        };

      }

      return {
        restrict: 'A',
        link: link
      };

    });

})();
