angular.module('core')
  .directive('fileUpload', function () {

    function link(scope, element) {

      var input = element.find('input');

      element.find('div').on('click', function (ev) {
        input.click();
        ev.preventDefault();
      });

      input.on('change', function (ev) {
        scope.$applyAsync(function () {
          scope.file = ev.target.files[0];
          element.addClass('ng-filled');
          scope.filename = scope.file.name;
        });
      });

      scope.$watch('file', function () {
        if (scope.file instanceof File) return;
        element.removeClass('ng-filled');
        input.val(null);
        scope.filename = undefined;
      });


    }

    return {
      link: link,
      scope: { file: '=', placeholder: '@' },
      restrict: 'E',
      template: '<div>{{filename||placeholder|translate}}</div><input type="file">'
    };

  });
