angular.module('livecam')

  .factory('LivecamButton', ['Livecam', 'user', 'Widget', 'isMobile', '$log', (Livecam, user, Widget, isMobile, $log) => {

    return class LivecamButton extends Widget
    {

      static params() {
        return {
          amateurId: '<',
          label: '<?',
          onsClick: '&?',
          type: '<'
        };
      }

      static template() {
        return `<span class="box" ng-click="click($event)">
            <span class="label">{{ label || 'livecam.start.' + type | translate}}</span>
            <span class="additional" ng-hide="user.guest">{{ type != 'free' ? 'livecam.price' : 'livecam.free' | translate:{price:price} }}</span>
        </span>`;
      }

      constructor(scope, element) {
        super(scope, element, 'ons-item');
        scope.user = user;

        let livecam = null;

        this.watch({
          watch: [
            () => livecam = Livecam.get(scope.amateurId),
            () => livecam instanceof Livecam && livecam.isAvailable(scope.type),
            'type',
            () => livecam instanceof Livecam && livecam.toycontrol],

          reset: (v, old) => {
            element.removeClass(old[2]);
            scope.price = null;
            scope.click = null;
          },
          validate: v => v[1],
          done: v => {
            element.addClass(v[2]);
            element.toggleClass('has-toy', livecam.toycontrol);
            scope.price = livecam.getPrice(v[2]);
            scope.click = $event => {
              $event.stopPropagation();
              const start = () => {
                this.ifNotBusy(() => livecam.start(scope.type))
                  .catch(err => this.notify(err));
              };
              if (!scope.onsClick) {
                start();
              } else {
                scope.onsClick({ isMobile, start });
              }
            };
          }
        });
      }
    };

  }])

  .directive('onswLivecamButton', ['LivecamButton', LivecamButton => {
    return LivecamButton.make(LivecamButton);
  }]);
