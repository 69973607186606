import angular from 'angular';
import {onsHistory} from 'acng/core/factory/history';
import {publish} from 'acng/core/context/event-bus.js';
import {ctxBonus} from '../context/bonus.js';
import {CTX_OBSERVE, CTX_SUBSCRIBE_CLASSNAME} from '@acng/frontend-relativity/minify';

ctxBonus[CTX_SUBSCRIBE_CLASSNAME]('payment-box');

/**
 * @typedef {angular.IScope & {
 *   freeCoinsForDoi: boolean;
 *   step: number;
 *   bonus: boolean;
 *   rebillRedirectUrl: string | null;
 * }} Scope
 */

angular.module('payment').controller('paymentBuyCtrl', ['$scope', '$window', 'iOS', 'payment', '$element', 'Widget', 'user', 'onsOverlay', 
  /**
   * @param {Scope} $scope
   * @param {angular.IWindowService} $window
   * @param {boolean} iOS
   * @param {import('acng/payment/factory/payment').Payment} payment
   * @param {JQLite} $element
   * @param {import('acng/core/factory/widget').WidgetConstructor} Widget
   * @param {import('acng/userPool/factory/user').User} user
   * @param {import('acng/core/service/overlay').OverlayConstructor} onsOverlay
   */
  function ($scope, $window, iOS, payment, $element, Widget, user, onsOverlay) {
    const widget = new Widget($scope, $element);
    widget.notifyElement = $element.find('.overlay-content');

    let skipHistory = false;

    $scope.freeCoinsForDoi = !user.doi_at && user.getsFreeCoinsForDoi();

    const freeCoinsPackage = $scope.freeCoinsForDoi ?
      { name: 'FreeCoins', price: 0, value: user.getFreeCoinsValue(), type: 'FreeCoins' } :
      null;

    $scope.step = 1;

    ctxBonus[CTX_OBSERVE]($element[0], (bonus) => {
      $scope.bonus = !!bonus;
    });

    $scope.rebillRedirectUrl = null;

    onsHistory.replace('payment', {
      methodId: null,
      packageId: null
    });
    onsHistory.addEventListener('payment', paymentHistory);
    $scope.$on('$destroy', () => onsHistory.removeEventListener('payment', paymentHistory));

    /**
     * @param {CustomEvent} ev
     */
    function paymentHistory(ev) {
      if (!ev.detail.state.methodId) {
        $scope.$apply(() => {
          if (!$scope.payment.method) {
            $scope.step = $scope.methodStep;
          }
          $scope.payment.method = null;
          $scope.resetMobilePayment();
        });
      } else {
        $scope.methods.forEach(m => {
          if (ev.detail.state.methodId == m.id) {
            skipHistory = true;
            $scope.$apply(() => $scope.payment.method = m);
          }
        });
      }
      if (!ev.detail.state.packageId) {
        $scope.$apply('payment.package = null');
      } else {
        ($scope.products[$scope.payment.country.currency] || $scope.products[$scope.payment.defaultCurrency.iso_code]).forEach(p => {
          if (ev.detail.state.packageId == p.id) {
            skipHistory = true;
            $scope.$apply(() => $scope.payment.package = p);
          }
        });
      }
    }

    $scope.siteStepping = [[1, 1], [2, 2], [3, 3]];
    if (payment.config.flow === 'productsFirst') {
      $scope.packageStep = 1;
      $scope.methodStep = 2;
      $scope.siteStepping[0][1] = 2;
      $scope.siteStepping[1][1] = 1;
      $scope.backButtonText = 'payment.backToPackageSelection';
    } else {
      $scope.packageStep = 2;
      $scope.methodStep = 1;
      $scope.backButtonText = 'payment.backToMethodSelection';
    }

    payment.create().then(function (paymentInst) {
      $scope.payment = paymentInst;
      $scope.$watchGroup(['payment.method', 'payment.package'], startTransaction);
      return $scope.payment.getIndex().then(function (data) {

        if (freeCoinsPackage) {
          for (const currency in data.packages) {
            if (!data.packages.hasOwnProperty(currency)) {
              continue;
            }

            data.packages[currency].unshift(freeCoinsPackage);
            data.packages[currency].pop();
          }
        }

        $scope.methods = data.methods;
        $scope.products = data.packages;
        $scope.mobilePayment = data.mobilePayment;
        if ($scope.mobilePayment) {
          $scope.$watch('mobilePayment.package', startMobilePaymentTransaction);
        }
        $scope.$applyAsync();
      });
    });

    $scope.isMobilePaymentAvailable = (country, normalPackage) => {
      return $scope.mobilePayment && $scope.mobilePayment.method.country_restrictions.includes(country) &&
    normalPackage && $scope.mobilePayment.method.packages.find(pkg => normalPackage.price === pkg.price);
    };

    $scope.goBackToStep = () => onsHistory.back();

    $scope.openExternal = function (paymentInstance) {
      if (paymentInstance.isLegalAgeConfirmationRequired()) {
        paymentInstance.setLegalAgeConfirmationClicked(true);
      }

      $window.open(paymentInstance.transaction.rawPspUrl);
      pushHistory();
      $scope.step = 4;
    };

    $scope.$watch('step', function () {
      scrollElement.animate({
        scrollTop: 0
      });
    });

    function pushHistory() {
      if (skipHistory) {
        skipHistory = false;
        return;
      }
      onsHistory.push('payment', {
        methodId: payment.config.flow === 'productsFirst' ? null : $scope.payment.method && $scope.payment.method.id,
        packageId: payment.config.flow === 'methodsFirst' ? null : $scope.payment.package && $scope.payment.package.id,
        step: $scope.step
      });
    }

    const scrollElement = $element.find('.overlay-content > .box');

    $scope.selectPackage = pkg => {
      if (pkg.type === 'FreeCoins') {
        onsOverlay.create('doiHint').open();
        $scope.overlay && $scope.overlay.close();
        return;
      }

      $scope.payment.package = pkg;
      $scope.secondClick(null, pkg);
    };

    $scope.secondClick = (method, pkg) => {
      let p = $scope.payment;
      if (!(p.method && p.package)) return;
      if (p.transaction && p.transaction.target === 'joinpage_new_window' &&
    p.transaction.method_id === (method ? method.id : p.method.id) &&
    p.transaction.package_id === (pkg ? pkg.id : p.package.id))
      {
        if (p.isLegalAgeConfirmationRequired()) {
          scrollElement.animate({ scrollTop: scrollElement[0].scrollHeight });
          return;
        }
        $scope.openExternal(p);
        return;
      }
    };

    var closeNotification = null;
    async function startTransaction() {
      var p = $scope.payment;

      if (p.package && p.package.type === 'FreeCoins') {
        return;
      }

      if ((p.method || p.package) && !(p.method && p.package)) {
        if ($scope.step == 1) {
          pushHistory();
        }
        $scope.step = 2;
        $scope.payment.transaction = null;
        return;
      }

      skipHistory = false;

      if (!p.method || !p.package) {
        $scope.step = 1;
        $scope.payment.transaction = null;
        return;
      }

      $scope.callbackTx = null;
      $scope.resetMobilePayment();

      if (typeof closeNotification === 'function') {
        closeNotification();
        closeNotification = null;
        widget.notifyElement.removeClass('error');
      }

      try {

        const res = await p.start();

        $scope.rebillRedirectUrl = null;
        if (iOS) p.transaction.target = 'joinpage_new_window';
        if (p.transaction.target === 'joinpage') {
          pushHistory();
          $scope.step = 3;
        }

        if (p.method.verified) {
          $scope.rebill = function () {
            pushHistory();
            $scope.rebillRedirectUrl = null;
            $scope.step = 4;

            payment.startFollow(res)
              .then(followResult => {
                if (followResult.data && followResult.data.url) {
                  $scope.rebillRedirectUrl = followResult.data.url;
                }
              }).catch(function (err) {
                res.code = err.data.error;
                publish({
                  type: `user.transaction`,
                  transaction: res,
                });
              });
          };
        } else {
          $scope.rebill = undefined;
        }
      } catch (err) {
        widget.notifyElement.addClass('error');
        if (!err.data) {
          err.data = {};
        }
        closeNotification = widget.notify(err.data.message || 'payment.error.' + (err.data.error || 100));
      }

      $scope.$applyAsync();
    }

    this.startTransaction = startTransaction;

    $scope.resetMobilePayment = () => {
      if (!$scope.mobilePayment) {
        return;
      }

      $scope.mobilePayment.transaction = null;
      $scope.mobilePayment.package = null;
    };

    $scope.selectMobilePaymentPackage = pkg => {
      if (!pkg) {
        return;
      }

      const mp = $scope.mobilePayment;
      if (!mp.package || mp.package.id !== pkg.id) {
        mp.package = pkg;
        return;
      }

      if (mp.transaction && mp.transaction.package_id === pkg.id && !mp.isLegalAgeConfirmationRequired()) {
        $scope.openExternal(mp);
      }
    };

    function startMobilePaymentTransaction() {
      const mp = $scope.mobilePayment;
      if (!mp.package) {
        mp.transaction = null;
        return;
      }

      $scope.payment.method = null;
      $scope.payment.transaction = null;
      $scope.callbackTx = null;

      mp.transaction = null;
      skipHistory = false;

      if (typeof closeNotification === 'function') {
        closeNotification();
        closeNotification = null;
        widget.notifyElement.removeClass('error');
      }

      mp.country = $scope.payment.country;

      mp.start()
        .catch(function (err) {
          widget.notifyElement.addClass('error');
          if (!err.data) {
            err.data = {};
          }
          closeNotification = widget.notify(err.data.message || 'payment.error.' + (err.data.error || 100));
        });
    }

    function messageHandler(event) {
      if (event.data === 'archimedes.transaction.started') {
        $scope.$apply(() => {
          $scope.rebillRedirectUrl = null;
          $scope.step = 4;
          if ($scope.overlay && $scope.payment.transaction && !payment.isTransactionInSession($scope.payment.transaction.id)) {
            $scope.overlay.close();
          }
        });
      }
    }
    $window.addEventListener('message', messageHandler, false);
    $scope.$on('$destroy', function () {
      $window.removeEventListener('message', messageHandler);
    });

  }
]);
