/**
 * @todo Collect bounty.
 * @module
 */
import {hasAttribute} from "@acng/frontend-bounty";

const ATTRIBUTE = 'debug';
const OFF = 'off';
const SELECTOR = `[${ATTRIBUTE}]`;

/**
 * @param {Element} element
 */
export const VERBOSE = (element) => {
  return hasAttribute(element, 'debug');
  //const closest = element.closest(SELECTOR);
  //
  //return closest && !hasAttribute(closest, ATTRIBUTE, OFF);
};
