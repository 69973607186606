import angular from 'angular';

import {getMessageDraft} from '../context/message-draft.js';
import {listen} from 'acng/core/context/event-bus.js';

angular.module('messenger').directive('onswAttachments', [
  'Widget',
  'attachmentList',
  /**
   * @param {import('acng/core/factory/widget').WidgetConstructor} Widget
   * @param {import('../factory/attachmentList.js').Attachments} attachmentList
   */
  function (Widget, attachmentList) {
    /**
     * @type {angular.IDirectiveLinkFn<angular.IScope & {
     *   attachments: import('../factory/attachmentList.js').Attachment[];
     *   select: Function;
     *   delete: Function;
     *   onselect: Function;
     * }>}
     */
    function link($scope, $element) {
      const widget = new Widget($scope, $element, 'ons-table');

      widget.busy(attachmentList.get()).then(function (attachments) {
        $scope.attachments = attachments;
      });

      /**
       * @param {import('../factory/attachmentList.js').Attachment} attachment
       */
      $scope.select = function (attachment) {
        if (attachment.locked) {
          return false;
        }
        const message = getMessageDraft($element[0]);
        if (message) {
          message.attachment = {...attachment, status: 'ok'};
          message.focusIfNotMobile();
        }
        $scope.onselect();
        return true;
      };

      /**
       * @param {import('../factory/attachmentList.js').Attachment} item
       */
      $scope.delete = function (item) {
        item.remove();
      };

      const update = () => {
        widget
          .clear()
          .busy(attachmentList.get())
          .then(function (res) {
            $scope.attachments = res;
          });
      };
      
      $scope.$on('$destroy', listen('attachment.added', update));
      $scope.$on('$destroy', listen('attachment.deleted', update));
    }

    return {
      link: link,
      scope: {
        onselect: '&',
      },
      templateUrl: '/template/messenger.attachments',
    };
  },
]);
