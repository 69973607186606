angular.module('core')

  .directive('onswText', ['fskTransFilter', 'translateFilter', '$rootScope', (fskTransFilter, translateFilter, $rootScope) => ({
    scope: {
      plain: '<?',
      fskObj: '<?',
      translationKey: '@?',
      parameters: '@?',
      textClick: '&'
    },
    template: '<div class="box" ng-click="click()"><span class="text" ng-bind="text"></span></div>',
    link: function (scope, element) {

      element.addClass('ons-text');

      let unwatchPlain,unwatchFsk,unwatchTrans,clean = false;

      function setText(t) {
        scope.text = t;
        element.toggleClass('empty', !t);
      }

      unwatchPlain = scope.$watch('plain', async t => {
        if (t instanceof Promise) {
          t = await t;
        }
        setText(t);
        if (t && !clean) {
          unwatchFsk();
          unwatchTrans();
          clean = true;
        }
      });

      unwatchFsk = scope.$watchGroup(['fskObj'], v => {
        if (!v[0]) {
          return;
        }
        setText(fskTransFilter(v[0]));
        if (!clean) {
          unwatchPlain();
          unwatchTrans();
          clean = true;
        }
      });

      unwatchTrans = scope.$watchGroup(['translationKey', 'parameters'], v => {
        if (!v[0]) {
          return;
        }
        setText(translateFilter(v[0], v[1] && angular.fromJson(v[1])));
        if (!clean) {
          unwatchPlain();
          unwatchFsk();
          clean = true;
          scope.$on('$destroy', $rootScope.$on('$translateChangeSuccess', () => {
            setText(translateFilter(scope.translationKey, angular.fromJson(scope.parameters)));
          }));
        }
      });

      scope.click = function () {
        element.addClass('clicked');
        scope.textClick();
      };
    }
  })]);
