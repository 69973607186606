import angular from 'angular';
import {cmToIn, kgToLbs, inToCm, lbsToKg} from 'acng/locale/service/units.js';
import debounce from 'underscore/modules/debounce.js';

angular.module('userPool').directive('onswProfile', [
  'userPoolApi',
  '$q',
  'Widget',
  'user',
  (userPoolApi, $q, Widget, user) => {
    return {
      scope: {},
      templateUrl: '/template/userPool.myProfile',
      link: (scope, element) => {
        const widget = new Widget(scope, element, 'ons-form');

        scope.parseInt = parseInt;
        scope.newProfile = {};

        widget
          .busy(
            $q.all([
              userPoolApi.getProfile().then(function (res) {
                scope.profile = angular.copy(res.data);
                if (!user.usesMetricUnits()) {
                  scope.profile['height'] = cmToIn(parseInt(scope.profile['height']));
                  scope.profile['weight'] = kgToLbs(parseInt(scope.profile['weight']));
                  scope.profile['penislength'] = cmToIn(parseInt(scope.profile['penislength']));
                  scope.profile['penisperimeter'] = cmToIn(parseInt(scope.profile['penisperimeter']));
                  scope.lengthUnit = 'inch';
                  scope.weightUnit = 'lbs';
                } else {
                  scope.lengthUnit = 'cm';
                  scope.weightUnit = 'kg';
                }
              }),
              userPoolApi.translations().then(function (res) {
                scope.options = res.data['models.UserProfile'];
              }),
            ])
          )
          .then(function () {
            element.addClass('ready');
          });

        scope.updateProfile = debounce(() => {
          console.log('update fn');
          widget
            .ifNotBusy(() => userPoolApi.updateProfile(scope.newProfile))
            .then(() => {
              scope.newProfile = {};
            })
            .catch((err) => widget.notify(err));
        }, 500);

        scope.$watchCollection('profile', function (n, o) {
          console.log('watch', o, n);
          if (!o) return;
          angular.forEach(n, function (v, k) {
            if (n[k] != o[k]) {
              if (!user.usesMetricUnits() && (k == 'height' || k == 'penislength' || k == 'penisperimeter')) {
                n[k] = inToCm(parseInt(n[k]));
              }
              if (!user.usesMetricUnits() && k == 'weight') {
                n[k] = lbsToKg(parseInt(n[k]));
              }
              scope.newProfile[k] = n[k];
            }
          });
          scope.updateProfile();
        });
      },
    };
  },
]);
