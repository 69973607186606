import {body, isString} from '@acng/frontend-bounty';
import {createCacheMap} from '@acng/frontend-bounty/cache';
import {fetchJSON} from '@acng/frontend-bounty/fetch';
import {FeatureUtility, createFeature, setLoader} from '@acng/frontend-stargazer';
import {VERSION, authUser, startUpLanguage} from 'acng/core/service/env.js';

const cache = createCacheMap();

let locale = /** @type {string} */ (authUser?.preferred_language ?? startUpLanguage);
body.lang = locale;

export const translateFeature = new FeatureUtility(
  createFeature(
    'translate',
    setLoader(([featureName], insert) => {
      const src = `/language/${locale}/${featureName}?v=${VERSION}`;
      return cache(src, async () => {
        const translations = await fetchJSON(src);
        mapTranslationMarkup(translations);
        insert(translations);
      });
    }),
    () => {
      if (locale !== body.lang) {
        locale = body.lang;
        translateFeature?.clear();
        return true;
      }
    }
  )
);

const REGEXP = /{{\W*(\w*)\W*}}/g;

/**
 * @typedef {{[key: string]: string | TranslationData}} TranslationData
 */

/**
 * @deprecated
 * @param {TranslationData} translations
 */
const mapTranslationMarkup = (translations) => {
  for (const [key, item] of Object.entries(translations)) {
    if (isString(item)) {
      let result = '';
      let match;
      let lastIndex = 0;
      REGEXP.lastIndex = 0;
      while ((match = REGEXP.exec(item))) {
        if (match.index > lastIndex) {
          result += item.substring(lastIndex, match.index);
        }
        result += `\${${match[1]}}`;
        lastIndex = REGEXP.lastIndex;
      }
      if (lastIndex < item.length) {
        result += item.substring(lastIndex);
      }
      translations[key] = result;
    } else {
      mapTranslationMarkup(item);
    }
  }
};

/**
 * @param {string|string[]} src
 * @param {Record<string, string | string[]>} [params]
 * @returns {Promise<string>}
 */
export async function t(src, params = {}) {
  if (isString(src)) {
    src = src.split('.');
  }
  return (await translateFeature.render(...src)).toText(params);
}
