import {window} from '@acng/frontend-bounty';
import {ARRAY, STRING, typeguard} from '@acng/frontend-bounty/typeguard';
import {parseJSON} from '@acng/frontend-bounty/parse';
import {createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {get} from 'acng/core/service/backend.js';

const MODULE = 'amateurPool/service/profile-visit';

/**
 * @type {Promise<void> | undefined}
 */
let amateurVisitsFromApi;

/**
 * @type {Array<string>}
 */
const amateurVisits = [];

const ctxAmateurVisits = createGlobalContext(amateurVisits);

const getUserVisitsFromLocalStorage = () => {
  const data = window.localStorage.getItem('profileVisits');

  if (!data) {
    return [];
  }

  try {
    const visits = parseJSON(data);
    ASSERT: typeguard(`${MODULE} userVisits`, visits, ARRAY(STRING));
    return visits;
  } catch (reason) {
    console.error(MODULE, reason);
    return [];
  }
};

const userVisits = getUserVisitsFromLocalStorage();


/**
 * @param {string} amateurId
 */
export const userVisitsAmateur = (amateurId) => {
  let i = userVisits.indexOf(amateurId);
  if (i >= 0) {
    userVisits.splice(i, 1);
  }
  userVisits.unshift(amateurId);
  window.localStorage.setItem('profileVisits', JSON.stringify(userVisits));
};

/**
 * @param {string} amateurId
 */
export const amateurVisitsUser = (amateurId) => {
  let i = amateurVisits.indexOf(amateurId);
  if (i >= 0) {
    amateurVisits.splice(i, 1);
  }
  amateurVisits.unshift(amateurId);
};

export const getAmateurVisits = async () => {
  if (!amateurVisitsFromApi) {
    amateurVisitsFromApi = (async () => {
      const data = await get('amateur-profile/visits');
      ASSERT: typeguard(`${MODULE} amateurVisits`, data, ARRAY(STRING));

      amateurVisits.length = 0;
      data.reverse().forEach((a) => amateurVisitsUser(a));
    })();
  }
  await amateurVisitsFromApi;
  ctxAmateurVisits[CTX_PROVIDE](null, amateurVisits);

  return ctxAmateurVisits;
};
