/**
 * @module acng/games/run/amateur
 *
 *    Bla huba
 *
 * @typedef {import('../factory/games').GamesService} GamesService
 * @typedef {import('acng/amateurPool/factory/Amateur').AmateurConstructor} AmateurConstructor
 */
export const //
  type = 'run',
  param = run;

run.$inject = ['games', 'Amateur'];

/**
 * @param {GamesService} games
 *
 *    The {@link GamesService} is required to retrieve information about games.
 *
 * @param {AmateurConstructor} Amateur
 *
 *    The {@link AmateurConstructor} will be decorated.
 */
function run(games, Amateur) {
  /**
   * @returns {import('../factory/game').Session | undefined}
   *
   *     Latest Game with this amateur
   */
  Amateur.prototype.getGame = function () {
    const session = games.sessionOfAmateur.get(this.id);
    if (session) {
      session.amateur = this;
    }
    return session;
  };
  /**
   * @returns {boolean}
   *
   *     `true` when the current user hat an active game with this amateur
   *     `false` otherwhise
   */
  Amateur.prototype.hasActiveGame = function () {
    return !!games.sessionOfAmateur.get(this.id)?.getState().isActive;
  };
}
