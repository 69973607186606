import {cmToIn, kgToLbs} from "../../locale/service/units";

angular.module('amateurPool')
  .component('onswPersonalInfo', {
    bindings: { 'amateur': '<' },
    templateUrl: '/template/amateurPool.personalInfo',
    controller: ['$filter', '$translate', 'fsk', 'user', '$scope', function ($filter, $translate, fsk, user, $scope) {
      const scope = $scope;
      let tiles = ['age', 'zip_area', 'height', 'weight', 'brasize', 'haircolor', 'eyecolor', 'tattoos', 'piercings',
        'smoking', 'glasses', 'gender'];

      let list = ['gender', 'height', 'weight', 'body', 'brasize', 'eyecolor', 'haircolor', 'hairlength', 'ethnic',
        'bodyhair', 'pubichair', 'tattoos', 'piercings', 'glasses', 'smoking', 'marital', 'country'];

      if (user.isInternational()) {
        tiles.splice(tiles.indexOf('zip_area'), 1);
        list.splice(list.indexOf('country'), 1);
      }

      let iconIds = {
        age: 'age', zip_area: 'plz', eyecolor: 'eyecolor', height: 'size', weight: 'weight', brasize: 'cup',
        haircolor: 'hair', gender: 'gender', country: 'plz', tattoos: 'tattoo', piercings: 'piercing', glasses: 'glass', smoking: 'smoker'
      };

      if (fsk.get() < 16) {
        list.splice(list.indexOf('brasize'), 1);
        tiles.splice(tiles.indexOf('brasize'), 1);
        list.splice(list.indexOf('bodyhair'), 1);
        list.splice(list.indexOf('pubichair'), 1);
      }

      scope.values = [];
      let used = [];

      function update(profile) {
        scope.values = [];
        used = [];

        if (!profile) return;
        for (let i = 0; i < tiles.length; i++) {
          if (!profile[tiles[i]]) continue;
          if (scope.values.length == 6) break;
          assign(tiles[i], profile);
        }

        list.forEach(key => {
          if (!profile[key] || used.indexOf(key) >= 0) {
            return false;
          }
          assign(key, profile);
        });
      }

      function assign(key, profile) {
        if (key == 'zip_area') {
          if (profile[key] < 1) {
            return false;
          }
          used.push(key);
          const country = profile['country'].toUpperCase();
          scope.values.push({
            key,
            value: `<span class="zipCountryCode">${country} - </span>${$filter('zip')(profile[key], country).substring(0, 2)}`,
            iconId: iconIds[key]
          });
          used.push('country');
        } else
          if (key == 'height' && !user.usesMetricUnits()) {
            scope.values.push({
              key,
              value: cmToIn(profile['height']) + ' in',
              iconId: iconIds[key]
            });
            used.push(key);
          } else
            if (key == 'weight' && !user.usesMetricUnits()) {
              scope.values.push({
                key,
                value: kgToLbs(profile['weight']) + ' lbs',
                iconId: key
              });
              used.push(key);
            } else
              if (key == 'country') {
                used.push(key);
                scope.values.push({
                  key,
                  value: $filter('country')(profile[key]),
                  iconId: iconIds['zip_area']
                });
              } else
                if (Object.prototype.hasOwnProperty.call(profile, key) && profile[key] != '') {
                  const transkey = `models.AmateurProfile.${key}_attributes.${profile[key]}`;

                  let o = { key, value: null, iconId: iconIds[key] };
                  scope.values.push(o);
                  used.push(key);

                  $translate(transkey)
                    .then(() => o.value = transkey)
                    .catch(() => {
                      let match = transkey.match(/(.*attribute)s/);
                      if (match[1]) {
                        $translate(match[1], { value: profile[key] })
                          .then(t => o.value = t)
                          .catch(() => o.value = profile[key]);
                      } else {
                        o.value = profile[key];
                      }
                    });
                }
      }

      this.$onChanges = () => update(this.amateur);
    }]
  });
