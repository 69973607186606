import {createHtml, createStylesheet, on, remove} from '@acng/frontend-bounty';

import {inject} from 'acng/core/service/ng';
import {getLocale} from 'acng/locale/model/locale';
import {bonus} from 'acng/payment/context/bonus.js';

type FskTrans = import('../../userPool/0fsk').FskTrans;
type GetTeaserUrl = (format: string) => Promise<string>;
type Click = () => void;
type Achievement = {
  name: string;
  key: string;
};

export type SpecialData = {
  caption: FskTrans;
  start_at: string;
  end_at: string;
  overlaySrc?: string;
  overlayStyleSrc?: string;
  getTeaserUrl: GetTeaserUrl;
  getVideoUrl: GetTeaserUrl;
  showCountdown?: boolean;
  click?: Click;
  getAchievement?: () => Achievement;
  videoSrc?: string;
};

export class Special {
  static testDateOffset = 0;

  name: string;
  caption: FskTrans;
  start_at: number;
  end_at: number;
  showCountdown: boolean;
  getTeaserUrl: GetTeaserUrl;
  getVideoUrl?: GetTeaserUrl;
  click?: Click;
  getAchievement?: () => Achievement;
  overlaySrc?: string;
  overlayStyleSrc?: string;

  constructor(name: string, data: SpecialData) {
    this.name = name;
    this.caption = data.caption;
    this.start_at = Date.parse(data.start_at) - Special.testDateOffset;
    this.end_at = Date.parse(data.end_at) - Special.testDateOffset;
    this.getAchievement = data.getAchievement;
    this.overlaySrc = data.overlaySrc;
    this.overlayStyleSrc = data.overlayStyleSrc;
    this.showCountdown = data.showCountdown ?? false;
    this.getTeaserUrl = data.getTeaserUrl;
    this.getVideoUrl = data.getVideoUrl;
    this.click = data.click;
    DEBUG: console.info('special/factory/special constructor ', {name, data, special: this});
  }

  get isActive() {
    const now = Date.now();
    const isActive = this.start_at <= now && this.end_at > now;
    DEBUG: console.debug('special/factory/special active', {isActive, special: this});
    return isActive;
  }

  get hasEnded() {
    const now = Date.now();
    return this.end_at <= now;
  }

  endsLaterThan(special: Special | null) {
    return !!(special && this.end_at > special.end_at);
  }

  compare(other: Special | null) {
    if (!this.isActive || this.endsLaterThan(other)) {
      return other;
    } else {
      return this;
    }
  }
  /**
   * @returns `true` when the currently logged in user
   *  already achieved the associated achievement, `false` otherwhise.
   *  Always `true` when there is no associated achievement.
   *  Always `false` when there is an associated achievement but no logged in user.
   */
  async userHasAchievement() {
    if (!this.getAchievement) {
      return false;
    }
    const user = inject('user');
    const http = inject('http');
    if (user.guest) {
      return false;
    }
    const res = await http().get(`/api/achievements/user?name=${this.getAchievement().name}`);
    return !!res.data.length;
  }

  grantAchievement() {
    if (!this.getAchievement) {
      throw new Error('something went wrong');
    }
    const achievement = this.getAchievement();
    return inject('http')().post('/api/achievements/user-achievement', achievement, {
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  showPaymentOverlay() {
    if (bonus) {
      inject('payment').overlay();
    }
  }

  async createOverlay() {
    const onsOverlay = inject('onsOverlay');
    const src = this.overlaySrc;
    if (!src || onsOverlay.isOpen(src)) {
      return;
    }

    const alreadyPlayed = await this.userHasAchievement();
    if (alreadyPlayed) {
      DEBUG: console.debug('special/config/overlay already played');
      return;
    }

    const [html, css] = await Promise.all([
      await createHtml(src), //
      await (this.overlayStyleSrc ? createStylesheet(this.overlayStyleSrc) : (false as false)),
    ]);
    const overlay = onsOverlay
      .create(src, {
        special: this,
      })
      .disableHistory();
    overlay.injectElement = html;
    overlay.css = `fixed-main special special-${this.name}`;
    overlay.params.locale = getLocale;
    overlay.open();
    if (css) {
      on(overlay, 'close', () => remove(css), {once: true});
    }
    return overlay;
  }
}
