angular.module('userPool')

  .directive('onswPasswordReset', ['http', 'Widget', (http, Widget) => ({
    scope: {
      onDone: '&',
      onError: '&'
    },
    template: `
<form class="box" ng-submit="remind()">
	<p>{{'userPool.passwordForgottenContent' | translate}}</p>
	<label>
		<span>{{'userPool.email' | translate}}</span>
		<input type="email" name="email" required ng-model="email">
	</label>
	<div class="ons-layout buttons">
		<div class="box">
			<span class="ons-item button submit">
				<button type="submit" class="box">
					<span class="label" translate="userPool.resetPassword"></span>
				</button>
			</span>
		</div>
	</div>
</form>`,
    link: (scope, element) => {

      const widget = new Widget(scope, element, 'ons-form');

      scope.remind = () => {
        widget.ifNotBusy(() => http().post('api/user/remind-password', $.param({ 'email': scope.email }), { dontIntercept: true }))
          .then(() => {
            scope.email = '';
            widget.notify('userPool.pwForgottenMailSent');
            scope.onDone();
          })
          .catch(err => {
            widget.notify(err);
            scope.onError(err);
          });
      };
    }
  })]);
