angular.module('friend')

  .component('onswFriendLink', {
    bindings: {
      type: '@',
      label: '@?'
    },
    template: '<a class="box" ng-href="{{route}}"><span class="label" ng-bind="($ctrl.label || \'friend.\' + $ctrl.type) | translate"></span><span class="value" ng-bind="count"></span></a><div class="gain animate ng-hide" ons-icon="::friendColor"></div>',
    controller: ['$scope', '$element', '$location', 'friends', '$animateCss', function ($scope, $element, $location, friends, $animateCss) {

      $element.addClass('ons-item');
      var box = $element.children('.box');
      let aniEl = $element.children('.gain');
      function checkLocation() {
        box.toggleClass('active', '#' + $location.path() == $scope.route);
      }

      $scope.$watch(() => {
        return friends.amateursOfStatus[this.type].length;
      }, (count, old) => {
        $scope.count = count;
        $element.toggleClass('empty', !count);
        $scope.route = '#/profile/friends/' + this.type;
        checkLocation();

        if (count > old && $element.hasClass('animation')) {
          $animateCss(aniEl,
            {
              removeClass: 'ng-hide',
              from: {
                top: '0',
                left: '0',
                opacity: '.2',
                transform: 'scale(1)'
              },
              to: {
                top: '300px',
                left: '-200px',
                transform: 'scale(10)',
                opacity: '1'
              },
              duration: 1
            }
          ).start().done(() => {
            $animateCss(aniEl,
              {
                to: {
                  top: '0',
                  left: '0',
                  opacity: '.2',
                  transform: 'scale(1)'
                },
                from: {
                  top: '300px',
                  left: '-200px',
                  transform: 'scale(10)',
                  opacity: '1'
                },
                duration: 1
              }
            ).start().done(() => {
              aniEl.addClass('ng-hide');
            });
          });
        }
      });

      $scope.$on('$locationChangeSuccess', checkLocation);
    }]
  });
