import {path, validate, alias, on} from '@acng/frontend-voyager';

import {hookFeature} from 'acng/core/config/feature';
import {settingsBaseRoute} from 'acng/userPool/config/routes';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {mainView} from 'acng/layout/service/view-middleware.js';

const friends = settingsBaseRoute.create(
  path('/friends/{friendStatus}'),
  validate(({friendStatus}) =>
    /accept|user_reject|amateur_request|user_request/.test(/** @type {string} */ (friendStatus))
  ),
  alias('friends')
);

amateurRoute.create(
  path('/friends'), //
  mainView(hookFeature, 'amateurFriends'),
  alias('amateurFriends')
);

runTracking.$inject = ['user'];
/**
 * @param {import('acng/userPool/factory/user').User} user -
 */
export function runTracking(user) {
  on('enter', () => void user.trackActivity())(friends);
}
