import {pushToArray} from '@acng/frontend-bounty';
import {slideshowConfig} from 'acng/core/factory/Slideshow';
import {media} from 'acng/core/service/env';

/** @param {"home" | "sidebar"} format */
const teaser = (format) => [
  'ImageTile',
  {
    caption: 'hotornot.hotornot',
    json: `${media.assets_ext}/img/hotornot/teaser-${format}.js`,
    click: () => (location.hash = '/hotornot'),
  },
];

export const extendSlideshow = () => {
  pushToArray(slideshowConfig.myportal_t1, teaser('home'));
  pushToArray(slideshowConfig.sidebar, teaser('sidebar'));
};
