import angular from 'angular';

angular.module('moviePool').directive('onswMovieInfo', ['Widget', 'user', 'Movie',
  /**
   * @param {import('acng/core/factory/widget').WidgetConstructor} Widget
   * @param {import('acng/userPool/factory/user').User} user
   * @param {import('acng/moviePool/factory/Movie').IMovieConstructor} Movie
   *
   * @returns {angular.IDirective<angular.IScope & {
   *   user: import('acng/userPool/factory/user').User;
   *   item: import('acng/moviePool/factory/Movie').IMovie;
   * }>}
   */
  (Widget, user, Movie) => {

    return {
      scope: {
        movie: '<'
      },
      templateUrl: '/template/moviePool.info',
      link: (scope, element) => {

        const widget = new Widget(scope, element);

        scope.user = user;
        scope.$watch('movie', movie => {

          scope.item = widget.wait(movie, movie instanceof Movie);
        });
      }
    };
  }
]);
