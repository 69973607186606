angular.module('payment')
  .factory('paymentHttpInterceptor', ['$injector', 'ARCHIMEDES', '$q', '$rootScope', '$translate', 'supportEmail', function ($injector, ARCHIMEDES, $q, $rootScope, $translate, supportEmail) {

    function handle1001(rejection) {
      rejection.config = rejection.config || {};
      rejection.config.dontIntercept = true;
      return $q.reject(rejection);
    }

    function handle409(rejection) {

      var replacements = {};
      switch (rejection.data.error) {
        case 6000:
          replacements = {
            period: rejection.data.details.period[0],
            currency: rejection.data.details.currency.symbol,
            amount: rejection.data.details.amount[0],
            alternatives: rejection.data.details.alternatives.join(', ')
          };
          break;
        case 6001:
        case 6002:
        case 6003:
          replacements = {
            supportEmail,
            methodName: rejection.data.details.method?.name || '',
            alternatives: rejection.data.details.alternatives.join(', ')
          };
          break;
      }

      return translate(rejection, replacements);
    }

    function handle504(rejection) {
      return translate(rejection, {});
    }

    function translate(rejection, replacements) {
      return $translate('payment.error.' + rejection.data.error, replacements)
        .catch(function (missingTranslation) {
          return missingTranslation;
        })
        .then(function (text) {
          rejection.data.message = text;
          return $q.reject(rejection);
        });
    }

    return {
      'responseError': function (rejection) {

        if (rejection.config.url.indexOf(ARCHIMEDES) === -1) {
          return $q.reject(rejection);
        }

        switch (rejection.status) {
          case 401:
            if (rejection.data.error == 1001) {
              return handle1001(rejection);
            }
            break;
          case 409:
            return handle409(rejection);
          case 504:
            return handle504(rejection);
        }

        return $q.reject(rejection);
      }

    };
  }]);
