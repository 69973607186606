angular.module('actionslider')
  .factory('ActionsliderProfile', ['Amateur', function (Amateur) {

    function Profile(data) {
      this.nickname = data.nickname;
      this.id = data.id;
      this.image = data.getImageUrl('small');
    }

    Profile.find = function (id) {
      return Amateur.get(id).then(function (res) {
        return new Profile(res);
      });
    };

    return Profile;

  }]);
