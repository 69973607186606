angular.module('core').filter('country', [
  '$translate',
  function ($translate) {
    function countryFilter(val) {
      if (typeof val !== 'string') return '';
      return $translate.instant('countries.iso.' + val.toUpperCase());
    }

    countryFilter.$stateful = true;

    return countryFilter;
  },
]);
