import angular from 'angular';
import {onsHistory} from 'acng/core/factory/history';

angular.module('payment')

  .directive('onswPaymentProgress', [() => {

    return {
      scope: {
        step: '<',
        siteStepping: '<'
      },
      template: `
<div class="bar"><div ng-style="{'width': (step > 2 ? 100 : (step-1)*50)+'%'}"></div></div>
<ul>
	<li ng-click="goBackToStep(s)" ng-repeat="s in [1,2,3]" ng-style="{'cursor':cursor(s), 'left':position(s)}" ng-class="{'active': isActual(s), 'finalized': isFinalized(s)}">
		<div>{{ isFinalized(s) ? '' : s }}</div>
		<p>{{'payment.step.' + siteStepping[s-1][1] | translate}}</p>
	</li>
</ul>`,
      link: function (scope) {
        scope.isActual = function (s) {
          return scope.step == s;
        };

        scope.isFinalized = function (s) {
          return scope.step > s;
        };

        scope.cursor = function (s) {
          return scope.step > s && scope.step <= 3 ? 'pointer' : '';
        };

        scope.position = function (s) {
          return (s - 1) * 33 + '%';
        };

        scope.isActive = function (s) {
          return scope.step >= s;
        };

        scope.goBackToStep = step => {
          if (scope.step > step) {
            onsHistory.back();
          }
        };
      }
    };
  }]);
