import {isArray, min} from '@acng/frontend-bounty';
import {validator, ARRAY, NULLABLE, OPTIONAL, STRING, TUPLE, INTEGER} from '@acng/frontend-bounty/typeguard';
import {hasFeature, media} from 'acng/core/service/env.js';
import {FSK_LEVEL, fsk} from 'acng/userPool/fsk.js';
import {FSKTRANS, fskTrans} from 'acng/userPool/0fsk.js';
import {getGlobalDialog} from 'acng/messenger/context/global-dialog.js';
import {getContactsVisibility} from 'acng/messenger/context/contacts-visibility.js';

import {isOnline} from '../service/online.js';
import {AMATEUR_PROFILE_IMAGES} from './amateur/images.js';
import {AMATEUR_FEATURES} from './amateur-feature.js';

import dummy from 'assets/basic/img/f_900.png';

/**
 * @typedef AmateurData
 *
 * @property {string} id
 * @property {string} nickname
 * @property {string} intlname
 * @property {number} message_price
 * @property {import('./amateur/images.js').AmateurProfileImages} images
 *
 * @property {Array<string>} languages
 * @property {import("./amateur-feature").AmateurFeature[]} features
 * @property {Array<string>} do_not_show_in
 * @property {Array<string>} niche_tags
 * @property {Array<string>} tags
 * @property {Array<string>} topics
 *
 * @property {number?} [preferred_image]
 * @property {number?} [profile_video_fsk]
 * @property {string?} [profile_video_path]
 *
 * @property {import("acng/userPool/0fsk").FskTrans} about_me
 * @property {import("acng/userPool/0fsk").FskTrans} description
 * @property {import("acng/userPool/0fsk").FskTrans} fantasies
 * @property {import("acng/userPool/0fsk").FskTrans} others_about_me
 * @property {Array<[number, number]>} experiences
 * @property {Array<[number, number]>} lookingfor
 * @property {Array<[number, number]>} preferences
 *
 * @property {string} gender
 * @property {number} age
 * @property {number?} [height]
 * @property {number?} [weight]
 * @property {string} country
 * @property {number} zip_area
 * @property {string} [body]
 * @property {string} [bodyhair]
 * @property {string} [eyecolor]
 * @property {string} [brasize]
 * @property {string} [ethnic]
 * @property {string} [glasses]
 * @property {string} [haircolor]
 * @property {string} [hairlength]
 * @property {string} [marital]
 * @property {string} [piercings]
 * @property {string} [pubichair]
 * @property {string} [smoking]
 * @property {string} [tattoos]
 * @property {string} [penislength]
 * @property {string} [penisperimeter]
 *
 * @property {string} [games] DEPRECATED
 * @property {boolean} [livedating] DEPRECATED
 * @property {boolean} [weblog] DEPRECATED
 * @property {boolean} [livecam] DEPRECATED
 */

/**
 * @implements {AmateurData}
 */
export class Amateur {
  /**
   * @param {AmateurData} data
   */
  constructor(data) {
    ASSERT: {
      const assert = validator('AmateurData', data);

      this.id = assert('id', STRING);
      this.nickname = assert('nickname', STRING);
      this.intlname = assert('intlname', STRING);
      this.message_price = assert('message_price', INTEGER);

      this.gender = assert('gender', STRING);
      this.age = assert('age', INTEGER);
      this.height = assert('height', OPTIONAL(NULLABLE(INTEGER)));
      this.weight = assert('weight', OPTIONAL(NULLABLE(INTEGER)));
      this.country = assert('country', STRING);
      this.zip_area = assert('zip_area', INTEGER);
      this.languages = assert('languages', ARRAY(STRING));
      this.do_not_show_in = assert('do_not_show_in', ARRAY(STRING));
      this.niche_tags = assert('niche_tags', ARRAY(STRING));
      this.features = assert('features', AMATEUR_FEATURES);
      this.tags = assert('tags', ARRAY(STRING));
      this.topics = assert('topics', ARRAY(STRING));
      this.preferred_image = assert('preferred_image', OPTIONAL(NULLABLE(INTEGER)));
      this.profile_video_fsk = assert('profile_video_fsk', OPTIONAL(NULLABLE(INTEGER)));
      this.profile_video_path = assert('profile_video_path', OPTIONAL(NULLABLE(STRING)));

      this.about_me = assert('about_me', FSKTRANS);
      this.description = assert('description', FSKTRANS);
      this.fantasies = assert('fantasies', FSKTRANS);
      this.others_about_me = assert('others_about_me', FSKTRANS);

      this.experiences = assert('experiences', ARRAY(TUPLE(FSK_LEVEL, INTEGER)));
      this.lookingfor = assert('lookingfor', ARRAY(TUPLE(FSK_LEVEL, INTEGER)));
      this.preferences = assert('preferences', ARRAY(TUPLE(FSK_LEVEL, INTEGER)));

      this.body = assert('body', OPTIONAL(STRING));
      this.bodyhair = assert('bodyhair', OPTIONAL(STRING));
      this.eyecolor = assert('eyecolor', OPTIONAL(STRING));
      this.brasize = assert('brasize', OPTIONAL(STRING));
      this.ethnic = assert('ethnic', OPTIONAL(STRING));
      this.glasses = assert('glasses', OPTIONAL(STRING));
      this.haircolor = assert('haircolor', OPTIONAL(STRING));
      this.hairlength = assert('hairlength', OPTIONAL(STRING));
      this.marital = assert('marital', OPTIONAL(STRING));
      this.piercings = assert('piercings', OPTIONAL(STRING));
      this.pubichair = assert('pubichair', OPTIONAL(STRING));
      this.smoking = assert('smoking', OPTIONAL(STRING));
      this.tattoos = assert('tattoos', OPTIONAL(STRING));
      this.penislength = assert('penislength', OPTIONAL(STRING));
      this.penisperimeter = assert('penisperimeter', OPTIONAL(STRING));

      this.images = assert('images', AMATEUR_PROFILE_IMAGES);

      delete data.livedating;
      delete data.weblog;
      delete data.games;
      delete data.livecam;

      assert.finish(Amateur, this);
    }

    Object.assign(this, data);
  }

  /**
   * @param {import('./amateur/images.js').AmateurProfileImageFormat} format
   */
  getImageUrl(format) {
    const wantedFsk = min(fsk.get(), this.preferred_image ?? 18);
    for (const [fsk, img] of this.images.small || []) {
      if (fsk <= wantedFsk) {
        return media.content.pictures + img.replace('-small', '-' + format);
      }
    }

    return `${media.assets}/${dummy}`;
  }

  isOnline() {
    return isOnline(this.id);
  }

  getDescription() {
    return fskTrans(this.description);
  }

  /**
   * @param {string} [add]
   */
  goto(add) {
    const path = `#/sedcard/${this.nickname}${add ? `/${add}` : ''}`;
    if (window.innerWidth < 768 && path == location.hash && (getGlobalDialog() || getContactsVisibility())) {
      location.hash = `${path}?x=${Math.random() * 1e6}`;
    } else {
      location.hash = path;
    }
  }

  /**
   * @param {number} visitDuration in seconds
   */
  trackVisit(visitDuration) {
    const formData = new FormData();
    formData.append('visit_duration', `${visitDuration}`);
    navigator.sendBeacon(`/api/amateur-profile/track-visit/${this.id}`, formData);
  }

  hasGames() {
    return hasFeature('games') && this.features.includes('games');
  }

  hasVoiceMessages() {
    return hasFeature('messenger') && this.features.includes('voice_messages');
  }
}
