angular.module('core').component('onswOverlayReason', {
  bindings: {overlay: '<'},
  transclude: 'element',
  controller: [
    '$scope',
    '$element',
    '$transclude',
    '$animate',
    '$compile',
    '$timeout',
    function ($scope, $element, $transclude, $animate, $compile, $timeout) {
      const template = `<div class="box" ng-click="$ctrl.click()">
      <span class="text">{{ $ctrl.overlay.params.reason | translate: $ctrl.overlay.params.reasonParams }}</span>
        <div onsw-item ons-icon="::close"></div>
      </div>`;
      this.$onChanges = () => {
        if (!this.overlay?.params.reason) {
          return;
        }
        $transclude(clone => {
          clone.append($compile(template)($scope));
          this.el = clone;
          this.el.addClass('overlay-toolbar');
          $animate.enter(clone, false, $element);
          $timeout(() => $animate.leave(clone), this.getHideTimeout());
        });
      };
      this.getHideTimeout = () => {
        if (this.overlay.params.reasonClasses?.includes('free-cam')) {
          return 20000;
        }
        return 8000;
      };
      this.click = () => {
        $animate.leave(this.el);
      };
    },
  ],
});
