angular.module('amateurPool')

  .factory('GlobalFeedTile', ['FeedTile', FeedTile => {
    class GlobalFeedTile extends FeedTile
    {
      load(scope, feed) {
        return super.load(scope, feed)
          .then(() => {
            scope.headline = () => 'amateurPool.feed.' + feed.type.toLowerCase() + 'WithoutDate';
            if (!scope.image) {
              scope.image = scope.amateur.getImageUrl('small');
            }
          });
      }
      hookname() {
        return 'smallFeed';
      }
      css() {
        return [this.cssClass, 'group', 'frame', 'globalfeeditem'];
      }
    }
    return GlobalFeedTile;
  }]);
