import {pushToArray} from '@acng/frontend-bounty';
import {slideshowConfig} from 'acng/core/factory/Slideshow';
import {media} from 'acng/core/service/env';
import {FreeMovie} from '../factory/FreeMovie.js';

/** @param {"home" | "sidebar"} format */
const teaser = (format) => [
  'ImageTile',
  {
    caption: 'moviePool.freemovie.otm',
    json: `${media.assets_ext}/img/fmotd/teaser-${format}.js`,
    click: () => FreeMovie.gotoRoute(),
  },
  5000,
];

export const extendSlideshow = () => {
  pushToArray(slideshowConfig.myportal_t1, teaser('home'));
  pushToArray(slideshowConfig.sidebar, teaser('sidebar'));
};
