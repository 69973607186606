angular.module('userPool')

  .directive('onswRequestMailDoi', ['http', 'Widget', 'supportEmail', 'user', (http, Widget, supportEmail, user) => ({
    scope: {
      onDone: '&',
      onError: '&'
    },
    template: `
    <form class="box" ng-submit="sendConfirmMail()">
      <p>{{'userPool.confirmEmailAgainText' | translate }}</p>
      <div class="ons-layout buttons">
        <div class="box">
          <span class="ons-item button">
            <button type="submit" class="box"><span class="label">{{'userPool.sendConfirmMailAgainBtn' | translate}}</span></button>
          </span>
        </div>
      </div>
    </form>`,
    link: (scope, element) => {

      const widget = new Widget(scope, element, 'ons-form');

      scope.sendConfirmMail = () => {

        widget.ifNotBusy(() => http().post('/api/user/send-confirm-mail', $.param({ 'email': user.email }), { dontIntercept: true }))
          .then(() => {
            widget.notify('userPool.confirmationMailSent');
            scope.onDone();
          })
          .catch(err => {
            if (err.data.code && err.data.code == 5004) {
              widget.notify('exception.5004', { mail: supportEmail });
            } else {
              widget.notify(err);
            }
            scope.onError(err);
          });
      };
    }
  })]);
