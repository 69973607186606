import {HTMLElement, addClass, classList, getAttribute, replace} from '@acng/frontend-bounty';

import {getIcon} from '../service/icon';
import {coreFeature} from '../config/feature.js';

coreFeature.defineElement(
  'onsw-icon',
  class extends HTMLElement {
    connectedCallback() {
      const name = getAttribute(this, 'replace');
      if (!name) {
        return;
      }
      getIcon(name).then((icon) => {
        if (!icon) {
          return;
        }
        replace(this, icon);
        addClass(icon, ...classList(this));
      });
    }
  }
);
