// @ts-nocheck
/**
 * @module acng/core/factory/widget
 */
export {};
angular.module('core')

  .factory('Widget', ['$q', '$log', '$translate', '$compile', '$rootScope', ($q, $log, $translate, $compile, $rootScope) => {

    function notify(widget, message, translateParams) {
      var reasons = [];

      if (message === null || message === 'busy') {
        return;
      }

      if (typeof message === 'object') {
        if (typeof message.data === 'object') {
          message = message.data;
        }
        angular.forEach(message.reasons, function (val) {
          if (typeof val == 'string') {
            reasons.push(val);
            return;
          }
          angular.forEach(val, function (msg) {
            reasons.push(msg);
          });
        });
        if (typeof message.data === 'string') {
          message = message.data;
        } else {
          message = message.message;
        }
      }

      var element, timeout;
      var closeFunction = function (event) {
        element && element.remove();
        timeout && window.clearTimeout(timeout);
        event && event.stopPropagation();
      };

      $translate(message, translateParams).catch(function () {
        return message;
      })
        .then(function (t) {
          element = angular.element(`<div class="notify" ons-icon="::close"><div class="box"><span class="message">${t}</span></div></div>`);
          if (reasons.length) {
            var elReasons = angular.element('<ul>');
            reasons.forEach(function (reason) {
              elReasons.append(angular.element('<li>' + reason + '</li>'));
            });
            element.append(elReasons);
          }
          element
            .on('click', closeFunction)
            .prependTo(widget.children('.box'));
          $compile(element)($rootScope);
          timeout = window.setTimeout(function () {
            element.remove();
          }, 15000);
        });

      return closeFunction;
    }

    function busy(element, p, queue) {
      const def = $q.defer();
      def.originalPromise = p;
      queue.unshift(def);
      (() => {
        if (queue.length > 1) {
          $log.debug('busy queue', queue.length, element);
          return queue[1].promise;
        } else {
          return $q.resolve();
        }
      })().catch(err => err).then(() => {
        element[0].classList.remove('error', 'done');
        element.addClass('busy');
        return p;
      }).then(function (res) {
        element.addClass('done');
        def.resolve(res);
      }).catch(function (err) {
        element.addClass('error');
        def.reject(err);
      }).finally(function () {
        queue.splice(queue.indexOf(def), 1);
        element.removeClass('busy');
      });
      return def.promise;
    }

    function wait(element, item, success) {
      element.toggleClass('busy', !item);
      element.toggleClass('done', success);
      element.toggleClass('error', item && !success);

      if (!success) {
        return null;
      }

      element.addClass('ready');
      return item;
    }

    class Widget
    {
      constructor(scope, element, type) {
        this.scope = scope;
        this.element = element;
        this.notifyElement = element;
        this.queue = [];
        if (type) {
          element.addClass(type);
        }
      }

      notify(msg, params) {
        return notify(this.notifyElement, msg, params);
      }

      isBusy() {
        return !!this.queue.length;
      }

      ifNotBusy(fn) {
        if (typeof fn !== 'function') {
          throw 'ifNotBusy param';
        }
        if (this.isBusy()) {
          $log.warn('widget is busy!', this);
          return $q.reject('busy');
        }
        return this.busy(fn());
      }

      busy(p) {
        if (typeof p === 'function') {
          p = $q(p);
        }
        return busy(this.element, p, this.queue);
      }

      /**
     * Add Listener to EventTarget which will be removed when this scope is destroyed
     *
     * @param {EventTarget} target
     * @param {string} type
     * @param {Function} listener
     * @param {object?} options
     */
      listen(target, type, listener, options) {
        target.addEventListener(type, listener, options);
        this.scope.$on('$destroy', () => target.removeEventListener(type, listener, options));
      }

      clear() {
        if (!this.queue.length) {
          return this;
        }
        $log.warn('busy queue instant override', this);
        this.queue.forEach(d => {
          d.originalPromise
            .then(res => $log.debug('busy buried success', res, this))
            .catch(err => $log.debug('busy buried error', err, this));
          d.reject('busy override');
        });
        this.queue.length = 0;
        return this;
      }

      wait(item, success) {
        return wait(this.element, item, success);
      }

      watch(config) {
        if (!config.reset) config.reset = () => this.scope.item = null;
        if (!config.validate) config.validate = res => !!res;
        if (!config.invalid) config.invalid = () => {};
        //if (!config.load) config.load = item => item;
        if (!config.done) config.done = res => this.scope.item = res;
        if (!config.error) config.error = err => this.notify(err);
        return this.scope['$watch' + (Array.isArray(config.watch) ? 'Group' : '')](config.watch, (res, old) => {
          config.reset(res, old);
          this.element.removeClass('active');
          this.element.removeClass('inactive');
          if (!config.validate(res)) {
            this.element.addClass('inactive');
            config.invalid(res);
            return;
          }
          if (!config.load) {
            this.element.addClass('active');
            config.done(res);
            return;
          }
          this.busy(config.load(res))
            .then(res => {
              this.element.addClass('active');
              config.done(res);
            })
            .catch(err => {
              this.element.addClass('inactive');
              config.error(err);
            });
        });
      }

      /**
     * @callback ObserveGet
     * @returns {Promise<Array>}
     *
     * @callback ObserveListener
     * @param {*} item
     *
     * @typedef {Object} Observer
     * @param {Function} off
     * @param {Function] play
     */

      /**
     * @param {ObserveGet} get
     * @param {string} eventName
     * @param {ObserveListener} callback
     * @returns {Observer}
     */
      observe(get, eventName, callback) {
        let stopped = true,paused = false,off = () => {};
        const fifo = [];
        const on = () => {
          off = this.scope.$on(eventName, (_ev, item) => {
            if (paused) {
              fifo.push(item);
              return;
            }
            callback(item);
          });
        };
        const stop = () => {
          off();
          fifo.length = 0;
          stopped = true;
          paused = false;
        };
        const play = async () => {
          stop();
          stopped = false;
          for (const item of await this.busy(get())) {
            if (paused) {
              fifo.push(item);
              continue;
            }
            callback(item);
            if (stopped) {
              break;
            }
          }
          on();
        };
        const pause = () => paused = true;
        const resume = () => {
          paused = false;
          for (const item of fifo) {
            callback(item);
            if (stopped) {
              break;
            }
          }
        };
        return { stop, play, pause, resume };
      }

      static make(fn) {
        return {
          scope: fn.params(),
          template: fn.template(),
          link: (scope, element) => new fn(scope, element)
        };
      }
    }

    return Widget;
  }]);
