import {hasFeature} from 'acng/core/service/env';
import {promise, checkPinById} from 'acng/pin/service';

/* prettier-ignore */
angular.module('amateurPool')

  .factory('AmateurFeed', ['$injector', 'HttpCache', 'user', ($injector, HttpCache, user) => {

    const cache = new HttpCache({
      prefix: '/api/amateur-profile/feed/',
      useCdn: true,
      fnResolve: res => res.data
        .filter(item => supportedTypes.indexOf(item.type) > -1)
        .map(item => new AmateurFeed(item))
    });

    const supportedTypes = ['Weblog'];
    const hideOldDate = Date.now() - 3600000 * 24 * 14; // 14 days

    if ($injector.has('PictureSet')) {
      supportedTypes.push('Picture');
    }

    if ($injector.has('Movie')) {
      supportedTypes.push('Movie');
    }

    class AmateurFeed
    {
      constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.date = data.date * 1000;
        this.userId = data.userId;

        if (this.date < hideOldDate) {
          this.date = null;
        }
      }

      static listByAmateur(id) {
        return cache.get(id + '?' + (AmateurFeed.globalFeedVersion || ''));
      }

      static global(filter, latest) {
        return cache.get(`global?filter=${filter == 'favorites' ? 'all' : filter}&latest=${latest}&niche=${user.niche}`)
          .then(feed => {
            if (feed.length > 0) {
              AmateurFeed.globalFeedVersion = feed[0].date;
            }

            if (filter == 'favorites' && hasFeature('pin')) {
              return promise.then(() => feed.filter(item => checkPinById(item.userId)));
            }

            return feed;
          });
      }
    }

    return AmateurFeed;
  }])

  .directive('onsdAmateurFeed', ['$q', 'PimpMyRide', 'Amateur', 'AmateurFeed', ($q, PimpMyRide, Amateur, AmateurFeed) => PimpMyRide(
    'AmateurFeed',
    ['amateurId', 'amateurName'],
    v => (Amateur.isValidId(v[0]) ? Amateur.get(v[0]) : Amateur.getByNickname(v[1]))
      .then(a => AmateurFeed.listByAmateur(a.id))
      .then(a => a.length ? a : $q.reject(a)),
    v => Amateur.isValidId(v[0]) || Amateur.isValidName(v[1])
  )])

  .directive('onsdGlobalFeed', ['PimpMyRide', 'AmateurFeed', (PimpMyRide, AmateurFeed) => PimpMyRide(
    'GlobalFeed', ['filter', 'latest'],
    v => AmateurFeed.global(v[0], v[1]),
    v => ['all', 'posts', 'media', 'friends', 'favorites'].indexOf(v[0]) >= 0
  )]);
