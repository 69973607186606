angular.module('rating')

  .run(['Rating', '$injector', '$q', function (Rating, $injector, $q) {

    if (!$injector.has('PictureSet')) {
      return;
    }

    var PictureSet = $injector.get('PictureSet');

    PictureSet.prototype.rateable = function () {
      var set = this;
      return $q(function (resolve, reject) {
        set.blocked ? reject('rating.notRateable') : resolve(true);
      }).then(function () {
        return set.getStock();
      }).then(function (res) {
        return res || $q.reject('rating.notInStock');
      });
    };

    PictureSet.prototype.getRating = function () {
      return Rating.get(this.set_id, 'Picture');
    };

    PictureSet.prototype.rate = function (rating) {
      return Rating.rate(this.set_id, 'Picture', rating);
    };

  }]);
