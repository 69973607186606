import {ImageTile} from 'acng/core/tiles/ImageTile';

angular.module('friend')

  .factory('AmateurFriendTile', [() => {
    class AmateurFriendTile extends ImageTile
    {
      load(scope, friend) {
        scope.friend = friend;
        return super.load(scope, { src: friend.image });
      }
      hookname() {
        return 'amateurFriend';
      }
    }
    return AmateurFriendTile;
  }]);
