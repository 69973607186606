import {createGlobalContext} from '@acng/frontend-relativity';
import {media} from '../../core/service/env';
import {Special} from '../model/special.js';
import {setTimeout, clearTimeout} from '@acng/frontend-bounty';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

/**
 * @type {import('@acng/frontend-relativity').Context<Special | null>}
 */
export const ctxSpecial = createGlobalContext(null);

/**
 * @type {number}
 */
let expireTimeout;

export const provideSpecial = async () => {
  clearTimeout(expireTimeout);

  const specials = await getSpecials();
  const special = specials.reduce(
    (current, next) => next.compare(current), //
    /** @type {Special | null} */ (null)
  );

  DEBUG: console.debug('special/service provide', {special});
  ctxSpecial[CTX_PROVIDE](null, special);

  special?.createOverlay();

  expireTimeout = setTimeout(() => {
    DEBUG: console.info('special/service provideSpecial timeout');
    provideSpecial();
  }, getTimeoutInterval(specials));
};

/**
 * @param {Special[]} specials
 */
const getTimeoutInterval = (specials) => {
  const now = Date.now();
  const time =
    specials.reduce((ms, special) => {
      if (now >= special.end_at) {
        return ms;
      }
      if (now >= special.start_at) {
        return Math.min(ms, special.end_at);
      }
      return Math.min(ms, special.end_at, special.start_at);
    }, now + 1000 * 3600 * 24) - now;
  DEBUG: console.info('special/service getTimeoutInterval', {time});
  return time;
};

const getSpecials = async () => {
  /**
   * @type {Special[]}
   */
  const specials = [];
  /**
   * @type {Record<string, import('../model/special.js').SpecialData>}
   */
  const res = await import(`${media.assets_ext}/specials/summer2024/config.js`);
  DEBUG: console.info('special/service specials imported', res);
  for (const [name, data] of Object.entries(res)) {
    specials.push(new Special(name, data));
  }
  return specials.filter((special) => !special.hasEnded);
};
