import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate.js';
import featureName from './config/feature.js';
import {BonusTile} from './tiles/BonusTile.js';

angular
  .module(featureName, [angularTranslate])
  .config(provideTranslations(featureName))
  .config(provideTranslations('countries'))
  .config([
    '$httpProvider',
    /**
     * @param {angular.IHttpProvider} $httpProvider
     */
    ($httpProvider) => {
      $httpProvider.interceptors.push('paymentHttpInterceptor');
    },
  ])
  .factory('BonusTile', () => BonusTile)
  .run([
    'payment',
    'Payback',
    /**
     * @param {import('acng/payment/factory/payment').Payment} payment
     * @param {import('acng/payment/factory/payback').IPayback} Payback
     */
    (payment, Payback) => {
      payment.checkPending();
      Payback.registerPending();
    },
  ]);
