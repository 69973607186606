import {requestAnimationFrame, cancelAnimationFrame, setInterval, clearInterval} from '@acng/frontend-bounty';
import {createDiv, addClass, removeClass, append, clear} from '@acng/frontend-bounty';
import angular from 'angular';
angular.module('core').component('onswTimer', {
  bindings: {
    // TODO value und max.
    startAt: '<',
    endAt: '<',
  },
  controller,
});
const CSS_COUNTDOWN = 'countdown';
const CSS_WAIT = 'wait';
const CSS_INACTIVE = 'inactive';
controller.$inject = ['$element'];
/**
 * @param {JQuery} $element -
 */
function controller($element) {
  /** @type {number | undefined } */
  let intervalId = undefined;
  /** @type {number | undefined } */
  let requestAnimationFrameHandle = undefined;
  const element = $element[0];
  const reset = () => {
    clearInterval(intervalId);
    cancelAnimationFrame(requestAnimationFrameHandle ?? -1);
    clear(element);
    removeClass(element, CSS_COUNTDOWN, CSS_WAIT, CSS_INACTIVE);
  };
  this.$onDestroy = reset;
  /**
   * @param {object} changes -
   * @param {angular.IChangesObject<Date | null>} changes.startAt -
   * @param {angular.IChangesObject<Date | null>} changes.endAt -
   */
  this.$onChanges = changes => {
    const {currentValue: startAt} = changes.startAt;
    const {currentValue: endAt = null} = changes.endAt || {};
    reset();
    DEBUG: console.debug('core/widgets/timer $onChanges', {startAt, endAt});
    if (!startAt) {
      addClass(element, CSS_INACTIVE);
      return;
    }
    const bar = createDiv('bar');
    append(element, bar);
    updateBar(bar, startAt, endAt);
    if (!endAt) {
      addClass(element, CSS_WAIT);
    } else {
      addClass(element, CSS_COUNTDOWN);
      intervalId = setInterval(() => {
        requestAnimationFrameHandle = requestAnimationFrame(() => {
          updateBar(bar, startAt, endAt);
        });
      }, 1000);
    }
    /** */
  };
}
/* prettier-ignore */
/**
 * @param {HTMLDivElement} bar -
 * @param {Date} startAt -
 * @param {Date | undefined | null} endAt -
 */
function updateBar(bar, startAt, endAt) {
  let ratio = 1;
  if (endAt) {
    if (endAt < startAt) {
      console.warn('core/widgets/timer endAt before startAt', {startAt, endAt});
      endAt = startAt;
    }
    const range = endAt.getTime() - startAt.getTime();
    const diff = Math.max(0, endAt.getTime() - Date.now());
    const h = Math.floor(diff / 36e5);
    const m = Math.floor(diff % 36e5 / 6e4).toString().padStart(2, '0');
    const s = Math.ceil(diff % 6e4 / 1e3).toString().padStart(2, '0');
    ratio = diff / range;
    bar.textContent = `${h ? h : ''}${h ? ':' : ''}${m}:${s}`;
  }
  bar.style.setProperty('--ratio', `${ratio}`);
}
