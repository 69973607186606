import {body, documentElement, observe, observeSize, toggleClass} from '@acng/frontend-bounty';
import {closest, window} from '@acng/frontend-bounty';
import {EVENT_CLICK, on} from '@acng/frontend-bounty/event';
import {createHistoryEntry} from '@acng/frontend-relativity/history';
import {CTX_OBSERVE, CTX_PROVIDE, CTX_SUBSCRIBE_TAGNAME, CTX_VALUE} from '@acng/frontend-relativity/minify';

import {getGlobalDialog, setGlobalDialog} from './global-dialog.js';

/**
 * Better do not export that, because internally it is observed from the `document.body`.
 */
const ctxContactsVisibility = createHistoryEntry('messenger-contacts', false);

/**
 * @param {boolean} visible
 */
export const setContactsVisibility = (visible) => ctxContactsVisibility[CTX_PROVIDE](null, visible);

/**
 * @returns {boolean}
 */
export const getContactsVisibility = () => ctxContactsVisibility[CTX_VALUE](documentElement);

/**
 * @deprecated (angularjs)
 */
export const runContactsVisibility = [
  'user',
  '$rootScope',
  '$location',
  /**
   * @param {import('acng/userPool/factory/user').User} user
   * @param {import('acng/zz-app').RootScope} $rootScope
   * @param {angular.ILocationService} $location
   */
  (user, $rootScope, $location) => {
    ctxContactsVisibility[CTX_SUBSCRIBE_TAGNAME]('body');
    ctxContactsVisibility[CTX_OBSERVE](body, (visible) => toggleClass(body, 'show-contacts', visible));

    const controlContactsVisibility = () => {
      if (user.guest) {
        setContactsVisibility(false);
      } else if (window.innerWidth >= 768) {
        setContactsVisibility(true);
      }
    };

    $rootScope.$on('userPool.login.success', controlContactsVisibility);

    $rootScope.$on('$locationChangeSuccess', (_ev, _newUrl, _oldUrl, newState) => {
      if (!newState && window.innerWidth < 768) {
        DEBUG: console.warn('$LOCATIONCHANGE without state MOBILE => replace close dialog & contacts');
        setGlobalDialog(null, true);
        setContactsVisibility(false);
      }
    });

    observe(observeSize(controlContactsVisibility), documentElement);

    // Last but not least: Really hack the shit out of the angular location service (without html5 mode).
    on(window, EVENT_CLICK, (ev) => {
      if (window.innerWidth < 768 && (getGlobalDialog() || getContactsVisibility())) {
        closest(
          ev.target,
          '.header-widget:not(.messenger-button):not(.menu-button):not(.main-menu-hook):not(.coins-button)',
          () => {
            DEBUG: console.warn('$LOCATIONCHANGE HACK kickoff');
            $rootScope.$applyAsync(() => {
              DEBUG: console.warn('$LOCATIONCHANGE HACK random hash param');
              $location.search('x', `${Math.random() * 1e6}`);
            });
          }
        );
      }
    });
  },
];
