import {documentElement} from '@acng/frontend-bounty';
import {createHistoryEntry, pushHistory} from '@acng/frontend-relativity/history';
import {CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';

export const ctxGlobalDialog = createHistoryEntry('messenger-dialog', /** @type {string | null} */ (null));

/**
 * Pushes the browser history!
 *
 * @param {string | null} amateurId
 *
 * @param {boolean} [replaceHistory]
 * Do not push the history when set to `true`.
 */
export const setGlobalDialog = (amateurId, replaceHistory) => {
  if (!replaceHistory) {
    pushHistory();
  }
  ctxGlobalDialog[CTX_PROVIDE](null, amateurId);
};

/**
 * @returns {string | null}
 */
export const getGlobalDialog = () => ctxGlobalDialog[CTX_VALUE](documentElement);
