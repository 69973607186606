import {media} from '../service/env';
import dummy from 'assets/basic/img/f_900.png';
angular.module('core')
  .factory('fskImage', ['fsk', 'media', '$timeout', function (fsk, media, $timeout) {

    function generateFormatToImageMapForFsk(images, fsk) {
      const mapped = {};
      for (let format of Object.keys(images)) {
        for (let data of images[format]) {
          if (data[0] <= fsk) {
            mapped[format] = `${media.content.pictures}${data[1]}`;
            break;
          }
        }
      }

      return mapped;
    }

    /**
   * @param {Object} images Expected format:
   * {
   *   <format>: [ // array ordered from highest to lowest fsk
   *     [<fsk>, <imagePath>],
   *     ...
   *   ],
   *   ...
   * }
   * @param {Integer|String} requestedFsk
   * @returns {function(format)}
   */
    function makeRawGetter(images, requestedFsk) {
      const mapped = generateFormatToImageMapForFsk(images, Number.parseInt(requestedFsk, 10));
      return format => mapped[format] || `${media.assets}/${dummy}`;
    }

    const data = new Map();
    fsk.addEventListener('change', () => data.clear());

    function clearCacheAfterTimeout() {
      $timeout(() => {
        data.clear();
        clearCacheAfterTimeout();
      }, 3600000);
    }

    function getKey(type, id, fsk) {
      return [type, id, fsk].join('.');
    }

    function make(type, id, images, preferredFsk = 100) {

      preferredFsk = Number.parseInt(preferredFsk, 10);
      const wantedFsk = Math.min(preferredFsk, fsk.get());
      const key = getKey(type, id, wantedFsk);

      if (!data.has(key)) {
        const map = generateFormatToImageMapForFsk(images, wantedFsk);
        data.set(key, map);

        // try to find matches without preferred image
        if (preferredFsk !== 100 && preferredFsk !== wantedFsk && !Object.keys(map).length) {
          return make(type, id, images);
        }
      }

      return format => get(key, format);
    }

    function get(key, format) {
      return data.get(key)[format] || `${media.assets}/${dummy}`;
    }

    clearCacheAfterTimeout();

    return {
      make: make,
      data: data,
      makeRawGetter: makeRawGetter
    };

  }]);
