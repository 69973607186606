export default 'pascalprecht.translate';
/**
 * @param {string} part
 */
export const provideTranslations = (part) => [
  '$translatePartialLoaderProvider',
  /**
   * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
   */
  ($translatePartialLoaderProvider) => {
    $translatePartialLoaderProvider.addPart(part);
  },
];
