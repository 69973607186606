import {INTEGER, typeguard, fail} from '@acng/frontend-bounty/typeguard';
import {startUpFsk} from '../core/service/env.js';

export const POSSIBLE_FSK_LEVELS_DESC = /** @type {const} */ ([18, 16, 12]);
export const POSSIBLE_FSK_KEYS = /** @type {const} */ (['18', '16', '12']);

/**
 * @typedef {typeof POSSIBLE_FSK_LEVELS_DESC[number]} FskLevel
 * @typedef {typeof POSSIBLE_FSK_KEYS[number]} FskString
 */

/**
 * @type {import('@acng/frontend-bounty/typeguard').Assertion<FskLevel>}
 */
export const FSK_LEVEL = (name, value, context) => {
  typeguard(name, value, INTEGER, context);
  if (!POSSIBLE_FSK_LEVELS_DESC.filter((level) => level == value).length) {
    fail(`${name} is not a possible fsk level`, {value, POSSIBLE_FSK_LEVELS_DESC}, context);
  }
};

class Fsk extends EventTarget {
  /**
   * @param {FskLevel} level
   */
  constructor(level) {
    super();
    this.level = level;
  }

  /**
   * @param {FskLevel} level
   */
  set(level) {
    var oldLevel = this.level;
    this.level = level;
    if (level != oldLevel) {
      this.dispatchEvent(new CustomEvent('change', {detail: {fsk: level, old: oldLevel}}));
    }
  }

  get() {
    return this.level;
  }
}

const startUpFskNumber = Number.parseInt(startUpFsk);
ASSERT: typeguard('startupFsk', startUpFskNumber, FSK_LEVEL);

export const fsk = new Fsk(startUpFskNumber);
