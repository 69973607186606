// @ts-nocheck
import {hasAttribute} from '@acng/frontend-bounty';
import {slideshowConfig as Slideshow} from '../factory/Slideshow';
angular.module('core').directive('onswSlideshow', ['$timeout', '$window', '$animate', '$injector', 'user', 'Widget', function ($timeout, $window, $animate, $injector, user, Widget) {

  let globalPage = 0;

  return {
    restrict: 'A',
    scope: {
      sliderDelay: '@',
      pages: '<',
      onSlide: '&'
    },
    template: '<div class="box" ng-show="count > 1"><span class="left" ng-click="goto(current - 1)"></span><span class="goto" ng-repeat="(i,v) in tiles" ng-click="goto(i)" ng-hide="tiles[i].disabled" ng-class="{active: current === i}"></span><span class="right" ng-click="goto(current + 1)"></span><span ng-if="user.tester" ng-click="toggle()" ons-icon="{{ !disabled ? \'close\' : \'forward\'  }}"></span></div>',
    link: function (scope, element) {
      const widget = new Widget(scope, element);
      const VERBOSE_DEBUG = hasAttribute(element[0], 'debug');

      $animate.on('enter', element.parent(), setTransformOrigin);
      $animate.on('leave', element.parent(), setTransformOrigin);

      function setTransformOrigin(el) {
        el.css({
          transformOrigin: '50% 50% -' + (element.parent().parent().is('.left, .right') ? element.parent().width() : element.parent().height()) / 2 + 'px'
        });
      }

      scope.user = user;
      scope.current = 0;
      scope.tiles = [];
      let childEl = false;
      scope.goto = function (i) {
        stop();
        if ((scope.current = i % scope.tiles.length) < 0) {
          scope.current += scope.tiles.length;
        }
        globalPage = scope.current ?? 0;
        i = scope.current;

        const tile = scope.tiles[i];

        DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow goto', tile);
        //tile.reset();
        widget.clear().busy(tile.get())
          .then(() => {
            let p;
            tile.el.classList.add('animate');
            if (tile.disabled) {
              throw 'disabled';
            }
            if (childEl == tile.el) {
              start();
              return;
            }
            if (childEl) {
              $animate.leave(childEl);
              p = $animate.enter(tile.el, element.parent()).then(start);
            } else {
              element.parent().append(tile.el);
              start();
            }

            scope.onSlide({ caption: tile.caption });
            childEl = tile.el;
            return p;
          })
          .catch(err => {
            if (err == 'busy override') {
              throw err;
            }
            DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow', { err, tile: scope.tiles[i] });
            if (!scope.tiles.reduce((empty, tile) => empty && tile.disabled, true)) {
              DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow is not empty -> skip', scope.tiles[i]);
              scope.goto(scope.current + 1);
            }
          })
          .then(() => {
            scope.count = scope.tiles.reduce((count, tile) => count += tile.disabled ? 0 : 1, 0);
          });
      };

      scope.$on('tile.update', (ev, tile) => {
        DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow tile update', ev, tile);
        if (tile.disabled && scope.current == tile.i) scope.goto(tile.i + 1);else
          if (!tile.disabled) scope.goto(tile.i);
      });

      //scope.$watch(() => element.is(':visible'), visible => $rootScope.sidebar = visible ? 'visible' : 'invisible');
      //scope.$on('$destroy', () => $rootScope.sidebar = false);

      scope.$watch('pages', pages => {
        scope.tiles = Slideshow[pages].filter(slide => $injector.has(slide[0])).map((slide, i) => {
          const tileClass = $injector.get(slide[0]);
          if (!tileClass) {
            return null;
          } else {
            let tile = new tileClass(slide[1], scope.$new());
            tile.setMeta(i);
            tile.delay = slide[2];
            return tile;
          }
        });
        DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow', scope.tiles);
        scope.goto(globalPage);
      });


      angular.element($window).on('blur', stop).on('focus', start);
      scope.$on('$destroy', function () {
        DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow $destroy');
        stop();
        angular.element($window).off('blur', stop).off('focus', start);
        scope.disabled = true;
      });

      element.parent()
        .mouseenter(stop)
        .focusin(stop)
        .mouseleave(start)
        .focusout(start);

      let timeout;

      function start(ev) {
        DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow start', ev);
        if (scope.disabled || !element.width()) return;
        stop();
        const delay = scope.tiles[scope.current]?.delay || scope.sliderDelay;
        DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow $timeout with %f s delay', delay / 1000);
        timeout = $timeout(function () {
          timeout = null;
          if (element.parent()[0].getBoundingClientRect().bottom < 0) {
            DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow out of view - skip');
            start();
            return;
          }
          scope.goto(scope.current + 1);
        }, delay);
      }

      scope.toggle = () => {
        scope.disabled = !scope.disabled;
        if (scope.disabled) stop();else
          scope.goto(scope.current + 1);
      };

      function stop() {
        if (timeout) {
          DEBUG: if (VERBOSE_DEBUG) console.debug('onsw-slideshow cancel $timeout');
          $timeout.cancel(timeout);
          timeout = null;
        }
      }

    }
  };
}]);
