// @ts-nocheck
/**
 * @module core/service/onsHistory
 */
import {setTimeout, onWindow, EventTarget, CustomEvent} from '@acng/frontend-bounty';
import {HISTORY_GET_STATE, HISTORY_PUSH_STATE, HISTORY_REPLACE_STATE} from '@acng/frontend-bounty/history';
import angular from 'angular';

    class History extends EventTarget
    {
      constructor() {
        super();
        this.state = {};
      }

      push(type, obj) {
        if (!type) return;
        let b = {...HISTORY_GET_STATE()};
        b[type] = obj;
        this.state[type] = obj;
        HISTORY_PUSH_STATE(b);
      }

      replace(type, obj) {
        if (!type) return;
        let b = {...HISTORY_GET_STATE()};
        b[type] = obj;
        this.state[type] = obj;
        HISTORY_REPLACE_STATE(b);
      }

      getfunction(type) {
        return angular.copy(this.state[type]);
      }

      raw(type) {
        return HISTORY_GET_STATE()?.[type] ?? null;
      }

      back() {
        setTimeout(() => window.history.back(), 10);
      }
    }

    export const onsHistory = new History();

    function load(event) {
      if (!event.state) {
        return;
      }
      for (var type in event.state) {
        var prevState = angular.copy(onsHistory.state[type]);
        if (!angular.equals(event.state[type], prevState)) {
          onsHistory.state[type] = event.state[type];
          onsHistory.dispatchEvent(new CustomEvent(type, { detail: {
            state: angular.copy(onsHistory.state[type]),
            prevState: prevState
          } }));
        }
      }
    }
    load(window.history);

    onWindow('popstate', load);
    onWindow('beforeunload', () => HISTORY_REPLACE_STATE(HISTORY_GET_STATE()));
