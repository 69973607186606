import {put} from 'acng/core/service/backend.js';

/**
 * @typedef {"de" | "en" | string} CountryCode
 */

/**
 * @param {CountryCode} countryCode
 * @param {HTMLElement} element
 * @returns {Promise<null>}
 */
export const putCountry = async (countryCode, element) => put(`locale/${countryCode}`, null, element);
