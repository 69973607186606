import {path, alias, expr, exact, middleware} from '@acng/frontend-voyager';
import {replaceUrl} from '@acng/frontend-relativity/history';

import {rootRoute} from 'acng/core/config/routes';
import {userRoute} from 'acng/userPool/config/routes';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {mainView} from 'acng/layout/service/view-middleware.js';
import {onlyUserOrPreview} from 'acng/userPool/config/middleware';
import {inject} from 'acng/core/service/ng';
import {ctxCommentable} from 'acng/comment/model/commentable';

import {moviePoolFeature} from './feature.js';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {FreeMovie} from '../factory/FreeMovie.js';

export const moviesRoute = rootRoute.create(
  onlyUserOrPreview, //
  expr(/^(?:\/sedcard\/.+)?\/movies$/y),
  mainView(moviePoolFeature, 'movies'),
  // TODO alias is required by onswCategorySelect in sidebar
  alias('movies')
);

userRoute.create(
  exact('/fmotd'), //
  alias('fmotd')
);

amateurRoute.create(
  path('/movie/{movieId}'), //
  mainView(moviePoolFeature, 'movie'),
  alias('movieDetail'),
  middleware((next, prev) => {
    if (next.movieId == prev.movieId) {
      next.movie = prev.movie;
      return true;
    }
    return (async () => {
      try {
        const movie = await inject('Movie').get(/** @type {string} */ (next.movieId));
        next.movie = movie;
        ctxCommentable[CTX_PROVIDE](null, {
          type: 'movie',
          id: movie.set_id,
          blockWithReason: async () => {
            if (movie.blocked) {
              return 'comment.notCommentable';
            }
            if (!(await movie.getStock())) {
              const free = await FreeMovie.isFree(movie.id);
              if (free) {
                if (!inject('user').isPremium()) {
                  inject('payment').overlay('comment.freeUser');
                  return 'comment.freeUser';
                }
                return false;
              }
              return 'comment.buyMovie';
            }
            return false;
          },
        });
      } catch (reason) {
        replaceUrl('/#/');
        throw reason;
      }
    })();
  })
);
