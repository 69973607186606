angular.module('ons-LaForge-client')
  .factory('onsLaforgeEvents', ['$location', '$translate', 'portal', function ($location, $translate, portal) {

    class LogEvent {

      constructor() {
        this.window = window;
        this.screen = screen;
        this.navigator = window.navigator;

      }

      getScreen() {
        return {
          width: this.screen.width,
          height: this.screen.height
        };
      }

      getWindow() {
        return {
          width: this.window.innerWidth,
          height: this.window.innerHeight,
          zoom: this.window.devicePixelRatio

        };
      }

      getUserAgent() {
        return this.navigator.userAgent;
      }

      getDTO() {
        return {
          type: this.type,
          screen: this.getScreen(),
          window: this.getWindow(),
          userAgent: this.getUserAgent(),
          url: $location.url(),
          locale: $translate.use(),
          whitelabel: portal.id
        };

      }
    }

    return { LogEvent };

  }]);
