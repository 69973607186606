angular.module('core')

  .directive('onswChosen', ['$document', '$rootScope', ($document, $rootScope) => {

    var unstash = 0;

    $rootScope.$watch(function () {
      if (unstash === 2) unstash = 0;
    });

    return {
      scope: {
        placeholder: '@',
        choose: '=',
        options: '<'
      },
      template: `
<div class="box">
    <div class="info no-options" ng-bind="'core.noOptions' | translate"></div>
    <div class="info please-choose" ng-bind="placeholder | translate"></div>
    <div class="selected">
        <div class="option" ng-repeat="option in selectedOptions" ng-click="remove(option)">{{option.val}}</div>
        <div class="add"><span>&plus;</span></div>
    </div>
    <div class="available ons-scroll">
        <div class="box">
            <input type="text" ng-model="filter">
            <div class="option" ng-repeat="option in availableOptions | filter:{val: filter}" ng-click="add(option)">
                {{option.val}}
            </div>
        </div>
    </div>
</div>`,
      link: function (scope, element) {

        scope.add = function (option) {
          (scope.choose || (scope.choose = [])).push(option.key);
          element.addClass('toggle');
          scope.$emit('onswChosenChange', {trigger: 'user'});
        };

        scope.remove = function (option) {
          scope.choose.splice(scope.choose.indexOf(option.key), 1);
          if (scope.choose.length === 0) {
            scope.choose = undefined;
          }
          element.addClass('toggle');
          scope.$emit('onswChosenChange', {trigger: 'user'});
        };

        var stashedOptions = [];
        scope.$watch('options', function () {
          if (unstash === 1) {
            unstash = 2;
          }
          update();
          if (scope.options && scope.choose) {
            for (var j = 0, l = stashedOptions.length; unstash === 0 && j < l; j++) {
              var bla = function (val) {
                return val.key === stashedOptions[j];
              };
              if (scope.options.find(bla)) {
                scope.choose.push(stashedOptions.splice(j, 1)[0]);
                unstash = 1;
              }
            }
            for (var i = 0; i < scope.choose.length; i++) {
              var isSelected = function (val) {
                return val.key === scope.choose[i];
              };
              if (!scope.options.find(isSelected)) {
                stashedOptions.push(scope.choose.splice(i, 1)[0]);
                scope.$emit('onswChosenChange', { trigger: 'autoAdjust' });
                i--;
              }
            }
          }
        });
        scope.$watchCollection('choose', update);

        function update() {
          scope.availableOptions = (scope.options || []).filter(function (option) {
            return (scope.choose || []).indexOf(option.key) === -1;
          }, []);
          scope.selectedOptions = (scope.options || []).filter(function (option) {
            return (scope.choose || []).indexOf(option.key) !== -1;
          }, []);
          element.toggleClass('has-options', !!(scope.options || []).length);
          element.toggleClass('has-selected-options', !!scope.selectedOptions.length);
          element.toggleClass('has-available-options', !!scope.availableOptions.length);
        }

        element.find('input').on('click', function ($event) {
          $event.stopPropagation();
        });

        $document.on('click', click);
        scope.$on('$destroy', function () {
          $document.off('click', click);
        });

        function click($event) {
          for (var el = $event.target; el; el = el.parentNode) {
            if (el === element[0]) {
              scope.filter = '';
              element.toggleClass('toggle');
              return;
            }
          }
          element.removeClass('toggle');
        }

      }
    };
  }]);
