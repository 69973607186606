import {Feature} from 'acng/core/model/feature.js';

const name = 'friend';
export default name;
export const friendFeature = new Feature(name);

['onsw-friend-link'].map((name) => friendFeature.defineRemoveWhenHidden(name));
['onsw-friend-operations', 'onsd-amateur-friends', 'onsd-friends'].map((name) =>
  friendFeature.defineRemoveWhenHidden(name, true)
);
