angular.module('payment').directive('onswPaymentPaybackOverview', ['payment', 'Payback', 'portal', 'supportEmail', '$window',
  function (payment, Payback, portal, supportEmail, $window) {

    return {
      scope: {
        transaction: '=',
        overlay: '<?'
      },
      templateUrl: '/template/payment.paybackOverview',
      link: function (scope) {

        scope.repayableDebts = null;
        scope.otherDebts = null;
        scope.bankTransferData = null;
        scope.joinpageData = null;
        scope.paybackInProgress = false;
        scope.paybackTransaction = null;

        scope.supportTranslateParams = {
          portal: portal.name,
          supportEmail: supportEmail
        };

        scope.openExternalJoinpage = () => {
          if (!scope.paybackTransaction) {
            return;
          }

          scope.transaction = scope.paybackTransaction;

          $window.open(scope.paybackTransaction.rawPspUrl);
        };

        payment.loadAndGetCustomerId().then(customerId => {
          Payback.index(customerId).then(res => {
            for (const deptInfo of res.data) {
              if (!deptInfo.items.length) {
                continue;
              }

              if (deptInfo.payback_in_progress === true) {
                scope.repayableDebts = deptInfo;
                scope.paybackInProgress = true;
              } else if (deptInfo.methods.length > 0) {
                scope.repayableDebts = deptInfo;
                for (const actual of deptInfo.methods) {
                  switch (actual.type) {

                    case 'manual':
                      scope.bankTransferData = actual;
                      break;

                    case 'joinpage':
                      scope.joinpageData = actual;
                      Payback.start(customerId, actual.parameter.id)
                        .then(res => scope.paybackTransaction = res)
                        .catch(e => {
                          console.error('Failed to init payback transaction', e);
                        });
                      break;

                  }
                }
              } else {
                scope.otherDebts = deptInfo;
              }
            }
            scope.$applyAsync();
          });
        });
      }
    };
  }]);
