angular.module('amateurPool')

  .directive('onswAmateurFilterHeading', ['AmateurFilter', 'amateurFacetsDefault', (AmateurFilter, amateurFacetsDefault) => {

    var headlines = {
      'all': 'amateurPool.allAmateurs',
      'new': 'amateurPool.newAmateurs',
      'top': 'amateurPool.topAmateurs',
      'online': 'amateurPool.onlineAmateurs',
      'livedating': 'amateurPool.allLivedates',
      'diaries': 'amateurPool.allDiaries',
      'livecam': 'livecam.livecams'
    };

    return {
      scope: {
        filterId: '@'
      },
      template: '<h1 class="box">{{label | translate:{nickname: filter.nickname.join(" ")} }}</h1>',
      link: function (scope, element) {

        element.addClass('ons-heading');

        scope.$watch('filterId', id => scope.filter = AmateurFilter.get(id));

        scope.$watch('filter', function () {
          scope.label = !angular.equals(scope.filter.facets, amateurFacetsDefault) ?
            'amateurPool.searchByPreferences' :
            scope.filter.nickname && scope.filter.nickname.length ?
              'amateurPool.searchByNickname' :
              headlines[scope.filter.category];
        }, true);
      }
    };
  }]);
