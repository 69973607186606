import angular from 'angular';
import {listen} from "acng/core/context/event-bus.js";

angular.module('rating')
  .directive('onswRatingRater', ['$injector', function ($injector) {

    function link(scope, element) {

      element.addClass('ons-form');

      scope.rating = 0;
      scope.success = false;
      var container = element.find('.outer');
      var rating = container.find('.inner');
      var item = null;

      container.on('click', function (ev) {
        if (!item) return;
        var offset = ev.pageX - angular.element(ev.currentTarget).offset().left;
        scope.$applyAsync(function () {
          scope.rating = Math.ceil(offset / (container.width() / 5));
          rating.css({ width: getWidth(scope.rating) + '%' });
        });
      });

      rating.css({ width: getWidth(scope.rating) + '%' });

      scope.rate = function () {
        item.rate(scope.rating).then(function () {
          scope.rated = true;
          scope.success = true;
        });
      };

      scope.close = function () {
        if (scope.onclose) {
          scope.onclose();
        }
      };

      scope.$watch('itemId', function (id) {
        if (!id) return;
        scope.rateable = false;
        scope.rated = false;
        $injector.get(scope.type).get(id).then(function (x) {
          item = x;
          return x;
        })
          .then(function (item) {
            checkRateable();
            item.getRating().then(function (res) {
              if (res === null) return;
              scope.rated = true;
            });
          });
      });

      scope.$on('$destroy', listen('gallery.rating', checkRateable));

      function checkRateable() {
        if (!item || scope.rateable) return;
        item.rateable().then(function () {
          scope.rateable = true;
        }, function (err) {
          scope.rateable = false;
          if (typeof err === 'string') {
            scope.notRateableMsg = err;
          }
        });
      }

      function getWidth(rating) {
        if (rating == undefined) rating = scope.personalRating || scope.item.rating;
        return 100 * rating / 5;
      }
    }

    return {
      scope: {
        itemId: '@',
        type: '@',
        onclose: '&?'
      },
      link: link,
      templateUrl: 'template/rating.rater'
    };

  }]);
