import angular from 'angular';
import {onHashChange, onPopState} from '@acng/frontend-relativity/history';
import {env, FEATURES} from 'acng/core/service/env';
import './layout';
import './achievement';
import './kiss';
import './imprint';
import './privacyPolicy';
import './alternativeProducts';
import './comment';
import './games';
import './locale';
import './regard';
import './sexicon';
import './special';
import './terms';
import './pin';
import voyager, {rootRoute} from '@acng/frontend-voyager/angularjs';

/**
 * @returns {string} -
 */
String.prototype.ucFirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
/**
 * @returns {string} -
 */
String.prototype.lcFirst = function () {
  return this.charAt(0).toLowerCase() + this.slice(1);
};
/**
 * @returns {string} -
 */
String.prototype.toDash = function () {
  return this.replace(/([A-Z])/g, function ($1) {
    return '-' + $1.toLowerCase();
  });
};
/**
 * @returns {string} -
 */
String.prototype.toCamel = function () {
  return this.replace(/(-[a-z])/g, function ($1) {
    return $1.toUpperCase().replace('-', '');
  });
};
/**
 * @returns {number} -
 */
String.prototype.hashCode = function () {
  var hash = 5381;
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = (hash << 5) + hash + char;
    /* hash * 33 + c */
  }
  return hash;
};

/**
 * @typedef RootScopeTrait
 * @property {(name: string) => boolean} hasFeature -
 * @property {() => string} locale -
 * @property {typeof rootRoute.globals} request
 * @property {import('acng/amateurPool/factory/Amateur').Amateur | null} dialogAmateur
 * @property {string[]} friends
 * @property {string[]} matches
 * @property {string[]} pins
 * @property {import('./messenger/model/dialog').Dialog[] & {changed?: number, online?: () => boolean}} dialogs
 */
/**
 * @typedef {angular.IRootScopeService & RootScopeTrait} RootScope
 */
angular
  .module('acngApp', [
    voyager,
    'core',
    'acngMisc',
    'ngSanitize',
    'pascalprecht.translate',
    'ngAnimate',
    'ngStorage',
    'storage',
    'ons-actionslider',
    'ngCookies',
    'ons-LaForge-client',
    ...FEATURES,
  ])
  .run([
    '$rootScope',
    /**
     * @param {RootScope} $rootScope
     */
    ($rootScope) => {
      $rootScope.request = rootRoute.globals;
      $rootScope.$on('$locationChangeSuccess', (ev, newURL, oldURL, newState, oldState) => {
        DEBUG: rootRoute.VERBOSE && console.info('$LOCATIONCHANGE', {newURL, newState, oldState});
        if (!newState) {
          DEBUG: rootRoute.VERBOSE && console.info('$LOCATIONCHANGE TRIGGERS relativity HASHCHANGE to polulate HISTORY STATE');
          onHashChange(new HashChangeEvent('hashchange', {newURL, oldURL}));
        } else if (newState !== oldState) {
          DEBUG: rootRoute.VERBOSE && console.info('$LOCATIONCHANGE TRIGGERS relativity POPSTATE to keep the contexts sync');
          onPopState(new PopStateEvent('popstate', {state: newState}));
        }
      });
    },
  ]);

for (const k in env) {
  angular.module('core').constant(k, env[k]);
}
