import {post} from 'acng/core/service/backend';
import {inject} from 'acng/core/service/ng.js';
import {recentMessageIsIcebreaker} from './recent-message.js';
import {publishUserMessage} from './event-bus.js';
import {t} from 'acng/locale/config/translate.js';

/**
 * @param {HTMLElement} host
 * @param {import('../model/message').Message} message
 * @param {number} sonusId
 * @returns {Promise<void>}
 */
export const sendVoice = async (host, message, sonusId) => {
  const {amateur} = message;
  const nickname = amateur.getNickname();


  const {time} = await post('message/voice/send', {
    recipient: amateur.id,
    id: sonusId,
    icebreakerAnswer: await recentMessageIsIcebreaker(amateur),
    price: inject('price').get(amateur),
  }, host,
    /** @returns {Promise<{time: number}>} */
    async (error) => {
      if (error.code >= 4000 && error.code < 5000) {
        error.message = await t('messenger.paymentRequired', {nickname});
      }
      throw error;
    }
  );
  
  const payloadBackup = message.payload;
  message.payload = {type: 'voice', id: sonusId};
  message.timestamp_ms = time * 1000;

  publishUserMessage(message);

  message.payload = payloadBackup;
};
