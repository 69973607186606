/**
 * @module acng/kiss
 */
import angular from 'angular';
import onswKissButton, {buttonComponent} from './widget/button';

const featureName = 'kiss';
export default featureName;

angular
  .module(featureName, ['pascalprecht.translate', 'core', 'userPool', 'amateurPool', 'messenger', 'payment'])
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    $translatePartialLoaderProvider => {
      $translatePartialLoaderProvider.addPart(featureName);
    },
  ])
  .component(onswKissButton, buttonComponent);
