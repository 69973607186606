// @ts-nocheck
import {getOnlineArray, isOnline} from '../service/online';
import {shuffle} from 'acng/core/factory/helper';
import {kgToLbs, cmToIn} from 'acng/locale/service/units.js';

angular.module('amateurPool')

  .constant('amateurFacetsDefault', { gender: ['f'] })

  .run(['AmateurFilter', (AmateurFilter) => {
    let onlineList = [];
    getOnlineArray().then(list => onlineList = list);
    AmateurFilter.get('online').category = 'online';
    AmateurFilter.get('online').strainer = getOnlineArray;
    AmateurFilter.get('gameScore').category = 'game_score';
    AmateurFilter.get('gameScore').transform = list => {
      let i = 0;
      do {
        if (!isOnline(list[i])) {
          list.splice(i, 1);
        } else {
          i++;
        }
      } while (i < list.length);
    };
    AmateurFilter.get('new').category = 'new';
    AmateurFilter.get('new').strainer = getOnlineArray;
    AmateurFilter.get('all').category = 'all';
    AmateurFilter.get('all').strainer = getOnlineArray;
    AmateurFilter.get('top').category = 'top';
    AmateurFilter.get('suggestion').category = 'suggestion';
    AmateurFilter.get('suggestion').strainer = getOnlineArray;
    AmateurFilter.get('attractiveness').category = 'attractiveness';
    AmateurFilter.get('attractiveness').strainer = getOnlineArray;
    AmateurFilter.get('attractiveness').transform = list => {
      list.length = 200;
      shuffle(list);
    };
    AmateurFilter.get('main').category = 'all';
    AmateurFilter.get('main').strainer = getOnlineArray;
    AmateurFilter.get('livedating').category = 'livedating';
    AmateurFilter.get('livedating').strainer = getOnlineArray;
    AmateurFilter.get('search').category = 'all';
    AmateurFilter.get('search').strainer = getOnlineArray;
  }])

  .factory('AmateurFilter', ['http', 'amateurFacetsDefault', '$rootScope', '$location', 'user', '$translate', '$log', '$q', 'Amateur', 'TagToTopicMapping', (http, amateurFacetsDefault, $rootScope, $location, user, $translate, $log, $q, Amateur, TagToTopicMapping) => {

    var filters = {};
    let allFacets = null;

    function prepareFacets(rawFacets) {
      const facets = {};
      angular.forEach(rawFacets, (facet, fieldName) => {
        facets[fieldName] = [];
        angular.forEach(facet.values, (value, attribute) => {

          if (value == 0) {
            return;
          }

          if (facet.gap) {
            if (fieldName == 'zip_area' && attribute < 0) {
              facets[fieldName].unshift({ key: attribute, val: $translate.instant('amateurPool.no_zip_area') + ' (' + value + ')' });
              return;
            }
            let va, vg;
            if (fieldName == 'weight' && !user.usesMetricUnits()) {
              va = kgToLbs(parseInt(attribute));
              vg = kgToLbs(parseInt(facet.gap));
            } else if (fieldName == 'height' && !user.usesMetricUnits()) {
              va = cmToIn(parseInt(attribute));
              vg = cmToIn(parseInt(facet.gap));
            } else {
              va = attribute;
              vg = facet.gap;
            }
            facets[fieldName].push({
              key: attribute,
              val: va.toString().padStart(2, '0') + ' - ' + (parseInt(va) + parseInt(vg) - 1).toString().padStart(2, '0') + ' (' + value + ')'
            });
          } else
          {
            $translate((fieldName === 'country' ? 'countries.iso.' : 'models.AmateurProfile.' + fieldName + '_attributes.') + attribute)
              .then(text => {
                facets[fieldName].push({
                  key: attribute,
                  val: text + ' (' + value + ')'
                });
              })
              .catch(err => $log.warn('AmateurFilter missing translation', err));
          }

        });
      });

      return facets;
    }

    class AmateurFilter extends EventTarget {

      constructor(id) {
        super();
        if (!id) throw new Error('No ID given', id);
        filters[id] = this;
        this.id = id;
        this.reset();
        this.urlKey = 'filter-' + this.id;
        this.strainer = () => $q.resolve([]);
        this.transform = null;

        //this.loadFromSearch();
        $rootScope.$on('$locationChangeSuccess', () => this.loadFromSearch());
      }

      loadFromSearch() {
        const search = angular.copy($location.search());

        let command = false;
        angular.forEach(search, (v, k) => {
          if (k.indexOf(this.urlKey) >= 0) {
            command = true;
          }
        });
        if (!command) return;

        this.facets = angular.copy(amateurFacetsDefault);
        this.nickname = [];
        let change = false;

        if (search['filter-search.facets.tag_list'] && !search['filter-search.facets.topic_list']) {
          search['filter-search.facets.topic_list'] = search['filter-search.facets.tag_list'].toString()
            .split(',')
            .map(tag => TagToTopicMapping[tag.toString().trim()] || tag)
            .join(',');

          delete search['filter-search.facets.tag_list'];
        }

        angular.forEach(search, (v, k) => {
          let key = k.split('.');
          let filterName = key.shift();
          if (filterName === this.urlKey) {
            let obj = this;
            let target = key.pop();
            key.forEach(a => {
              obj = obj[a] || {};
            });
            v = v.split(',').filter(x => x !== '');
            if (!Array.isArray(v)) v = [];
            if (!angular.equals(obj[target], v)) {
              $location.search(k, null).replace();
              obj[target] = v;
              change = true;
            }
          }
        });

        if (change) {
          this.dispatchEvent(new CustomEvent('change'));
        }
      }

      updateUrl() {
        const obj = this.toSearchObj();
        const search = $location.search();

        if (angular.equals(obj, search)) {
          return;
        }

        angular.forEach(search, (v, k) => {
          if (k.split('.')[0] === this.urlKey) {
            delete search[k];
          }
        });
        $location.replace().search($.param(angular.merge(search, obj)));
      }

      toSearchObj() {
        const res = {};
        const data = { facets: this.facets };
        if (this.nickname) {
          data.nickname = this.nickname;
        }
        (function recurse(obj, current) {
          for (let key in obj) {
            let value = obj[key];
            let newKey = current ? current + '.' + key : key;
            if (Array.isArray(value)) {
              res[newKey] = value.join(',');
            } else if (value && typeof value === 'object') {
              recurse(value, newKey);
            } else {
              res[newKey] = value;
            }
          }
        })(data, this.urlKey);
        return res;
      }

      reset() {
        this.category = 'all';
        this.nickname = [];
        this.facets = angular.copy(amateurFacetsDefault);
        this.dispatchEvent(new CustomEvent('change'));
      }

      serializeOtherParams() {
        const res = [];

        const nickname = this.nickname.join(' ').trim();
        if (nickname) {
          const searchValue = `${nickname.replace(/(?!-)\W/g, '*')}*`;
          res.push({ 'field': 'nickname', 'value': searchValue });
        }

        return angular.toJson(res);
      }

      equals(otherFilter) {
        if (!(otherFilter instanceof AmateurFilter)) {
          return false;
        }

        return angular.equals(this.facets, otherFilter.facets) && angular.equals(this.nickname, otherFilter.nickname);
      }

      static get(id) {
        return filters[id] || new AmateurFilter(id);
      }

      static list() {
        return filters;
      }

      static getAllFacets() {
        return allFacets || (
          allFacets = http().get(`/api/amateur-profile/facets/all?niche=${user.niche}`)
            .then(res => prepareFacets(res.data.data)));
      }

      static getAllCountries() {
        return AmateurFilter.getAllFacets().then(function (facets) {
          facets.country.forEach(function (val, i) {
            facets.country[i].val = /^([^(]*)/.exec(facets.country[i].val)[1];
          });
          return facets.country;
        });
      }

      async getActualFacets()
      {
        const params = {
          niche: user.niche,
          filters: this.serializeOtherParams(),
          facets: angular.toJson(this.facets),
        };

        const res = await http().get(`/api/amateur-profile/facets/${this.category || ''}`, { params });
        return prepareFacets(res.data.data);
      }

      list(limit) {
        const params = {
          trigger: this.trigger,
          limit: limit || 30,
          niche: user.niche,
          filters: this.serializeOtherParams(),
          facets: angular.toJson(this.facets),
          x: 2
        };

        if (user.list_sorting_suffix) {
          params.sorting_suffix = user.list_sorting_suffix;
        }

        this.trigger = undefined;
        return this.strainer()
            .then(strainer => http().get(`/api/amateur-profile/list/${this.category || ''}`, { params })
              .then((res) => {
                this.searchResults = AmateurFilter.customSort(res.data.data, strainer);
                if (this.transform) {
                  this.transform(this.searchResults);
                }

                if (res.data.bl) {
                  this.searchResults.bl = true;
                }

                return this.searchResults;
            }));
      }

    }

    AmateurFilter.customSort = function (a, b) {

      let i,j,count = 0;

      if (!Array.isArray(a)) {
        return;
      }

      if (!Array.isArray(b)) {
        b = [];
      }

      const res = a.slice();

      if (b.length || Amateur.doNotShow.length) {
        for (i = res.length - 1; i >= count; i--) {
          if (Amateur.doNotShow.indexOf(res[i]) >= 0) {
            res.splice(i, 1);
            continue;
          }
          j = b.indexOf(res[i]);
          if (j >= 0) {
            res.splice(i, 1);
            res.unshift(b[j]);
            count++;
            i++;
          }
        }
      }

      return res;
    };

    return AmateurFilter;
  }]);
