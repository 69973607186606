// @ts-nocheck
angular.module('amateurPool')

  .factory('AmateurTopicBadges', ['AmateurFilter', '$rootScope', '$window', 'storage', 'user', '$q', '$translate', (AmateurFilter, $rootScope, $window, storage, user, $q, $translate) => {

    const store = storage.driver('persistent');
    let storageKey = 'user-' + user.id + '-topic';
    let userTopics = store.get(storageKey) || [];
    const searchFilter = AmateurFilter.get('search');
    const translationCache = new Map();

    $rootScope.$on('userPool.login.success', () => {
      storageKey = 'user-' + user.id + '-topic';
    });

    $rootScope.$watch(() => searchFilter.facets.topic_list, topics => {
      userTopics = Array.isArray(topics) ? userTopics = angular.copy(topics) : [];
      $rootScope.$broadcast('user-topics-change');
    }, true);

    $window.addEventListener('beforeunload', () => store.set(storageKey, userTopics));

    function matchTopics(amateur) {
      return userTopics.reduce((matches, topic) => {
        if (matches.length < 3 && amateur.topics.indexOf(topic) >= 0) {
          matches.push(topic);
        }
        return matches;
      }, []);
    }

    function translateAndConcat(topics) {
      const translations = topics.map(topic => {
        if (!translationCache.has(topic)) {
          translationCache.set(topic, $translate('models.AmateurProfile.topic_list_attributes.' + topic));
        }
        return translationCache.get(topic);
      });
      return $q.all(translations)
        .then(labels => labels.join(', '));
    }

    $rootScope.$on('$translateChangeSuccess', () => translationCache.clear());

    return amateur => translateAndConcat(matchTopics(amateur));
  }])

  .directive('onswAmateurTopicBadge', ['AmateurTopicBadges', '$rootScope', (AmateurTopicBadges, $rootScope) => {

    return {
      template: '<div class="box badge" ons-icon="::*,tag"><span class="label">{{ labels }}</span></div>',
      scope: {
        amateur: '<'
      },
      link: (scope, element) => {

        function update() {
          //DEBUG: console.debug('onsw-amateur-topic-badge update', scope.amateur);
          element.hide();

          if (!scope.amateur) {
            return;
          }

          AmateurTopicBadges(scope.amateur)
            .then(labels => {
              if (!labels) return;
              scope.labels = labels;
              element.show();
            });
        }

        scope.$watch('amateur', update);
        scope.$on('user-topics-change', update);
        $rootScope.$on('$translateChangeSuccess', update);
      }
    };
  }]);
