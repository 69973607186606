import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {t} from 'acng/locale/config/translate.js';
import {popup} from '@acng/frontend-discovery';

import {coreFeature} from '../config/feature.js';
import {authUser, portal} from '../service/env';
import {post} from '../service/backend';
import {addClass, createDiv, removeClass} from '@acng/frontend-bounty';

/** @type {HTMLFormElement} */
let formRef;

defineCustomWidget(
  coreFeature,
  'onsw-content-removal-info',
  class extends Widget {
    sitename = portal.name;

    click() {
      formRef.scrollIntoView();
    }
  }
);

defineCustomWidget(
  coreFeature,
  'onsw-content-removal-form',
  class extends Widget {
    render() {
      const {category, email, nickname, form} = /** @type {TemplateElements} */ (this.nodes);
      formRef = form;
      if (authUser !== null) {
        email.value = authUser.email;
        nickname.value = authUser.nickname;
        email.setAttribute('readonly', 'readonly');
        nickname.setAttribute('readonly', 'readonly');
      }
      this.getCategories().then((cat) => {
        for (const c of cat) {
          const option = document.createElement('option');
          option.value = c.key;
          option.innerHTML = c.value;
          category.append(option);
        }
      });
    }
    async submit() {
      /** @type {TemplateElements} */
      //@ts-ignore
      const {category, email, nickname, subject, description, form} = this.template.elements;
      const errorMessages = [];
      email.parentElement?.querySelector('.error')?.remove();
      description.parentElement?.querySelector('.error')?.remove();
      removeClass(email, 'error-field');
      removeClass(description, 'error-field');

      //email validation
      if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z-]+)+$/.test(email.value)) {
        const errorMessage = await t('core.contentRemoval.errors.email.invalid');
        const div = createDiv('error');
        div.innerHTML = errorMessage;
        email.parentElement?.append(div);
        addClass(email, 'error-field');
        errorMessages.push(errorMessage);
      }
      //description validation
      if (description.value.length < 30) {
        const errorMessage = await t('core.contentRemoval.errors.description.toShort');
        const div = createDiv('error');
        div.innerHTML = errorMessage;
        description.parentElement?.append(div);
        addClass(description, 'error-field');
        errorMessages.push(errorMessage);
      } //
      if (errorMessages.length > 0) {
        console.error('Content Removal validation', errorMessages);
        return;
      }

      const data = {
        email: email.value,
        nickname: nickname.value,
        subject: subject.value,
        category: category.value,
        description: description.value,
      };
      post('complaint/content-removal', data, form, (e) => {
        return t(e.message).then((text) => popup().error(text));
      }).then(() => {
        t('core.contentRemoval.response.success').then((text) => popup().info(text));
        if (authUser === null) {
          email.value = '';
          nickname.value = '';
        }
        category.value = '';
        description.value = '';
      });
    }
    /**
     * @async
     * @returns {Promise<SelectElement[]>}
     */
    async getCategories() {
      const categories = [
        'fakeAccount',
        'sharingInappropriateContent',
        'under18',
        'illegalContent',
        'violationTerms',
      ];
      /** @type {SelectElement[]} */
      const data = [];
      for (const cat of categories) {
        data.push({
          key: cat,
          value: await t(`core.contentRemoval.categories.${cat}`),
        });
      }
      return data;
    }
  }
);
/**
 * @typedef {Object} SelectElement
 * @property {string} key
 * @property {string} value
 */
/**
 * @typedef {Object} TemplateElements
 * @property {HTMLFormElement} form
 * @property {HTMLInputElement} email
 * @property {HTMLInputElement} nickname
 * @property {HTMLSelectElement} category
 * @property {HTMLInputElement} subject
 * @property {HTMLTextAreaElement} description
 */
