angular.module('amateurPool')

  .factory('OtmWithFallbackTile', ['OtmTile', 'Otm', '$log', (OtmTile, Otm, $log) => {

    return class OtmWithFallbackTile extends OtmTile
    {
      async load(scope, type) {
        let list;
        try {
          list = await Otm.get(type);
        } catch (e) {
          this.disabled = true;
          throw e;
        }

        for (let otm of list) {
          this.reset();
          try {
            await super.load(scope, otm);
            this.disabled = false;
            return;
          } catch (err) {
            continue;
          }
        }

        this.disabled = true;
        throw 'no otm';
      }
    };
  }]);
