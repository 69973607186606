import {Tile} from 'acng/core/widgets/basic-tile.js';
import {FreeMovie} from 'acng/moviePool/factory/FreeMovie.js';
angular.module('moviePool')

  .factory('FreeMovieCountdown', ['$location',($location) => {
      class FreeMovieCountdown extends Tile
      {
        load(scope, id) {
          if (!$location.search().fmotd) {
            throw { msg: 'not from teaser' };
          }
          return FreeMovie.willBeFree(id)
            .then(freemovie => {
              scope.time = Date.parse(freemovie.free_since);
              return freemovie.movie.getStock();
            })
            .then(stock => {
              if (stock) {
                throw { msg: 'movie is in stock', id };
              }
            });
        }
        hookname() {
          return 'freeMovieCountdown';
        }
      }
      return FreeMovieCountdown;
    }]);
