angular.module('userPool')
  .directive('onswNotificationButtons', ['Notificator', Notificator => {

    function link(scope, element) {
      element.addClass('ons-layout buttons');

      scope.click = accept => {
        Notificator[accept ? 'subscribe' : 'decline']();
        if (scope.$parent.overlay)
          scope.$parent.overlay.close();
      };

    }

    return {
      scope: {},
      link: link,
      template: '<div class="box"><span class="ons-item button cancel"><button class="box" ng-click="click(false)"><span class="label" translate="core.notification.notifyMe.no"></span></button></span> <span class="ons-item button"><button class="box" ng-click="click(true)"><span class="label" translate="core.notification.notifyMe.yes"></span></button></span></div>'
    };

  }]);
