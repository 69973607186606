angular.module('amateurPool')

  .directive('onswFacetGroup', ['isMobile', 'user', (isMobile, user) => {
    return {
      scope: {
        fieldNames: '@',
        options: '<',
        filter: '<',
        filled: '='
      },
      template: `
<form class="box">
    <label ng-repeat="fieldName in _fieldNames" ng-click="click($event)">
        <span>{{translationKey(fieldName) | translate}}</span>
        <div onsw-chosen placeholder="core.pleaseChoose" choose="filter.facets[fieldName]" options="options[fieldName]"></div>
    </label>
</form>`,
      link: function (scope, element) {

        element.addClass('ons-form');

        scope.$watch('fieldNames', function (fieldNames) {
          scope._fieldNames = fieldNames ? fieldNames.split(',') : [];
          if (user.isInternational()) {
            let i = scope._fieldNames.indexOf('zip_area');
            if (i >= 0) {
              scope._fieldNames.splice(i, 1);
            }
          }
        });

        scope.click = function ($event) {
          if (isMobile) {
            $event.preventDefault();
          }
        };

        scope.translationKey = fieldName => {
          if (fieldName == 'zip_area') {
            return 'amateurPool.zip_area_search';
          } else if (fieldName == 'height') {
            return 'amateurPool.height_' + (user.isInGroup('uk') ? 'uk' : 'metric');
          } else if (fieldName == 'weight') {
            return 'amateurPool.weight_' + (user.isInGroup('uk') ? 'uk' : 'metric');
          }

          return 'amateurPool.'.concat(fieldName);
        };

        scope.$watch('filter.facets', function () {
          if (scope.filter && scope._fieldNames) {
            scope._fieldNames.forEach(function (field) {
              if (scope.filter.facets[field] && scope.filter.facets[field].length) {
                scope.filled = 'true';
              }
            });
          }
        }, true);

      }
    };
  }]);
