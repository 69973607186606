angular.module('moviePool')

  .factory('SmallMovieTile', ['MovieTile', MovieTile => {

    return class SmallMovieTile extends MovieTile
    {
      dontSetBg = true;

      async load(scope, movie_id) {
        await super.load(scope, movie_id);
        this.box.prepend(this.image);
      }

      hookname() {
        return 'smallMedia';
      }
    };

  }]);
