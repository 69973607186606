import {listen, path, alias, middleware} from '@acng/frontend-voyager';
import {on, off} from '@acng/frontend-bounty';

import {rootRoute} from 'acng/core/config/routes';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {onlyUser} from 'acng/userPool/config/middleware';

import {gamesFeature} from './feature.js';
import {mainView} from 'acng/layout/service/view-middleware.js';

rootRoute.create(
  onlyUser, //
  path('/games'),
  mainView(gamesFeature, 'games'),
  alias('games')
);

const gameTab = amateurRoute.create(
  onlyUser, //
  path('/game'),
  mainView(gamesFeature, 'game-tab'),
  alias('game')
);

runMiddleware.$inject = ['games'];
/**
 * @param {import('../factory/games').GamesService} games -
 */
export function runMiddleware(games) {
  gameTab.config(
    middleware(load),
    listen('enter', () => on(games, 'update', load)),
    listen('leave', () => off(games, 'update', load))
  );
  /** */
  async function load() {
    DEBUG: console.debug('games/config/routes wait for amateur', {gameTab});
    await amateurRoute.wait;
    /** @typedef {import('acng/amateurPool/factory/Amateur').Amateur} Amateur */
    let amateur = /** @type {Amateur} */ (amateurRoute.nextParams.amateur);
    DEBUG: console.info('games/config/routes wait for session', {amateur});
    const session = await games.getLatestGame(amateur);
    if (session) {
      // TODO add amateur to getLatestGame
      session.amateur = amateur;
    }
    DEBUG: console.info('games/config/routes load', {session});
    // set both so never trigger change?
    // TODO implement helper in router for this
    gameTab.nextParams.gameSession = session;
    gameTab.params.gameSession = session;
    rootRoute.globals.gameSession = session;
  }
}
