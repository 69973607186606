import {Widget} from '@acng/frontend-stargazer';
import {on, EVENT_INPUT, EVENT_SUBMIT} from '@acng/frontend-bounty/event';

import {inject} from 'acng/core/service/ng';

import {userPoolFeature} from '../config/feature.js';
import {confirmDevice} from '../service/http.js';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {SecurityCodeElement} from 'acng/core/widgets/securityCode.js';

const MODULE = 'userPool/widget/confirm-device';

userPoolFeature.defineWidget(
  'onsw-confirm-device',
  class extends Widget {
    render() {
      ASSERT: typeguard(
        MODULE,
        this.nodes,
        OBJECT({
          form: IS(HTMLFormElement),
          code: IS(SecurityCodeElement),
          button: IS(HTMLButtonElement),
        })
      );

      const {form, code, button} = this.nodes;

      on(code, EVENT_INPUT, () => (button.disabled = code.value.length != 6));
      on(form, EVENT_SUBMIT, async (e) => {
        e.preventDefault();
        try {
          button.disabled = true;

          const user = await confirmDevice(code.value, this);

          inject('$rootScope').$broadcast('userPool.login.success', user);
          inject('onsOverlay').get('confirm-device')?.close();
        } finally {
          button.disabled = false;
        }
      });
    }
  }
);
