/**
 * @module acng/sexicon
 */
import angular from 'angular';
import {type as t2, name as n2, param as p2} from './factory/sexicon';
import {type as t3, name as n3, param as p3} from './directive/sexicon';
import {type as t4, name as n4, param as p4} from './widget/sexicons';
angular
  .module('sexicon', ['pascalprecht.translate']) //
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    $translatePartialLoaderProvider => {
      $translatePartialLoaderProvider.addPart('sexicon');
    },
  ])
  [t2](n2, p2)
  [t3](n3, p3)
  [t4](n4, p4);
