import angular from 'angular';
import {addClass, cloneNode, createDiv, isUndefined} from '@acng/frontend-bounty';

import {inject} from '../service/ng';
import {tileFeature} from '../config/feature';

export const TILE_LOAD = 'load';

export class Tile {
  /**
   * @private
   * @type {Record<string, boolean>}
   */
  applied = {};
  /**
   * @param {object} config - Tile configuration object.
   * @param {angular.IScope} [scope] - Scope object to apply to the tile.
   * @param {HTMLElement} [element] - Element to use for the tile.
   */
  constructor(config, scope, element) {
    this.scope = /** @type {angular.IScope & {[key: string]: unknown}} */(scope ?? inject('$rootScope').$new(true));
    this.el = element ?? createDiv();
    this.ref = config;
    this.isCompiled = false;
  }
  /**
   * Sets the meta data for the tile.
   * @param {number} index - Index of the tile.
   */
  setMeta(index) {
    this.i = index;
    this.visible = false;
  }
  /**
   * Loads the tile template and applies the scope.
   * @param {angular.IScope} _scope - Scope object to apply to the tile.
   * @param {object} _arg - Additional arguments to pass to the tile.
   * @returns {Promise<any>} Promise that resolves when the tile is loaded.
   */
  async load(_scope, _arg) {
    // eslint-disable-line no-unused-vars
  }
  /**
   * Applies the given object to the scope of the tile.
   * @param {Record<string, unknown>} obj - Object to apply to the scope.
   */
  apply(obj) {
    Object.getOwnPropertyNames(obj).forEach((name) => {
      if (isUndefined(this.scope[name])) {
        this.scope[name] = obj[name];
        this.applied[name] = true;
      }
    });
  }
  /**
   * Resets the scope of the tile.
   */
  reset() {
    Object.getOwnPropertyNames(this.applied).forEach((name) => {
      delete this.scope[name];
    });
    this.applied = {};
  }

  /**
   * Gets the tile element and compiles it if necessary.
   * @returns {Promise<JQLite>} Promise that resolves when the tile is loaded and compiled.
   */
  async get() {
    if (!this.box) {
      const template = cloneNode(await tileFeature.lookup(this.hookname()));
      addClass(this.el, this.hookname().toDash() + '-hook', 'ons-layout', ...template.classList);
      this.box = template.content.firstElementChild;
      if (!this.box) {
        throw Error();
      }
    }
    await this.load(this.scope, this.ref);
    addClass(this.el, ...this.css());
    if (!this.isCompiled) {
      this.el.appendChild(this.box);
      inject('$compile')(this.box)(this.scope);
      this.scope.$digest();
      this.isCompiled = true;
    }
    return angular.element(this.el);
  }

  hookname() {
    return 'generic';
  }

  css() {
    return [];
  }
}
