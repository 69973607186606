import angular from 'angular';
import {hasFeature} from 'acng/core/service/env';
import {runTracking} from './config/routes';
import {extendSlideshow} from './config/slideshow';

const name = 'hotornot';

if (hasFeature(name)) {
  extendSlideshow();
}

angular
  .module(name, ['pascalprecht.translate', 'core', 'amateurPool', 'userPool'])
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    ($translatePartialLoaderProvider) => {
      $translatePartialLoaderProvider.addPart(name);
    },
  ])
  .run(runTracking);
