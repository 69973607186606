angular.module('userPool').directive('onswLogout', ['user', function (user) {
  return {
    scope: {},
    template: '<span class="box" style="cursor:pointer" ng-attr-title="{{\'userPool.logout\'|translate:{nickname:user.nickname} }}" ng-click="logout()"><span class="label" ng-bind="\'userPool.logout\' | translate: {nickname: user.nickname}"></span></span>',
    link: function (scope, element) {
      element.addClass('ons-item');
      scope.user = user;
      scope.logout = () => user.logout();
    }
  };
}]);
