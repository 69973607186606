import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

import onswRegardButton, {buttonComponent} from './widget/button';
import onswRegardCategories, {categoriesComponent} from './widget/categories';
import onswRegardCloseShop, {closeComponent} from './widget/closeShop';
import onswRegards, {regardsComponent} from './widget/regards';
import onswRegardSend, {sendComponent} from './widget/send';
import featureName from './config/feature.js';

angular
  .module(featureName, [angularTranslate])
  .config(provideTranslations(featureName))
  .component(onswRegardButton, buttonComponent)
  .component(onswRegardCategories, categoriesComponent)
  .component(onswRegardCloseShop, closeComponent)
  .component(onswRegards, regardsComponent)
  .component(onswRegardSend, sendComponent);
