import {spliceArray} from '@acng/frontend-bounty';
import {media, portal} from '../service/env';
import {inject} from '../service/ng';

/** @type {Record<string, Array<string | object | number>>} */
export const slideshowConfig = {
  /** Guest Page Slider */
  welcome: [1, 2, 3, 4, 5].map((i) => [
    'ImageTile',
    {
      json: `${media.assets_ext}/img/welcome/slide-${i}.js`,
      click: () => inject('user').guestSignup(),
    },
  ]),
  /** my portal page slider aot & lot mixed */
  myportal_t2: [
    ['OtmListTile', 'amateur-profile'],
    ['OtmListTile', 'livecam'],
  ],
  /** my portal page main slider */
  myportal_t1: [
    ['TeaserTile', {format: 'overlay'}, 10000],
    ['BonusTile', {format: 'overlay'}, 8000],
  ],
  /** sidebar slider */
  sidebar: [
    ['TeaserTile', {format: 'sidebar'}, 10000],
    ['BonusTile', {format: 'sidebar'}, 8000],
    ['OtmWithFallbackTile', 'amateur-profile'],
    ['OtmWithFallbackTile', 'livecam'],
  ],
};

/* MCA sidebar slider filter for aot */
if (portal.shortcut === 'MCA') {
  spliceArray(slideshowConfig.sidebar, 2, 1);
}
