/**
 * @module acng/amateurPool/widget/profileVideo
 */
export default 'onswProfileVideo';
/** @type {angular.IComponentOptions} */
export const profileVideoComponent = {
  controller: ['$element', controller],
};

import {media} from 'acng/core/service/env';
import {player, mapSources} from 'acng/core/factory/flowplayer';
import {fsk} from 'acng/userPool/fsk';
import {
  addClass,
  removeClass,
  show,
  hide,
  createSvg,
  append,
  clear,
  onClick,
  createVideo,
  prepend,
  query,
} from '@acng/frontend-bounty';
import {setTimeout} from '@acng/frontend-bounty';
import playIconSrc from '../../../basic/img/play.svg';
import {ctxAmateur} from '../context/amateur.js';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
/**
 * @typedef {import('acng/amateurPool/factory/Amateur').Amateur} Amateur
 * @typedef {import('acng/core/factory/flowplayer').Sources} Sources
 */
ctxAmateur[CTX_SUBSCRIBE_TAGNAME]('onsw-profile-video');

/**
 * We are denied to use the VOD system for this because it can't handle any load.
 */
const USE_VOD_SYSTEM = false;
const PROFILE_SRC = 'profile_video_path';
const PROFILE_FSK = 'profile_video_fsk';

/**
 * Indicates that the player is visible
 */
const CSS_ACTIVE = 'active';

/**
 * Make the player a target for the "kiss" animation
 */
const CSS_KISS_PREFIX = 'kissAnimation';

/**
 * @param {JQLite} $element
 *
 *    Used to carry the context and CSS classes according to the profile video.
 *
 * @this {angular.IController}
 */
function controller($element) {
  const element = $element[0];
  ctxAmateur[CTX_OBSERVE](element, async (amateur) => {
    // reset
    clear(element);
    removeClass(element, CSS_ACTIVE);

    // do nothing more without sources
    const sources = await getSources(amateur);
    if (!sources || !amateur) {
      return;
    }

    // append play button as inline svg
    const svg = await createSvg(`${media.assets}/${playIconSrc}`);
    append(element, svg);

    /**
     * Once loaded, the player will be reused.
     * @type {import('video.js').VideoJsPlayer}
     */
    let vjs;
    const imageContainer = query('.amateurPool-sedcard-image.layout-container > .ons-image');

    // add click listener to the button
    onClick(svg, async () => {
      // load the player once
      vjs ??= await createPlayer();
      addClass(element, CSS_ACTIVE);
      if (imageContainer) {
        addClass(imageContainer, 'blur');
      }
      if (vjs.paused()) {
        vjs.show();
        vjs.play();
      }
    });

    const createPlayer = async () => {
      const video = createVideo();
      prepend(element, video);
      const vjs = await player(video, sources, true);

      addClass(vjs.el(), `${CSS_KISS_PREFIX}${amateur.id}`);

      // hide the button when the video is played
      vjs.on('play', () => hide(svg));

      // show the button on the player when the video is paused
      vjs.on('pause', () => show(svg));

      // hide the player on error after 2s and do not show the button again
      vjs.on('error', (reason) => {
        console.error('amateurPool/widgets/profileVideo video.js error', {reason});
        setTimeout(() => {
          vjs.hide();
          removeClass(element, CSS_ACTIVE);
        }, 2000);
      });

      // hide the player when the video has ended and show the button again
      vjs.on('ended', () => {
        show(svg);
        vjs.hide();
        removeClass(element, CSS_ACTIVE);
        if (imageContainer) {
          removeClass(imageContainer, 'blur');
        }
      });
      return vjs;
    };
  });
}

/**
 * Get sources for a profile video.
 *
 * @param {Amateur | null} amateur
 *
 *    `null` is allowed for convenience.
 *
 * @returns {Promise<Sources | undefined>}
 *
 *    - `undefined` when the given `amateur` is `null`
 *    - {@link Sources} object when the given `amateur` has a profile video
 *    - `undefined` when the given `amateur` has no profile video
 */
const getSources = async (amateur) => {
  if (!amateur) {
    return;
  }
  // return 'https://c2.ng-source.com/mediafiles/356/272/009/u3562729/194760_m_1453818713.mp4';
  if (!amateur[PROFILE_SRC]) {
    return;
  }
  if (fsk.get() < amateur[PROFILE_FSK]) {
    return;
  }
  if (!USE_VOD_SYSTEM) {
    return [{type: 'video/mp4', src: `${media.content.movies}${amateur[PROFILE_SRC]}`}];
  }
  const response = await fetch(`/api/amateur-profile/video/${amateur.id}`);
  return mapSources(await response.json());
};
