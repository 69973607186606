angular.module('livecam')

  .directive('onswLivecamToyButton', ['LivecamButton', LivecamButton => {

    class LivecamToyButton extends LivecamButton
    {
      static template() {
        return `<span class="box" ng-click="click($event)">
                <span class="default-text">
                    <span class="label">{{ label || 'livecam.start.' + type | translate}}</span>
                    <span class="additional" ng-hide="user.guest">{{ type != 'free' ? 'livecam.price' : 'livecam.free' | translate:{price:price} }}</span>
                </span>
                <span class="toy-active" ons-icon="lovense">
                    <span class="toy-text">
                        <span class="label">TOY</span>
                        <span class="additional">aktiv</span>
                    </span>
                </span>
        </span>`;
      }
    }

    return LivecamButton.make(LivecamToyButton);
  }]);
