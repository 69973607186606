angular.module('amateurPool')

  .constant('facetFilterGroups', [
    {
      name: 'general',
      visible: true,
      data: ['gender', 'ethnic']
    },
    {
      name: 'hair',
      visible: false,
      data: ['haircolor', 'hairlength', 'bodyhair', 'pubichair']
    },
    {
      name: 'body',
      visible: false,
      data: ['body', 'height', 'weight', 'eyecolor', 'brasize']
    },
    {
      name: 'specials',
      visible: false,
      data: ['glasses', 'piercings', 'tattoos']
    },
    {
      name: 'personal',
      visible: false,
      data: ['marital', 'smoking']
    },
    {
      name: 'activities',
      visible: false,
      data: ['livedating', 'livecam'] //'weblog'
    }]
  )

  .directive('onswFacetFilter', ['AmateurFilter', 'facetFilterGroups', 'amateurFacetsDefault', function (AmateurFilter, facetFilterGroups, amateurFacetsDefault) {
    return {
      scope: {},
      templateUrl: '/template/amateurPool.facetFilter',
      link: function (scope, element) {

        element.addClass('ons-layout');

        scope.filterGroups = facetFilterGroups;
        scope.filter = AmateurFilter.get('search');

        scope.reset = function () {
          scope.filter.trigger = 'user';
          scope.filter.reset();
        };

        scope.scroll = function () {
          let sb = element.closest('.ons-scroll > .box')[0];
          let o = element.outerHeight();
          let last = Date.now();

          run();
          function run() {
            requestAnimationFrame(() => {
              let now = Date.now();
              sb.scrollTop += Math.min(now - last, o - sb.offsetTop);
              if (sb.scrollTop < o) {
                last = now;
                run();
              }
            });
          }
        };

        const facets = angular.copy(scope.filter.facets);
        delete facets.age;
        delete facets.zip_area;
        scope.detailSearch = !angular.equals(facets, amateurFacetsDefault);

        scope.$on('onswChosenChange', (a, p) => {
          scope.filter.trigger = p ? p.trigger : undefined;
        });

        function emptyArrayToUndefined(object) {
          for (const key in object) {
            if (Array.isArray(object[key]) && object[key].length === 0) {
              object[key] = void 0;
            }
          }

          return object;
        }

        scope.$watch('filter.facets', function (newVal, oldVal) {
          newVal = emptyArrayToUndefined(newVal);
          oldVal = emptyArrayToUndefined(oldVal);
          if (angular.equals(newVal, oldVal)) {
            return;
          }

          scope.filter.dispatchEvent(new CustomEvent('change'));
        }, true);

        async function updateFacets() {
          delete scope.searchFacets;
          scope.searchFacets = await scope.filter.getActualFacets();
          scope.$applyAsync();
        }

        scope.filter.addEventListener('change', updateFacets);

        scope.$on('$destroy', () => {
          scope.filter.removeEventListener('change', updateFacets);
        });

        updateFacets();
      }
    };
  }]);
