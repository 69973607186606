import {middleware, rootRoute} from '@acng/frontend-voyager';
import {off, on} from '@acng/frontend-bounty';
import {updateView} from '../context/view.js';

/**
 * @param {string} name
 */
export const ViewMiddleware = (name) => {
  /**
   * @param {import("acng/core/model/feature").Feature} feature
   * @param {string[]} path
   */
  return (feature, ...path) =>
    /**
     * @param {import("@acng/frontend-voyager").Route} route
     */
    (route) =>
      middleware(async () => {
        const template = await feature.lookup(...path);
        const enter = () => {
          DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" enter`);
          updateView(name, template);
          on(
            route,
            'leave',
            () => {
              DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" leave`);
              updateView(name, null);
            },
            {once: true}
          );
        };
        on(route, 'enter', enter, {once: true});
        on(
          rootRoute,
          'settled',
          () => {
            DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" settled`);
            off(route, 'enter', enter);
          },
          {once: true}
        );
      })(route);
};

export const mainView = ViewMiddleware('main');
