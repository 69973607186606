angular.module('userPool')

  .directive('onswLogin', ['Widget', 'iOS', 'user', (Widget, iOS, user) => {

    return {
      scope: {
        focus: '@',
        onDone: '&',
        onError: '&'
      },
      template: `
<form class="box" ng-submit="submit()">
  <label>
    <span>{{'userPool.nickname' | translate}}</span>
    <input type="text" ng-model="loginData.login" required placeholder="{{'userPool.nickname' | translate}}">
  </label>
  <label>
    <span>{{'userPool.password' | translate}}</span>
    <input type="password" ng-model="loginData.password" required placeholder="{{'userPool.password' | translate}}">
  </label>
  <label>
    <span>{{'userPool.rememberMe' | translate}}</span>
    <input type="checkbox" ng-model="loginData.remember" ng-checked="1">
  </label>
  <div class="ons-layout buttons">
    <div class="box">
      <span class="ons-item button submit">
        <button class="box" type="submit"><span class="label" translate="userPool.login"></span></button>
      </span>
    </div>
  </div>
</form>`,
      link: (scope, element) => {

        const widget = new Widget(scope, element, 'ons-form');

        scope.loginData = { remember: 1 };
        scope.$watch('focus', function (focus) {
          if (!angular.isDefined(focus) || focus) {
            if (iOS) return;
            element.find('input').first().focus();
          }
        });

        scope.submit = () => {
          widget.ifNotBusy(() => user.login(scope.loginData))
            .then(() => {
              scope.onDone();
            })
            .catch(err => {
              scope.onError(err);
            });
        };
      }
    };
  }]);
