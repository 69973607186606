import find from 'underscore/modules/find.js';
import {on} from "@acng/frontend-bounty";

angular.module('userPool')
  .factory('director', ['$rootScope', '$location', 'user', 'onsOverlay', 'passwordResetToken', '$injector', function ($rootScope, $location, user, onsOverlay, passwordResetToken, $injector) {

    var _passwordResetToken = passwordResetToken;
    var signupEmail;
    var showDoiHint = false;
    var showChangeEmail = false;
    var showRequestMailDoi = false;
    var wait = false;
    var rename = false;
    var hadRename = false;

    function init() {
      $rootScope.$on('$locationChangeStart', function () {
        triggerUserPopups();
      });

      $rootScope.$on('userPool.login.ready', function () {
        wait = true;
      });

      $rootScope.$on('userPool.popups', function (_event, popups) {
        if (find(popups, { 'template': 'doiHint' })) {
          showDoiHint = true;
        }
        if (find(popups, { 'template': 'renameOverlay' })) {
          rename = !hadRename;
        }
        if (find(popups, { 'template': 'changeEmail' })) {
          showChangeEmail = true;
        }
        if (find(popups, { 'template': 'requestMailDoi' })) {
          showRequestMailDoi = true;
        }
      });

      $rootScope.$on('userPool.login.success', function () {
        wait = false;
        triggerUserPopups();

        if (user.redirectPath) {
          $location.path(user.redirectPath).replace();
          user.redirectPath = null;
        } else {
          var redirectPaths = ['signup', 'login', 'reactivate'];

          if (RegExp('/(' + redirectPaths.join('|') + ')', 'i').test($location.path())) {
            $location.path('/').replace();
          }
        }
      });
    }

    function setSignupEmail(email) {
      signupEmail = email;
    }

    function setPasswordResetToken(token) {
      _passwordResetToken = token;
    }

    return {
      'init': init,
      'setSignupEmail': setSignupEmail,
      'setPasswordResetToken': setPasswordResetToken,
      'triggerUserPopups': triggerUserPopups
    };

    function livecam() {
      if (!$injector.has('Livecam')) return false;

      return $injector.get('Livecam').isActive();
    }

    function triggerUserPopups() {
      if (wait || livecam()) return;

      if (!user.guest) {

        if (_passwordResetToken) {
          onsOverlay.create('passwordSetOverlay', {
            'email': user.email,
            'token': _passwordResetToken
          })
            .disableBackdrop()
            .disableCloseButtonInToolbar()
            .open();

          _passwordResetToken = false;
        } else if (rename) {
          onsOverlay.create('renameOverlay')
            .disableBackdrop()
            .disableCloseButtonInToolbar()
            .open();

          rename = false;
          hadRename = true;
        } else if (showDoiHint) {
        // soi
          const doiHint = onsOverlay.create('doiHint')
            .disableBackdrop()
            .open();

          showDoiHint = false;
        } else if (showChangeEmail) {
          onsOverlay.create('changeEmailOverlay')
            .disableBackdrop()
            .open();
          showChangeEmail = false;
        } else if (showRequestMailDoi) {
          onsOverlay.create('requestMailDoiOverlay')
            .disableBackdrop()
            .open();
          showRequestMailDoi = false;
        }

      } else if (signupEmail) {
      // doi
        onsOverlay.create('doiHint', { 'signupEmail': signupEmail })
          .disableBackdrop()
          .disableCloseButtonInToolbar()
          .open();

        showDoiHint = false;
        signupEmail = null;
      }
    }

  }]);
