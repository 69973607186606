import angular from 'angular';

import {type as t1, name as n1, param as p1} from './widget/payttachment';
import {type as t2, name as n2, param as p2} from './tiles/PayttachmentTile';
import {type as t3, name as n3, param as p3} from './factory/payttachment';
import {type as t5, name as n5, param as p5} from './factory/attachmentList';
import onscMessageCount, {messageCountDirective} from './directive/count';
import onswMessages, {messagesComponent} from './widget/messages';
import featureName from './config/feature.js';
import './widget/messenger.js';
import './widget/message-composer.js';
import './widget/send.js';
import './widget/voice-recorder.js';
import './widget/message/voice.js';
import './widget/open-dialog.js';
import './widget/button.js';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate.js';
import {runContactsVisibility} from './context/contacts-visibility';

export default featureName;

angular
  .module(featureName, [angularTranslate, 'amateurPool'])
  .config(provideTranslations(featureName))
  .config(provideTranslations('hotornot'))
  .directive(onscMessageCount, messageCountDirective)
  .component(onswMessages, messagesComponent)
  .run(runContactsVisibility)
  [t1](n1, p1)
  [t2](n2, p2)
  [t3](n3, p3)
  [t5](n5, p5);
