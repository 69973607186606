angular.module('userPool').directive('onswPasswordChange', ['Widget', 'userPoolApi', function (Widget, userPoolApi) {

  return {
    scope: {},
    templateUrl: '/template/userPool.passwordChange',
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-form ready');

      scope.save = () => {
        var data = {
          old_password: scope.old_password,
          password: scope.password,
          password_confirmation: scope.password_confirmation
        };
        widget.ifNotBusy(() => userPoolApi.updatePassword(data))
          .then(() => scope.done = true)
          .catch(err => widget.notify(err));
      };
    }
  };

}]);
