import {HTMLElement, setStyle} from '@acng/frontend-bounty';

import {coreFeature} from '../config/feature.js';
import {media} from '../service/env';

const tagName = 'onsw-img-ext';

coreFeature.defineElement(
  tagName,
  class extends HTMLElement {
    constructor() {
      super();
      if (this.dataset.aspectRatio) {
        setStyle(this, 'display', 'block');
        setStyle(this, 'aspect-ratio', this.dataset.aspectRatio);
      }
    }

    connectedCallback() {
      DEBUG: console.debug(`${tagName} connectedCallback`, this.dataset);
      const configSrc = `${media.assets_ext}/img/${this.dataset.config}`;
      fetchConfig(configSrc).then(async (src) => {
        const img = new Image();
        img.src = src;
        setStyle(img, 'max-width', '100%');
        setStyle(img, 'vertical-align', 'top');
        await img.decode();
        this.replaceWith(img);
      });
    }
  }
);

/**
 * Fetches the image source from a JSON file.
 * @param src The URL of the JSON file.
 * @returns A promise that resolves with the image source.
 */
async function fetchConfig(src: string) {
  const {default: get} = await import(src);
  DEBUG: console.debug(`${tagName} fetch src from json`, {src, get});
  return `${src.replace(/[^/]+$/, '')}${get()}`;
}
