angular.module('userPool').directive('onswUserDelete', [
  'portal',
  'userPoolApi',
  '$translate',
  '$window',
  '$timeout',
  'user',
  'Widget',
  '$q',
  function (portal, userPoolApi, $translate, $window, $timeout, user, Widget, $q) {
    return {
      scope: {},
      templateUrl: '/template/userPool.myProfileDelete',
      link: function (scope, element) {
        const widget = new Widget(scope, element, 'ons-form ready');
        scope.delete = () => {
          widget
            .ifNotBusy(() => {
              return $q((resolve, reject) => {
                $translate('userPool.confirmDeletion', {sitename: portal.name}).then(function (text) {
                  if (confirm(text)) {
                    userPoolApi
                      .deleteSafely()
                      .then(function () {
                        DEBUG: console.log('Delete user:', $window.location);
                        $window.location.search = `?spaRoute=%2FalternativeProducts%3Fuserid=${user.uid}`;
                        resolve(null);
                      })
                      .catch(reject);
                  } else {
                    resolve(null);
                  }
                });
              });
            })
            .catch(err => widget.notify(err));
        };
        scope.user = user;
      },
    };
  },
]);
