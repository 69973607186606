/**
 * @module acng/sexicon/widget/sexicon
 */
//TODO: add correct types instead of any
export const name = 'onswSexicons';
export const type = 'directive';
export const param = config;
config.$inject = ['Widget', 'Sexicon'];
/**
 * @param {*} Widget -
 * @param {types.SexiconConstructor} Sexicon -
 * @returns {*} -
 */
function config(Widget, Sexicon) {
  const config = {
    scope: {
      amateurId: '<',
      childClasses: '@',
      category: '@',
      onclose: '&',
    },
    template:
      '<img class="{{ childClasses }}" alt="{{ icon.text }}" ng-src="{{ icon.imageUrl }}" ng-attr-title="{{ icon.text }}" ng-repeat="icon in list" ng-click="send(icon)">',
    /**
     * @param {*} scope -
     * @param {*} element -
     */
    link: function (scope, element) {
      const widget = new Widget(scope, element);

      if (scope.category) {
        scope.list = Sexicon.category(scope.category);
      } else {
        scope.list = Sexicon.list();
      }
      /**
       * @param {*} sexicon -
       */
      scope.send = function (sexicon) {
        widget
          .ifNotBusy(() => sexicon.send(scope.amateurId))
          .then(() => scope.onclose())
          .catch(err => widget.notify(err));
      };
    },
  };

  return config;
}
/* Types */
import * as types from '../factory/sexicon'; //eslint-disable-line no-unused-vars
