/**
 * @module acng/userPool/service/user
 */
/**
 * @typedef {import('acng/userPool/factory/user').User} User
 */
/**
 * @type {User | undefined}
 */
export let user;
/**
 * @param {User} ngUser -
 */
export const runUser = ngUser => {
  user = ngUser;
};
runUser.$inject = ['user'];
