import extend from 'underscore/modules/extend.js';
import isObject from 'underscore/modules/isObject.js';
import debounce from 'underscore/modules/debounce.js';

angular.module('core')
  .factory('bulk', ['$q', 'http', function ($q, http) {

    var defer = {};
    var resolveQueue = {};

    function get(key, url, config) {

      config = extend({ requestParam: 'ids', field: 'id' }, isObject(config) ? config : {});
      var index = url + '||' + config.field;

      if (!resolveQueue[index]) {
        resolveQueue[index] = debounce(_resolveQueue, 10);
      }

      defer[index] = defer[index] || {};
      key = key.toString();
      defer[index][key] = defer[index][key] || $q.defer();

      resolveQueue[index](url, config, index);

      return defer[index][key].promise;
    }

    function _resolveQueue(url, config, index) {
      var ds = defer[index];
      var ids = Object.keys(defer[index]);

      var params = {};
      params[config.requestParam] = ids;

      defer[index] = {};

      http().post(url, params, {
        headers: { 'Content-Type': 'application/json' },
        ignoreLoadingBar: true
      })
        .then(function (res) {
          res.data.forEach(function (element) {
            ids.splice(ids.indexOf(element[config.field].toString()), 1);
            ds[element[config.field].toString()].resolve(element);
          });
        })
        .finally(function () {
          ids.forEach(function (id) {
            ds[id.toString()].reject(null);
          });
        });
    }

    return {
      get: get
    };

  }]);
