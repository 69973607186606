import angular from 'angular';
import find from 'underscore/modules/find.js';

angular.module('payment').directive('onswPaymentCountry', ['$translate', 'payment',
  /**
   * @param {angular.translate.ITranslateService} $translate
   * @param {import('acng/payment/factory/payment').Payment} payment
   * @returns {angular.IDirective<angular.IScope & {
   *   countries: import('../service/typeguard').CountryData[];
   *   payment: import('acng/payment/factory/payment').Payment;
   * }>}
   */
  function ($translate, payment) {
    return {
      scope: {
        payment: '<'
      },
      templateUrl: '/template/payment.country',
      link: function (scope, element) {
        element.addClass('ons-form');
        scope.$watch('payment', function (pmnt) {
          if (!pmnt || scope.countries) {
            return;
          }
          payment.countries().then(function (countries) {
            for (var i = 0, l = countries.length; i < l; i++) {
              countries[i].name = $translate.instant('countries.iso.' + countries[i].iso_code_2);
            }
            if (!pmnt.country && pmnt.preselectedCountryCode) {
              pmnt.country = find(countries, { iso_code_2: pmnt.preselectedCountryCode.toUpperCase() });
            }
            scope.countries = countries;
          }).catch(angular.noop);
        });

        scope.$watch('payment.preselectedCountryCode', function (newCode, oldCode) {
          if (!newCode || newCode === oldCode || !scope.countries || scope.payment.country) {
            return;
          }

          scope.payment.country = find(scope.countries, { iso_code_2: newCode.toUpperCase() });
        });
      }
    };
  }
]);
