import angular from 'angular';
import {queryAll} from "@acng/frontend-bounty";
import {connectPopupController} from '@acng/frontend-discovery';

angular.module('userPool')

  .directive('onswSignup', ['user', '$location', 'director', 'Widget', (user, $location, director, Widget) => {

    return {
      scope: {
        overlay: '<?',
        withoutPassword: '@?',
        showAllSteps: '@?'
      },
      templateUrl: '/template/userPool.signupForm',
      link: (scope, element) => {

        const widget = new Widget(scope, element, 'ons-form');

        for (const label of queryAll('label', element[0])) {
          connectPopupController(label);
        }

        scope.signupSuccessful = false;

        scope.progress = [];
        scope.step = 1;

        scope.$watchGroup(['nickname', 'email', 'password'], function (values, old) {
          for (var i = 0; i < values.length; i++) {
            if (values[i] !== old[i]) {
              scope.progress[i] = true;
            }
          }
          scope.step = scope.progress.length + 1;
        });

        var elForm = element.children('form');

        scope.submit = function () {
          if (elForm.hasClass('ng-pending') || elForm.hasClass('ng-invalid') || !scope.termsAccepted) {
            return;
          }
          widget.ifNotBusy(() => user.signup({
            nickname: scope.nickname,
            email: scope.email,
            password: scope.password
          })).then(function (res) {

            director.setSignupEmail(scope.email);

            if (res.passwordResetToken) {
              director.setPasswordResetToken(res.passwordResetToken);
            }

            if (scope.overlay) {
              scope.overlay.close();
            }

            if (user.guest) {
              director.triggerUserPopups();
            }

            scope.signupSuccessful = true;

          }).catch(err => widget.notify(err));
        };

      }
    };

  }]);
