import {getLocale} from 'acng/locale/model/locale.js';

angular.module('payment').directive('onswPaymentNewWindow', [function () {
  return {
    require: '^^ngController',
    scope: {
      payment: '<',
      openAction: '&',
      resetAction: '&'
    },
    templateUrl: '/template/payment.newWindow',
    link: function (scope, element, attr, paymentController) {
      element.addClass('ons-form');
      scope.locale = getLocale;
      scope.toggleConsentForDataStorage = async () => {
        element.addClass('busy');
        await paymentController.startTransaction();
        element.removeClass('busy');
      };
    }
  };
}]);
