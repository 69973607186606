angular.module('moviePool').directive('onswAmateurMoviesLink', ['$location', 'Movie', 'Widget', 'fsk', 'HttpCache', ($location, Movie, Widget, fsk, HttpCache) => {

  const counter = new HttpCache({
    fnRequest: nickname => Movie.list(0, 0, { nickname }),
    fnResolve: res => res.total,
    minExpire: 3600 * 1000
  });

  return {
    scope: { nickname: '@' },
    template:
    '<a class="box" ng-href="{{href}}">' +
    '<span class="label" ng-bind="\'moviePool.movies\' | translate"></span>' +
    '<span class="value" ng-bind="count"></span>' +
    '</a>',
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-item');

      widget.listen(fsk, 'change', changeFsk);

      scope.$watch('nickname', update);

      function changeFsk() {
        counter.clear();
        update(scope.nickname);
      }

      function update(name) {
        element.addClass('empty');
        delete scope.count;
        delete scope.href;
        if (!name) {
          return;
        }

        scope.href = '#/sedcard/' + name + '/movies';
        checkLocation();

        widget.clear().busy(counter.get(name))
          .then(function (count) {
            scope.count = count;
            if (scope.count) {
              element.removeClass('empty');
            }
          });
      }

      scope.$on('$locationChangeSuccess', checkLocation);
      function checkLocation() {
        if (!scope.nickname) return;
        if ('#' + $location.path() == scope.href) {
          element.addClass('active');
        } else {
          element.removeClass('active');
        }
      }
    }
  };
}]);
