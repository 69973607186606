import {setGlobalDialog} from '../context/global-dialog.js';

export class Dialog {
  /**
   * @param {string} id
   */
  constructor(id) {
    this.id = id;
    this.lastActivity = new Date(0);
    this.new = false;
    this.activated = 0;
    this.open = false;
  }

  get active() {
    if (!!this.activated && this.lastActivity.getTime() < Date.now() - 1000 * 60 * 15) {
      this.activated = 0;
    }
    return !!this.activated;
  }

  show() {
    setGlobalDialog(this.id);
  }
}
