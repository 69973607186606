import {addClass} from '@acng/frontend-bounty';
import {connectPopupController, popup} from '@acng/frontend-discovery';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME, CTX_UNOBSERVE} from '@acng/frontend-relativity/minify';

import {createComment, ctxCommentable} from '../model/commentable.js';
import {ctxStockUpdate} from 'acng/userPool/context/stock-update.js';

/**
 * @module acng/comment/widget/create
 */
const name = 'onswCommentCreate';
export default name;

ctxCommentable[CTX_SUBSCRIBE_TAGNAME]('onsw-comment-create');
ctxStockUpdate[CTX_SUBSCRIBE_TAGNAME]('onsw-comment-create');

/** @type {angular.IComponentOptions} */
export const createComponent = {
  bindings: {
    onclose: '&',
  },
  templateUrl: '/template/comment.create',
  controller: ['$scope', '$element', '$rootScope', '$translate', controller],
};

/**
 * @this {angular.IController & {
 *   text?: string;
 *   notCommentable?: string | false;
 *   create?: Function;
 *   onclose: Function;
 * }}
 * @param {angular.IScope} $scope
 * @param {JQLite} $element
 * @param {import("acng/zz-app").RootScope} $rootScope
 * @param {angular.translate.ITranslateService} $translate
 */
function controller($scope, $element, $rootScope, $translate) {
  const element = $element[0];

  addClass(element, 'ons-form');
  connectPopupController(element);

  this.text = '';
  this.notCommentable = '';

  this.$onInit = () =>
    ctxCommentable[CTX_OBSERVE](element, async (nextCommentable) => {
      if (!nextCommentable) {
        this.create = undefined;
        this.notCommentable = 'comment.noCommentable';
        ctxStockUpdate[CTX_UNOBSERVE](element);
        return;
      }
      const commentable = nextCommentable;

      const create = async () => {
        this.create = undefined;
        try {
          if (!this.text) {
            throw 'comment.noTextGiven';
          }
          if (this.text.length < 15) {
            throw 'comment.shortText';
          }
          await createComment(commentable, this.text);
          this.text = '';
          $rootScope.$broadcast('comment.created', {itemId: commentable.id, itemType: commentable.type});
          this.onclose();
        } catch (/** @type {any} */ reason) {
          // TODO error types
          if (reason?.data?.message) {
            reason = reason.data.message;
          }
          if (typeof reason == 'string') {
            popup(element).warn(await $translate(reason).catch(err => err));
          } else {
            console.error(`${name} create`, {reason});
          }
        } finally {
          this.create = create;
          $scope.$digest();
        }
      };

      ctxStockUpdate[CTX_OBSERVE](element, async () => {
        this.notCommentable = await commentable.blockWithReason?.();
        this.create = this.notCommentable ? undefined : create;
        $scope.$digest();
      });
    });
}
