/**
 * @module hotornot/config/routes
 */
import {rootRoute} from 'acng/core/config/routes';
import {path, createMany, index, alias, expr} from '@acng/frontend-voyager';
import {onlyUser} from 'acng/userPool/config/middleware';
import {on} from '@acng/frontend-bounty';

const base = rootRoute.create(
  onlyUser,
  path('/hotornot'), //
  alias('hotOrNotBase'),
  createMany(
    [index, alias('hotOrNot')], //
    [expr(/\/(?<listType>votes|matches)$/y), alias('hotOrNotList')]
  )
);

runTracking.$inject = ['user'];
/**
 * @param {import('acng/userPool/factory/user').User} user -
 */
export function runTracking(user) {
  on(base, 'active', () => void user.trackActivity());
}
