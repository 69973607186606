// @ts-nocheck
import angular from 'angular';
import find from 'underscore/modules/find.js';
import {LivecamSession} from '../model/session.js';
import {listen} from 'acng/core/context/event-bus.js';

angular.module('livecam')
  .directive('onswLivecamRebuy', ['user', '$timeout', 'payment', 'Widget', '$translate', '$window', (user, $timeout, payment, Widget, $translate, $window) => ({
    scope: { token: '=' },
    template: `
        <div ng-if="showRebuy && !loadingPaymentData && !rebillRedirectUrl" class="box" ng-class="{'toy-control-error': paymentRequired}">
          <div ng-if="verifiedMethod" class="verified">
            <div class="text-box">
              <div ng-if="paymentRequired" class="msg" ons-icon="lovense">{{msg | translate}}</div>
              <div ng-show="showLowCoinsTimer" class="seconds" ng-bind-html="secondsLeftText"></div>
              <div ng-if="showLowCoinsTimer && !paymentRequired" class="msg">{{'livecam.showWillEnd' | translate}}</div>
            </div>
            <div ng-if="!txStartInProgress" onsw-payment-packages class="rebuy package-box" item-class="button ons-item" products="products[payment.country.currency] || products[payment.defaultCurrency.iso_code]" select-package="selectPackage(package)"></div>
            <div ng-if="txStartInProgress" class="loading box">
              <span onsw-loader></span><span>{{'payment.pleaseWait' | translate}}...</span>
            </div>
          </div>

          <div ng-if="!verifiedMethod" class="not-verified">
            <div class="text-box">
              <div ng-if="paymentRequired" class="msg" ons-icon="lovense">{{msg | translate}}</div>
              <div ng-show="showLowCoinsTimer" class="seconds" ng-bind-html="secondsLeftText"></div>
              <div ng-if="showLowCoinsTimer && !paymentRequired" class="msg">{{'livecam.showWillEnd' | translate}}</div>
            </div>
            <div onsw-open-overlay class="rebuy button" label="payment.buyCoins" hookname="payment"></div>
          </div>
        </div>

        <div class="box" ng-if="rebillRedirectUrl !== null">
          <iframe style="width: 100%;height: 425px;border:0;" ng-src="{{rebillRedirectUrl}}">
        </div>`,

    link: (scope, element) => {
      const widget = new Widget(scope, element, 'ons-layout');

      let startTime;
      let secondsBeforeShutdown = 120;
      let x;
      let paymentInst;
      let secondsLeftTextRaw = $translate.instant('livecam.coinsForSomeSeconds');
      let coins = user.coins;
      let wait;

      function onShowChange(show) {
        if (isNaN(scope.session.cam.getPrice(show.showType))) {
          console.error('rebuy widget got no price');
          return;
        }
        scope.pricePerSecond = scope.session.cam.getPrice(show.showType) / 60;
      }

      scope.$on('$destroy', function () {
        startTime = null;
        $timeout.cancel(x);
        scope.session.client.off('showChanged', onShowChange);
      });

      scope.$on('LivecamShow.PaymentRequired', function ($event, code) {
        scope.msg = `livecam.payment_required_${code == 4006 ? 4006 : 4000}`;
        if (scope.showRebuy) {
          scope.session.client.exitFullscreen();
        }
        scope.paymentRequired = true;
      });

      scope.$watch('token', function (token) {
        scope.session = LivecamSession.get(token);
        scope.pricePerSecond = scope.session.getPrice() / 60;
      });

      scope.$watch(() => scope.session.cam.running, running => {
        if (running) {
          startTime = Date.now();
          scope.session.client.on('showChanged', onShowChange);
          run();
        }
      });

      scope.showRebuy = false;
      scope.showLowCoinsTimer = false;
      scope.msg = null;
      scope.verifiedMethod = null;
      scope.rebillRedirectUrl = null;
      scope.loadingPaymentData = false;
      scope.txStartInProgress = false;

      function run() {
        calculateSecondsLeft();
        x = $timeout(run, 1000);
      }

      function calculateSecondsLeft() {
        wait = wait && !(user.coins > coins);

        if (!startTime || scope.pricePerSecond === 0 || wait) return;
        if (coins != user.coins) {
          startTime = Date.now();
          coins = user.coins;
        }

        let deltaSeconds = (Date.now() - startTime) / 1000;
        scope.secondsLeft = Math.max(Math.floor(coins / scope.pricePerSecond - deltaSeconds), 0);
        scope.showLowCoinsTimer = scope.secondsLeft <= secondsBeforeShutdown;
        scope.secondsLeftText = secondsLeftTextRaw.replace(':secondsLeft:', scope.secondsLeft);
      }

      scope.$watchGroup(['showLowCoinsTimer', 'paymentRequired'], (newValues, old) => {
        const rebuyBarAlreadyActive = scope.showRebuy;

        scope.showRebuy = newValues[0] || newValues[1];
        if (!scope.showRebuy) {
          return;
        }

        // when the timer switches to true and the re-buy bar is already present => exit the fullscreen mode
        if (rebuyBarAlreadyActive && newValues[0] && newValues[0] !== old[0]) {
          scope.session.client.exitFullscreen();
          return;
        }

        if (!rebuyBarAlreadyActive) {
          init();
        }
      }
      );

      function init() {
        scope.loadingPaymentData = true;
        payment.create()
          .then(function (Inst) {
            paymentInst = Inst;
            scope.payment = Inst;
            return payment.countries();
          })
          .then(function (countries) {
            return paymentInst.getIndex()
              .then(function (data) {
                paymentInst.country = find(countries, { iso_code_2: paymentInst.preselectedCountryCode.toUpperCase() });
                scope.products = data.packages;
                scope.verifiedMethod = data.methods.reduce(
                  (acc, current) => {
                    if (current.verified) {
                      return !acc || current.verified_priority < acc.verified_priority ? current : acc;
                    }

                    return acc || null;
                  },
                  null
                );

                if (scope.verifiedMethod) {
                  paymentInst.method = scope.verifiedMethod;
                }
              });
          })
          .catch(function (err) {
            console.warn('onswLivecamRebuy: failed to init payment data', err);
          })
          .finally(() => {
            scope.loadingPaymentData = false;
            scope.session.client.exitFullscreen();
          });
      }

      scope.selectPackage = function (pkg) {
        paymentInst.package = pkg;

        let confirmText = $translate.instant('livecam.rebuyConfirmation', {
          coins: pkg.value, symbol: pkg.currency.symbol, price: pkg.price.toFixed(2)
        });

        if (confirm(confirmText) !== true) return;
        wait = true;
        scope.txStartInProgress = true;
        paymentInst.start()
          .then(call => payment.startFollow(call))
          .then(followResult => {
            if (followResult.data && followResult.data.url) {
              scope.rebillRedirectUrl = followResult.data.url;
            }

            scope.paymentRequired = false;
            scope.showLowCoinsTimer = false;
          })
          .catch(function (err) {
            element.addClass('error');
            if (!err.data) {
              err.data = {};
            }

            widget.notify(err.data.message || 'payment.error.' + (err.data.error || 100));
            wait = false;
          }).finally(() => {
            scope.txStartInProgress = false;
          });
      };

      function messageHandler(event) {
        if (event.data === 'archimedes.transaction.started') {
          scope.$apply(() => {
            scope.rebillRedirectUrl = null;
          });
        }
      }

      const offTransaction = listen('user.transaction', () => {
        scope.paymentRequired = false;
        scope.showLowCoinsTimer = false;
      });

      $window.addEventListener('message', messageHandler, false);
      scope.$on('$destroy', function () {
        $window.removeEventListener('message', messageHandler);
        offTransaction();
      });

    }
  })]);
