import angular from 'angular';
import {onTransaction} from '../service/event-bus.js';

angular.module('payment').directive('onswPaymentPaybackInProgress', [() => {
  return {
    scope: {
      transaction: '=',
      overlay: '<?'
    },
    template: `
<div class="loading box">
    <div onsw-loader></div>
    <h1>{{'payment.pleaseWait' | translate}}</h1>
    <p>{{'payment.pleaseWaitText' | translate}}</p>
    <p>
        {{'payment.payback.pleaseWaitTxCancellationNotice' | translate}}
        <div onsw-item class="ons-item link backLink" label="'core.previous' | translate" click-action="backToPaybackOverview()"></div>
    </p>
</div>`,
    /**
     * @type {angular.IDirectiveLinkFn<angular.IScope & {
     *   backToPaybackOverview: Function;
     *   transaction: import('../service/typeguard.js').Transaction | null;
     *   overlay: import('acng/core/service/overlay').Overlay | null;
     * }>}
     */
    link: function (scope, element) {

      scope.backToPaybackOverview = () => {
        scope.transaction = null;
      };

      scope.$on('$destroy', onTransaction(() => {
        element.addClass('done');
        scope.overlay && scope.overlay.close();
      }));
    }
  };
}]);
