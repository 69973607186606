/**
 * Visual representation of a Payttachment inside the messenger dialog
 * @module acng/messenger/widget/payttachment
 */
export const //
  type = 'directive',
  name = 'onswPayttachment',
  param = directive;
import angular from 'angular';
import {createVideo, append} from '@acng/frontend-bounty';
import {player} from 'acng/core/factory/flowplayer';
/**
 * @typedef ScopeTrait
 * @property {import('acng/messenger/model/message').Message} message -
 * @property {import('acng/messenger/factory/payttachment').Payttachment} payttachment -
 * @property {Function} buy -
 * @property {Function} openPayttachment -
 * @property {string} error -
 */
directive.$inject = ['Widget', 'payttachment', '$compile'];
/**
 * @param {import('acng/core/factory/widget').WidgetConstructor} Widget -
 * @param {*} payttachment -
 * @param {angular.ICompileService} $compile -
 * @returns {angular.IDirective<angular.IScope & ScopeTrait>}
 */
function directive(Widget, payttachment, $compile) {
  /**
   * @type {angular.IDirectiveLinkFn<angular.IScope & ScopeTrait>}
   */
  function link($scope, $element) {
    const element = $element[0];
    const widget = new Widget($scope, $element, 'ons-item');

    $scope.message.ready = false;
    widget
      .busy(payttachment.get($scope.message.payload.payttachment?.id))
      .then(r => ($scope.payttachment = r))
      .catch(e => {
        console.log('error', e);
        $scope.error = 'messenger.payttachment.unavailable';
      })
      .finally(() => ($scope.message.ready = true));

    $scope.buy = () => {
      if (!$scope.payttachment) {
        return;
      }

      if ($scope.payttachment.file === null) {
        widget
          .ifNotBusy(() => $scope.payttachment.buy())
          .then(r => ($scope.payttachment = r))
          .then(() => {
            $scope.openPayttachment();
          })
          .catch((err) => widget.notify(err));
      } else {
        $scope.openPayttachment();
      }
    };

    $scope.openPayttachment = () => {
      if ($scope.payttachment.file === null) {
        return;
      }

      if ($scope.payttachment.type === 'picture') {
        $compile('<image-overlay src="' + $scope.payttachment.file + '"></image-overlay>')($scope).appendTo(angular.element('body'));
      }

      if ($scope.payttachment.type === 'movie') {
        $element.find('.video-js').remove();
        $element.find('img').remove();
        const video = createVideo();
        append(element, video);
        // @ts-expect-error vjs-types bug
        player(video, $scope.payttachment.file);
      }
    };
  }

  return {
    link: link,
    scope: {
      message: '<',
    },
    template: `
      <div class="box" ng-if="payttachment || error" ng-click="buy()">
        <p ng-if="!error">{{'messenger.payttachment.'+payttachment.type+'.message' | translate}}</p>
        <img ng-class="{dummy: payttachment.dummy}" ng-if="payttachment.thumb" ng-src="{{payttachment.thumb}}" />
        <div ng-if="payttachment.file === null">{{'messenger.payttachment.'+payttachment.type+'.buy' | translate}} <span ons-icon="::*,coinsColor" class="coins">{{payttachment.price}}</span></div>
        <span ng-if="error">{{ error | translate }}</span>
      </div>`,
  };
}
