angular.module('core')

  .directive('onswItem', ['Widget', 'onsOverlay', 'isMobile', '$location', '$timeout', (Widget, onsOverlay, isMobile, $location, $timeout) => ({
    scope: {
      label: '<?',
      value: '<?',
      clickAction: '&'
    },
    template: (element, attrs) => {

      return '<span class="box"' + (attrs.clickAction ? ' ng-click="click($event)"' : '') + '>' + (attrs.label ? '<span class="label" ng-bind="label"></span>' : '') + (attrs.value ? '<span class="value" ng-show="value" ng-bind="value"></span>' : '') + '</span>';
    },
    link: (scope, element) => {

      const widget = new Widget(scope, element, 'ons-item');

      scope.click = ev => scope.clickAction({
        isMobile: isMobile,
        busy: p => widget.ifNotBusy(p).catch(err => widget.notify(err)),
        goto: path => $timeout(() => $location.path(path), 100),
        openOverlay: hookname => onsOverlay.create(hookname).disableBackdrop().open(),
        notify: text => widget.notify(text),
        stop: () => ev.stopPropagation()
      });
    }
  })]);
