angular.module('payment').directive('onswPaymentPaybackResult', ['portal', 'supportEmail', 'Payback', 'Widget', function (portal, supportEmail, Payback, Widget) {
  return {
    scope: {
      transaction: '<',
      overlay: '<?'
    },
    templateUrl: '/template/payment.paybackResult',
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-layout ons-form');

      scope.translateParams = {
        portal: portal.name,
        supportEmail: supportEmail
      };

      scope.submitAction = () => {
        if (!scope.success) {
          Payback.openOverlay();
        }

        scope.overlay && scope.overlay.close();
      };

      scope.$watch('transaction', tx => {
        Payback.closeOverlay();

        scope.success = tx.code === null;

        if (scope.success) {
          scope.headline = 'payment.payback.completed_headline';
          scope.showErrorDetails = false;
        } else {
          scope.overlay && scope.overlay.disableCloseButtonInToolbar();
          scope.showErrorDetails = tx.code != 109;
          scope.headline = 'payment.' + (scope.showErrorDetails ? 'errorHeadline' : 'error.' + tx.code);
        }

        element.toggleClass('success', scope.success);
        element.toggleClass('error', !scope.success);
      });
    }
  };
}]);
