/**
 * @module acng/pin
 */
import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

import './config/routes';
import onswPinButton, {buttonComponent} from './widget/button';
//import onscPinCount, {countDirective} from './directive/count';
import {promise} from './service';
import featureName from './config/feature.js';

angular
  .module(featureName, [angularTranslate, 'core', 'userPool', 'amateurPool'])
  .config(provideTranslations(featureName))
  //.directive(onscPinCount, countDirective)
  .component(onswPinButton, buttonComponent)
  .directive('onsdPins', [
    'PimpMyRide',
    PimpMyRide =>
      PimpMyRide(
        'Pins', //
        false,
        () => promise
      ),
  ]);
