import {popup} from "@acng/frontend-discovery";

import {getLocale} from 'acng/locale/model/locale.js';

angular.module('userPool').factory('userPoolApi', ['$rootScope', 'http', '$translate', function ($rootScope, http, $translate) {
  var qGetProfile;
  return {
    signup: function (signupData) {
      /*
      var preservedMessage = storage.get('message_not_sent');
      if(preservedMessage) {
        storage.set('message_not_sent', '');
        var dialog = $injector.get('Dialog').get(preservedMessage);
        if(dialog && dialog.lastMessage) {
          signupData.saveMessage = {
            partner: dialog.id,
            dialog: dialog.lastMessage
          };
        }
      }*/

      return http().post('/api/user/signup/', $.param(signupData), { dontIntercept: true }).then(function (res) {
        if (res.status == 202) {
          $translate('userPool.reactivationMailSent').then(function (text) {
            popup().warn(text);
          });
          throw 'reactivationMailSent';
        }

        $rootScope.$broadcast('userPool.signup.success', res.data);
        return res.data;
      }).catch(function (err) {
        $rootScope.$broadcast('userPool.signup.error', err.errors);
        throw err;
      });
    },
    resetPassword: function (formData) {
      return http().post('api/user/reset-password', $.param(formData));
    },
    updatePassword: function (data) {
      return http().put('api/user/update-password', data, { dontIntercept: true });
    },
    getProfile: function () {
      if (qGetProfile) return qGetProfile;
      qGetProfile = http().get('api/userProfile/view');
      qGetProfile.finally(function () {
        qGetProfile = null;
      });
      return qGetProfile;
    },
    updateProfile: function (profile) {
      return http().put('api/userProfile/update', profile, { dontIntercept: true });
    },
    uploadImage: function (image) {
      return http().post('api/userProfile/image', image, {
        dontIntercept: true,
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined }
      });
    },
    getWaitingImages: function () {
      return http().get('/api/userProfile/waiting-images');
    },
    deactivate: function () {
      return http().post('/api/user/deactivate');
    },
    reactivate: function (key) {
      return http().post('/api/user/reactivate', $.param({ 'key': key }));
    },
    translations: function () {
      return http().get(`/language/${getLocale()}/models.UserProfile`);
    },
    getAuthUser: function () {
      return http().get('/api/user/auth-user');
    },
    rename: function (nickname) {
      return http().post('/api/user/rename', $.param({ 'nickname': nickname }));
    },
    changeEmail: function (email) {
      return http().post('/api/user/change-email', $.param({ 'email': email }));
    },
    deleteSafely: function () {
      return http().delete('/api/user', { dontIntercept: true });
    }
  };
}]);
