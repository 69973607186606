/**
 * @module acng/core/config/debug
 */
import {BUILD} from '../service/env';
import {setTimeout, EventSource} from '@acng/frontend-bounty';
import {on} from '@acng/frontend-bounty';

const {enableDebug, enableEventSource, eventSourceUrl} = BUILD;

DEBUG: console.debug('core/config/debug', BUILD);

ESBUILD_EVENTSOURCE: if (enableEventSource) {
  /** @type {EventSource} */
  let es,
    retryCount = 0;
  (function connect() {
    es = new EventSource(`${eventSourceUrl}`);
    on(es, 'open', () => {
      console.warn('core/config/debug EventSource connected', {es});
      if (retryCount) {
        console.warn('core/config/debug EventSource is back online - reload just for the case');
        setTimeout(() => location.reload(), 500);
      }
    });
    on(es, 'change', () => location.reload());
    on(es, 'message', ev => console.log('core/config/debug EventSource message', {es, ev}));
    on(es, 'error', ev => {
      console.error('core/config/debug EventSource', {es, ev});
      es.close();
      const timeout = Math.min(30000, (++retryCount) ** 2 * 50); // eslint-disable-line no-extra-parens, max-len
      console.info('core/config/debug EventSource scheduled in %ss', (timeout / 1000).toFixed(1));
      setTimeout(connect, timeout);
    });
  })();
}

configDebug.$inject = ['$compileProvider', '$logProvider'];
/**
 * @param {angular.ICompileProvider} $compileProvider -
 * @param {angular.ILogProvider} $logProvider -
 */
export function configDebug($compileProvider, $logProvider) {
  DEBUG: if (enableDebug) {
    console.warn('core/config/debug', {enableDebug});
    return;
  }
  $compileProvider.debugInfoEnabled(false);
  $logProvider.debugEnabled(false);
}
