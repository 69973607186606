angular.module('amateurPool')
  .directive('onswAmateurResidentFilter', ['AmateurFilter', '$log', (AmateurFilter, $log) => {

    return {
      scope: {},
      templateUrl: '/template/amateurPool.residentFilter',
      link: function (scope, element) {

        element.addClass('ons-form');

        scope.filter = AmateurFilter.get('search');

        AmateurFilter.getAllCountries()
          .then(countries => scope.countries = countries)
          .catch(err => {
            $log.error('onswAmateurRedidentFilter.getAllCountries', err);
            scope.countries = [];
          });

        scope.$watch('filter.facets.country', function (val) {
          scope.country = val && val[0] || null;
        });

        scope.$watch('country', function (country, old) {
          if (country == old) {
            return;
          }
          if (Array.isArray(scope.filter.facets.country) && scope.filter.facets.country[0] == country) {
            return;
          }
          if (!country) {
            delete scope.filter.facets.country;
          } else {
            scope.filter.facets.country = [country];
          }

          if (scope.filter.facets.country && country != scope.filter.facets.country[0]) {
            delete scope.filter.facets.zip_area;
          }
          if (!scope.filter.trigger) {
            scope.filter.trigger = 'user';
          }
          scope.filter.dispatchEvent(new CustomEvent('change'));
        });

      }
    };
  }]);
