import {FreeMovie} from 'acng/moviePool/factory/FreeMovie.js';
angular.module('moviePool')

  .directive('onswFreeMovie', ['Widget','FreeMovieTile',(Widget, FreeMovieTile) => {

      return {
        scope: {
          day: '<',
          onFreeMovieDone: '&'
        },
        templateUrl: '/template/tile.media',
        link: (scope, element, attrs) => {
          const widget = new Widget(scope, element);
          const tile = new FreeMovieTile(false, scope, element[0]);
          tile.box = element.children('.box')[0];

          scope.$watch('day', day => widget.busy(FreeMovie.day(day)
          .then(freemovie => {
                if (!freemovie) return;
                tile.load(scope, freemovie);
                scope.lockHover = true;
                scope.onFreeMovieDone({ item: freemovie });
              })
            ));
        }
      };
    }]);
