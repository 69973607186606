/**
 * @module acng/picturePool
 */
import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

import './config/routes';
import {runLaforge} from './config/laforge';
import featureName from './config/feature.js';

angular
  .module(featureName, [angularTranslate]) //
  .config(provideTranslations(featureName))
  .run(runLaforge);
