/**
 * @module messenger/directive/count
 */
const ngDirectiveName = 'onscMessageCount';
export default ngDirectiveName;
export const messageCountDirective = [
  () => ({
    scope: false,
    restrict: 'A',
    controller: ['$scope', '$element', '$attrs', controller],
  }),
];

import {toggleClass} from '@acng/frontend-bounty';
import angular from 'angular';
import {dialogIsNew, newMessagesCount} from '../service/dialogs.js';

function moreThan(val: number, max: number) {
  if (val <= max) {
    return val;
  }
  return `${max}+`;
}

function controller(
  $scope: angular.IScope, //
  $element: JQLite,
  $attrs: angular.IAttributes,
) {
  let value: JQLite | undefined;
  const watch = () => {
    if (!$attrs.onscMessageCount) {
      return newMessagesCount;
    }
    return $scope.$eval($attrs.onscMessageCount)?.filter((a: string | import('../model/dialog').Dialog) => {
      return dialogIsNew(a);
    }).length;
  };
  $scope.$watch(
    watch,
    (count, prev) => {
      if (value && (count > prev || !count)) {
        value.remove();
        value = undefined;
      }
      toggleClass($element[0], 'unread-dialog', !!count);
      if (!count) {
        return;
      }
      if (value) {
        value.text(moreThan(count, 9));
      } else {
        value = angular.element(`<span class="value">${moreThan(count, 9)}</span>`);
        value.appendTo($element.find('.box'));
      }
    },
    true
  );
}
