angular.module('amateurPool')
  .directive('onswAmateurSignup', ['http', '$log', 'Widget', (http, $log, Widget) => {

    return {
      scope: {
        onSuccess: '&'
      },
      template: `
<form class="box" ng-submit="submit()" style="position: relative">
  <p>{{ 'amateurPool.signup.headline' | translate:{sitename:$root._globals.portal.name} }}</p>
  <label>
    <span>{{ 'amateurPool.signup.name' | translate }}</span> *
    <input type="text" ng-model="data.nickname">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.password' | translate }}</span> *
    <input type="password" ng-model="data.password">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.password2' | translate }}</span> *
    <input type="password" ng-model="data.password_confirmation">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.mail' | translate }}</span> *
    <input type="text" ng-model="data.email">
  </label>
  <p>{{ 'amateurPool.signup.support' | translate }}</p>
  <label>
    <span>{{ 'amateurPool.signup.prename' | translate }}</span> *
    <input type="text" ng-model="data.forename">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.surname' | translate }}</span> *
    <input type="text" ng-model="data.surname">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.address' | translate }}</span> *
    <input type="text" ng-model="data.address1">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.address2' | translate }}</span>
    <input type="text" ng-model="data.address2">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.zip' | translate }}</span> *
    <input type="text" ng-model="data.zip">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.city' | translate }}</span> *
    <input type="text" ng-model="data.city">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.country' | translate }}</span>
    <input type="text" ng-model="data.country">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.birthday' | translate }}</span> *
    <input type="text" ng-model="data.birthday">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.phone' | translate }}</span> *
    <input type="text" ng-model="data.phone">
  </label>
  <label>
    <span>{{ 'amateurPool.signup.skype' | translate }}</span>
    <input type="text" ng-model="data.skype">
  </label>
  <p>
    {{ 'amateurPool.signup.footer' | translate }}<br />
    <br />
    <span onsw-open-overlay class="link" hookname="amateurAgb" label="amateurPool.signup.confirmAGB"></span>
  </p>
  <div class="ons-layout buttons">
    <div class="box">
      <span class="ons-item button submit">
        <button type="submit" class="box"><span class="label">{{'amateurPool.signup.submit' | translate}}</span></button>
      </span>
    </div>
  </div>
</form>`,
      link: (scope, element) => {
        const widget = new Widget(scope, element, 'ons-form');
        scope.data = {};

        scope.submit = () => {
          widget.ifNotBusy(() =>
            http().post('/api/amateur-signup', $.param(scope.data), { dontIntercept: true })
              .then(res => {
                scope.onSuccess();
              })
              .catch(err => {
                $log.error('amateur-signup', err);
                widget.notify(err.data);
                throw null;
              })
          );
        };
      }
    };
  }]);
