import {asset} from '../service/env.js';

class Smiley {
  /**
   * @param {string} key
   * @param {string} name
   * @param {string} path
   * @param {string} ext
   */
  constructor(key, name, path, ext) {
    this.key = key;
    this.name = name;
    this.file = `${path}${name}${ext}`;
    this.url = asset(`basic/img/smileys/${this.file}`);
    this.regex = new RegExp(key.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'gi');
  }
  getUrl() {
    return asset(`basic/img/smileys/${this.file}`);
  }
  getImage() {
    return '<span style="background-image:url(' + this.url + ')" class="smiley ' + this.name + '"></span>';
  }
  /**
   * @param {string} text
   */
  replace(text) {
    return text.replace(this.regex, this.getImage());
  }
}

export const smileys = [
  [':)', 'smile'],
  [':(', 'sad'],
  [':down:', 'down'],
  [':D', 'laugh'],
  [':*', 'kiss'],
  ['8)', 'cool'],
  [';)', 'wink'],
  [';(', 'cry'],
  [':|', 'speechless'],
  [':p', 'tongue'],
  [':$', 'blush'],
  [':s', 'confused'],
  [':lol:', 'lol'],
  [':wasntme:', 'wasntme'],
  ['x(', 'angry'],
  [':evil:', 'evil'],
  ['8|', 'eek'],
  [':yell:', 'yell'],
  [':love:', 'love'],
].map((i) => new Smiley(i[0], i[1], '', '.svg'));

export const externalSmileys = [
  [':-|', 'livecam_1'],
  [':-[', 'livecam_3'],
  [':-S', 'livecam_5'],
  [';-(', 'livecam_6'],
  [':-L', 'livecam_7'],
  [':-O', 'livecam_8'],
  ['[hello]', 'livecam_hello'],
  ['[bye]', 'livecam_bye'],
  ['[wave]', 'livecam_wave'],
  ['[thumbup]', 'livecam_thumbup'],
  ['[erection]', 'livecam_erection'],
  ['[wank]', 'livecam_wank'],
  ['[blowjob]', 'livecam_blowjob'],
  ['[cunnilingus]', 'livecam_cunnilingus'],
  ['[exhibitonist]', 'livecam_exhibitonist'],
  ['[laugh]', 'livecam_laugh'],
  ['[amor]', 'livecam_amor'],
  ['[hdl]', 'livecam_hdl'],
  ['[lol]', 'livecam_lol'],
  ['[inlove]', 'livecam_inlove'],
  ['[heart]', 'livecam_hearts'],
].map((i) => new Smiley(i[0], i[1], 'ext/', '.png'));

/**
 * @param {string} text
 */
export const replace = (text) => {
  const replaced = smileys.reduce((text, smiley) => smiley.replace(text), text);
  return externalSmileys.reduce((replaced, smiley) => smiley.replace(replaced), replaced);
};

/**
 * @param {string} text
 */
export const clearText = (text) => {
  const cleared = smileys.reduce((text, smiley) => text.replace(smiley.regex, ''), text);
  return externalSmileys.reduce((text, smiley) => text.replace(smiley.regex, ''), cleared).trim();
};
