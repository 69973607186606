angular.module('ons-LaForge-client')
  .run(['onsLaforgeEvents', onsLaforgeEvents => {

    class IcebreakerResponse extends onsLaforgeEvents.LogEvent {

      constructor(text, ibid, recipient) {
        super();
        this.type = 'IcebreakerResponse';
        this.text = text;
        this.icebreakerId = ibid;
        this.recipient = recipient;
      }

      getDTO() {
        const dto = super.getDTO();
        dto.icebreakerId = this.icebreakerId;
        dto.recipient = this.recipient;
        return dto;
      }

    }
    onsLaforgeEvents.IcebreakerResponse = IcebreakerResponse;

  }]);
