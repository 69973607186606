/**
 * @module acng/alternativeProducts/factory/products
 */
import {media} from 'acng/core/service/env';
const name = 'AlternativeProducts';
export default name;
/**
 * Factory class for the feature Alternative Products to handle the api communication
 */
export class Products {
  static $inject = ['http', '$location'];
  /**
   * @type {Product[] | undefined}
   * @private
   */
  products;
  /**
   * @type {getProductsFnType}
   */
  getProducts;
  /**
   * @param {core.Http} http Http client for fetching data from backend
   * @param {angular.ILocationService} $location -
   */
  constructor(http, $location) {
    this.getProducts = async () => {
      if (!this.products) {
        const location = $location.search();
        DEBUG: console.debug($location.search());
        /** @type {string | undefined} */
        let uid;
        if (location.userid) {
          uid = location.userid;
        }

        DEBUG: console.debug('Load products from api');
        const userId = uid?.split('-').pop() || '';
        const res = await http().get(`/api/alternative-products/${userId}`);
        /** @type {(Product&ApiProduct)[]} */
        const data = res.data;
        this.products = data.map(product => {
          const imgName = product.whitelabel_shortcut?.length === 3
            ? product.whitelabel_shortcut.toLowerCase()
            : `ext_${product.name.toLowerCase()}`;
          delete product.whitelabel_shortcut;
          product.img = `${media.assets}/basic/img/alternativeProducts/logos/${imgName}.png`;
          if (uid) {
            product.lp_url = `${product.lp_url}&userid=${uid}`;
          }
          return product;
        });
      }
      return this.products;
    };
  }
}
/* Types */
/**
 * @typedef ApiProduct
 * @property {string | null | undefined} whitelabel_shortcut Shortcut of the whitelabel
 */
/**
 * Retrieve all available products from the api
 * @callback getProductsFnType
 * @returns {Promise<Product[]>} List of all available products to show on the page
 * @async
 */
/**
 * @typedef AlternativeProducts
 * @property {getProductsFnType} getProducts Retrieve all available products from the api
 */
/**
 * @typedef Product
 * @property {string} name Name of the portal
 * @property {string} lp_url Url to the target landingpage
 * @property {number} fsk Max fsk level of the product
 * @property {string} img Logo image url
 */
