import {popup} from "@acng/frontend-discovery";

angular.module('userPool').directive('onswUserDeactivate', ['portal', 'userPoolApi', '$translate', '$window', '$timeout', function (portal, userPoolApi, $translate, $window, $timeout) {

  function deactivate(scope, element) {
    element.addClass('busy');
    $translate('userPool.confirmDeactivation', { sitename: portal.name }).then(function (text) {
      if (confirm(text)) {
        userPoolApi.deactivate().then(function () {
          $translate('userPool.deactivated').then(text => popup(element[0]).info(text));
          $timeout(function () {
            $window.location.reload();
          }, 5000);
        }).catch(function () {
          element.addClass('error');
        }).finally(function () {
          element.removeClass('busy');
        });
      } else {
        element.removeClass('busy');
      }
    });
  }

  return {
    scope: {},
    templateUrl: '/template/userPool.myProfileDeactivate',
    link: function (scope, element) {
      element.addClass('ons-form ready');
      scope.deactivate = deactivate.bind(this, scope, element);
    }
  };
}]);
