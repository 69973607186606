import {rootRoute} from '@acng/frontend-voyager/angularjs';
import {middleware, createMany, path, expr, alias, incomplete, listen} from '@acng/frontend-voyager';
import {replaceUrl} from '@acng/frontend-relativity/history';

import {mainView} from 'acng/layout/service/view-middleware.js';
import {coreFeature, hookFeature} from './feature.js';
import {portal} from '../service/env';
export {rootRoute};

rootRoute.config(
  listen('unknown', () => replaceUrl('/#/')),
  middleware((next) => {
    next.portal = portal;
    next.locale = document.body.lang;
  }),
  createMany(
    [path('/privacyPolicy'), mainView(hookFeature, 'privacyPolicy'), alias('privacyPolicy')],
    [path('/terms'), mainView(hookFeature, 'terms'), alias('terms')],
    [path('/imprint'), mainView(hookFeature, 'imprint'), alias('imprint')],
    [path('/contentRemoval'), mainView(coreFeature, 'content-removal')],
    [expr(/(?:\/movies|\/pictures\/sets)$/), alias('gallerySearch')],
    [incomplete, expr(/^\/(?!livecamShow|hotornot)/), alias('footer')]
  )
);
