import {popup} from '@acng/frontend-discovery';
import {SimpleQueue} from '@acng/frontend-bounty/timing';
import {ANY, IS, RECORD, typeguard} from '@acng/frontend-bounty/assert';
import {AVAILABLE_VALIDATORS, VALIDATOR_DATA} from '../service/backend/typeguard.js';
import {setMessage} from '../service/backend/validation-messages.js';
import {Validator} from '../service/backend/validation.js';

const MODULE = 'core/directive/apiValidator';

const messageQueue = SimpleQueue();

/**
 * @type {angular.IDirective}
 */
export const onsApiValidatorDirective = {
  require: 'ngModel',
  scope: false,
  link: function (scope, $element, attrs, ctrl) {
    ASSERT: {
      typeguard(`${MODULE} element`, $element[0], IS(HTMLInputElement));
      typeguard(`${MODULE} controller`, ctrl, RECORD(ANY, ANY));
      typeguard(`${MODULE} apiPath`, attrs.onsApiValidator, AVAILABLE_VALIDATORS);
    }

    const element = $element[0];

    /** @type {HTMLDivElement | undefined} */
    let box;

    const validate = Validator(element, attrs.onsApiValidator);

    const clearMessage = async () => {
      if (box) {
        box.remove(); //popup(element).close(box); // do not await animation
        box = undefined;
      }
    };

    /**
     * @param {string} value
     */
    ctrl.$asyncValidators.api = async (value) => {
      delete ctrl.$validators.minlength;
      delete ctrl.$validators.required;
      delete ctrl.$validators.email;

      messageQueue(clearMessage);

      const res = validate(value);
      scope.$emit('apivalidator.validate', res);
      const reason = await res;

      if (!reason.valid) {
        messageQueue(async () => {
          clearMessage();
          box = await setMessage(element, reason, (value) => ctrl.$setViewValue(value));
          element.after(box); //popup(element).show(box);
        });

        throw reason;
      }
    };
  },
};
