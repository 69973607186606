import './jquery.js';

import 'angular';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-translate-loader-static-files';
import 'angular-sanitize';
import 'angular-animate';
import 'angular-cookies';
import 'ngstorage';
import 'storage';
import 'ons-actionslider/common';

import 'acng/Lib/Log/00_index.js';
import 'acng/Lib/Log/client.js';
import 'acng/Lib/Log/events/icebreakerResponse.js';
import 'acng/Lib/Log/events/kiss.js';
import 'acng/Lib/Log/events/visit.js';
import 'acng/Lib/Log/events.js';

import 'acng/actionslider/0module.js';
import 'acng/actionslider/factory/actionslider.js';
import 'acng/actionslider/factory/profile.js';

import 'acng/amateurPool';
import 'acng/amateurPool/directive/gotoProfile.js';
import 'acng/amateurPool/factory/Amateur.js';
import 'acng/amateurPool/factory/Feed.js';
import 'acng/amateurPool/factory/Filter.js';
import 'acng/amateurPool/factory/ModeratedAmateur.js';
import 'acng/amateurPool/factory/Weblog.js';
import 'acng/amateurPool/tiles/AmateurTile.js';
import 'acng/amateurPool/tiles/FeedTile.js';
import 'acng/amateurPool/tiles/GlobalFeedTile.js';
import 'acng/amateurPool/tiles/ModeratedAmateurTile.js';
import 'acng/amateurPool/tiles/OtmTile.js';
import 'acng/amateurPool/tiles/OtmWithFallbackTile.js';
import 'acng/amateurPool/tiles/PortalFeedTile.js';
import 'acng/amateurPool/widgets/commonFilter.js';
import 'acng/amateurPool/widgets/facetFilter.js';
import 'acng/amateurPool/widgets/facetGroup.js';
import 'acng/amateurPool/widgets/filterHeading.js';
import 'acng/amateurPool/widgets/label.js';
import 'acng/amateurPool/widgets/moreAboutMe.js';
import 'acng/amateurPool/widgets/nicknameSearch.js';
import 'acng/amateurPool/widgets/personalInfo.js';
import 'acng/amateurPool/widgets/residentFilter.js';
import 'acng/amateurPool/widgets/signup.js';
import 'acng/amateurPool/widgets/tagList.js';
import 'acng/amateurPool/widgets/topicBadge.js';

import 'acng/core/0core.js';
import 'acng/core';
import 'acng/core/0misc.js';
import 'acng/core/constant/iOS.js';
import 'acng/core/directive/crop.js';
import 'acng/core/directive/fileupload.js';
import 'acng/core/directive/imageOverlay.js';
import 'acng/core/directive/responsive.js';
import 'acng/core/directive/scroll.js';
import 'acng/core/directive/scrollIntoView.js';
import 'acng/core/factory/Slideshow.js';
import 'acng/core/factory/bulk.js';
import 'acng/core/factory/cache.js';
import 'acng/core/factory/country.js';
import 'acng/core/factory/fskImage.js';
import 'acng/core/factory/http.js';
import 'acng/core/factory/httpInteceptor.js';
import 'acng/core/factory/jsLoader.js';
import 'acng/core/factory/log.js';
import 'acng/core/factory/mailApi.js';
import 'acng/core/factory/price.js';
import 'acng/core/factory/ride.js';
import 'acng/core/factory/widget.js';
import 'acng/core/filter/acDate.js';
import 'acng/core/filter/country.js';
import 'acng/core/filter/dateFromTimestamp.js';
import 'acng/core/filter/fskMask.js';
import 'acng/core/filter/price.js';
import 'acng/core/filter/reverse.js';
import 'acng/core/filter/unit.js';
import 'acng/core/run/scrollIntoView.js';
import 'acng/core/tiles/LPBanner.js';
import 'acng/core/widgets/categorySelect.js';
import 'acng/core/widgets/chosen.js';
import 'acng/core/widgets/countdown.js';
import 'acng/core/widgets/heading.js';
import 'acng/core/widgets/image.js';
import 'acng/core/widgets/item.js';
import 'acng/core/widgets/link.js';
import 'acng/core/widgets/loader.js';
import 'acng/core/widgets/openOverlay.js';
import 'acng/core/widgets/overlayReason.js';
import 'acng/core/widgets/pagination.js';
import 'acng/core/widgets/text.js';
import 'acng/core/widgets/timer.js';
import 'acng/core/widgets/toggleButton.js';

import 'acng/friend';
import 'acng/friend/factory/friend.js';
import 'acng/friend/tiles/AmateurFriendTile.js';
import 'acng/friend/tiles/FriendTile.js';
import 'acng/friend/widget/link.js';
import 'acng/friend/widget/operations.js';

import 'acng/hotornot';
import 'acng/hotornot/hotornot.js';

import 'acng/livecam/0module.js';
import 'acng/livecam';
import 'acng/livecam/factory/livecam.js';
import 'acng/livecam/tiles/SmallLivecamTile.js';
import 'acng/livecam/widgets/button.js';
import 'acng/livecam/widgets/close.js';
import 'acng/livecam/widgets/heading.js';
import 'acng/livecam/widgets/rebuy.js';
import 'acng/livecam/widgets/show.js';
import 'acng/livecam/widgets/timer.js';
import 'acng/livecam/widgets/tipButton.js';
import 'acng/livecam/widgets/toyButton.js';

import 'acng/messenger';
import 'acng/messenger/tiles/ContactTile.js';
import 'acng/messenger/tiles/PayttachmentTile.js';
import 'acng/messenger/widget/attachments.js';
import 'acng/messenger/widget/create.js';
import 'acng/messenger/widget/messageNotification.js';
import 'acng/messenger/widget/nicknameFilter.js';

import 'acng/moviePool';
import 'acng/moviePool/directive/FreeMovie.js';
import 'acng/moviePool/factory/Movie.js';
import 'acng/moviePool/tiles/FreeMovieCountdown.js';
import 'acng/moviePool/tiles/FreeMovieTile.js';
import 'acng/moviePool/tiles/MovieTile.js';
import 'acng/moviePool/tiles/SmallMovie.js';
import 'acng/moviePool/widget/amateurMoviesLink.js';
import 'acng/moviePool/widget/freeMovie.js';
import 'acng/moviePool/widget/info.js';

import 'acng/payment';
import 'acng/payment/controller/buy.js';
import 'acng/payment/factory/api.js';
import 'acng/payment/factory/interceptor.js';
import 'acng/payment/factory/payback.js';
import 'acng/payment/factory/payment.js';
import 'acng/payment/widget/country.js';
import 'acng/payment/widget/methods.js';
import 'acng/payment/widget/mobilePayment.js';
import 'acng/payment/widget/newWindow.js';
import 'acng/payment/widget/packages.js';
import 'acng/payment/widget/paybackInProgress.js';
import 'acng/payment/widget/paybackOverview.js';
import 'acng/payment/widget/paybackResult.js';
import 'acng/payment/widget/progress.js';
import 'acng/payment/widget/transactionInProgress.js';
import 'acng/payment/widget/transactionResult.js';

import 'acng/picturePool';
import 'acng/picturePool/factory/Picture.js';
import 'acng/picturePool/factory/PictureSet.js';
import 'acng/picturePool/run/Picture.js';
import 'acng/picturePool/tiles/PictureSetTile.js';
import 'acng/picturePool/tiles/PictureThumbnail.js';
import 'acng/picturePool/widget/amateurPicturesLink.js';
import 'acng/picturePool/widget/info.js';
import 'acng/picturePool/widget/slideshow.js';

import 'acng/rating';
import 'acng/rating/factory/rating.js';
import 'acng/rating/run/Movie.js';
import 'acng/rating/run/PictureSet.js';
import 'acng/rating/widget/rater.js';
import 'acng/rating/widget/rating.js';

import 'acng/userPool';
import 'acng/userPool/0fsk.js';
import 'acng/userPool/0stock.js';
import 'acng/userPool/controller/doiHint.js';
import 'acng/userPool/controller/requestMailDoi.js';
import 'acng/userPool/directive/crop.js';
import 'acng/userPool/factory/api.js';
import 'acng/userPool/factory/director.js';
import 'acng/userPool/factory/notification.js';
import 'acng/userPool/factory/user.js';
import 'acng/userPool/run/reactivate.js';
import 'acng/userPool/tiles/StockTile.js';
import 'acng/userPool/widget/changeEmail.js';
import 'acng/userPool/widget/confirmMail.js';
import 'acng/userPool/widget/deactivate.js';
import 'acng/userPool/widget/delete.js';
import 'acng/userPool/widget/description.js';
import 'acng/userPool/widget/login.js';
import 'acng/userPool/widget/logout.js';
import 'acng/userPool/widget/notificationButtons.js';
import 'acng/userPool/widget/notificationToggle.js';
import 'acng/userPool/widget/passwordChange.js';
import 'acng/userPool/widget/passwordReset.js';
import 'acng/userPool/widget/passwordSet.js';
import 'acng/userPool/widget/preferences.js';
import 'acng/userPool/widget/profile.js';
import 'acng/userPool/widget/profileImage.js';
import 'acng/userPool/widget/profileImageCropper.js';
import 'acng/userPool/widget/profileInfo.js';
import 'acng/userPool/widget/rename.js';
import 'acng/userPool/widget/signup.js';

import 'acng/zz-app.js';
