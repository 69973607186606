import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {remove} from '@acng/frontend-bounty';
import {getCookie, setCookie} from '@acng/frontend-bounty/cookie';

import {coreFeature} from '../config/feature.js';
import {portal} from '../service/env';

const COOKIE_NAME = 'cookies';
const COOKIE_VALUE = 'accepted';

defineCustomWidget(
  coreFeature,
  'onsw-cookie-bar',
  class extends Widget {
    sitename = portal.name;

    constructor() {
      super();
      if (getCookie(COOKIE_NAME) === COOKIE_VALUE) {
        remove(this);
      }
    }

    click() {
      setCookie(COOKIE_NAME, COOKIE_VALUE);
      remove(this);
    }
  }
);
