import {Promise, setInterval, createSet, clearSet, addToSet, hasInSet} from '@acng/frontend-bounty';
import {ARRAY, STRING, typeguard} from '@acng/frontend-bounty/typeguard';
import {createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {inject} from 'acng/core/service/ng';
import {API_cdn} from 'acng/core/service/env';

const MODULE = 'amateurPool/service/online';
const VERBOSE = false;

/**
 * @param {string} amateurId
 */
export const isOnline = (amateurId) => hasInSet(online, amateurId);

/**
 * Observe the (messenger-) online state of Amateurs.
 */
export const ctxOnline = createGlobalContext(isOnline);

/**
 * @type {Set<string>}
 */
const online = createSet();

/**
 * @deprecated
 */
export const getOnlineArray = async () => {
  await init;
  return Array.from(online);
};

/**
 * @returns {Promise<void>}
 */
const fetchOnline = async () => {
  const res = await fetch(`${API_cdn}/api/amateur-profile/online`);

  if (!res.ok) {
    return;
  }

  clearSet(online);

  const data = await res.json();

  ASSERT: typeguard(`${MODULE} data`, data, ARRAY(STRING));

  for (const id of data) {
    addToSet(online, id);
  }

  DEBUG: VERBOSE && console.info(MODULE, online);

  init = Promise.resolve(undefined);
  ctxOnline[CTX_PROVIDE](null, isOnline);

  try {
    inject('$rootScope').$digest();
  } catch (reason) {
    //
  }
};

let init = fetchOnline();
setInterval(fetchOnline, 30e3);
