import {ageFromBirthday} from 'acng/core/factory/helper';
import debounce from 'underscore/modules/debounce.js';

angular.module('userPool').directive('onswProfileInfo', [
  'country',
  '$filter',
  'userPoolApi',
  '$q',
  'Widget',
  function (country, $filter, userPoolApi, $q, Widget) {
    function init(scope, element, widget) {
      widget
        .busy(
          $q.all([
            country.all().then((res) => {
              scope.countries = res.data
                .map((c) => ({
                  key: c['countries_iso_code_2'],
                  value: $filter('country')(c['countries_iso_code_2']),
                }))
                .sort((a, b) => new Intl.Collator('de').compare(a.value, b.value));
            }),
            userPoolApi.getProfile().then((res) => {
              scope.profile = angular.copy(res.data);
              scope.profile.birthday = $filter('date')(scope.profile.birthday, 'dd.MM.yyyy');
            }),
          ])
        )
        .then(() => {
          element.addClass('ready');
        });
    }

    const save = debounce((scope, element, widget) => {
      widget
        .ifNotBusy(() => {
          if (scope.newProfile.birthday) {
            var age = ageFromBirthday(scope.newProfile.birthday);
            if (age === false) {
              return $q.reject('userPool.birthdayRegexFail');
            }
            if (age.age < 18) {
              return $q.reject('userPool.birthdayTooYoung');
            }
          }
          var profile = angular.copy(scope.newProfile);
          if (scope.newProfile.birthday) {
            profile.birthday = $filter('date')(age.birthday, 'yyyy-MM-dd');
          }
          return userPoolApi.updateProfile(profile);
        })
        .then(() => {
          scope.newProfile = {};
        })
        .catch((err) => {
          widget.notify(err);
        });
    }, 500);

    return {
      scope: {},
      templateUrl: '/template/userPool.myProfileInfo',
      link: function (scope, element) {
        const widget = new Widget(scope, element, 'ons-form');

        init(scope, element, widget);
        scope.updateProfile = save.bind(this, scope, element, widget);
        scope.newProfile = {};
        scope.$watchCollection('profile', function (n, o) {
          if (!o) return;
          angular.forEach(n, (v, k) => {
            if (n[k] != o[k]) {
              scope.newProfile[k] = n[k];
            }
          });
          scope.updateProfile();
        });
      },
    };
  },
]);
