angular.module('payment').directive('onswPaymentMobilePayment', ['payment',
  function (payment) {

    return {
      scope: {
        mobilePayment: '<',
        filterPrice: '<',
        selectPackage: '&'
      },
      template: `
<div class="box">
    <div class="package col50-md" ng-repeat="package in mobilePayment.method.packages" ng-class="{'withBonus':package.bonus, active: isSelected(package)}" 
        ng-if="isAvailable(package)" ng-click="select(package)">
        <div class="box">
            <div class="ons-item bonus" ng-if="package.bonus">
                <span class="box">
                    <span class="value">{{ percentValue(package) }}%</span>
                    <span class="label" ng-bind="'payment.package.bonusBadge' | translate"></span>
                </span>
            </div>
            <div class="price">{{package.price | currency : package.currency[package.currency.symbol ? 'symbol' : 'iso_code'] : 0}}</div>
            <div class="box"><img ng-src="{{mobilePayment.method.icon.path}}" alt=""></div>
            <div class="value coins">
                <span class="default">{{package.value}} Coins</span>
                <span class="bonus" ng-if="package.bonus">{{"payment.package.values.coins" | translate:'{coins:'+(package.value + package.bonus)+'}'}}</span>
            </div>
        </div>
    </div>
</div>`,
      link: function (scope, element) {

        element.addClass('ons-layout ons-list');

        scope.isAvailable = function (pkg) {
          return !scope.filterPrice || scope.filterPrice > 0 && scope.filterPrice === pkg.price;
        };

        scope.select = function (pkg) {
          scope.selectPackage({ pkg });
        };

        scope.isSelected = function (pkg) {
          return scope.mobilePayment.package === pkg;
        };

        scope.percentValue = payment.calculateBonusPercentage;

      }
    };
  }]);
