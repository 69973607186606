import {floor} from "@acng/frontend-bounty";

/**
 * @param {number} weight
 */
export const kgToLbs = (weight) => floor(2.2 * weight);

/**
 * @param {number} weight
 */
export const lbsToKg = (weight) => floor((1.0 / 2.2) * weight);

/**
 * @param {number} length
 */
export const cmToIn = (length) => floor(0.39 * length);

/**
 * @param {number} length
 */
export const inToCm = (length) => floor((1.0 / 0.39) * length);
