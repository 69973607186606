/**
 * @module acng/games/decorator/slideshow
 */
import {pushToArray} from '@acng/frontend-bounty';
import {slideshowConfig} from 'acng/core/factory/Slideshow';
import {media} from 'acng/core/service/env';
import {inject} from 'acng/core/service/ng';

/** @param {"home" | "sidebar"} format */
const teaser = (format) => [
  'ImageTile',
  {
    caption: 'games.games',
    json: `${media.assets_ext}/img/games/teaser-${format}.js`, //
    click: () => (location.hash = '/games'),
    disable: () => !inject('user').hasGames(),
  },
  5000,
];

export const extendSlideshow = () => {
  pushToArray(slideshowConfig.myportal_t1, teaser('home'));
  pushToArray(slideshowConfig.sidebar, teaser('sidebar'));
};
