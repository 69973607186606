import angular from 'angular';

import './widget/list';
import './widget/count';
import onswCommentCreate, {createComponent} from './widget/create';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';
import name from './config/feature.js';

export default name;

angular
  .module(name, [angularTranslate])
  .config(provideTranslations(name))
  .component(onswCommentCreate, createComponent);
