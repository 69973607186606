angular.module('amateurPool')

  .factory('OtmTile', ['AmateurTile', 'Otm', 'fsk', '$log', (AmateurTile, Otm, fsk, $log) => {

    return class OtmTile extends AmateurTile
    {
      async load(scope, otm) {
        if (otm.fsk > fsk.get()) {
          $log.warn('OtmTile FSK', this);
          throw 'otm fsk';
        }
        this.caption = otm.getCaption();
        this.apply({
          otm: otm,
          image: otm.imageXL
        });
        await super.load(scope, `${otm.amateurId}`);
        if (this.disabled) {
          delete this.disabled;
          //throw 'otm image not found';
        }
        otm.defer();
        Object.assign(this.box.style, {
          'background': `linear-gradient( to bottom, ${scope.otm.color}00 60%, ${scope.otm.color}FF 80% ), no-repeat top/contain url(${this.image.src})`,
          'border-bottom': `2px solid ${scope.otm.color}`,
          'background-color': scope.otm.color
        });
      }
      hookname() {
        return 'aotm';
      }
    };

  }])

  .factory('OtmTileTrans', ['OtmTile', OtmTile => {
    return class OtmTileTrans extends OtmTile
    {
      async load(scope, otm) {
        await super.load(scope, otm);
        Object.assign(this.box.style, {
          'background': `linear-gradient( to bottom, ${scope.otm.color}00 calc(100% - 120px), ${scope.otm.color}C0 calc(100% - 40px) ), no-repeat top/cover url(${this.image.src})`,
          'border-bottom': `2px solid ${scope.otm.color}C0`,
          'background-color': scope.otm.color + 'C0'
        });
      }
    };
  }]);
