/** @param {string} val */
export function ageFromBirthday(val) {
  var matches = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.((19|20)\d\d)$/.exec(val);

  if (!matches) {
    return false;
  }

  var birthday = new Date(parseInt(matches[3]), parseInt(matches[2]) - 1, parseInt(matches[1]));
  var today = new Date();
  var age = today.getFullYear() - birthday.getFullYear();
  if (
    today.getMonth() < birthday.getMonth() ||
    (today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDate())
  ) {
    age--;
  }
  return {age: age, birthday: birthday};
}

/**
 * @template T
 * @param {T[]} array
 * @param {number} [limit]
 * @returns {T[]}
 */
export function shuffle(array, limit) {
  for (var i = (limit || array.length) - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}
