import angular from 'angular';
import throttle from 'underscore/modules/throttle.js';
import {append, createDiv, onClick} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';
import {useLocale} from 'acng/locale/model/locale.js';
import {userPoolFeature} from '../config/feature';
import {authUser, hasFeature, portal} from 'acng/core/service/env';
import {stargazer} from '@acng/frontend-stargazer';
import {ctxAuthUser} from '../context/auth-user.js';
import {listen} from 'acng/core/context/event-bus.js';
import {EVENTBUS_USER, typeguard} from '../service/typeguard.js';

const ERR_LOGOUT_GUEST = 'A guest cannot logout.';

angular
  .module('userPool')
  .factory('user', [
    '$rootScope',
    'userPoolApi',
    '$translate',
    '$location',
    'onsOverlay',
    'Notificator',
    'http',
    'freeCoinsConfig',
    /**
     * @param {import('acng/zz-app').RootScope} $rootScope
     * @param {unknown} userPoolApi
     * @param {angular.translate.ITranslateService} $translate
     * @param {angular.ILocationService} $location
     * @param {import('acng/core/service/overlay').OverlayConstructor} onsOverlay
     * @param {unknown} Notificator
     * @param {core.Http} http
     * @param {unknown} freeCoinsConfig
     */
    function (
      $rootScope,
      userPoolApi,
      $translate,
      $location,
      onsOverlay,
      Notificator,
      http,
      freeCoinsConfig,
    ) {
      /** @implements {_AuthUser} */
      class User {
        nickname = 'guest';
        guest = true;
        niche = portal.niche;
        list_sorting_suffix = portal.list_sorting_suffix;
        redirectPath = null;
      }

      User.prototype.hasGames = function () {
        return !this.guest && hasFeature('games');
      };

      User.prototype.hasVoiceMessage = function () {
        return !this.guest && hasFeature('messenger');
      };

      User.prototype.login = function (loginData) {
        return http()
          .post('/api/user/login/', $.param(loginData))
          .then(function (res) {
            if (res.status == 202) {
              $translate('userPool.reactivationMailSent').then(function (text) {
                popup().warn(text);
              });
              return;
            }
            $rootScope.$broadcast('userPool.login.success', res.data);
          })
          .catch(function (err) {
            console.log('foo', err);

            if (err.data.code == 1007) {
              let overlay = onsOverlay.create('confirm-device');
              overlay.open();
              return;
            }
            if (!onsOverlay.count()) {
              onsOverlay.create('pageLogin').disableBackdrop().open();
            }
            throw err;
          });
      };

      User.prototype.logout = async function () {
        if (this.guest) {
          throw Error(ERR_LOGOUT_GUEST);
        }

        userPoolFeature.popup(
          'confirm-logout',
          (close) => ({
            yes: async () => {
              await Notificator.unsubscribe().catch(console.error);
              await http().post('/api/user/logout');
              this.clear();
            },
            no: () => close(), 
          }),
          true
        );
      };

      User.prototype.signup = userPoolApi.signup;

      User.prototype.guestSignup = function (reason, params) {
        if (this.guest) {
          if (this.preview) {
            return true;
          }
          if (onsOverlay.count()) {
            console.warn('Overlay already open', onsOverlay.count());
            return true;
          }
          if (typeof reason == 'object') {
            params = reason.params;
            reason = reason.reason;
          }
          onsOverlay
            .create('signup', {reason: reason, reasonParams: angular.toJson(params)})
            .disableBackdrop()
            .open();
          return true;
        }
        return false;
      };

      User.prototype.goto = function (path) {
        if (!this.guestSignup()) {
          $location.url(path);
        }
      };

      if (new URL(location.hash.substring(1), location.href).searchParams.has('logout')) {
        history.replaceState(null, '', '#/');
        $translate('userPool.logoutSuccess').then((text) => popup().info(text));
      }

      User.prototype.clear = function () {
        if (this.guest) {
          throw Error(ERR_LOGOUT_GUEST);
        }

        history.pushState(null, '', '#/?logout');
        location.reload();
      };

      User.prototype.getAuthUser = function () {
        userPoolApi.getAuthUser().then(function (res) {
          if (res.status == 200) {
            $rootScope.$broadcast('userPool.login.success', res.data);
          }
        });
      };

      User.prototype.getFirstLogin = function () {
        return new Date(this.first_login);
      };

      User.prototype.checkFirstLoginWithinDays = function (days) {
        return this.getFirstLogin().getTime() >= Date.now() - days * 24 * 60 * 60 * 1000;
      };

      User.prototype.getDays = function () {
        const days = Math.ceil((Date.now() - this.getFirstLogin().getTime()) / (24 * 60 * 60 * 1000));
        return days;
      };

      User.prototype.isPremium = function () {
        return this.level >= 10;
      };

      User.prototype.rename = function (nickname) {
        return userPoolApi.rename(nickname).then(function (res) {
          angular.extend($rootScope.user, angular.fromJson(res.data));
          $translate('userPool.nicknameChanged').then(function (text) {
            popup().info(text);
          });
        });
      };

      User.prototype.changeEmail = function (email) {
        return userPoolApi.changeEmail(email);
      };

      User.prototype.getProfile = function () {
        return userPoolApi.getProfile();
      };

      User.prototype.setDescription = function (text) {
        return http().put('/api/userProfile/description', {text}, {dontIntercept: true});
      };

      User.prototype.isInSplittyGroup = function (splitTestId, splitTestGroup) {
        return (
          (this.split_tests || []).find(
            (element) => element.id == splitTestId && element.group == splitTestGroup
          ) !== void 0
        );
      };

      User.prototype.isInGroup = function (groupName) {
        if (!Array.isArray(this.usergroups)) {
          return false;
        }
        return this.usergroups.find((v) => v.name == groupName);
      };

      User.prototype.usesMetricUnits = function () {
        return !this.isInGroup('uk');
      };

      User.prototype.isInternational = function () {
        return this.is_international;
      };

      User.prototype.getsFreeCoinsForDoi = function () {
        return this.doi_reward_group === 'free_coins';
      };

      User.prototype.getFreeCoinsValue = function () {
        return freeCoinsConfig.value;
      };

      User.prototype.hasNiche = function (niche_tags) {
        return Array.isArray(niche_tags) && niche_tags.includes(this.niche);
      };

      User.prototype.trackActivity = throttle(function () {
        if (user.guest) {
          return;
        }
        return http().post('/api/user/track-activity');
      }, 60000);

      User.prototype.activateMailing = async function () {
        if (this.is_mailing_active) {
          return;
        }

        return http().put('api/user/mail-config/active', {}, {dontIntercept: true});
      };

      /**
       * @type {User}
       */
      var user;

      if (authUser) {
        Object.setPrototypeOf(authUser, User.prototype);
        user = /** @type {User} */ (authUser);
        user.guest = false;
      } else {
        user = new User();
      }

      listen('user', (data) => {
        ASSERT: typeguard('', data, EVENTBUS_USER());

        $rootScope.$applyAsync(function () {
          user.coins = data.user.coins;
          user.level = data.user.level;
        });
      });

      $rootScope.$on('userPool.login.success', function (ev, data) {
        /**
         * @type {import('acng/core/service/env').AuthUserData}
         */
        const authUser = angular.fromJson(data);
        user.guest = false;
        if (authUser.id != user.id) {
          Object.assign(user, authUser);
          ctxAuthUser.provide(null, user);
          useLocale(data.preferred_language);
        }
      });

      $rootScope.$on('userPool.login.ready', function () {
        user.getAuthUser();
      });

      $rootScope.$on('userPool.redirect.login', function (ev, path) {
        // TODO check this
        user.redirectPath = path;
      });

      return user;
    },
  ])

  .factory('User', ['user', (user) => user]);

/**
 * @typedef {import('acng/core/service/env').AuthUserData} _AuthUser
 */
