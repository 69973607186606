angular.module('userPool')

  .directive('onswConfirmMail', ['http', 'Widget', 'supportEmail', 'user', (http, Widget, supportEmail, user) => ({
    scope: {
      onDone: '&',
      onError: '&'
    },
    template: `
<form class="box" ng-submit="sendConfirmMail()">
  <p ng-if-start="allowed">{{'userPool.sendConfirmMailAgain' | translate}}</p>
  <label>
    <span>{{'userPool.email' | translate}}</span>
    <input type="email" required ng-model="$parent.email">
  </label>
  <div class="ons-layout buttons">
    <div class="box">
      <span class="ons-item button submit">
        <button type="submit" class="box"><span class="label">{{'userPool.sendConfirmMailAgainBtn' | translate}}</span></button>
      </span>
    </div>
  </div>
  <p ng-if-end class="small">{{'userPool.sendConfirmMailSupport' | translate:transParams }}</p>
  <span ng-if="!allowed" translate="exception.5004" translate-value-mail={{transParams.supportEmail}}></span>
</form>`,
    link: (scope, element) => {

      const widget = new Widget(scope, element, 'ons-form');

      scope.transParams = { supportEmail };

      if (user.guest) {
        scope.allowed = true;
      } else {
        widget.busy(http().get('api/user/requested-confirm-mail'))
          .then(res => {
            scope.allowed = !res.data;
          });
      }

      scope.sendConfirmMail = () => {

        widget.ifNotBusy(() => http().post('/api/user/send-confirm-mail', $.param({ 'email': scope.email }), { dontIntercept: true }))
          .then(() => {
            scope.email = '';
            widget.notify('userPool.confirmationMailSent');
            scope.onDone();
          })
          .catch(err => {
            if (err.data.code && err.data.code == 5004) {
              widget.notify('exception.5004', { mail: scope.transParams.supportEmail });
            } else {
              widget.notify(err);
            }
            scope.onError(err);
          });
      };
    }
  })]);
