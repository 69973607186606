angular.module('moviePool')

  .factory('FreeMovieTile', ['MovieTile', MovieTile => {
    class FreeMovieTile extends MovieTile
    {
      load(scope, freemovie) {
        scope.freemovie = freemovie;
        scope.forceProfileImage = freemovie.use_profile_image;
        scope.click = () => freemovie.goto();
        return super.load(scope, freemovie.id);
      }
      hookname() {
        return 'media';
      }
    }
    return FreeMovieTile;
  }]);
