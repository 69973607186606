/**
 * @module acng/moviePool/config/laforge
 */
import {rootRoute} from 'acng/core/config/routes';
import {moviesRoute} from './routes';
import {on} from '@acng/frontend-bounty';

runLaforge.$inject = ['logger', 'onsLaforgeEvents', 'user'];
/* prettier-ignore */
/**
 * @param {laforge.Logger} logger
 * @param {laforge.Events} onsLaforgeEvents
 * @param {import('acng/userPool/factory/user').User} user -
 */
export function runLaforge(logger, onsLaforgeEvents, user) {
  on(moviesRoute, 'enter', () => on(rootRoute, 'settled', () => {
    if (user.guest || !rootRoute.globals.amateur) {
      return;
    }
    DEBUG: console.debug('laforge movies', rootRoute.globals.amateur);
    const laforgeEvent = new onsLaforgeEvents.MoviesVisit(
      user.uid, //
      /** @type {import('acng/amateurPool/factory/Amateur').Amateur} */ (rootRoute.globals.amateur)
    );
    on(moviesRoute, 'leave', () => logger(laforgeEvent.endVisit()), {once: true});
  }, {once: true}));
}
