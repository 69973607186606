// @ts-nocheck
import {createPromise, createVideo, documentElement, prepend, on, createElement, append} from '@acng/frontend-bounty';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';

import {ImageTile} from 'acng/core/tiles/ImageTile';
import {fskTrans} from 'acng/userPool/0fsk';

import {ctxSpecial} from '../service/special';
import {Special} from '../model/special';

/**
 * @typedef TeaserTileScope
 * @property {Special} [special]
 */

ctxSpecial[CTX_SUBSCRIBE_TAGNAME]('html');

export class TeaserTile extends ImageTile {
  /**
   * @override
   */
  dontSetBg = true;
  observer = false;

  /**
   * @type {HTMLVideoElement | undefined}
   */
  video;

  /**
   * @override
   * @param {angular.IScope & TeaserTileScope} scope
   * @param {import('acng/core/tiles/ImageTile').ImageTileConfig} config
   */
  async load(scope, config) {
    if (this.image) {
      this.image.remove();
    }
    if (this.video) {
      this.video.remove();
      this.video = undefined;
    }

    if (!this.observer) {
      // TODO subscribe
      ctxSpecial[CTX_OBSERVE](documentElement, async (next) => {
        if (next == scope.special || next?.name == scope.special?.name) {
          return;
        }
        scope.special = next ?? undefined;
        this.disabled = !next;
        if (this.observer) {
          scope.$emit('tile.update', this);
        }
      });
      this.observer = true;
    }

    const special = scope.special;

    if (!special) {
      this.disabled = true;
      throw 'no special';
    }

    const imageSrc = await special.getTeaserUrl(config.format);
    
    if (!this.video && special.getVideoUrl) {
      const video = createVideo();
      const source = createElement('source');
      source.src = await special.getVideoUrl(config.format);
      source.type = 'video/mp4';
      const fallback = createElement('source');
      fallback.src = imageSrc;
      fallback.type = 'image/jpg';
      append(video, source, fallback);
      video.autoplay = true;
      video.playsInline = true;
      video.muted = true;
      video.loop = true;
      video.poster = imageSrc;
      setTimeout(() => video.play(), 500);
      this.video = video;
    }

    await super.load(scope, {
      caption: fskTrans(special.caption),
      click: () => special.click?.(),
      src: imageSrc,
    });

    this.disabled = false;

    if (this.video) {
      prepend(this.box, this.video);
    } else {
      prepend(this.box, this.image);
    }
  }

  /**
   * @override
   */
  hookname() {
    return 'teaser';
  }
}
