// @ts-nocheck
import angular from 'angular';
import {useToken} from 'acng/userPool/service/token.js';

angular.module('core')
  .factory('logger', ['$rootScope', 'user', 'onsLaForgeClient', 'LaForge', ($rootScope, user, onsLaForgeClient, LaForge) => {

    const client = new onsLaForgeClient({
      url: LaForge,
      retry: true
    });

    client.setAuthProcedure(() => useToken());
    user.guest ?
      $rootScope.$on('userPool.login.success', () => client.connect()) :
      client.connect();

    return client.log.bind(client);

  }]);
