import {rootRoute} from 'acng/core/config/routes';
import {sedcardIndex} from './routes';
import {on} from '@acng/frontend-bounty';

runLaforge.$inject = ['logger', 'onsLaforgeEvents', 'user'];
/**
 * @param {any} logger -
 * @param {any} onsLaforgeEvents -
 * @param {import('acng/userPool/factory/user').User} user -
 */
export function runLaforge(logger, onsLaforgeEvents, user) {
  on(sedcardIndex, 'enter', () => {
    if (user.guest) return;
    DEBUG: console.debug('laforge profile', rootRoute.globals.amateur);
    const laforgeEvent = new onsLaforgeEvents.ProfileVisit(
      user.uid, //
      sedcardIndex.params.amateur
    );
    on(sedcardIndex, 'leave', () => logger(laforgeEvent.endVisit()), {once: true});
  });
}
