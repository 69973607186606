angular.module('core')

  .directive('_responsiveBox', function () {
    var ro = new ResizeObserver(function (entrys) {
      entrys.forEach(function (entry) {
        angular.element(entry.target).trigger('responsiveresize', [entry.contentRect.width]);
      });
    });

    function link(scope, element) {
      ro.observe(element[0]);
    }

    return {
      restrict: 'CA',
      link: link
    };
  })
  .directive('_responsiveItem', function () {

    var sizes = [
      [0, 'xs', 'min-xs'],
      [576, 'sm', 'min-sm'],
      [768, 'md', 'min-md'],
      [992, 'lg', 'min-lg'],
      [1200, 'xl', 'min-xl']];


    function link(scope, element) {
      var box = null;
      var classes = [].concat.apply([], sizes).filter(function (s) {
        return typeof s == 'string';
      }).join(' ');

      getBox();

      scope.$on('$destroy', function () {
        box.off('responsiveresize', setClasses);
      });


      function getBox() {
        box = element.closest('[responsive-box], .responsive-box');
        if (!box.length) return setTimeout(getBox);
        setClasses(null, box[0].clientWidth);
        box.on('responsiveresize', setClasses);
      }

      function setClasses(ev, width) {
        element.removeClass(classes);
        var classList = [];
        var i, n;
        for (i = 0, n = sizes.length; i < n; i++) {
          if (width >= sizes[i][0]) {
            classList.push(sizes[i][2]);
          } else
          {
            break;
          }
        }
        classList.push(sizes[i - 1][1]);
        element.addClass(classList.join(' '));
      }
    }

    return {
      restrict: 'C',
      link: link
    };

  });
