import {Widget} from '@acng/frontend-stargazer';
import {on, EVENT_CLICK} from '@acng/frontend-bounty/event';

import {userPoolFeature} from '../config/feature.js';
import {activate2fa, deactivate2fa, generate2fa, get2fa} from '../service/http.js';
import {popup} from '@acng/frontend-discovery';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {SecurityCodeElement} from 'acng/core/widgets/securityCode.js';

const MODULE = 'userPool/widget/2fa-setup';

userPoolFeature.defineWidget(
  'onsw-2fa-setup',
  class extends Widget {
    render() {
      ASSERT: typeguard(
        MODULE,
        this.nodes,
        OBJECT({
          activateButton: IS(HTMLButtonElement),
          generateButton: IS(HTMLButtonElement),
          setup: IS(HTMLDivElement),
          complete: IS(HTMLDivElement),
          incomplete: IS(HTMLDivElement),
          qrcode: IS(HTMLImageElement),
          code: IS(SecurityCodeElement),
          deactivateButton: IS(HTMLButtonElement),
          deactivateCode: IS(SecurityCodeElement),
          setupkey: IS(HTMLSpanElement),
        })
      );

      const {activateButton, setup, qrcode, complete, code, deactivateButton, deactivateCode, setupkey} =
        this.nodes;

      (async () => {
        const {active} = await get2fa(this);
        if (active) {
          this.append(complete);
        } else {
          const {svg, key} = await generate2fa(this);
          qrcode.setAttribute('src', 'data:image/svg+xml;base64,' + svg);
          setupkey.innerHTML = key;
          this.append(setup);
        }
      })();

      complete.remove();
      setup.remove();

      on(deactivateButton, EVENT_CLICK, async () => {
        await deactivate2fa(deactivateCode.value, this);
        popup(this).info(await userPoolFeature.translate('2fa.deactivated'));
        complete.remove();
        this.append(setup);
      });

      on(activateButton, EVENT_CLICK, async () => {
        await activate2fa(code.value, this);
        popup(this).info(await userPoolFeature.translate('2fa.activated'));
        setup.remove();
        this.append(complete);
      });
    }
  }
);
