import angular from 'angular';
import {listen} from "acng/core/context/event-bus.js";
import {EVENTBUS_FRIEND_STATUS, typeguard} from '../service/typeguard.js';

angular.module('friend')

  .directive('onswFriendOperations', [() => {

    var opMap = {
      yes: {
        amateur_request: 'accept',
        reset: 'request'
      },
      no: {
        accept: 'reset',
        amateur_request: 'reject',
        user_reject: 'reset',
        user_request: 'reset'
      }
    };

    return {
      scope: {
        amateur: '<'
      },
      template: `
<form class="box" ng-submit="set('yes')">
	<p ng-bind="text | translate:{nickname:amateur.getNickname()}"></p>
	<div class="ons-layout buttons">
		<div class="box">
			<span class="ons-item button submit" ng-show="opMap.yes[status]" ons-icon="::checked">
				<button class="box" type="submit">
					<span class="label" ng-bind="yesLabel | translate"></span>
				</button>
			</span>
			<span class="ons-item button cancel" ng-show="opMap.no[status]" ons-icon="::close">
				<button class="box" type="reset" ng-click="set('no')">
					<span class="label" ng-bind="noLabel | translate"></span>
				</button>
			</span>
		</div>
	</div>
</form>`,
      link: function (scope, element) {

        element.addClass('ons-form');
        scope.opMap = opMap;

        function update(amateur) {
          if (!amateur) {
            element[0].classList.remove('status-amateur_request', 'status-reset', 'status-accept', 'status-user_reject', 'status-user_request');
            scope.status = null;
            return;
          }
          var s = amateur.getFriendStatus();
          scope.status = s;
          scope.text = 'friend.explain_' + s;
          scope.yesLabel = 'friend.' + s + '_' + opMap.yes[s];
          scope.noLabel = 'friend.' + s + '_' + opMap.no[s];
          element.addClass('status-' + s);
        }

        scope.$watch('amateur', update);

        scope.$on('$destroy', listen('friend.status', (data) => {
          ASSERT: typeguard('', data, EVENTBUS_FRIEND_STATUS());
          const f = data.friend;

          if (f.amateur_id == scope.amateur.id) {
            update(scope.amateur);
          }
        }));

        scope.set = function (yesNo) {
          var status = scope.opMap[yesNo][scope.status];
          scope.status = 'busy';
          scope.amateur.setFriendStatus(status)
            .catch(function () {
              update(scope.amateur);
            });
        };

      }
    };
  }]
  );
