angular.module('amateurPool').directive('onswAmateurLabel', ['user', user => {
  return {
    scope: {
      amateur: '<',
      clickAction: '&?'
    },
    template: `<h1 class="box" ng-click="clicki($event)">
                  <span class="name" ng-bind="nickname"></span>
                  <span class="age" ng-bind="age"></span>
                  <span class="from" ng-bind="from"></span>
              </h1>`,
    link: function (scope, element) {

      element.addClass('ons-heading');

      scope.clicki = function ($event) {
        if (!scope.clickAction) {
          scope.amateur.goto();
        } else {
          scope.clickAction();
        }
      };

      scope.$watch('amateur', amateur => {
        if (!amateur) {
          element.addClass('empty');
          return;
        }
        element.removeClass('empty');
        scope.nickname = amateur.getNickname();
        scope.age = amateur.age;
        scope.from = '';
        if (!user.isInternational()) {
          amateur.getLocation()
            .then(from => scope.from = from);
        }
      });
    }
  };
}]);
