import angular from 'angular';
import {getLocale} from 'acng/locale/model/locale.js';
import {useToken, discardToken} from 'acng/userPool/service/token.js';

angular.module('payment')
  .factory('paymentApi', ['http', '$location', 'ARCHIMEDES', 'portal', 'isMobile', '$injector', function (http, $location, ARCHIMEDES, portal, isMobile, $injector) {

    function index(customer) {
      return useToken().then(function (token) {

        return http().get(ARCHIMEDES + '/index', {
          headers: {
            'X-AuthToken': token,
            'X-Customer': customer.customer_id,
            'X-Language': getLocale()
          }
        })
          .catch(function (err) {
            if (!err.data || err.data.error != 1001) throw err;
            discardToken(token);
            return index(customer);
          });

      });
    }

    function methods(customer, country) {
      const params = {
        country: country.iso_code_2
      };

      return useToken().then(function (token) {
        return http().get(ARCHIMEDES + '/methods?' + angular.element.param(params),
          {
            headers: {
              'X-AuthToken': token,
              'X-Customer': customer.customer_id,
              'X-Language': getLocale()
            }
          })
          .catch(function (err) {
            if (!err.data || err.data.error != 1001) throw err;
            discardToken(token);
            return methods(customer, country);
          });
      });
    }

    function products(customer, country, method) {
      const params = {
        country: country.iso_code_2,
        method: method.id
      };

      return useToken().then(function (token) {
        return http().get(ARCHIMEDES + '/products?' + angular.element.param(params),
          {
            headers: {
              'X-AuthToken': token,
              'X-Customer': customer.customer_id,
              'X-Language': getLocale()
            }
          })
          .catch(function (err) {
            if (!err.data || err.data.error != 1001) throw err;
            discardToken(token);
            return products(customer, country, method);
          });
      });
    }


    async function transaction(customer, country, method, packageObj) {
      const baseUrl = `${$location.protocol()}://${$location.host()}/`;

      const params = {
        country: country.iso_code_2,
        method: method.id,
        package: packageObj.id,
        portal_id: portal.id,
        wants_mobile: isMobile ? 1 : 0,
        terms_url: `${baseUrl}?spaRoute=%2Fterms`,
        right_of_withdrawal_url: `${baseUrl}?spaRoute=%2Fterms%23widerrufsbelehrung`
      };

      if (method.request_consent_for_data_storage && method.consent_for_data_storage === true) {
        params.save_payment_information = 1;
      }

      return useToken().then(function (token) {
        return http().post(ARCHIMEDES + '/transactions', angular.element.param(params),
          {
            headers: {
              'X-AuthToken': token,
              'X-Customer': customer.customer_id,
              'X-Language': getLocale(),
              'X-Domain': $location.host(),
              'X-DomainIsSecure': $location.protocol() == 'https'
            },
            dontIntercept: true
          })
          .catch(function (err) {
            if (!err.data) {
              throw err;
            }

            switch (err.data.error) {

              case 1001:
                discardToken(token);
                return transaction(customer, country, method, packageObj);

              case 6010:
                $injector.get('Payback').openOverlay();
                throw err;

            }

            throw err;
          });
      });
    }

    return {
      init: function () {
        return http().get('/api/payment/init');
      },
      customer: function () {
        return http().get('api/payment/customer');
      },
      index: index,
      methods: methods,
      products: products,
      transaction: transaction
    };
  }]
  );
