import {before, cloneNode, createComment, replace} from '@acng/frontend-bounty';

/**
 * @template T
 * @param {Element} master
 * @param {Iterable<T>} iterable
 * @param {(item: T) => void}  callback
 * @param {(item: T, clone: Element) => void} [postprocess]
 */
export function repeatElement(master, iterable, callback, postprocess) {
  const A = createComment();
  const B = createComment();

  replace(master, A, B);

  for (const item of iterable) {
    callback(item);
    const element = cloneNode(master);
    before(B, element);
    postprocess?.(item, element);
  }
}
