import {media} from './env';
import iconsSrc from '../../../basic/img/icons.svg';
import {Map, createSvg} from '@acng/frontend-bounty';

const VERBOSE = false;

const map = new Map<string, [number, number]>(
  Object.entries({
    login: [0, 0 * 40],
    signup: [0, 1 * 40],
    language: [0, 2 * 40],
    notvisible: [0, 3 * 40],
    visible: [0, 4 * 40],
    search: [0, 5 * 40],
    coins: [0, 6 * 40],
    coinsColor: [40, 6 * 40],
    menu: [0, 7 * 40],
    chat: [0, 8 * 40],
    chatColor: [40, 8 * 40],
    user: [0, 9 * 40],
    amateur: [0, 10 * 40],
    gallery: [0, 11 * 40],
    cam: [0, 12 * 40],
    upsize: [0, 13 * 40],
    downsize: [0, 14 * 40],
    noFavorite: [0, 15 * 40],
    favoriteColor: [0, 16 * 40],
    favorite: [40, 16 * 40],
    friendColor: [0, 17 * 40],
    friend: [40, 17 * 40],
    kiss: [0, 18 * 40],
    regard: [0, 19 * 40],
    sexicon: [0, 20 * 40],
    attachment: [0, 21 * 40],
    send: [0, 22 * 40],
    reply: [0, 23 * 40],
    down: [0, 25 * 40],
    up: [0, 26 * 40],
    checked: [0, 27 * 40],
    bought: [0, 29 * 40],
    expired: [0, 30 * 40],
    vipColor: [0, 31 * 40],
    vip: [40, 31 * 40],
    german: [0, 32 * 40],
    english: [0, 33 * 40],
    smileySmile: [0, 34 * 40],
    support: [0, 57 * 40],
    quote: [0, 58 * 40],
    cup: [0, 59 * 40],
    weight: [0, 60 * 40],
    size: [0, 61 * 40],
    eyecolor: [0, 62 * 40],
    plz: [0, 63 * 40],
    age: [0, 64 * 40],
    hair: [0, 65 * 40],
    gender: [0, 66 * 40],
    dating: [0, 67 * 40],
    movie: [0, 68 * 40],
    back: [0, 69 * 40],
    forward: [0, 70 * 40],
    close: [0, 71 * 40],
    toTop: [0, 72 * 40],
    voyeur: [0, 73 * 40],
    hotornot: [0, 74 * 40],
    hot: [0, 75 * 40],
    not: [0, 76 * 40],
    piercing: [0, 77 * 40],
    tattoo: [0, 78 * 40],
    glass: [0, 79 * 40],
    smoker: [0, 80 * 40],
    news: [0, 81 * 40],
    match: [0, 82 * 40],
    match2: [0, 83 * 40],
    online: [0, 84 * 40],
    play: [0, 85 * 40],
    tag: [0, 86 * 40],
    offline: [0, 87 * 40],
    lovense: [0, 88 * 40],
    game: [0, 89 * 40],
    gameColor: [40, 89 * 40],
    mic: [0, 90 * 40],
    pause: [0, 91 * 40],
    stop: [0, 92 * 40],
    trash: [0, 93 * 40],
    info: [0, 94 * 40],
  })
);

const cache = new Map<string, Promise<SVGSVGElement>>();

const source = (async () => {
  const dom = await createSvg(`${media.assets}/${iconsSrc}`);
  document.head.appendChild(dom.getElementsByTagName('style')[0]);
  const gradients = dom.querySelectorAll('radialGradient,linearGradient');
  const globalSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  globalSVG.setAttribute('width', '1');
  globalSVG.setAttribute('height', '1');
  for (const gradient of Array.from(gradients)) {
    gradient.remove();
    globalSVG.append(gradient);
  }
  document.body.append(globalSVG);
  return dom;
})();

export async function getIcon(name: string) {
  let icon = cache.get(name);
  if (!icon) {
    icon = fetchIcon(name);
    cache.set(name, icon);
  }
  return (await icon).cloneNode(true) as SVGSVGElement;
}

const fetchIcon = async (name: string) => {
  const config = map.get(name);
  DEBUG: VERBOSE && console.debug('core/service/icon getIcon', {name, config});
  const dom = await source;
  const lba = dom.querySelector(`#${name}`);
  if (!config || !lba) {
    throw new Error(`core/service/icon getIcon("${name}") does not exist`);
  }
  const i = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  i.classList.add('icon', name);
  i.setAttribute('viewBox', `${config[0]} ${config[1]} 40 40`);
  lba.removeAttribute('id');
  i.innerHTML = lba.outerHTML;
  return i;
};
