/**
 * Formats the given seconds to minutes and seconds (m:ss)
 * @param {number} seconds
 * @returns {string} Formatted seconds
 */
export const formatTime = (seconds) => {
  const roundedSeconds = Math.round(seconds);
  const minutes = Math.floor(roundedSeconds / 60);
  const reminingSeconds = Math.round(roundedSeconds) - 60 * minutes;
  const formattedSeconds = reminingSeconds < 10 ? `0${reminingSeconds}` : `${reminingSeconds}`;
  return `${minutes}:${formattedSeconds}`;
};

