angular.module('userPool')
  .directive('onswNotificationToggle', ['Notificator', Notificator => {

    function link(scope) {

      let notifications = {
        message: {
          general: 'Allgemein neue Nachricht',
          favorite: 'Nachricht von Favorit',
          friend: 'Nachricht von Freund'
        },
        specials: {
          bonus: 'Bonus Aktion',
          game: 'Special'
        },
        livecam: {
          favorite: 'Favorit geht in LC ONLINE',
          friend: 'Freund geht in LC ONLINE'
        },
        online: {
          favorite: 'Favorit geht ONLINE',
          friend: 'Freund geht ONLINE'
        },
        gallery: {
          general: 'Galerie wurde veröffentlicht',
          favorite: 'Galerie wurde von Favorite veröffentlicht',
          friend: 'Galerie wurde von Freund veröffentlicht'
        }
      };

      scope.busy = true;

      scope.$watch('shouldNotify', function (val, old) {
        if (val == old || old == undefined) return;

        scope.busy = true;
        Notificator[val ? 'subscribe' : 'unsubscribe']().then(function () {
          scope.busy = false;
        });
      });

      Notificator.hasSubscription().then(function (val) {
        scope.shouldNotify = !!val;
        scope.busy = false;
      });

    }

    return {
      scope: {},
      link: link,
      template: '<div class="box"><label class="label">Notify me</label><input ng-model="shouldNotify" type="checkbox"></div>'
    };

  }]);
