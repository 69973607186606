import {Widget} from '@acng/frontend-stargazer';
import {query, queryAll} from '@acng/frontend-bounty/query';
import {InputEvent, TAGNAME_INPUT} from '@acng/frontend-bounty/types';
import {EVENT_INPUT} from '@acng/frontend-bounty/event';

import {coreFeature} from '../config/feature.js';

export class SecurityCodeElement extends Widget {
  link() {
    const inputs = queryAll(TAGNAME_INPUT, this);

    for (let i = 0; i < inputs.length; i++) {
      const el = inputs[i];

      el.onkeydown = ({code}) => {
        if (code === 'Backspace' && el.value === '') {
          inputs[Math.max(0, i - 1)].focus();
        }
      };

      el.oninput = () => {
        const [first, ...rest] = el.value;
        el.value = first ?? '';
        const lastInputBox = i === inputs.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          const next = inputs[i + 1];
          next.focus();
          next.value = rest.join('');
          next.dispatchEvent(new InputEvent(EVENT_INPUT));
        }
      };
    }

    query(TAGNAME_INPUT, this)?.focus();
  }

  get value() {
    return [...queryAll(TAGNAME_INPUT, this)].reduce((p, c) => (p += c.value), '');
  }
}

coreFeature.defineWidget('onsw-security-code', SecurityCodeElement);
