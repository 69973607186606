import angular from 'angular';
import {hasFeature} from 'acng/core/service/env';
import {listen} from 'acng/core/context/event-bus.js';
import {FreeMovie as FreeMovieService} from 'acng/moviePool/factory/FreeMovie.js';
import {typeguard, EVENTBUS_STOCK_ITEM} from 'acng/payment/service/typeguard.js';

angular.module('stock', ['core', 'userPool', 'payment'])

  .factory('StockItem', ['http', '$q', 'user', 'bulk', 'HttpCache', '$injector',

    function (http, $q, user, bulk, HttpCache, $injector) {

      const cache = new HttpCache({
        minExpire: 3600 * 1000,
        fnResolve: res => res
      });

      var factoryOfType = {
        Movie: 'Movie',
        Picture: 'Picture',
        PictureSet: 'PictureSet',
        Payttachment: 'payttachment'
      };

      function StockItem(data) {
        this.article_id = data.article_id;
        this.type = data.type;
        this.valid_until = data.valid_until;
        var m;
        if (!(m = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/.exec(this.valid_until))) {
          console.error('Date-Format', this);
          return;
        }
        this.is_valid = new Date(m[1], m[2] - 1, m[3], m[4], m[5], m[6]) >= Date.now();
      }

      StockItem.injectArticle = function (type, article_id) {
        return $injector.get(factoryOfType[type]).get(article_id);
      };

      StockItem.check = function (type, id) {
        if (user.guest) return $q.resolve(null);
        return cache.get(type + '.' + id, () => update(type, id));
      };

      StockItem.list = function (type) {
        return http().get('/api/user/stock?' + $.param({
          filter: JSON.stringify({ type: type })
        }))
          .then(res => res.data.map(item => new StockItem(item)));
      };

      listen('stock-item', (ev) => {
        ASSERT: typeguard('', ev, EVENTBUS_STOCK_ITEM());
        const data = ev.item;
        cache.delete(data.type + '.' + data.article_id);
        cache.get(data.type + '.' + data.article_id, () => update(data.type, data.article_id));
      });

      function update(type, id) {
        return bulk.get(id, 'api/stock/' + type, { field: 'article_id' }).then(function (res) {
          return new StockItem(res);
        }).catch(function () {
          return null;
        });
      }

      return StockItem;
    }]
  )

  .run(['StockItem', '$injector', (StockItem, $injector) => {

    if (!$injector.has('payttachment')) {
      return;
    }

    const Payttachment = $injector.get('payttachment');

    Payttachment.prototype.getStock = function () {
      return StockItem.check('Payttachment', this.id);
    };
  }])

  .run(['StockItem', '$injector', 'http', 'price',

    function (StockItem, $injector, http, price) {

      if (!$injector.has('Movie')) {
        return;
      }

      var Movie = $injector.get('Movie');

      Movie.prototype.getStock = function () {
        return StockItem.check('Movie', this.id);
      };

      Movie.prototype.buy = async function () {
        var movie = this;
        let nickname;
        try {
          nickname = (await this.getAmateur()).getNickname();
        } catch (reason) {
          console.warn('movie::buy get nickname failed with reason', reason);
          nickname = '?';
        }
        const res = await http().post(
          `/api/movie/buy/${movie.id}`,
          {price: price.get(movie)},
          {
            paymentOverlayParams: {reason: 'moviePool.paymentRequired', params: {nickname}},
            signupOverlayParams: {reason: 'moviePool.signupRequired', params: {nickname}},
            headers: {'Content-Type': 'application/json'},
          }
        );
        return res.data;
      };
    }]
  )

  .run(['StockItem', '$injector', 'http', '$q', 'price',

    function (StockItem, $injector, http, $q, price) {

      if (!$injector.has('PictureSet')) {
        return;
      }

      var PictureSet = $injector.get('PictureSet');

      PictureSet.prototype.getStock = function () {
        return $q.all(this.items.map(function (id) {
          return StockItem.check('Picture', id);
        })).then(function (stock) {
          stock = stock.filter(function (res) {
            return res !== null;
          });
          return stock.length > 0 ? stock : null;
        });
      };

      PictureSet.prototype.buy = function (id) {
        var set = this;
        return set.getAmateur()
          .then(amateur => amateur.getNickname())
          .catch(() => '?')
          .then(nickname => {
            return http().post('/api/picture/buy/' + id, { price: price.get(set) }, {
              paymentOverlayParams: { reason: 'picturePool.paymentRequired', params: { nickname } },
              signupOverlayParams: { reason: 'picturePool.signupRequired', params: { nickname } },
              headers: { 'Content-Type': 'application/json' }
            });
          });
      };
    }]
  )

  .run(['StockItem', '$injector',

    function (StockItem, $injector) {

      if (!$injector.has('Picture')) {
        return;
      }

      var Picture = $injector.get('Picture');

      Picture.prototype.getStock = function () {
        return StockItem.check('Picture', this.id);
      };
    }]
  )

  .directive('onsdStock', ['PimpMyRide', 'StockItem', (PimpMyRide, StockItem) => PimpMyRide(
    'Stock', 'type', type => StockItem.list(type))])

  .directive('onswStockFilter', [() => {
    return {
      scope: {
        type: '=?',
        amateurId: '=?'
      },
      template: '<form class="box">' +
    '<label><select ng-options="actual[0] as actual[1] | translate for actual in types" ng-model="type">\n' +
    '<option value="" ng-bind="\'core.showAll\' | translate"></option>\n' +
    '</select></label>' +
    '</form>',
      link: function (scope, element) {

        element.addClass('ons-form');

        scope.types = [];

        if (hasFeature('messenger')) {
          scope.types.push(['Payttachment', 'messenger.payttachment.stock']);
        }

        if (hasFeature('picturePool')) {
          scope.types.unshift(['Picture', 'picturePool.pictures']);
        }

        if (hasFeature('moviePool')) {
          scope.types.unshift(['Movie', 'moviePool.movies']);
        }

      }
    };
  }])

  .directive('onswStockIndicator', ['StockItem', 'user', 'Widget', 'price',

    function (StockItem, user, Widget, price) {
      const FreeMovie = hasFeature('moviePool') ? FreeMovieService : null;
      return {
        scope: {
          type: '@',
          articleId: '@',
          msgValid: '@',
          msgInvalid: '@',
          msgExpired: '@'
        },
        template: '<span class="box" ons-icon="{{ icon }}">' +
    '<span class="label" ng-bind="msg | translate"></span>' +
    '<span class="value" ng-show="!user.guest" ons-icon="::*,coinsColor">{{ price | price }}</span>' +
    '</span>',
        link: function (scope, element) {

          const widget = new Widget(scope, element);
          scope.user = user;

          function update() {
            element.addClass('invalid error');
            element[0].classList.remove('valid', 'expired', 'free', 'fmotd');

            delete scope.price;
            delete scope.icon;
            scope.msg = scope.msgInvalid;

            if (!(scope.type && scope.articleId)) {
              return;
            }

            widget.clear()
              .busy(StockItem.injectArticle(scope.type, scope.articleId)
                .then(function (article) {

                  scope.price = price.get(article);

                  if (!scope.price) {
                    element.addClass('free');
                  }

                  if (scope.type === 'PictureSet') {
                    return [];
                  }

                  let p = [
                    article.getStock()];


                  if (FreeMovie && scope.type === 'Movie') {
                    p.push(FreeMovie.isFree(article.id));
                  }

                  return Promise.all(p);
                })).then(function (data) {
                let [stock, isFree] = data;
                if (stock || isFree) {
                  element.removeClass('invalid');
                  scope.msg = isFree ? scope.msgFree : stock.is_valid ? scope.msgValid : scope.msgExpired;
                  scope.icon = isFree ? 'bought' : stock.is_valid ? 'bought' : 'expired';
                  if (isFree) {
                    element.addClass('fmotd');
                  }
                  element.addClass(isFree || stock.is_valid ? 'valid' : 'expired');
                }
              });
          }

          scope.$watchGroup(['type', 'articleId'], update);
          scope.$on('$destroy', listen('stock-item', update));
          scope.$on('userPool.login.success', update);
          scope.$on('price.changed', update);

        }
      };
    }]
  );
