angular.module('userPool')
  .directive('onswRename', ['Widget', 'user', function (Widget, user) {
    return {
      scope: {
        overlay: '<?'
      },
      templateUrl: '/template/userPool.rename',
      link: function (scope, element) {

        const widget = new Widget(scope, element, 'ons-form');

        scope.send = function () {
          widget.ifNotBusy(() => user.rename(scope.nickname))
            .then(function () {
              if (scope.overlay) scope.overlay.close();
            });
        };
      }
    };
  }]);
