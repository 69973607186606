import {BOOLEAN, STRING, validator} from '@acng/frontend-bounty/typeguard';

export class BuildInfo {
  /**
   * @param {Record<string, unknown>} data
   */
  constructor(data) {
    ASSERT: {
      const assert = validator('ENV["BUILD"]', data);

      this.enableAsserts = assert('enableAsserts', BOOLEAN);
      this.throwFailedAsserts = assert('throwFailedAsserts', BOOLEAN);
      this.enableDebug = assert('enableDebug', BOOLEAN);
      this.enableEventSource = assert('enableEventSource', BOOLEAN);
      this.eventSourceUrl = assert('eventSourceUrl', STRING);

      assert.finish(BuildInfo, this);
    }

    Object.assign(this, data);
  }
}
