// @ts-nocheck
import angular from 'angular';
import {Picture} from '../model/picture.js';

angular
  .module('picturePool')

  .factory('Picture', ['http', 'HttpCache', (http, HttpCache) => {
    const pictures = new HttpCache({
      prefix: '/api/picture/picture/',
      useCdn: true,
      fnError: (err, id) => {
        if (err.status == 404 || err.status == -1) {
          return http().get('/api/picture/picture/blocked/' + id, {
            dontIntercept: true
          });
        }
        throw err;
      },
      fnResolve: res => {
        res.data.price = res.data.set.price;
        delete res.data.set;
        return new Picture(res.data);
      }
    });

    Picture.get = function (id) {
      return pictures.get(parseInt(id));
    };

    Picture.cache = pictures;

    return Picture;
  }])

  .directive('onsdPicture', ['PimpMyRide', 'Picture', (PimpMyRide, Picture) =>
    PimpMyRide(
      'Picture',
      'pictureId',
      id => Picture.get(id),
      id => !isNaN(id) && id
    )]
  );
