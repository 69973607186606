angular
  .module('userPool')

  .directive('onswUserPreferences', [
    'Widget',
    'user',
    'http',
    '$log',
    '$timeout',
    (Widget, user, http, $log, $timeout) => {
      const prefs = loadPrefs();

      function loadPrefs() {
        return http()
          .get('/api/preferences')
          .then((res) => res.data)
          .catch((err) => {
            $log.error(err);
            return $timeout(loadPrefs, 1000);
          });
      }

      return {
        scope: {
          type: '@',
          itemClass: '@',
        },
        template: `
      <form class="box" ng-submit="save()">
        <label ng-repeat="item in prefs" ng-class="::itemClass">
          <span translate="models.AmateurProfile.{{ type }}_list.b{{ item }}"></span>
          <input type="checkbox" ng-click="click(item)" ng-checked="conf.indexOf(item) > -1" />
        </label>
      </form>`,
        link: (scope, element) => {
          const widget = new Widget(scope, element, 'ons-form');

          scope.click = (item) => {
            const i = scope.conf.indexOf(item);
            if (i < 0) {
              scope.conf.push(item);
            } else {
              scope.conf.splice(i, 1);
            }
            scope.save();
          };

          scope.save = () =>
            widget
              .ifNotBusy(() =>
                http().put(
                  '/api/userProfile/preferences',
                  {type: scope.type, binaries: scope.conf},
                  {dontIntercept: true}
                )
              )
              .catch((err) => widget.notify(err));

          widget.busy(
            prefs
              .then((p) => {
                p = p.filter((v) => v.name == scope.type);
                scope.prefs = p[0].preferences;
              })
              .then(() => user.getProfile())
              .then((res) => res.data)
              .then((profile) => {
                element.addClass('ready');
                scope.conf = profile[scope.type];
              })
          );
        },
      };
    },
  ]);
