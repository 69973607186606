/**
 * Indicate that the completion of a defined payment transaction is expected.
 * Then close the payment overlay.
 *
 * @see [Payment-Overlay](../../../html/hook/payment.html)
 *
 * @module
 */

import angular from 'angular';
import {onTransaction} from '../service/event-bus.js';

const MODULE = 'payment/widget/transactionInProgress';
const VERBOSE = true;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

angular.module('payment').directive('onswPaymentTransactionInProgress', [function () {
  return {
    scope: {
      transactionId: '<',
      overlay: '<?'
    },
    template: `
<div class="loading box">
    <div onsw-loader></div>
    <h1>{{'payment.pleaseWait' | translate}}</h1>
    <p>{{'payment.pleaseWaitText' | translate}}</p>
    <p>{{'payment.pleaseWaitTxCancellationNotice' | translate}}</p>
</div>`,
    /**
     * @type {angular.IDirectiveLinkFn<angular.IScope & {
     *   transactionId: number;
     *   overlay: import('acng/core/service/overlay').Overlay | null;
     * }>}
     */
    link: function (scope, element) {
      DEBUG: if (VERBOSE || element[0].hasAttribute('debug')) {
        console.debug(`${MODULE} link`, {scope, element});
      }

      scope.$on('$destroy', onTransaction((tx) => {
        if (tx.id != scope.transactionId) {
          DEBUG: if (VERBOSE || element[0].hasAttribute('debug')) {
            console.debug(`${MODULE} skip`, {scope, element, tx});
          }

          return;
        }

        DEBUG: if (VERBOSE || element[0].hasAttribute('debug')) {
          console.debug(`${MODULE} done`, {scope, element, tx});
        }

        element.addClass('done');
        scope.overlay && scope.overlay.close();
      }));
    }
  };
}]);
