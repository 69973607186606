import {append, clearTimeout, clone, createDiv, setTimeout} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';

import {STYLE_EMPTY_SPINNER} from 'acng/layout';

/**
 * The spinner will appear with a delay of `SPINNER_DELAY` milliseconds.
 */
const SPINNER_DELAY = 500;

const busy = createDiv('core-loader');
append(busy, createDiv(STYLE_EMPTY_SPINNER));

/**
 * @template T
 * @param {Promise<T>} promise
 * @param {HTMLElement} [context] - Define a context for the spinner.
 * @returns {Promise<T>}
 */
export const spinner = async (promise, context) => {
  /**
   * The default http implementation provides a loader element.
   * @type {HTMLDivElement | undefined}
   */
  let loaderElement;

  /**
   * The loader element will be popped up after a delay, if the
   * fetch is still in progress, to prevent permanent UI glitches.
   * @type {number | undefined}
   */
  let timeout = setTimeout(() => {
    timeout = undefined;
    loaderElement = clone(busy);
    popup(context).showModal(
      loaderElement,
      async () => {}, // prevent discovery default animations
      async () => {}
    );
  }, SPINNER_DELAY);

  try {
    return await promise;
  } finally {
    // Remove the loader element after the fetch is done.
    DEBUG: console.assert(
      timeout ? !loaderElement : !!loaderElement,
      'either timeout or overlay have to be present'
    );

    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    }

    if (loaderElement) {
      popup(context).close(loaderElement);
      loaderElement = undefined;
    }
  }
};
