import angular from 'angular';
import {listen} from 'acng/core/context/event-bus.js';
import dummySrc from 'assets/basic/img/noImg.png';
import {typeguard, EVENTBUS_FRIEND_STATUS} from '../service/typeguard.js';

export const friends = {
  statusOfAmateur: {},
  amateursOfStatus: {
    accept: [],
    user_request: [],
    amateur_request: [],
    user_reject: [],
    reset: [],
    cooldown: []
  }
};

angular.module('friend')

  .value('friends', friends)

  .factory('Friend', [
    'user',
    'http',
    'friends',
    /**
     * @param {import('acng/userPool/factory/user').User} user
     * @param {core.Http} http
     * @param {any} friends
     */
    function (user, http, friends) {

      function drop(f) {
        delete friends.statusOfAmateur[f.amateur_id];
        for (var state in friends.amateursOfStatus) {
          var i = friends.amateursOfStatus[state].indexOf(f.amateur_id);
          if (i > -1) {
            friends.amateursOfStatus[state].splice(i, 1);
            friends.amateursOfStatus[state].changed = (friends.amateursOfStatus[state].changed || 0) + 1;
            break;
          }
        }
      }

      function load(f) {
        friends.statusOfAmateur[f.amateur_id] = f.status;
        if (!friends.amateursOfStatus[f.status]) {
          return;
        }
        friends.amateursOfStatus[f.status].push(f.amateur_id);
        friends.amateursOfStatus[f.status].changed = (friends.amateursOfStatus[f.status].changed || 0) + 1;
      }

      function Friend(f) {
        f.amateur_id = `${f.amateur_id}`;
        if (user.id == f.user_id) {
          drop(f);
          load(f);
        }
      }

      Friend.reload = function () {
        return http().get('/api/friends/')
          .then(function (res) {
            res.data.forEach(Friend);
          });
      };

      Friend.listByUser = function (type) {
        return friends.amateursOfStatus[type];
      };

      Friend.listByAmateur = function (amateurId) {
        return http().get('/api/friends/' + amateurId)
          .then(function (res) {
            return res.data;
          });
      };

      Friend.drop = drop;

      return Friend;
    }]
  )

  .run([
    'Friend',
    '$rootScope',
    '$log',
    function (Friend, $rootScope, $log) {
      $rootScope.$on('userPool.login.success', function () {
        Friend.reload()
          .catch(function (err) {
            $log.error('friend list', err);
          });
      });
    }]
  )

  .run(['Friend', '$rootScope', (Friend, $rootScope) => listen('friend.status', (data) => {
    ASSERT: typeguard('', data, EVENTBUS_FRIEND_STATUS());

    $rootScope.$apply(() => Friend(data.friend));
  })])

  .run([
    'http',
    '$q',
    'Amateur',
    'friends',
    'onsOverlay',
    'Friend',
    function (http, $q, Amateur, friends, onsOverlay, Friend) {

      Amateur.prototype.getFriendStatus = function () {
        return friends.statusOfAmateur[this.id] || 'reset';
      };

      Amateur.prototype.setFriendStatus = function (status) {

        var p = $q.defer();
        var overlay;

        function onCloseOverlay(ev) {
          if (ev.overlay === overlay && p) {
            p.reject(null);
          }
        }

        if (status === 'reset' && this.getFriendStatus() === 'accept') {
          overlay = onsOverlay.create('friendConfirmEnd', { p: p, nickname: this.getNickname() });
          overlay.addEventListener('close', onCloseOverlay);
          overlay.open();
        } else {
          p.resolve(null);
        }

        p.promise.finally(function () {
          overlay && overlay.removeEventListener('close', onCloseOverlay);
        });

        return p.promise.then(function () {
          return http().put('/api/friends/' + this.id, { status: status })
            .catch(function (err) {
              if (err.status == 409) {
                Friend(err.data);
                if (err.data.status == status || err.data.status == 'user_' + status) {
                  err.status = 204;
                  err.data = '';
                  return err;
                }
              }
              throw err;
            });
        }.bind(this));
      };

      Amateur.prototype.getFriends = function () {
        return Friend.listByAmateur(this.id);
      };

    }]
  )

  .directive('onsdFriends', ['PimpMyRide', 'friends', '$q', 'FriendTile', (PimpMyRide, friends, $q, FriendTile) => PimpMyRide(
    'Friends', 'type',
    v => {
      friends.amateursOfStatus[v].Tile = FriendTile;
      return $q.resolve(friends.amateursOfStatus[v]);
    },
    v => Object.keys(friends.amateursOfStatus).indexOf(v) > -1
  )])

  .directive('onsdAmateurFriends', ['PimpMyRide', 'Amateur', 'media', (PimpMyRide, Amateur, media) => PimpMyRide(
    'AmateurFriends', ['amateurId', 'amateurNickname'],
    v => (Amateur.isValidId(v[0]) ? Amateur.get(v[0]) : Amateur.getByNickname(v[1]))
      .then(amateur => amateur.getFriends())
      .then(friends => friends.map(friend => ({
        name: friend.user_profile.nickname,
        age: friend.user_profile.age,
        image: friend.user_profile.images ? media.user + friend.user_profile.images.wide[900] : `${media.assets}/${dummySrc}`
      }))),
    v => Amateur.isValidId(v[0]) || Amateur.isValidName(v[1])
  )]);
