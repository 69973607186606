/**
 * @module acng/games
 */
import angular from 'angular';
import {type as t3, name as n3, param as p3} from './factory/games';
import {type as t4, name as n4, param as p4} from './widgets/game';
import onswGames, {gamesComponent} from './widgets/games';
import {name as n7, param as p7} from './widgets/link';
import onswGameSuggestions, {suggestionsComponent} from './widgets/suggestions';
import {name as n9, param as p9} from './widgets/user-stats';
import {runMiddleware} from './config/routes';
import {type as t11, param as p11} from './run/amateur';
import {extendSlideshow} from './decorator/slideshow';
import {hasFeature} from 'acng/core/service/env';

const featureName = 'games';

if (hasFeature(featureName)) {
  extendSlideshow();
}

angular
  .module(featureName, ['core', 'userPool', 'amateurPool'])
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    $translatePartialLoaderProvider => {
      $translatePartialLoaderProvider.addPart(featureName);
    },
  ])
  [t3](n3, p3)
  [t4](n4, p4)
  .component(onswGames, gamesComponent)
  .component(n7, p7)
  .component(onswGameSuggestions, suggestionsComponent)
  .component(n9, p9)
  .run(runMiddleware)
  [t11](p11);
