import {
  EVENT_CLICK,
  closestData,
  createDiv,
  on,
  removeNode,
  setDataAttribute,
  setText,
  whenAll,
} from '@acng/frontend-bounty';
import {t} from 'acng/locale/config/translate.js';
import {coreFeature} from '../../config/feature.js';
import {typeguard} from '@acng/frontend-bounty/assert';
import {repeatElement} from 'acng/layout/service/repeat.js';
import {NODES} from '@acng/frontend-stargazer';

const MODULE = 'core/service/backend/validation-messages';

const TEMPLATE = () =>
  NODES({
    fail: HTMLElement,
    alternatives: HTMLElement,
    alternative: HTMLElement,
    message: Text,
    suggestion: Text,
  });

/**
 * @param {HTMLInputElement} inputElement
 * @param {import('./validation.js').ApiValidatorResponseData} params
 * @param {(suggestion: string) => void} [suggestionClick]
 * @returns {Promise<HTMLDivElement>}
 */
export const setMessage = async (inputElement, params, suggestionClick) => {
  const [template, translations] = await whenAll([
    coreFeature.render('api-validator'),
    whenAll((params.rules ?? []).map((rule) => t(`validation.frontend.${rule.split(':')[0]}`))),
  ]);

  return template.toElement(createDiv(), {}, (nodes) => {
    ASSERT: typeguard(MODULE, nodes, TEMPLATE());

    const {fail, alternatives, alternative, message, suggestion} = nodes;

    repeatElement(fail, params.failed ?? [], (i) => setText(message, translations[i]));
    if (!params.suggestions || !params.suggestions.length) {
      removeNode(alternatives);
    } else {
      on(alternatives, EVENT_CLICK, ({target}) =>
        closestData(
          target,
          'suggestion',
          (value) => {
            inputElement.value = value;
            suggestionClick?.(value);
          },
          alternatives
        )
      );
      repeatElement(alternative, params.suggestions, (str) => {
        setDataAttribute(alternative, 'suggestion', str);
        setText(suggestion, str);
      });
    }
  });
};
