import angular from 'angular';
import {stargazer} from '@acng/frontend-stargazer';
import {Error, HTMLElement, append, clear, cloneNode, getAttribute} from '@acng/frontend-bounty';

import {portal} from '../service/env';
import {spinner} from '../service/spinner';
import {coreFeature} from '../config/feature';
import {inject, $compile} from '../service/ng.js';

const SCOPE = Symbol();

coreFeature.defineElement(
  'onsw-template',
  class extends HTMLElement {
    connectedCallback() {
      connect(this, (this[SCOPE] = inject('$rootScope').$new()));
    }

    disconnectedCallback() {
      this[SCOPE]?.$destroy();
    }
  }
);

/**
 * @param {HTMLElement} host
 * @param {angular.IScope & {portal?: typeof portal}} $scope
 */
const connect = async (host, $scope) => {
  const [feature, name] = (() => {
    if (host.dataset.feature) {
      return [host.dataset.feature, host.dataset.name];
    }
    return (getAttribute(host, 'template') ?? '').split('.');
  })();

  clear(host);
  if (!feature || !name) {
    throw Error();
  }

  const template = await spinner(stargazer.lookup(feature, name), host);
  append(host, cloneNode(template.content));
  $scope.portal = portal;
  inject($compile)(angular.element(host).children())($scope);
  $scope.$digest();
};
