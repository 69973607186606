angular.module('core')

  .directive('onsdMix', () => {

    return {
      transclude: 'element',
      scope: {
        listA: '<',
        listB: '<',
        onMixDone: '&'
      },
      link: scope => {

        let res = [];

        scope.$watchGroup(['listA', 'listB'], list => {

          res.length = 0;

          const a = list[0],b = list[1];
          let i,j,count = 0;

          if (!Array.isArray(a)) {
            return;
          }

          res = a.slice();

          if (Array.isArray(b)) {
            for (i = res.length - 1; i >= count; i--) {
              j = b.indexOf(res[i]);
              if (j >= 0) {
                res.splice(i, 1);
                res.unshift(b[j]);
                count++;
              }
            }
          }

          scope.onMixDone({ item: res });
        });
      }
    };
  })

  .factory('PimpMyRide', ['$animate', '$log', '$location', ($animate, $log, $location) => {

    const states = ['reset', 'invalid', 'done', 'error'];

    return (name, watch, get, validate) => {

      const s = {};

      if (typeof watch === 'string') {
        s[watch] = '<';
      } else if (Array.isArray(watch)) {
        watch.forEach(val => s[val] = '<');
      }

      states.forEach(state => {
        s['on' + name + state.charAt(0).toUpperCase() + state.slice(1)] = '&';
      });

      if (typeof get !== 'function') {
        throw 'RIDE BAD GETTER';
      }

      if (typeof validate !== 'function') {
        validate = () => true;
      }

      return {
        scope: s,
        transclude: 'element',
        link: (scope, element, attrs, ctrl, $transclude) => {
          let childScope = null,block = null,multishot = false;
          function toggle(state, param) {
            scope['on' + name + states[state].charAt(0).toUpperCase() + states[state].slice(1)](param);
            scope.$applyAsync();
            if (!attrs['show' + name] || attrs['show' + name].indexOf(states[state].charAt(0)) === -1) {
              if (childScope) {
                childScope.$destroy();
                childScope = null;
                if (block) {
                  $animate.leave(block);
                  block = null;
                }
              }
              return;
            }
            if (!childScope) {
              $transclude((clone, newScope) => {
                childScope = newScope;
                block = clone;
                $animate.enter(clone, element.parent(), element);
              });
            } else {
              block.removeClass(states);
            }
            block.addClass(states[state]);
          }
          scope.$on('$destroy', () => toggle(0));
          let unreg = scope[typeof watch === 'string' ? '$watch' : '$watchGroup'](watch, v => {
            toggle(0);
            if (!validate(v)) {
              toggle(1);
            } else {
              get(v, scope, element).then(item => toggle(2, { item }))
                .catch(err => {
                  $log.error('RIDE', name, v, err);
                  toggle(3, { err, redirect: () => $location.url('/') });
                });
              if (!multishot) {
                if (attrs['oneshot' + name]) {
                  unreg();
                } else {
                  multishot = true;
                }
              }
            }
          });
        }
      };
    };
  }]);
