import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {connectPopupController} from '@acng/frontend-discovery';
import {hide, setText, show, toggleClass} from '@acng/frontend-bounty';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {messengerFeature} from '../config/feature.js';
import {ctxMessageDraft, getMessageDraft, provideComposer} from '../context/message-draft.js';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {connectedCallback} from '@acng/frontend-bounty/dom/custom.js';

const MODULE = 'messenger/widget/message-composer';

/**
 * @module
 * [Template]{@link ../../../html/messenger/onsw-message-composer.html}
 */

defineCustomWidget(
  messengerFeature,
  'onsw-message-composer',
  class extends Widget {
    static consumables = [ctxAmateur, ctxMessageDraft];

    [connectedCallback]() {
      ASSERT: typeguard('', this.parentElement, IS(HTMLElement));
      const p = this.parentElement;

      connectPopupController(p);
      ctxAmateur[CTX_OBSERVE](this, (amateur) => provideComposer(p, amateur));
      super[connectedCallback]();
    }

    /**
     * Two nodes are expected in the template.
     *
     * - attachment
     *   Show the `#attachment` element while an attachment is assigned to the composer.
     * - attachmentName
     *   Show the "name" of the attachment at the `${attachmentName}` node.
     */
    render() {
      ctxMessageDraft[CTX_OBSERVE](this, (message) => {
        if (!message) {
          return;
        }

        const {attachment, voice} = message;
        ASSERT: typeguard(
          MODULE,
          this.nodes,
          OBJECT({
            attachment: IS(HTMLElement),
            attachmentName: IS(Text),
          })
        );

        const {attachment: element, attachmentName: name} = this.nodes;

        toggleClass(this, 'voice', !!voice);
        if (attachment && !voice) {
          setText(name, attachment.name);
          show(element);
        } else {
          setText(name, null);
          hide(element);
        }
      });
    }

    removeAttachment() {
      const message = getMessageDraft(this);
      if (message) {
        message.attachment = null;
      }
    }
  }
);
