import {createGlobalContext} from '@acng/frontend-relativity';
import {LivecamSession} from '../model/session.js';

const MODULE = 'livecam/context/session';

/**
 * The `Livecam` the `User` is currently watching.
 *
 * @type {?LivecamSession}
 */
export let livecamSession = null;

/**
 * Observe which `Livecam` the `User` is currently watching.
 *
 * @type {import('@acng/frontend-relativity').Context<?LivecamSession>}
 */
export const ctxLivecamSession = createGlobalContext(livecamSession);

/**
 * Set the current livecam show.
 *
 * @param {LivecamSession | null} session
 */
export const setGlobalLivecamSession = (session) => {
  DEBUG: console.info(MODULE, {session});
  livecamSession = session;
  ctxLivecamSession.provide(null, session);
};
