/**
 * @module livecam/config/routes
 */
import {userRoute} from 'acng/userPool/config/routes';
import {middleware, path, index, alias, validate} from '@acng/frontend-voyager';

userRoute.createMany(
  [
    path('/livecamShow/{livecamShow}'), //
    index,
    alias('livecamShow'),
  ],
  [
    path('/livecams/{livecams}'), //
    validate(({livecams}) => /^(all|online|new|toycontrol)$/.test(/** @type {string} */ (livecams))),
    alias('livecams'),
  ],
  [
    path('/livecams'),
    index,
    middleware(() => {
      DEBUG: console.warn('livecam/config/routes deprecated route - redirect');
      location.hash = '/livecams/online';
    }),
  ]
);
