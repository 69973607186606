angular.module('amateurPool')
  .factory('country', ['http', function (http) {

    return {
      all: function () {
        return http().get('/api/country/all', { cache: true });
      }
    };

  }]);
