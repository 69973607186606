import {assert, RECORD, ARRAY, STRING, fail, TUPLE, ENUM} from '@acng/frontend-bounty/typeguard';
import {FSK_LEVEL} from 'acng/userPool/fsk.js';

const POSSIBLE_FORMATS = /** @type {const} */ ([
  '300',
  '400',
  '500',
  '600',
  '800',
  '1000',
  '1200',
  'large',
  'small',
  'svga',
  'xsignup',
  'mini',
  'th',
  'thumb',
  'fsk',
  'status',
  'pw',
  'avatar',
  'sm',
  'medium',
  'preview',
  'portrait',
  'xl',
  'xxl',
  'xga',
]);

/**
 * @typedef {typeof POSSIBLE_FORMATS[number]} AmateurProfileImageFormat
 */

/**
 * @typedef {{
 *   [key in AmateurProfileImageFormat]: Array< [import("acng/userPool/fsk").FskLevel, string] >
 * }} AmateurProfileImages
 */

export const AMATEUR_PROFILE_IMAGES = RECORD(ENUM(POSSIBLE_FORMATS), ARRAY(TUPLE(FSK_LEVEL, STRING)));
