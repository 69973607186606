/**
 * @module acng/core/factory/flowplayer
 * @typedef {import('video.js').default.Tech.SourceObject[]} Sources
 */
import {setAttribute, addClass} from '@acng/frontend-bounty';
/**
 * @param {HTMLVideoElement} element - Append the videoplayer to that HTMLElement
 * @param {Sources} sources - Enable these sources for the Player
 * @param {boolean} autoplay - Start the Video when loaded
 * @returns {Promise<import('video.js').VideoJsPlayer>} -
 */
export async function player(element, sources, autoplay = true) {
  setAttribute(element, 'controls', '');
  addClass(element, 'video-js', 'vjs-default-skin', 'vjs-fluid', 'vjs-16-9');

  const {default: videojs} = await import('video.js');

  const vjs = videojs(element, {
    autoplay: autoplay,
    sources,
  });

  for (let type of ['play', 'ended', 'pause', 'abort', 'error']) {
    vjs.on(type, e => {
      DEBUG: console.debug('core/factory/flowplayer event', e);
      emit(type, e);
    });
  }
  vjs.on('ended', () => {
    if (vjs.isFullscreen()) {
      vjs.exitFullscreen();
    }
  });

  return vjs;
}
/**
 * @type {Record<string, Function[]>}
 */
const handlers = {};
/**
 * @param {string} type -
 * @param {Function} handler -
 */
export function on(type, handler) {
  if (typeof handler !== 'function') return;
  if (!handlers[type]) {
    handlers[type] = [];
  }

  handlers[type].push(handler);
}
/**
 * @param {string} type -
 * @param {any} data -
 */
function emit(type, data) {
  for (let handler of handlers[type] || []) {
    handler(data);
  }
}
/**
 * @param {any} manifest -
 * @returns {Sources | undefined} -
 */
export function mapSources(manifest) {
  if (typeof manifest !== 'object') {
    console.warn('core/video mapSources is not an object', {manifest});
    return;
  }
  return Object.values(manifest)
    .filter(v => v.header && v.url)
    .map(v => ({type: v.header, src: v.url}));
}
