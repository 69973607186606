/**
 * @module core/service/overlay
 */
import angular, {IScope} from 'angular';
import {on, body, append, remove} from '@acng/frontend-bounty';
import {onsHistory} from '../factory/history';
import {RootScope} from 'acng/zz-app';

const factoryName = 'onsOverlay';

export default factoryName;

export interface Overlay extends EventTarget {
  open(): Overlay;
  show(): void;
  close(): void;
  disableHistory(): Overlay;
  css: string;
  disableBackdrop(): Overlay;
  injectElement?: string;
  params: Record<string, unknown>;
  setPositionMain(): Overlay;
}

export interface OverlayConstructor {
  new (template: string, params?: Record<string, unknown>): Overlay;
  get(name: string): Overlay | undefined;
  isOpen(name: string): boolean;
  create(temlate: string, params?: Record<string, unknown>): Overlay;
  count(): number;
}

export const overlayFactory = ['$rootScope', '$compile', '$animate', factory];

type HistoryEntry = {
  template: string;
  params: unknown;
};

type Scope = IScope & {hookData?: Record<string, unknown>};

function factory(
  $rootScope: RootScope, //
  $compile: angular.ICompileService,
  $animate: angular.animate.IAnimateService
): OverlayConstructor {
  const overlays: Overlay[] = [];

  class Overlay extends EventTarget implements Overlay {
    template: string;
    params: Record<string, unknown>;
    closable: boolean;
    backdropClose: boolean;
    history: boolean;
    scope: Scope | null;
    element: JQLite | null;
    css: string;
    injectElement: string | undefined;

    constructor(template: string, params?: Record<string, unknown>) {
      super();
      this.template = template;
      this.params = params || {};
      this.closable = true;
      this.backdropClose = true;
      this.history = true;
      this.scope = null;
      this.element = null;
      this.css = '';
    }

    open() {
      DEBUG: console.debug('core/service/overlay open', {overlay: this});
      if (this.closable && this.history) {
        try {
          const items = overlays.filter(o => o.history).map(o => ({
            template: o.template,
            params: o.params,
          }));
          if (!onsHistory.raw('overlay')) onsHistory.replace('overlay', items);
          items.push({
            template: this.template,
            params: this.params,
          });
          onsHistory.push('overlay', items);
        } catch (err) {
          console.warn('overlay history exception', err);
          this.history = false;
        }
      }
      this.show();
      return this;
    }

    show() {
      if (this.injectElement) {
        this.element = angular.element(`<aside class="${this.css}">${this.injectElement}</aside>`);
        this.element.addClass('ons-layout animate overlay ' + this.css);
      } else {
        this.element = angular.element(
          '<aside class="animate overlay ' + this.css + '" onsw-hook hookname="' + this.template + '" hook-data="hookData" extract-data></aside>'
        );
      }
      if (this.backdropClose) {
        this.element.on('click', ev => {
          if (!this.element || !this.scope) {
            throw new Error('something went wrong');
          }
          if (this.element[0] === ev.target) {
            this.scope.$apply(() => this.close());
          }
        });
      }
      if (this.template === 'specialOverlay') {
        this.element.css('z-index', '1');
      }
      this.scope = $rootScope.$new(true);
      this.scope.hookData = {overlay: this};
      Object.assign(this.scope.hookData, this.params);
      if (this.injectElement) {
        Object.assign(this.scope, this.scope.hookData);
      }
      overlays.push(this);
      append(body, this.element[0]);
      this.element[0].animate([{opacity: '0'}, {opacity: '1'}], {duration: 500}).finished;
      $compile(this.element)(this.scope);
    }

    async hide() {
      overlays.splice(overlays.indexOf(this), 1);
      if (!this.element || !this.scope) {
        throw new Error('something went wrong');
      }
      const element = this.element[0];
      await element.animate([{opacity: '1'}, {opacity: '0'}], {duration: 500}).finished;
      remove(element);
      this.dispatchEvent(new CustomEvent('close', {detail: {overlay: this}}));
      this.scope.$destroy();
      this.scope = null;
      this.element = null;
    }

    goto(hookname: string, params: Record<string, unknown>) {
      this.hide();
      this.template = hookname;
      this.params = params;
      this.open();
    }

    disableBackdrop() {
      this.backdropClose = false;
      return this;
    }

    disableCloseButtonInToolbar() {
      this.closable = false;
      this.history = false;
      return this;
    }

    disableHistory() {
      this.history = false;
      return this;
    }

    setPositionMain() {
      this.css = 'fixed-main';
      return this;
    }

    close() {
      this.hide();
      if (this.closable && this.history) {
        const items = overlays
          .filter(o => o.history)
          .map(
            o =>
              ({
                template: o.template,
                params: o.params,
              } as HistoryEntry)
          );
        onsHistory.push('overlay', items);
      }
    }

    static create(tpl: string, params?: Record<string, unknown>) {
      return new Overlay(tpl, params);
    }

    static count() {
      return overlays.length;
    }

    static isOpen(hookName: string) {
      return !!this.get(hookName);
    }

    static get(hookName: string) {
      return overlays.find(overlay => overlay.template === hookName);
    }
  }

  on(onsHistory, 'overlay', ev => {
    const state = (ev as CustomEvent).detail.state as HistoryEntry[];
    overlays.forEach(o => {
      if (!state.find(h => h.template == o.template)) {
        $rootScope.$apply(() => o.hide());
      }
    });
    state.forEach(h => {
      if (!overlays.find(o => o.template == h.template)) {
        let o = new Overlay(h.template, h.params as Record<string, unknown>);
        $rootScope.$apply(() => o.show());
      }
    });
  });

  return Overlay;
}
