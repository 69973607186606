import SockJS from 'sockjs-client';

angular.module('ons-LaForge-client')
  .factory('onsLaForgeClient', ['onsLaforgeEvents', onsLaforgeEvents => {
    class Client {

      constructor(options) {
        if (!options.url || typeof options.url !== 'string') {
          throw new Error('No url given');
        }

        this.config = {};
        this.authenticated = false;
        this.config.url = options.url;
        this.config.retry = options.retry === true || !!options.retryInterval;
        this.config.retryInterval = options.retryInterval || 5000;
      }

      connect(token) {

        this.sock = new SockJS(`${this.config.url}/log`);

        this.sock.onopen = () => this.authenticate(token);

        if (this.config.retry) {
          this.sock.onclose = () => setTimeout(() => this.connect(token), this.config.retryInterval);
        }
      }

      setAuthProcedure(fn) {
        this.getToken = fn;
      }

      send(data) {
        if (!this.sock || this.sock.readyState !== 1 || !this.authenticated) {
          setTimeout(() => this.send(data), 1000);
          return;
        }
        this.sock.send(JSON.stringify(data));
      }

      authenticate() {
        this.getToken().then(token => {
          this.sock.send(JSON.stringify({ token: token }));
          this.authenticated = true;
        });
      }

      log(event) {
        if (!(event instanceof onsLaforgeEvents.LogEvent)) {
          throw new Error('No Log Event given');
        }

        this.send(event.getDTO());
      }
    }

    return Client;
  }]);
