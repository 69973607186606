/**
 * @module amateurPool/widgets/amateur-list-small
 *
 * What it does:
 * - Show an icon according to the amateur.
 * What it does NOT:
 * - Show loading animation.
 */
import {HTMLElement} from '@acng/frontend-bounty/types';
import {append, createDiv, createSpan, createTemplate, loadImage} from '@acng/frontend-bounty';
import {onClick, prepend, remove, setText} from '@acng/frontend-bounty';
import {createPerceptiveHTML} from '@acng/frontend-discovery';
import {createStream} from '@acng/frontend-relativity';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';

import {inject} from 'acng/core/service/ng';
import {getIcon} from 'acng/core/service/icon';
import {asset} from 'acng/core/service/env';
import dummySrc from 'assets/basic/img/f_320.png';

import {amateurPoolFeature} from '../config/feature.js';
import {ctxOnline} from '../service/online.js';
import {getAmateurVisits} from 'acng/amateurPool/service/profile-visit.js';

const disconnect_ = Symbol();
const VERBOSE_DEBUG = false;

/** @type {import('acng/amateurPool/factory/Amateur').AmateurConstructor} */
let Amateur;

amateurPoolFeature.defineElementLoader('onsw-amateur-list-small', async () => {
  Amateur = inject('Amateur');
  ctxOnline.subscribeClassName('layout-toolbar toolbar-list');
  return class extends HTMLElement {
    connectedCallback() {
      this[disconnect_] = connect(this);
    }
    disconnectedCallback() {
      this[disconnect_]?.();
    }
  };
});

/**
 * @param {HTMLElement} host
 */
const connect = (host) => {
  const stream = createStream(/** @type {string[]} */([]));

  const perceptive = createPerceptiveHTML(host, stream, async (amateurId) => {
    /** @type {SVGSVGElement | undefined } */
    let activeIcon;
    const amateur = await Amateur.get(amateurId);
    const bar = createDiv('layout-toolbar', 'toolbar-list');
    const label = createSpan('title');
    const [image] = await Promise.all([
      loadImage(amateur.getImageUrl('800'), asset(dummySrc)),
      ctxOnline[CTX_OBSERVE](bar, async (isOnline) => {
        const iconName = (() =>
          amateur.livecamIsOnline()
            ? amateur.getShowIcon('normal')
            : isOnline(amateurId)
              ? 'online'
              : false)();
        if (iconName) {
          const svg = await getIcon(iconName);
          if (activeIcon) {
            activeIcon.replaceWith(svg);
          } else {
            prepend(bar, svg);
          }
          activeIcon = svg;
        } else if (activeIcon) {
          remove(activeIcon);
          activeIcon = undefined;
        }
      }),
    ]);
    setText(label, amateur.getNickname());
    append(bar, label);
    onClick(image, () => amateur.goto());
    return createTemplate('', image, bar);
  });

  DEBUG: if (VERBOSE_DEBUG) perceptive.enableDebug(`${host.localName}:${host.dataset.name ?? 'ERROR'}`);
  if (host.dataset.name == 'visits') {
    getAmateurVisits().then((visits) =>
      visits.observe(host, (ids) => {
        stream.streamReset(ids.slice());
        perceptive.connect();
      })
    );
  } else {
    inject('AmateurFilter')
      .get(host.dataset.name ?? '')
      .list(20000)
      .then((res) => {
        stream.streamReset(res.slice());
        perceptive.connect();
      });
  }

  return () => perceptive.disconnect();
};
