angular.module('amateurPool')
  .directive('onswAmateurNicknameSearch', ['$location', function ($location) {

    return {
      scope: {
        focus: '@'
      },
      link: link,
      templateUrl: 'template/amateurPool.nicknameSearch'
    };

    function link(scope, element) {

      element.addClass('ons-form');

      scope.$on('$locationChangeSuccess', getNickname);
      getNickname();

      scope.submit = function () {
        if (!scope.nickname) return;
        var path = $location.path();
        if (!path.match(/^\/amateurs\/.*/))
          path = '/amateurs/all';
        $location.path(path).search({ nickname: scope.nickname });
        element.find('input').first().blur();
      };

      scope.reset = function () {
        scope.nickname = '';
      };

      function getNickname() {
        scope.nickname = $location.search().nickname || '';
      }

      scope.$watch('focus', function (focus) {
        if (focus) {
          element.find('input').first().focus();
        }
      });

    }
  }]);
