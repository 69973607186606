import {FreeMovie} from 'acng/moviePool/factory/FreeMovie.js';
angular.module('rating')

  .run(['Rating', '$injector', '$q', 'user', 'payment', (Rating, $injector, $q, user, payment) => {
      
    if (!$injector.has('Movie')) {
        return;
      }

      var Movie = $injector.get('Movie');

      Movie.prototype.rateable = function () {

        if (this.blocked) {
          return $q.reject('rating.notRateable');
        }

        return $q.all([FreeMovie.isFree(this.id), this.getStock()])
        .then(res => {
          let [free, stock] = res;

          if (stock) {
            return true;
          }

          if (free) {
            if (!user.isPremium()) {
              payment.overlay('rating.freeUser');
              throw 'rating.freeUser';
            }
            return true;
          }

          throw 'rating.notInStock';
        });
      };

      Movie.prototype.getRating = function () {
        return Rating.get(this.set_id, 'movie');
      };

      Movie.prototype.rate = function (rating) {
        return Rating.rate(this.set_id, 'movie', rating);
      };
      
    }]);
