import angular from 'angular';
import {LivecamSession} from "../model/session.js";

angular.module('livecam')
  .directive('onswLivecamTimer', ['user', '$timeout', function (user, $timeout) {

    function link(scope) {

      scope.isPremium = user.isPremium();

      scope.$watch('token', token => {
        const session = LivecamSession.get(token);
        scope.start = Date.now() / 1000;
        scope.duration = session.duration;
        scope.roomType = session.cam.showType;
      });

      scope.timeLeft = function () {
        const left = Math.round(scope.duration - (Date.now() / 1000 - scope.start));
        const min = Math.floor(left / 60);
        const sec = (left - min * 60).toString().padStart(2, '0');
        return `${min}:${sec}`;
      };

      run();
      function run() {
        $timeout(run, 500);
      }

    }

    return {
      scope: { token: '=' },
      link: link,
      template:
    `<div class="box" ng-if="!isPremium && roomType == 'event'">
    <div class="text-box">
      <div class="seconds" translate="livecam.freeshow.left" translate-value-time="{{timeLeft()}}"></div>
      <div class="msg" translate="livecam.freeshow.hint"></div>
    </div>
    <div onsw-open-overlay class="button" label="payment.buyCoins" hookname="payment"></div>
</div>`
    };

  }]);
