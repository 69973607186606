import angular from 'angular';
import {useToken, discardToken} from '../service/token.js';

angular.module('userPool')

  .run(['Notificator', '$rootScope', function (Notificator, $rootScope) {

    $rootScope.$on('userPool.login.success', function () {
      Notificator.getSubscription()
        .then(res => console.log('already subscribed', res))
        .catch(possible => {
          if (!possible) return;

          if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'notifications' })
              .then(res => {
                if (res.state === 'granted' || res.state === 'prompt') {
                  return Notificator.subscribe();
                }
              });
          } else
          {
            Notificator.subscribe();
          }
        });
    });
  }])
  .factory('Notificator', ['$q', '$timeout', '$rootScope', 'SWFile', 'OBRIEN', 'onsOverlay', 'VERSION', 'storage', ($q, $timeout, $rootScope, SWFile, OBRIEN, onsOverlay, VERSION, storage) => {

    let vapidKey, requested, swregistration;

    try {
      swregistration = navigator.serviceWorker.register(SWFile);
    } catch (e) {
      console.log('no service worker allowed', e);
    }

    try {
      requested = sessionStorage.getItem('notification-requested') === '1';
    } catch (e) {
      requested = false;
    }

    if (swregistration) {

      let lastVersion = storage.driver('persistent').get('last-version');
      if (VERSION != lastVersion) {
        swregistration.then(reg => reg.update());
      }
      storage.driver('persistent').set('last-version', VERSION);

      $rootScope.$on('userPool.login.success', () => {
        navigator.serviceWorker.addEventListener('message', ev => {
          let action = JSON.parse(ev.data);
          $rootScope.$broadcast(action.name, action.id + '');
        });
      });
    }

    return { getSubscription, subscribe, hasSubscription, requestPermission, unsubscribe, decline: setRequested, isRequested };

    function hasSubscription() {
      if (!swregistration) return $q.reject();
      return getSubscription().then(sub => !!sub);
    }

    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

      const rawData = window.atob(base64);
      return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
    }

    function getSubscription() {
      if (!swregistration) return $q.reject(false);

      return swregistration.then(reg => {
        return reg.pushManager.getSubscription().then(sub => {
          if (!sub) return $q.reject(true);
          return sub;
        });
      })
        .catch(res => $q.reject(res === true));
    }

    function getVapidKey() {

      if (vapidKey) return vapidKey;
      return vapidKey = useToken().then(token => {
        return fetch(OBRIEN + '/vapidkey', { headers: { 'x-token': token } })
          .then(res => {
            if (!res.ok) {
              vapidKey = null;
              if (res.status == 401) {
                discardToken(token);
                return $timeout(getVapidKey, 1000);
              }
              throw new Error('failed to get vapid key');
            }
            return res.json();
          });
      });
    }

    function subscribe() {
      setRequested();

      if (!swregistration) return $q.reject(false);

      return swregistration.then(reg => {
        return getVapidKey().then(key => {
          return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(key)
          })
            .then(sub => postSubscription(sub, key));
        });
      });
    }

    function unsubscribe() {
      if (!swregistration) return $q.reject();

      return getSubscription().then(sub => {
        if (!sub) return true;
        return sub.unsubscribe().then(success => {
          if (!success) return false;

          return useToken().then(token => {
            return fetch(OBRIEN + '/subscription', {
              method: 'DELETE',
              body: JSON.stringify(sub),
              headers: {
                'content-type': 'application/json',
                'x-token': token
              }
            });
          });
        });
      });
    }

    function setRequested() {
      requested = true;
      try {
        sessionStorage.setItem('notification-requested', '1');
      } catch (e) {
      }
    }

    function requestPermission(amateurId) {
      if (!onsOverlay.count()) {
        return onsOverlay.create('notificationRequest', { hookData: amateurId })
          .disableBackdrop()
          .open();
      }
    }

    function isRequested() {
      return requested;
    }

    function postSubscription(sub, key) {
      return useToken()
        .then(token => {
          return fetch(OBRIEN + '/subscription', {
            method: 'POST',
            body: JSON.stringify(sub),
            headers: {
              'content-type': 'application/json',
              'x-token': token,
              'x-key': key
            }
          })
            .catch(err => {
              if (err.statusCode == 401) {
                discardToken(token);
                return postSubscription(sub);
              }

              throw err;
            })
            .then(() => sub);
        });
    }

  }]);
