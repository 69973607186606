angular.module('payment').directive('onswPaymentTransactionResult', ['portal', 'supportEmail', 'Widget', function (portal, supportEmail, Widget) {
  return {
    scope: {
      transaction: '<',
      overlay: '<',
      sessionTx: '<',
      payment: '<'
    },
    templateUrl: '/template/payment.transactionResult',
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-form');

      scope.translateParams = {
        portal: portal.name,
        supportEmail: supportEmail
      };

      scope.$watch('transaction', function (t) {
        scope.success = t.code === null;
        scope.translateParams.value = t.value - 0 + t.bonus;

        if (scope.success) {
          scope.headline = 'payment.txCompleted';
          scope.showErrorDetails = false;
        } else {
          scope.showErrorDetails = t.code != 109;
          scope.headline = 'payment.' + (scope.showErrorDetails ? 'errorHeadline' : 'error.' + t.code);
        }

        element.toggleClass('success', scope.success);
        element.toggleClass('error', !scope.success);
      });

      scope.$watchGroup(['transaction', 'payment'], function (s) {
        const payment = s[1];
        if (scope.success || !payment || typeof scope.sessionTx != 'object' || scope.sessionTx.method.id != 2) return;

        payment.country = scope.sessionTx.country;
        payment.package = scope.sessionTx.package;

        function setPayment() {
          if (!payment.country) payment.country = scope.sessionTx.country;
          if (!payment.package) payment.package = scope.sessionTx.package;
          payment.method = scope.sessionTx.method;
        }

        scope.errorAction = [112, 113].includes(scope.transaction.code) ?
          () => widget.ifNotBusy(() => payment.resetVerifiedMethod(scope.transaction.method)).then(() => {
            setPayment();
            scope.$applyAsync();
          }) :
          setPayment;
      });
    }
  };
}]);
