/**
 * Provides user authentication on the `EventBus`.
 *
 * @module EventBusUser
 * @author Jacob Viertel <jv@onscreen.net>
 * @see [EventBus](../../core/context/event-bus.js)
 * @todo import authUser
 */

import {createElement, createGlobalContext, watch} from '@acng/frontend-relativity';
import {CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';
import {pick} from '@acng/frontend-bounty/object.js';

import {discardToken, useToken} from '../service/token.js';
import {ctxAuthUser} from './auth-user.js';
import {ctxEventBus, listen, publish, request} from 'acng/core/context/event-bus.js';
import {typeguard, EVENTBUS_AUTH} from '../service/typeguard.js';

const MODULE = 'userPool/context/event-bus-user';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

/**
 * Observe the authenticated even bus user.
 *
 * @type {import('@acng/frontend-relativity').Context<?import('acng/core/service/env').AuthUserData>}
 */
export const ctxEventBusUser = createGlobalContext(null);

export {listen, publish, request};

(() => {
  const TROI_AUTH = 'auth';

  /**
   * Send the user auth to `TROI`.
   *
   * @param {?import('acng/core/service/env').AuthUserData} authUser
   */
  const login = async (authUser) => {
    if (!authUser) {
      return;
    }

    const loginData = {
      type: TROI_AUTH,
      user: pick(authUser, 'id', 'pool_id'),
      token: await useToken(),
    };

    DEBUG: if (VERBOSE) console.info(`${MODULE} <---`, loginData);

    publish(loginData);
  };

  listen(TROI_AUTH, (data) => {
    DEBUG: if (VERBOSE) console.debug(`${MODULE} --->`, data);
    ASSERT: typeguard(MODULE, data, EVENTBUS_AUTH());

    const authUser = ctxAuthUser[CTX_VALUE](document.documentElement);

    // TROI responded with an invalid WORF token.
    if (data.token) {
      discardToken(data.token);
      login(authUser);
      return;
    }

    if (authUser && authUser.id == data.user?.id) {
      DEBUG: if (VERBOSE) console.info(`${MODULE} provide`, {authUser});

      ctxEventBusUser[CTX_PROVIDE](null, authUser);
    }
  });

  watch(
    createElement(),
    ([isConnected, authUser], [wasConnected, prevUser]) => {
      DEBUG: if (VERBOSE) console.debug(`${MODULE} watch`, {isConnected, wasConnected, authUser, prevUser});

      if (!isConnected) {
        if (authUser && wasConnected) {
          DEBUG: console.warn(`${MODULE} disconnect`);

          ctxEventBusUser[CTX_PROVIDE](null, null);
        }
        return;
      }

      if (!wasConnected) {
        login(authUser);
        return;
      }

      ASSERT: if (authUser?.id == prevUser?.id) {
        console.warn(`${MODULE} asserted authUser change did not take place`);
      }

      if (prevUser) {
        const logoutData = {
          type: TROI_AUTH,
          user: null,
        };

        DEBUG: if (VERBOSE) console.info(`${MODULE} <---`, logoutData);

        ctxEventBusUser[CTX_PROVIDE](null, null);
        publish(logoutData);
      }

      login(authUser);
    },
    ctxEventBus,
    ctxAuthUser
  );
})();
