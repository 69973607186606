import {rootRoute} from '@acng/frontend-voyager';

angular.module('picturePool')

  .directive('onswPictureSlideshow', ['$interval', 'Picture', 'PictureSet', ($interval, Picture, PictureSet) => {

    function link($scope, $element) {

      const route = rootRoute.find('pictureSlideshowImage');
      if (!route) {
        throw Error();
      }

      $element.addClass('ons-layout');

      const update = () => {
        const id = route.params.item;
        if (!id) {
          return;
        }

        $scope.path = null;
        Picture.get(id)
          .then(pic => PictureSet.get(pic.set_id))
          .then(set => {
            $scope.set = set;
            for (let i = 0; i < set.items.length; i++) {
              if (set.items[i] == id) {
                $scope.index = i;
                $scope.set.buy($scope.set.items[i]).then(function (res) {
                  $scope.path = res.data;
                }).catch(err => {
                  if (!err) {
                    $scope.close();
                  }
                });
                break;
              }
            }
          });
      };
      route.addEventListener('enter', update);
      update();

      $scope.timer = null;
      $scope.buying = false;

      $scope.toggleShow = function () {
        $scope.timer ? $scope.stopShow() : $scope.startShow();
      };

      $scope.startShow = function () {
        $scope.timer = $interval(function () {
          $scope.buy($scope.index + 1).catch(() => {
            $scope.stopShow();
          });
        }, 5000);
      };

      $scope.stopShow = function () {
        if ($scope.timer) {
          $interval.cancel($scope.timer);
          $scope.timer = null;
        }
      };

      $scope.$on('$destroy', () => {
        route.removeEventListener('enter', update);
        $scope.stopShow();
      });

      $scope.buy = function (index) {
        if (index + 1 > $scope.set.items.length) index = 0;
        if (index < 0) index = $scope.set.items.length - 1;
        return Picture.get($scope.set.items[index])
          .then(pic => pic.slideshow());
      };

      $scope.clickNext = () => $scope.buy($scope.index + 1);
      $scope.clickPrev = () => $scope.buy($scope.index - 1);

      $scope.toggleSize = function () {
        var img = $element.find('[onsw-image] > .box')[0];
        img.style.backgroundSize = img.style.backgroundSize == 'cover' ? '' : 'cover';
      };

      $scope.close = function () {
        $scope.set.goto();
      };

      $element.on('click', ev => {
        if (ev.target === $element[0]) {
          $scope.$apply('close()');
        }
      });

    }

    return {
      scope: {},
      link: link,
      templateUrl: '/template/picturePool.slideshow'
    };
  }]);
