/**
 * @module acng/alternativeProducts/widgets/products
 */
const name = 'onswAlternativeProducts';
export default name;
/** @type {angular.IComponentOptions} */
export const productsComponent = {
  templateUrl: '/template/alternativeProducts.products',
  /** @implements {angular.IController} */
  controller: class {
    static $inject = ['$scope', 'AlternativeProducts'];
    /**
     * @param {AlternativeProductsScope} $scope Local scope
     * @param {types.AlternativeProducts} AlternativeProducts Factory class
     */
    constructor($scope, AlternativeProducts) {
      $scope.loading = true;
      DEBUG: console.debug('ALTERNATIVE-PRODUCTS: Entry');
      const startTime = performance.now();
      AlternativeProducts.getProducts().then(products => {
        $scope.products = products;
        const endTime = performance.now();
        $scope.$apply('loading = false');
        DEBUG: console.debug($scope.products);
        DEBUG: console.debug(`ALTERNATIVE-PRODUCTS: Loading products took ${endTime - startTime}`);
      });
    }
  },
};
/* Types */
import * as types from '../factory/products'; // eslint-disable-line no-unused-vars
import angular from 'angular'; // eslint-disable-line no-unused-vars
/**
 * @typedef {object} AlternativeProductsScopeType
 * @property {boolean} loading Loading status flag
 * @property {types.Product[]} products Product list
 */
/**
 * @typedef {import('angular').IScope & AlternativeProductsScopeType} AlternativeProductsScope
 */
