import angular from 'angular';

import core from 'acng/core';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate.js';

import './service/route.js';
import './widget/latest.js';
import './widget/points.js';
import './widget/points-to-coins.js';
import featureName from './config/feature.js';

export default featureName;

angular.module(featureName, [core, angularTranslate]).config(provideTranslations(featureName));
