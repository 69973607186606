import {hasFeature} from 'acng/core/service/env';
import escape from 'underscore/modules/escape.js';

angular.module('amateurPool')

  .factory('weblogStore', ['Weblog', 'HttpCache', 'Amateur', '$injector', '$log', (Weblog, HttpCache, Amateur, $injector, $log) => {

    const Movie = hasFeature('moviePool') ? $injector.get('Movie') : null;
    const PictureSet = hasFeature('picturePool') ? $injector.get('PictureSet') : null;

    function prepareApiResponse(data) {
      data.amateurId = data.user_id;
      data.mediaFactory = {
        picture: PictureSet,
        movie: Movie
      }[data.media_type];
      data.mediaId = {
        picture: data.set_id,
        movie: data.movie_id
      }[data.media_type];
      data.mediaType = {
        picture: 'PictureSet',
        movie: 'Movie'
      }[data.media_type];
      return data;
    }

    return new HttpCache({
      prefix: '/api/amateur-profile/weblog/',
      useCdn: true,
      fnResolve: res => {

        const item = prepareApiResponse(res.data);

        let amateur = null;
        let mediaItem = null;

        const amateurPromise = Amateur.get(item.amateurId)
          .then(item => amateur = item)
          .catch(err => {
            if (err.status == 404) {
              throw err;
            }
            $log.error('Weblogs Amateur Error', res.data, item, err);
          });

        const mediaPromise = item.mediaFactory && item.mediaFactory.get(item.mediaId)
          .then(item => mediaItem = item)
          .catch(err => $log.warn('Weblog: media not present - discard', { err, item }));

        return amateurPromise
          .then(() => mediaPromise)
          .then(() => new Weblog(item, amateur, mediaItem));
      }
    });
  }])

  .factory('Weblog', ['fsk', 'media', (fsk, media) => {

    class Weblog
    {
      constructor(data, amateur, mediaItem) {

        this.id = data.id;
        this.amateurId = data.amateurId;
        this.amateur = amateur;

        this.mediaType = data.mediaType;
        this.mediaId = data.mediaId;
        this.media = mediaItem;

        this.createdAt = data.created * 1000;
        this.publishedAt = data.published * 1000;
        this.title = data.title;
        this.content = escape(data.content).replace(/\[link\](.*)\[\/link\]/, (m, a) => {
          if (!this.media) {
            return a;
          }
          return `<span class="ons-item link"><span class="box"><span class="label">${a}</span></span></span>`;
        });
        this.image = data.image;
        this.imageFsk = data.image_fsk;
      }

      getImageUrl() {

        if (this.image && fsk.get() >= this.imageFsk) {
          return media.content.pictures + this.image;
        }

        if (this.media) {
          return this.media.getImageUrl();
        }

        return null;
      }

      textClick(ev) {
        if (ev.target.className == 'label') {
          this.goto();
        }
      }

      goto() {
        (this.media || this.amateur).goto();
      }
    }

    return Weblog;
  }])

  .directive('onsdWeblog', ['PimpMyRide', 'weblogStore', (PimpMyRide, weblogStore) => PimpMyRide(
    'Weblog', 'weblogId', id => weblogStore.get(id), id => !isNaN(id) && id)]);
