import {Widget} from '@acng/frontend-stargazer';
import {
  closestData,
  onClick,
  replace,
  setDataAttribute,
  setText,
  toggleClass,
  whenAll,
} from '@acng/frontend-bounty';

import {getIcon} from 'acng/core/service/icon.js';

import {localeFeature} from '../config/feature.js';
import {setLocale, getLocale, POSSIBLE_LANGUAGES} from '../model/locale.js';
import {ENUM, IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {repeatElement} from 'acng/layout/service/repeat.js';

const MODULE = 'locale/widget/change';

/**
 * @todo TODO use available languages from backend
 * @module
 */

/**
 * @type {Array<[import('../service/http.js').CountryCode, "german" | "english"]>}
 */
const AVAILABLE = [
  ['de', 'german'],
  ['en', 'english'],
];

/**
 * @typedef {Array<[import('../service/http.js').CountryCode, "german" | "english", SVGSVGElement, string]>} Available
 */

localeFeature.defineWidget(
  'onsw-locale-change',
  class LocaleChange extends Widget {
    /**
     * @type {Available}
     */
    static available;

    static async setup() {
      LocaleChange.available = await whenAll(
        AVAILABLE.map((item) =>
          whenAll([item[0], item[1], getIcon(item[1]), localeFeature.translate(item[1])])
        )
      );
    }

    render() {
      ASSERT: typeguard(
        MODULE,
        this.nodes,
        OBJECT({
          repeat: IS(HTMLElement),
          box: IS(HTMLElement),
          icon: IS(SVGSVGElement),
          label: IS(Text),
        })
      );

      let {icon} = this.nodes;

      const {repeat, box, label} = this.nodes;

      const currentLocale = getLocale();

      repeatElement(repeat, LocaleChange.available, (item) => {
        replace(icon, item[2]);
        icon = item[2];
        setText(label, item[3]);
        toggleClass(repeat, 'active', currentLocale == item[0]);
        toggleClass(repeat, 'inactive', currentLocale != item[0]);
        setDataAttribute(box, 'countryCode', item[0]);
      });

      onClick(this, ({target}) =>
        closestData(
          target,
          'countryCode',
          (value) => {
            ASSERT: typeguard('', value, ENUM(POSSIBLE_LANGUAGES));
            setLocale(value);
          },
          this
        )
      );
    }
  }
);
