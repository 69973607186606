angular.module('acngMisc', ['ng', 'pascalprecht.translate'])

  .directive('onsLive', [
    '$compile',
    '$log',
    function ($compile, $log) {

      return {
        scope: false,
        restrict: 'C',
        link: function (scope, element) {

          var box = element.children('.box');

          if (!box.length) {
            $log.error('now live not live');
            return;
          }

          var linkFn = $compile(
            '<span class="live">' +
        '<span class="label" translate="core.nowLive"></span>' +
        '</span>'
          );

          box.append(linkFn(scope));

        }
      };
    }]
  );
