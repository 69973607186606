import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {EVENT_TRANSITIONEND, on, addClass, removeClass} from '@acng/frontend-bounty';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {pushHistory} from '@acng/frontend-relativity/history';

import {inject} from 'acng/core/service/ng.js';

import {messengerFeature} from '../config/feature.js';
import {setContactsVisibility, getContactsVisibility} from '../context/contacts-visibility.js';
import {setGlobalDialog, getGlobalDialog} from '../context/global-dialog.js';
import {ctxReceivedMessage} from '../context/message.js';

const STYLE_WUMPER = 'wumper';

defineCustomWidget(
  messengerFeature,
  'onsw-messenger-button',
  class extends Widget {
    static consumables = [ctxReceivedMessage];

    render() {
      wumper(this);
    }

    click() {
      if (!getContactsVisibility() || getGlobalDialog()) {
        setGlobalDialog(null);
        setContactsVisibility(true);
      } else {
        pushHistory();
        setContactsVisibility(false);
      }
      inject('$rootScope').$digest();
    }
  }
);

/**
 * @param {Element} host
 */
const wumper = (host) => {
  on(host, EVENT_TRANSITIONEND, () => removeClass(host, STYLE_WUMPER));
  ctxReceivedMessage[CTX_OBSERVE](host, (msg) => msg && addClass(host, STYLE_WUMPER));

  // Add random wumper for testing the wumper transition
  //(function bb() {
  //  setTimeout(() => (addClass(host, STYLE_WUMPER), bb()), Math.random() * 1500);
  //})();
};
