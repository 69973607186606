/**
 * @module userPool/idle-popup
 */
import angular from 'angular';
import {on} from '../core/factory/flowplayer';
import coreFeature from '../core';
import {User} from 'acng/userPool/factory/user';
import {RootScope} from 'acng/zz-app';
import {onWindow, setTimeout} from '@acng/frontend-bounty';
import overlayFactory, {Overlay, OverlayConstructor} from '../core/service/overlay';
import {hasFeature} from 'acng/core/service/env';
import {LivecamConstructor} from 'acng/livecam/factory/livecam';

const ngModuleName = 'ons.idlePopup';
const ngFactoryName = 'IdleWindowManager';
const VERBOSE = false;
export default ngModuleName;

angular
  .module(ngModuleName, ['ng', coreFeature, 'pascalprecht.translate'])
  .config(['$translatePartialLoaderProvider', configTranslate])
  .factory(ngFactoryName, ['$injector', overlayFactory, factory])
  .run([ngFactoryName, 'user', '$rootScope', run]);

interface IdleWindowManager {
  run(): void;
}

type IdleWindowManagerConstructor = //
  new (idletime: number) => IdleWindowManager;

function factory(
  $injector: angular.auto.IInjectorService, //
  onsOverlay: OverlayConstructor
): IdleWindowManagerConstructor {
  return class implements IdleWindowManager {
    idletime: number;
    lastaction = 0;
    runningMovies = 0;
    overlay: Overlay | null = null;

    constructor(idletime: number) {
      this.idletime = idletime * 60e3;
    }

    schedule() {
      DEBUG: VERBOSE && console.debug('userPool/idle-popup schedule');
      setTimeout(this.schedule.bind(this), 1e4);

      if (this.movieRunning() || this.livecamRunning() || onsOverlay.isOpen('idle')) {
        return;
      }

      if (this.idletime + this.lastaction < Date.now()) {
        this.openOverlay();
      }
    }

    openOverlay() {
      if (onsOverlay.isOpen('idle')) {
        return;
      }

      this.overlay = onsOverlay.create('idle').disableHistory().open();
    }

    closeOverlay() {
      if (!onsOverlay.isOpen('idle')) {
        this.overlay = null;
        return;
      }
      this.overlay?.close();
      this.overlay = null;
    }

    livecamRunning() {
      if (hasFeature('livecam')) {
        const Livecam = $injector.get('Livecam') as LivecamConstructor;
        if (Livecam.isActive()) {
          return true;
        }
      }
      return false;
    }

    movieRunning() {
      return this.runningMovies > 0;
    }

    run() {
      this.lastaction = Date.now();
      if (!this.idletime) {
        throw new Error('No idletime configured');
      }

      onWindow('mousemove', () => (this.lastaction = Date.now()));
      onWindow('keypress', () => (this.lastaction = Date.now()));

      this.schedule();

      on('play', () => {
        this.runningMovies++;
      });

      on('pause', () => {
        this.runningMovies--;
      });
    }
  };
}

function run(
  IdleWindowManager: IdleWindowManagerConstructor, //
  user: User,
  $rootScope: RootScope
) {
  const groupTimings = {
    1: 10,
    2: 15,
    3: 20,
  };

  function handle() {
    const [test] = user.split_tests.filter(t => t.id === 46);

    if (!test) {
      return;
    }

    DEBUG: VERBOSE && console.debug('userPool/idle-popup handle', {test});

    const {group} = test;
    if (group < 1 || group > 3) {
      return;
    }

    if (groupTimings[group as 1 | 2 | 3]) {
      let manager = new IdleWindowManager(groupTimings[group as 1 | 2 | 3]);
      manager.run();
    }
  }

  if (user.guest) {
    $rootScope.$on('userPool.login.success', handle);
  } else {
    handle();
  }
}

// eslint-disable-next-line max-len
function configTranslate(loader: angular.translate.ITranslatePartialLoaderProvider) {
  loader.addPart('idlepopup');
}
