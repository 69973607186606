import {media} from 'acng/core/service/env';
import {slideshowConfig} from 'acng/core/factory/Slideshow';
import {pushToArray} from '@acng/frontend-bounty';

export const extendSlideshow = () => {
  pushToArray(slideshowConfig.myportal_t1, [
    'ImageTile',
    {
      json: `${media.assets_ext}/img/toycontrol/index.js`,
      click: () => (location.hash = '/livecams/toycontrol'),
    },
  ]);
};
