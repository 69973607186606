/**
 * @module
 * Similarities between `Movie` and `PictureSet`
 */
import {
  validator,
  STRING,
  BOOLEAN,
  ABSTRACT_CLASS,
  INTEGER,
  FLOAT,
  ARRAY,
  OPTIONAL,
} from '@acng/frontend-bounty/typeguard';
import {loadImage} from '@acng/frontend-bounty';
import {fsk, POSSIBLE_FSK_LEVELS_DESC} from 'acng/userPool/fsk.js';
import {FSKTRANS, fskTrans} from 'acng/userPool/0fsk.js';
import {inject} from '../service/ng.js';
import {asset, media} from '../service/env.js';
import dummySrc from 'assets/basic/img/f_900.png';

/**
 * @typedef GalleryData
 *
 * @property {number} set_id
 * @property {string} nickname
 * @property {number} user_id
 * @property {string} published
 * @property {number} price
 * @property {boolean} [blocked] NOT part of the solr profile, eventually set by the "blocked-api" or the "stock-api"
 *
 * @property {number} votes   The number of reviews. (0+)
 * @property {number} rating  The average rating. (1-5 with votes, 0 without)
 *
 * @property {string[]} category_codes
 * @property {string} [image_fsk18]
 * @property {string} [image_fsk16]
 * @property {string} [image_fsk12]
 * @property {import('../../userPool/0fsk').FskTrans} name
 * @property {import('../../userPool/0fsk').FskTrans} description
 *
 */

/**
 * ## Those are provided by solr but are not used in the frontend.
 *
 * @typedef UnusedGalleryData
 *
 * @property {number} [age2]
 * @property {string} [gender2]
 *
 * @property {boolean} [gratis]
 * @property {number} [media_rank]
 * @property {number} [media_score]
 *
 * @property {number[]} [category_list]
 *
 * @property {number} [age]
 * @property {string} [gender]
 *
 * @property {{large: Array<[18 | 16 | 12, string]>}} [images]
 * @property {Array<18 | 16 | 12>} [image_fsk_list]
 *
 * @property {number} [score]
 * @property {number} [stream_id]
 * @property {number} [list_score]
 * @property {number} [_version_]
 */

/**
 * @implements {GalleryData}
 */
export class Gallery {
  /**
   * @param {GalleryData & UnusedGalleryData} data
   */
  constructor(data) {
    ASSERT: {
      ABSTRACT_CLASS(Gallery, this);

      const get = validator('GalleryData', data);

      this.set_id = get('set_id', INTEGER);
      this.user_id = get('user_id', INTEGER);
      this.nickname = get('nickname', STRING);
      this.name = get('name', FSKTRANS);
      this.description = get('description', FSKTRANS);
      this.published = get('published', STRING);
      this.price = get('price', INTEGER);
      this.votes = get('votes', INTEGER);
      this.rating = get('rating', FLOAT);
      // TODO it would be better to use the field GalleryData.category_list
      this.category_codes = get('category_codes', ARRAY(STRING));
      this.image_fsk18 = get('image_fsk18', OPTIONAL(STRING));
      this.image_fsk16 = get('image_fsk16', OPTIONAL(STRING));
      this.image_fsk12 = get('image_fsk12', OPTIONAL(STRING));
      this.blocked = get('blocked', OPTIONAL(BOOLEAN));

      // TODO remove from GalleryData
      delete data.age;
      delete data.age2;
      delete data.gender;
      delete data.gender2;
      delete data.gratis;
      delete data.media_rank;
      delete data.media_score;
      delete data.category_list;
      delete data.images;
      delete data.image_fsk_list;
      delete data.score;
      delete data.list_score;
      delete data._version_;
    }
  }

  getAmateur() {
    return inject('Amateur').get(`${this.user_id}`);
  }

  getImageUrl() {
    const userFsk = fsk.get();

    for (const level of POSSIBLE_FSK_LEVELS_DESC) {
      const value = this[GALLERY_FSK_IMAGE_MAP[level]];

      if (value && userFsk >= level) {
        return `${media.content.pictures}${value}`;
      }
    }

    return asset(dummySrc);
  }

  async getImage() {
    return await loadImage(this.getImageUrl(), asset(dummySrc));
  }

  getTitle() {
    return fskTrans(this.name);
  }

  getDescription() {
    return fskTrans(this.description);
  }
}

const GALLERY_FSK_IMAGE_MAP = /** @type {const} */ ({
  18: 'image_fsk18',
  16: 'image_fsk16',
  12: 'image_fsk12',
});
