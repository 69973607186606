angular.module('amateurPool')

  .factory('PortalFeedTile', ['FeedTile', FeedTile => {

    return class PortalFeedTile extends FeedTile
    {
      dontSetBg = true;

      async load(scope, feed) {
        await super.load(scope, feed);
        this.box.prepend(this.image);
      }

      hookname() {
        return 'portalFeed';
      }

      css() {
        return [];
      }
    };
  }]);
