/**
 * @module Open Overlay
 * @since 3.77.0
 * @author Jacob Viertel <jv@onscreen.net>
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 *
 * It is possible to open an `Overlay` directly from the templates.
 *
 * `Overlay` parameters are currently **not** supported.
 *
 * ```html
 * <span :open-overlay="payment">Payment Overlay</span>
 * ```
 *
 * @see [Overlay](../../../core/service/overlay.ts)
 */

import {onClick} from '@acng/frontend-bounty';
import {defineController} from '@acng/frontend-stargazer';

import {inject} from 'acng/core/service/ng.js';

const MODULE = 'layout/config/controller/open-overlay';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Verbose import', MODULE);

defineController(':open-overlay', (element, overlayName) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element, overlayName});

  onClick(element, () => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug'))
      console.info(MODULE, 'click', {element, overlayName});

    inject('onsOverlay').create(overlayName).disableBackdrop().open();
  });
});
