import angular from 'angular';
import {replace, clearText} from '../factory/smileyList.js';

angular.module('core').filter('smiley', ['$sanitize', '$sce', function ($sanitize, $sce) {

  /**
   * @param {string} text
   */
  return function (text) {
    // TODO(perf) this bloody bastard
    if (typeof text != 'string') {
      return text;
    }
    return $sce.trustAs('html', replace($sanitize(text)));
  };

}]);

angular.module('core').filter('onlySmileys', [function () {

  /**
   * @param {string} text
   */
  return function (text) {
    if (!text) return false;
    return clearText(text).trim().length === 0;
  };

}]);
