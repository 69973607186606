import {validator, STRING, INTEGER} from '@acng/frontend-bounty/typeguard';

/**
 * @typedef PictureData
 *
 * @property {number} id
 * @property {number} set_id
 * @property {number} fsk
 * @property {number} width
 * @property {number} height
 * @property {number} price
 * @property {string} preview That is the full URL of the preview image.
 * @property {number} [position] NOT USED
 */

/**
 * @param {PictureData} data
 */
function fixPictureData(data) {
  // PictureSetData contains wrong type for that.
  // @ts-ignore
  data.fsk = parseInt(data.fsk);

  // PictureSetData contains wrong type for that.
  // @ts-ignore
  data.width = parseInt(data.width);

  // PictureSetData contains wrong type for that.
  // @ts-ignore
  data.height = parseInt(data.height);

  // PictureSetData contains wrong key and wrong type for that.
  // @ts-ignore
  data.set_id ??= data.setId;
  // @ts-ignore
  data.set_id = parseInt(data.set_id);
}

/**
 * @implements {PictureData}
 */
export class Picture {
  /**
   * @param {PictureData} data
   */
  constructor(data) {
    fixPictureData(data);

    ASSERT: {
      const assert = validator('Picture', data);

      this.id = assert('id', INTEGER);
      this.set_id = assert('set_id', INTEGER);
      this.fsk = assert('fsk', INTEGER);
      this.width = assert('width', INTEGER);
      this.height = assert('height', INTEGER);
      this.preview = assert('preview', STRING);
      this.price = assert('price', INTEGER);

      // @ts-ignore TODO see fixPictureData
      delete data.setId;
      delete data.position;
    }

    Object.assign(this, data);
  }

  getImageUrl() {
    return this.preview;
  }

  getBlurredImageUrl() {
    const cdn_url_regex = /c2\.ng-source\.com/;
    if (cdn_url_regex.test(this.preview)) {
      return this.preview.replace(cdn_url_regex, 'c2.ng-source.com/cdn-cgi/image/blur=35,20quality=20');
    } else {
      return this.preview;
    }
  }
}

/**
 * @type {import('@acng/frontend-bounty/typeguard').Assertion<PictureData>}
 */
export function PICTURE_DATA(value) {}
