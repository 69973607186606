import {createMap, deleteFromMap, getFromMap} from '@acng/frontend-bounty/map';

/**
 * @type {Map<string, LivecamSession>}
 */
const sessions = createMap();

export class LivecamSession {
  static prevPath = '/livecams/online';

  /**
   * @param {import('acng/livecam/factory/livecam').Livecam} livecam
   * @param {string} sid
   * @param {unknown} duration
   * @param {import('acng/amateurPool/factory/Amateur').Amateur} amateur
   * @param {import('./livecam').RoomType} roomType
   * @param {string} source
   * @param {string} host
   */
  constructor(livecam, sid, duration, amateur, roomType, source, host) {
    sessions.set(sid, this);
    this.sid = sid;
    this.cam = livecam;
    this.duration = duration;
    this.amateur = amateur;
    this.roomType = roomType;
    this.start_at = Date.now();
    this.source = source;
    this.host = host;
    /**@ts-ignore */
    this.client = this.source == 'vx' ? window.vLivecam : window.mLivecam;
  }

  /**
   * @param {string} sid
   * @returns {LivecamSession | undefined}
   */
  static get(sid) {
    return getFromMap(sessions, sid);
  }

  /**
   * @param {string} sid
   */
  static remove(sid) {
    deleteFromMap(sessions, sid);
  }

  /**
   * @returns {number}
   */
  getPrice() {
    return this.cam.getPrice(this.roomType);
  }
}
