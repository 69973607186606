angular.module('livecam')

  .factory('SmallLivecamTile', ['AmateurTile', AmateurTile => {
    class SmallLivecamTile extends AmateurTile
    {
      hookname() {
        return 'livecamTile';
      }
    }
    return SmallLivecamTile;
  }]);
