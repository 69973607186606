import {query, setTimeout} from '@acng/frontend-bounty';
import {ONSW_MENU_BUTTON} from 'acng/layout/widgets/menu-button.js';

angular.module('core').directive('onswLink', ['$location', ($location) => {
  return {
    scope: {
      label: '@',
      route: '@',
      blank: '@',
      parameters: '@',
      clickAction: '&'
    },
    template: '<a ng-href="{{route}}" ng-click="checkSameLocation()" ng-attr-title="{{label|translate:params}}" class="box"><span class="label">{{label|translate:params}}</span></a>',
    link: function (scope, element) {
      var box = element.children('.box');
      element.addClass('ons-item');
      function checkLocation() {
        element.toggleClass('active', '#' + $location.path() == scope.route);
      }
      scope.checkSameLocation = function () {
        scope.clickAction({
          openMenu: () => setTimeout(() => query(ONSW_MENU_BUTTON).openMenu(), 100)
        });
      };
      scope.$on('$locationChangeSuccess', function (angularEvent, newUrl, oldUrl) {
        if (newUrl === oldUrl) return;
        checkLocation();
      });
      scope.$watch('route', checkLocation);
      scope.$watch('parameters', function (params) {
        scope.params = params ? angular.fromJson(params) : {};
      });

      if (scope.blank) {
        box.attr('target', '_blank');
      }
    }
  };
}]);
