import {validator, ARRAY, FINAL_CLASS, INTEGER, MIN, FULLY_ASSIGNED} from '@acng/frontend-bounty/typeguard';
import {Gallery} from 'acng/core/model/gallery.js';
import {inject} from 'acng/core/service/ng.js';
import {fsk} from 'acng/userPool/fsk.js';
import {PICTURE_DATA} from './picture.js';

/**
 * @typedef PictureSetData
 *
 * @property {number} id
 * @property {import('./picture.js').PictureData[]} rawItems
 * @property {number[]} items
 */

/**
 * Those fields are part of the solr model but are not used in the frontend
 *
 * @typedef UnusedPictureSetData
 *
 * @property {number} [number]
 * @property {number} [min_fsk]
 */

/**
 * @typedef {import('acng/core/model/gallery.js').GalleryData
 * & import('acng/core/model/gallery.js').UnusedGalleryData
 * & PictureSetData & UnusedPictureSetData
 * } FinalPictureSetData
 */

/**
 * @deprecated
 * @param {import('acng/core/model/gallery.js').GalleryData & PictureSetData} data
 */
function fixPictureSetData(data) {
  data.id = data.set_id;
  data.rawItems = /** @type {any} */ (data.items);
  data.items = data.rawItems.filter((i) => Number(i.fsk) <= fsk.get()).map((v) => v.id);
}

/**
 * @implements {PictureSetData}
 */
export class PictureSet extends Gallery {
  /**
   * @param {import('acng/core/model/gallery').GalleryData & PictureSetData & UnusedPictureSetData} data
   */
  constructor(data) {
    super(data);

    fixPictureSetData(data);

    ASSERT: {
      FINAL_CLASS(PictureSet, this);

      const get = validator('PictureSetData', data);

      this.id = get('id', MIN(1, INTEGER));
      this.rawItems = get('rawItems', ARRAY(PICTURE_DATA));
      this.items = get('items', ARRAY(MIN(1, INTEGER)));

      delete data.number;
      delete data.min_fsk;
      FULLY_ASSIGNED(this, data);
    }

    Object.assign(this, data);
  }

  getPictures() {
    const Picture = inject('Picture');
    return this.rawItems
      .filter((i) => Number(i.fsk) <= fsk.get())
      .map((v) => {
        v.price = this.price;
        Picture.cache.set(v.id, Date.now() + 3600000, new Picture(v));
        return v.id;
      });
  }

  /**
   * @returns {Promise<void>}
   */
  async goto() {
    const nickname = await this.getAmateur()
      .then((amateur) => amateur.nickname)
      .catch(() => 'unknown');

    location.hash = `/sedcard/${nickname}/pictures/set/${this.id}`;
  }

  /**
   * @deprecated
   * @param {"nickname" | "recommendation"} type
   * @param {number} limit
   */
  more(type, limit) {
    return inject('http')()
      .get('/api/picture/more/' + this.id + '?type=' + type + '&limit=' + limit, {dontIntercept: true})
      .then((res) => res.data);
  }
}
