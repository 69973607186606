import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {isInstanceOf} from '@acng/frontend-bounty';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';

import {getMessageDraft} from 'acng/messenger/context/message-draft.js';

import {coreFeature} from '../config/feature.js';
import {smileys} from '../factory/smileyList.js';
import {repeatElement} from 'acng/layout/service/repeat.js';

const MODULE = 'core/widgets/smileys';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

defineCustomWidget(
  coreFeature,
  'onsw-smileys',
  class extends Widget {
    render() {
      ASSERT: typeguard(MODULE, this.nodes, OBJECT({repeat: IS(HTMLImageElement)}));

      const {repeat} = this.nodes;

      repeatElement(repeat, smileys, (smiley) => {
        repeat.alt = smiley.key;
        repeat.src = smiley.getUrl();
        repeat.title = smiley.key;
      });

      this.onclick = ({target}) => {
        if (!isInstanceOf(target, HTMLImageElement)) {
          DEBUG: if (VERBOSE) console.debug(`${MODULE} the click didn't hit a smiley`, {element: this});
          return;
        }

        const draft = getMessageDraft(this);

        if (!draft) {
          DEBUG: console.warn(`${MODULE} there is no draft message`, {element: this});
          return;
        }

        DEBUG: if (VERBOSE) console.info(MODULE, {element: this, smiley: target.alt, draft});

        draft.body += target.alt;
        draft.focusIfNotMobile();
      };
    }
  }
);
