import { FreeMovie } from '../factory/FreeMovie';
/**
 * @module acng/moviePool/widget/player
 */
export const //
  type = 'component',
  name = 'onswMoviePlayer',
  /** @type {angular.IComponentOptions} */
  param = {
    bindings: {
      movie: '<',
    },
    controller,
  };
import {player, mapSources} from 'acng/core/factory/flowplayer';
import {onClick, append, createDiv, addClass, removeClass, hasClass, remove, createVideo} from '@acng/frontend-bounty';
const CSS_PLAYING = 'playing';
const CSS_PREVIEW = 'preview';
const CSS_LOADING = 'loading';
controller.$inject = ['$element', 'Movie', 'payment'];
/**
 * @param {JQLite} $element -
 * @param {*} Movie -
 * @param {*} payment -
 */
function controller($element, Movie, payment) {
  /** @type {HTMLDivElement | undefined} */
  let previousContext;
  const element = $element[0];
  /**
   * TODO the template implementation should not need the `CSS_PLAYING` at the component
   * @param {{movie: angular.IChangesObject<Movie>}} changes -
   */
  this.$onChanges = async ({movie: {currentValue: movie}}) => {
    // 1. remove the previous context
    removeClass(element, CSS_PLAYING);
    remove(previousContext);
    // 2. create new context with "loading state"
    const context = createDiv();
    addClass(context, CSS_LOADING);
    append(element, context);
    previousContext = context;
    // 3. stay put without a movie
    // TODO show hint
    if (!movie) {
      return;
    }
    // 4. append preview image with click handler and switch to "preview state"
    // TODO register click handler not on the image but on the context?
    // TODO movie types
    const image = /** @type {HTMLImageElement} */ (await movie.getImage());
    await image.decode();
    append(context, image);
    removeClass(context, CSS_LOADING);
    addClass(context, CSS_PREVIEW);
    onClick(image, async () => {
      if (hasClass(context, CSS_LOADING)) {
        return;
      }
      addClass(context, CSS_LOADING);
      try {
        const sources = mapSources(await movie.buy());
        if (!sources) {
          throw new Error('no sources');
        }
        remove(image);
        removeClass(context, CSS_PREVIEW);
        const video = createVideo();
        append(context, video);
        await player(video, sources);
        addClass(context, CSS_PLAYING);
        if (context === previousContext) {
          addClass(element, CSS_PLAYING);
        }
      } catch (/** @type {any} */ res) {
        if (!(res?.status == 402 || res?.status == 401)) {
          console.error('moviePool/widget/player', res);
          //widget.notify(err);
        }
      }
      removeClass(context, CSS_LOADING);
    });
    // 5. show payment overlay if the movie is free for premium users
    if (await FreeMovie.couldBeFree(movie.id)) {
      payment.overlay('moviePool.becomePremium');
    }
  };
}
