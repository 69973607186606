// @ts-nocheck
import angular from 'angular';
import {ctxCommentable} from "../../comment/model/commentable.js";

angular.module('picturePool')

  .factory('PictureSetTile', ['AmateurTile', 'PictureSet', (AmateurTile, PictureSet) => {
    class PictureSetTile extends AmateurTile
    {
      load(scope, set_id) {
        return PictureSet.get(set_id)
          .then(set => {
            ctxCommentable.provide(this.el, {type: 'pictures', id: set.id});
            this.apply({
              fnMain: 'detail',
              set: set,
              title: set.getTitle(),
              mediaId: set.id,
              mediaType: 'PictureSet',
              mediaSetId: set.id,
              publishedAt: set.published,
              image: set.getImageUrl(),
              click: () => set.goto()
            });
            return super.load(scope, `${set.user_id}`)
              .finally(() => {
                scope.desc = set.getDescription();
              });
          })
          .catch(err => {
            if (err.status == 404) {
              PictureSet.doNotShow.push(parseInt(set_id));
            }
            throw err;
          });
      }
      hookname() {
        return 'media';
      }
    }
    return PictureSetTile;
  }]);
