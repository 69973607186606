/**
 * @module acng/games/widgets/suggestions
 */
import {append, createTemplate, toggleClass} from '@acng/frontend-bounty';
import {createPerceptiveHTML} from '@acng/frontend-discovery';
import {createStream} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {VERBOSE} from 'acng/core/service/debug.js';

const logName = 'onswGameSuggestions';
export default logName;
export const suggestionsComponent: angular.IComponentOptions = {
  transclude: true,
  controller: ['$element', '$transclude', 'AmateurFilter', 'Amateur', Controller],
};

const CSS_SMALL = 'small';
const CSS_TRESHOLD = 520;
const CSS_LARGE = 'large';

type AmateurContructor = import('acng/amateurPool/factory/Amateur').AmateurConstructor;
type Amateur = import('acng/amateurPool/factory/Amateur').Amateur;
type FilterConstructor = import('acng/amateurPool/factory/Filter').FilterConstructor;
type SuggestionScope = angular.IScope & {
  amateur?: Amateur;
  nickname?: string;
  desc?: string;
  age?: number;
};

const scopes = new WeakMap<object, SuggestionScope>();

function Controller(
  this: angular.IController, //
  $element: JQLite,
  $transclude: angular.ITranscludeFunction,
  AmateurFilter: FilterConstructor,
  Amateur: AmateurContructor
) {
  let amateurIds: Array<string> = [];
  const element = $element[0];
  const stream = createStream<string>([]);
  const perceptive = createPerceptiveHTML(
    element,
    stream,
    async (amateurId, loader) => {
        const amateur = await Amateur.get(amateurId);

        if (!loader.target) {
          return;
        }

        if (!amateur.isOnline() || amateur.hasActiveGame()) {
          throw `${logName} amateur is offline or has an active game`;
        }

        ctxAmateur[CTX_PROVIDE](loader.target, amateur);
        const template = createTemplate();

        $transclude((clone, $scope) => {
          ASSERT: if (!clone || !$scope) {
            throw Error(`${logName} template required`);
          }

          const scope = $scope as SuggestionScope;
          scopes.set(loader, scope);
          scope.amateur = amateur;
          append(template.content, ...clone);
        });

        return template;
      },
    (loader) => scopes.get(loader)?.$destroy()
  );

  DEBUG: if (VERBOSE(element)) perceptive.enableDebug(logName);

  this.$onInit = async () => {
    amateurIds = await AmateurFilter.get('gameScore').list(10000);
    DEBUG: console.debug('onsw-game-suggestions $onInit', amateurIds);
    stream.streamReset(amateurIds);
    perceptive.connect();
    //start(drive, amateurIds, min(20, sizeOfArray(amateurIds))); // TODO hardlimit?
  };

  this.$onDestroy = () => perceptive.disconnect();

  new ResizeObserver(
    ([
      {
        contentRect: {width},
      },
    ]) => {
      toggleClass(element, CSS_SMALL, width < CSS_TRESHOLD);
      toggleClass(element, CSS_LARGE, width >= CSS_TRESHOLD);
    }
  ).observe(element);
}
