import angular from 'angular';

import {media} from 'acng/core/service/env.js';
import {fsk} from 'acng/userPool/fsk.js';
import {fskTrans} from 'acng/userPool/0fsk.js';
import {publishUserMessage} from 'acng/messenger/service/event-bus.js';
import {recentMessageIsIcebreaker} from 'acng/messenger/service/recent-message.js';
import {Message} from 'acng/messenger/model/message';

export const name = 'Sexicon';
export const type = 'factory';
export const param = factory;
factory.$inject = ['http', 'user', 'Amateur', '$rootScope', 'price'];
/**
 * @param {core.Http} http -
 * @param {import('acng/userPool/factory/user').User} user -
 * @param {import('acng/amateurPool/factory/Amateur').AmateurConstructor} Amateur -
 * @param {angular.IRootScopeService} $rootScope -
 * @param {core.price} price -
 */
function factory(http, user, Amateur, $rootScope, price) {
  /**
   * @type {*}
   */
  const all = [];
  /**
   * @type {*}
   */
  const list = [];
  /**
   * @type {*}
   */
  const categories = {};
  /**
   * @type {number}
   */
  const maxAge = 30 * 60 * 1000;
  /**
   * @type {Promise<*> | null}
   */
  let promise = null;
  /**
   * @type {number}
   */
  let age = Date.now() - 2 * maxAge;
  /** */
  function init() {
    const now = Date.now();
    if (!promise || age < now - maxAge) {
      age = now;
      promise = http()
        .get('/api/sexicon')
        .then(res => {
          all.length = 0;
          res.data.forEach(item => all.push(new Sexicon(item)));
        })
        .then(update)
        .catch(err => {
          console.error(err);
          promise = null;
          throw err;
        });
    }
  }
  /** */
  function update() {
    list.length = 0;
    // eslint-disable-next-line no-extra-parens
    Object.values(categories).forEach(cat => (cat.length = 0));
    all
      .filter(sexicon => sexicon.fsk <= fsk.get())
      .forEach(sexicon => {
        sexicon.categories.forEach(i => {
          if (!categories[i]) {
            categories[i] = [];
          }
          categories[i].push(sexicon);
        });
        list.push(sexicon);
        sexicon.text = fskTrans(sexicon.texts);
      });
  }
  $rootScope.$on('$translateChangeEnd', update);
  fsk.addEventListener('change', update);
  /**
   * @implements {Sexicon}
   */
  class Sexicon {
    /**
     * @param {*} item -
     */
    constructor(item) {
      this.id = item.id;
      this.name = item.name;
      this.image = item.image;
      this.imageUrl = media.content.items + item.image;
      this.fsk = item.fsk;
      this.texts = item.text;
      this.text = '';
      this.categories = item.categories;
    }
    /**
     * @returns {*} -
     * @static
     */
    static load() {
      init();
      return promise;
    }
    /**
     * @returns {*} -
     * @static
     */
    static list() {
      init();
      return list;
    }
    /**
     * -
     * @param {*} cat -
     * @returns {*} -
     * @static
     */
    static category(cat) {
      if (!categories[cat]) {
        categories[cat] = [];
      }
      init();
      return categories[cat];
    }
    /**
     * -
     * @returns {*} -
     */
    static categories() {
      return categories;
    }
    /**
     * -
     * @param {string} recipientId
     */
    async send(recipientId) {
      const recipient = await Amateur.get(recipientId);

      try {
        await http().post(
          'api/sexicon/send',
          {
            recipient: recipientId,
            id: this.id,
            icebreakerAnswer: await recentMessageIsIcebreaker(recipient),
            price: price.get({type: 'sexicon', data: recipient}),
          },
          {
            headers: {'Content-Type': 'application/json'},
            paymentOverlayParams: {reason: 'sexicon.paymentRequired', params: {nickname: recipient.getNickname()}},
          }
        );

      } catch (err) {
        if (err.status == 401) {
          user.guestSignup('sexicon.signupRequired', {nickname: recipient.getNickname()}) || user.clear();

          throw null;
        }

        throw err;
      }

      publishUserMessage(new Message(recipient, {
        sender: {
          product_id: user.pool_id,
          id: `${user.id}`,
        },
        attachment: null,
        body: this.text,
        payload: {
          type: 'sexicon',
          text: this.text,
          image: this.image,
        },
        timestamp_ms: Date.now(), // TODO get real time from post
      }));
    }
  }
  return Sexicon;
}
/**
 * @typedef {object} Sexicon
 * @property {number} id -
 * @property {string} name -
 * @property {string} image -
 * @property {string} imageUrl -
 * @property {*} fsk -
 * @property {string} texts -
 * @property {string} text -
 * @property {SexiconSendFn} send -
 */
/**
 * @callback SexiconSendFn -
 * @param {number} recipientId
 * @returns {angular.IPromise<*>}
 */
