// @ts-nocheck
import {Amateur} from '../model/amateur.js';

angular
  .module('amateurPool')

  .factory('Amateur', [
    'fskImage',
    'http',
    '$filter',
    '$translate',
    'user',
    'HttpCache',
    (fskImage, http, $filter, $translate, user, HttpCache) => {
      const cache = new HttpCache();

      Amateur.prototype.doNotShow = function () {
        return (
          this.do_not_show_in.includes(user.first_login_country) ||
          !user.hasNiche(this.niche_tags)
        );
      };

      Amateur.doNotShow = [];

      Amateur.prototype.getNickname = function () {
        return user.isInternational() ? this.intlname : this.nickname;
      };

      Amateur.prototype.getPreferredImageUrl = function (format) {
        return (
          this.preferredImageUrlGetter ||
          (this.preferredImageUrlGetter = fskImage.makeRawGetter(this.images, this.preferred_image))
        )(format);
      };

      Amateur.prototype.hasFskAndTranslateAttribute = function (name) {
        return !!$filter('fskTrans')(this[name]);
      };

      Amateur.prototype.hasAboutMe = function () {
        return this.hasFskAndTranslateAttribute('about_me');
      };

      Amateur.prototype.hasOthersAboutMe = function () {
        return this.hasFskAndTranslateAttribute('others_about_me');
      };

      Amateur.prototype.hasFantasies = function () {
        return this.hasFskAndTranslateAttribute('fantasies');
      };

      cache.prefix = '/api/amateur-profile/view?v=string&id=';
      cache.useCdn = true;
      cache.fnResolve = (res) => cache.set(res.data.nickname, res.expire, new Amateur(res.data));

      Amateur.get = function (id) {
        return cache
          .get(id)
          .then((amateur) => {
            if (amateur.doNotShow()) {
              //console.log('amateur removed', amateur.id, amateur.nickname, amateur.age);
              cache.delete(amateur.nickname);
              throw {status: 404};
            }
            return amateur;
          })
          .catch((err) => {
            if (err.status == 404) {
              Amateur.doNotShow.push(id);
            }
            throw err;
          });
      };

      Amateur.isValidId = function (id) {
        return typeof id == 'string' && id.length;
      };

      Amateur.isValidName = function (name) {
        return typeof name == 'string' && name.length > 0;
      };

      Amateur.getByNickname = function (nickname) {
        return cache.get(
          nickname,
          () => http(true).get('/api/amateur-profile/id-by-nickname/' + nickname, {dontIntercept: true}),
          (res) => Amateur.get(res.data)
        );
      };

      Amateur.prototype.getLocation = async function () {
        return $translate('amateurPool.fromZipArea', {
          zip_area: $filter('zip')(this.zip_area, this.country),
          country: this.country ? await $translate(`countries.iso.${this.country.toUpperCase()}`) : '',
        });
      };

      return Amateur;
    },
  ])

  .directive('onsdAmateurs', [
    'PimpMyRide',
    'AmateurFilter',
    '$q',
    (PimpMyRide, AmateurFilter, $q) =>
      PimpMyRide(
        'Amateurs',
        ['filterId', 'limit', 'reload', 'trigger'],
        (v, scope) => {
          if (!scope.filter) {
            scope.$watch('filterId', (id) => {
              scope.filter = AmateurFilter.get(id);
              scope.filter.addEventListener('change', changeFilter);
              scope.$on('$destroy', () => scope.filter.removeEventListener('change', changeFilter));
              function changeFilter() {
                scope.reload = (scope.reload || 0) + 1;
              }
            });
            scope.reload = 0;
            return $q.resolve(null);
          }
          if (v[3]) {
            scope.filter.trigger = v[3];
          }
          return scope.filter.list(20000);
        },
        (v) => v[1] > 0 && !!v[0]
      ),
  ])

  .directive('onsdAmateur', [
    'PimpMyRide',
    'Amateur',
    (PimpMyRide, Amateur) =>
      PimpMyRide(
        'Amateur',
        ['amateurId', 'amateurName'],
        (v) => (Amateur.isValidId(v[0]) ? Amateur.get(v[0]) : Amateur.getByNickname(v[1])),
        (v) => Amateur.isValidId(v[0]) || Amateur.isValidName(v[1])
      ),
  ]);
