// @ts-nocheck
import {hasFeature, media} from 'acng/core/service/env';
import {getOnlineArray} from '../service/online';
import {shuffle} from 'acng/core/factory/helper';

export default 'Otm';
export const OtmFactory = ['user', 'HttpCache', '$injector', '$timeout', factory];

/**
 * @param {import('acng/userPool/factory/user').User} user
 * @param {unknown} HttpCache
 * @param {angular.auto.IInjectorService} $injector
 * @param {angular.ITimeoutService} $timeout
 */
function factory(user, HttpCache, $injector, $timeout) {
  const cache = new HttpCache({
    useCdn: true,
    prefix: '/api/',
    fnResolve: async (res, stale) => {
      const list = /** @type {OtmData[]} */ (res.data)
        .filter((d) => user.hasNiche(d.niche_tags))
        .map((d) => new Otm(d));
      if (!stale) {
        shuffle(list);
        return list;
      }
      const previous = await stale;
      for (let i = 0; i < previous.length; i++) {
        if (!list.find((otm) => otm.amateurId == previous[i].amateurId)) {
          previous.splice(i--, 1);
        }
      }
      for (let i = 0; i < list.length; i++) {
        if (!previous.find((otm) => list[i].amateurId == otm.amateurId)) {
          previous.push(list[i]);
        }
      }
      return previous;
    },
  });

  /**
   * @param {IOtm[]} list
   * @param {Array<number>} online
   */
  function sort(list, online) {
    for (let count = 0, i = list.length - 1, j; i >= count; i--) {
      j = online.indexOf(list[i].amateurId);
      if (j >= 0) {
        const swap = list[i];
        list.splice(i, 1);
        list.unshift(swap);
        count++;
        i++;
      }
    }
    return list;
  }

  const supportedTypes = ['amateur-profile'];
  if (hasFeature('livecam')) {
    supportedTypes.push('livecam');
  }

  /** @implements {IOtm} */
  class Otm {
    /** @type {IOtm[]} */
    static list = [];
    static timeouts = new Map();

    static supportedTypes = supportedTypes;

    /** @type {OtmType | undefined} */
    type;

    /**
     * @param {OtmData} data
     */
    constructor(data) {
      this.amateurId = data.user_id;
      this.color = data.color;
      this.fsk = data.fsk;
      this.image = media.content.pictures + data.image;
      this.imageXL = media.content.pictures + data.image_xl;
      this.text = data.text;
    }

    getCaption() {
      return {
        'amateur-profile': 'amateurPool.amateurOfTheMoment',
        livecam: 'livecam.livecamOfTheMoment',
      }[this.type];
    }

    defer() {
      if (Otm.timeouts.has(this.amateurId)) {
        return;
      }
      Otm.timeouts.set(
        this.amateurId,
        $timeout(() => {
          const list = Otm.list;
          const i = list.indexOf(this);
          if (i >= 0) {
            list.splice(i, 1);
            list.push(this);
          }
          Otm.timeouts.delete(this.amateurId);
        }, 5 * 60 * 1000)
      );
    }

    /** @param {OtmType} type */
    static async get(type) {
      if (!Otm.isValidType(type)) {
        throw {status: 400};
      }
      /** @type {IOtm[]} */
      const list = await cache.get(type + '/otm');
      Otm.list = list;

      for (let otm of list) {
        otm.user_id = `${otm.user_id}`;
        otm.type = type;
      }

      if (type == 'livecam') {
        await sort(list, await $injector.get('Livecam').list());
      } else {
        await sort(list, await getOnlineArray());
      }
      return list;
    }

    static async getCombined() {
      const list = [...await cache.get('livecam/otm'), ...await cache.get('amateur-profile/otm')];
      shuffle(list);
      sort(list, [...await $injector.get('Livecam').list(), ...await getOnlineArray()]);
      return list;
    }

    /** @param {OtmType} type */
    static isValidType(type) {
      return this.supportedTypes.includes(type);
    }
  }

  return Otm;
}
