/**
 * @module If User
 * @since 3.77.0
 * @author Jacob Viertel <jv@onscreen.net>
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 *
 * Hide or show parts of a template when a `User` is authenticated.
 *
 * ```html
 * <span :if-user>You are an authenticated user</span>
 *
 * <span :if-user="guest">You are <strong>NOT</strong> an authenticated user</span>
 * ```
 *
 * @see [User](../../../userPool/model/user.js)
 */

import {removeNode} from '@acng/frontend-bounty';
import {ENUM, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {defineController} from '@acng/frontend-stargazer';
import {inject} from 'acng/core/service/ng';

const MODULE = 'userPool/config/controller/if-user';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Verbose import', MODULE);

defineController(':if-user', (element, flag) => {
  ASSERT: typeguard(MODULE, flag, ENUM(/** @type {const} */ (['', 'auth', 'guest'])));
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element, flag});

  const isGuest = inject('user').guest;

  if ((flag != 'guest' && isGuest) || (flag == 'guest' && !isGuest)) {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'remove', {element, flag});

    removeNode(element);
  }
});
