angular.module('core')
  .filter('unit', ['$translate', function ($translate) {

    function formatTime(time) {
      var units = ['s', 'm', 'h'];
      var t = [];
      var sec_num = parseInt(time, 10);
      t.push(Math.floor(sec_num / 3600));
      t.push(Math.floor((sec_num - t[0] * 3600) / 60));
      t.push(sec_num - t[0] * 3600 - t[1] * 60);

      t = t.map(function (u) {
        return u < 10 ? '0' + u : u.toString();
      });

      t = t.join(':', t).replace(/^(00:)+/mg, '').replace(/^0/, '');

      return t + ' ' + $translate.instant('core.units.' + units[Math.ceil(t.length / 3 - 1)]);
    }

    function formatByte(bytes) {
      var i = 0;
      var units = ['B', 'KB', 'MB', 'GB', 'TB'];
      while (bytes > 1024 && i < units.length) {
        bytes /= 1024;
        i++;
      }
      return Math.round(10 * bytes) / 10 + ' ' + units[i];
    }

    return function (val, unit) {
      switch (unit) {
        case 'byte':
          return formatByte(val);
        case 'sec':
          return formatTime(val);
        default:
          return val + ' ' + unit;
      }
    };


  }]);
