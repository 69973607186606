import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {removeChildNodes, removeClass, addClass, HTMLElement} from '@acng/frontend-bounty';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {inject} from 'acng/core/service/ng.js';
import {livecamFeature} from '../config/feature.js';

const CSS_ACTIVE = 'active';
const CSS_INACTIVE = 'inactive';

livecamFeature.defineElementLoader('onsw-livecam-motto', async (localName) => {
  const Livecam = inject('Livecam');
  const $sanitize = inject('$sanitize');
  const fskTransFilter = inject('fskTransFilter');

  ctxAmateur[CTX_SUBSCRIBE_TAGNAME](localName);

  return class extends HTMLElement {
    connectedCallback() {
      const element = this;

      ctxAmateur[CTX_OBSERVE](element, (amateur) => {
        removeChildNodes(element);
        removeClass(element, CSS_ACTIVE);
        addClass(element, CSS_INACTIVE);

        if (!amateur) {
          return;
        }

        const livecam = Livecam.get(amateur.id);
        if (!livecam) {
          return;
        }

        const text = fskTransFilter(livecam.motto);
        DEBUG: console.debug('livecam/widget/motto', {amateur, livecam, text});
        if (!text) {
          return;
        }

        removeClass(element, CSS_INACTIVE);
        addClass(element, CSS_ACTIVE);
        element.innerHTML = $sanitize(text);
      });
    }
  };
});
