import angular from 'angular';

angular.module('payment').directive('onswPaymentMethods', ['payment', '$translate', 'Widget',
  /**
   * @param {import('acng/payment/factory/payment').Payment} payment
   * @param {angular.translate.ITranslateService} $translate
   * @param {import('acng/core/factory/widget').WidgetConstructor} Widget
   * @returns {angular.IDirective<angular.IScope & {
   *   isMethodAvailable: Function;
   *   select: Function;
   *   isSelected: Function;
   *   reset: Function;
   * }>}
   */
  function (payment, $translate, Widget) {

    return {
      scope: {
        methods: '<',
        method: '=',
        selectMethod: '&',
        country: '@',
        packageId: '@?',
        customerId: '@',
        itemClass: '@',
        currency: '@',
        defaultCurrency: '@'
      },
      templateUrl: '/template/payment.methods',
      link: function (scope, element) {

        const widget = new Widget(scope, element, 'ons-layout ons-list');
        widget.notifyElement = angular.element('.overlay-content.payment');

        scope.isMethodAvailable = function (method) {
          return isMethodAvailableInCountry(method) && isMethodAvailableByFlowOrPackage(method);
        };

        scope.select = function (method) {
          scope.method = method;
          scope.selectMethod({ method: method });
        };

        scope.isSelected = function (method) {
          return scope.method === method;
        };

        scope.reset = function ($event, method) {
          $event.stopPropagation();
          $translate('payment.areYouSureToResetVerifiedMethod').then(function (text) {
            if (confirm(text)) {
              widget.ifNotBusy(() => payment.resetMethod(scope.customerId, method.id))
                .then(() => {
                  method.resettable = false;
                  method.verified = false;
                  method.verified_priority = null;
                  widget.notify('payment.resetVerifiedMethodSuccess');
                  scope.$applyAsync();
                })
                .catch(err => widget.notify(err));
            }
          });
        };

        function isMethodAvailableInCountry(method) {
          return method.country_restrictions.length === 0 || method.country_restrictions.indexOf(scope.country) !== -1;
        }

        function isMethodAvailableByFlowOrPackage(method) {
          if (!scope.packageId) {
            return true;
          }

          const packages = method.packages[scope.currency] || method.packages[scope.defaultCurrency];
          return packages && packages[scope.packageId];
        }

      }
    };
  }]);
