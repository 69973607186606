import angular from 'angular';
import {getLocale} from 'acng/locale/model/locale.js';
import {useToken, discardToken} from 'acng/userPool/service/token.js';
import {onTransaction} from '../service/event-bus';

angular.module('payment')
  .factory('Payback', ['$sessionStorage', 'payment', 'onsOverlay', 'http', 'ARCHIMEDES', 'isMobile', '$location', 'portal', function (
    $sessionStorage, payment, onsOverlay, http, ARCHIMEDES, isMobile, $location, portal)
  {

    const session = $sessionStorage;
    if (session.paybackDetails === void 0) {
      session.paybackDetails = {};
    }

    class Payback {

      static openOverlay = params => {
        payment.closeOverlay();

        if (onsOverlay.isOpen('payback')) {
          return;
        }

        angular.element(document).focus();

        onsOverlay.create('payback', { reasonParams: angular.toJson(params) })
          .disableBackdrop()
          .disableCloseButtonInToolbar()
          .open();
      };

      static closeOverlay = () => {
        const overlay = onsOverlay.get('payback');
        if (overlay) {
          overlay.close();
        }
      };

      static index = customerId => {
        return useToken()
          .then(token => {
            return http().get(`${ARCHIMEDES}/customer/${customerId}/payback`, {
              headers: {
                'X-AuthToken': token,
                'X-Language': getLocale()
              }
            })
            .catch(err => {
              if (!err.data || err.data.error != 1001) {
                throw err;
              }

              discardToken(token);
              return this.index(customerId);
            });
          });
      };

      static start = (customerId, paybackId) => {
        return useToken()
          .then(token => {
            const params = {
              id: paybackId,
              portal_id: portal.id,
              wants_mobile: isMobile ? 1 : 0
            };

            return http().post(
              `${ARCHIMEDES}/customer/${customerId}/payback`,
              angular.element.param(params),
              {
                headers: {
                  'X-AuthToken': token,
                  'X-Language': getLocale(),
                  'X-Domain': $location.host(),
                  'X-DomainIsSecure': $location.protocol() === 'https'
                },
                dontIntercept: true
              }
            )
              .then(res => {
                const tx = res.data;
                tx.is_payback = true;
                tx.rawPspUrl = tx.url + '?' + angular.element.param(tx.parameter);
                this.remember(tx);
                return tx;
              })
              .catch(err => {
                if (!err.data) {
                  throw err;
                }

                switch (err.data.error) {

                  case 1001:
                    discardToken(token);
                    return this.start(customerId, paybackId);

                }

                throw err;
              });
          });
      };

      static registerPending = () => {
        angular.forEach(session.paybackDetails, (details, txId) => {
          this.register(txId);
        });
      };

      static register = txId => {
        onTransaction((transaction) => {
          if (transaction.id != txId) {
            return;
          }

          onsOverlay.create('paybackResultOverlay', {
            callbackTx: transaction,
            sessionTx: session.paybackDetails[transaction.id],
            hideBonusBanner: true
          })
            .disableBackdrop()
            .disableHistory()
            .open();


          delete session.paybackDetails[transaction.id];
        });
      };

      static remember = tx => {
        session.paybackDetails[tx.id] = {
          is_payback: tx.is_payback
        };
        this.register(tx.id);
      };


    }

    return Payback;
  }]);
