import { shuffle } from 'acng/core/factory/helper';

angular.module('amateurPool')

  .factory('ModeratedAmateur', ['http', '$location', (http, $location) => {

    class ModeratedAmateur {
      constructor(data) {
        this.amateur_id = data.amateur_id;
        this.nickname = data.nickname;
        this.image_url = data.image_url;
        this.description = data.description;
      }

      static list(name) {
        // use origin domain because of niches (afo) and the related "global" moderated amateur list
        return http(false).get('/api/amateur-profile/moderated-list/' + name + ($location.search().nocache ? '?' + Math.random() : ''))
          .then(res => shuffle(res.data.map(data => new ModeratedAmateur(data))));
      }
    }

    return ModeratedAmateur;
  }])

  .directive('onsdModeratedAmateurs', ['PimpMyRide', 'ModeratedAmateur', 'portal', (PimpMyRide, ModeratedAmateur, portal) => PimpMyRide(
    'ModeratedAmateurs', false, () => ModeratedAmateur.list(portal.shortcut.toLowerCase())
  )]);
