import {removeClass, body, addClass} from '@acng/frontend-bounty';
import {watch} from '@acng/frontend-relativity';
import {CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {ctxGlobalDialog} from 'acng/messenger/context/global-dialog';
import {ctxSentMessage} from 'acng/messenger/context/message.js';

export default 'onswRegardCloseShop';
export const closeComponent: angular.IComponentOptions = {
  template: '<span class="box" ng-click="$ctrl.close()"></span>',
  controller: ['$element', controller],
};

ctxGlobalDialog[CTX_SUBSCRIBE_TAGNAME]('onsw-regard-close-shop');
ctxSentMessage[CTX_SUBSCRIBE_TAGNAME]('onsw-regard-close-shop');

function controller(
  this: angular.IController & {close(): void},
  $element: JQLite,
) {
  const element = $element[0];
  addClass(element, 'ons-item');
  this.close = () => removeClass(body, 'regard-shop-open');
  watch($element[0], this.close, ctxGlobalDialog, ctxSentMessage);
}
