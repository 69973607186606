import {popup} from '@acng/frontend-discovery';

angular.module('userPool')
  .directive('onswProfileImageCropper', ['http', '$location', '$translate', 'user', '$window', function (http, $location, $translate, user, $window) {

    return {
      scope: {
        image: '@'
      },
      templateUrl: '/template/userPool.profileImageEditor',
      link: function ($scope, element) {

        element.addClass('ons-form');

        const resizeObserver = new ResizeObserver(() => {
          $window.dispatchEvent(new Event('resize'));
        });
        resizeObserver.observe(element[0]);

        $scope.$on('$destroy', () => {
          resizeObserver.unobserve(element[0]);
        });

        var crop = null;

        $scope.crop = function (detail, format) {
          crop = {
            detail: detail,
            format: format
          };
        };
        $scope.busy = false;

        $scope.save = function () {
          $scope.busy = true;
          http().put('/api/userProfile/crop-info/' + $scope.image, crop).then(function () {
            $translate('userPool.imageUpload.cropSuccess').then(function (text) {
              // TODO turbo boost
              popup(element[0]).info(text);
              $location.path('/profile');
            }).finally(function () {
              $scope.busy = false;
            });
            user.trackActivity();
          });
        };

      }
    };

  }]);
